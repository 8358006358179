import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { DialogService, DialogButton } from 'src/app/services/dialog.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { Questionnaire, Settings } from 'src/app/models/questionnaire';
import { BackendService } from 'src/app/services/backend.service';
import { SwiperConfigInterface, SwiperScrollbarInterface, SwiperPaginationInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { QuestionnaireFormService } from 'src/app/services/questionnaire-form.service';
import { QuestionPage } from 'src/app/models/question-page';
import { Question } from 'src/app/models/question';
import { Membership } from 'src/app/models/membership';
import { QuestionnaireAnswer } from 'src/app/models/questionnaire-answer';
import { Router } from '@angular/router';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit, OnDestroy {
  authUser = null;
  observers = new Array<Subscription>();

  constructor(private localAuthService: LocalAuthService,
    private backend: BackendService,
    private router: Router,
    private dataSource: DataService) { }

  ngOnInit() {
    const observer = this.localAuthService.getAuthUser().subscribe(data => {
      this.authUser = data;
    });
    this.observers.push(observer);
    if (this.authUser.membershipId === null || this.authUser.membershipId === '') {
      this.router.navigate(['membership/questionnaire'], { skipLocationChange: false });
    }
  }

  ngOnDestroy(): void {
    this.observers.forEach((observer) => {
      observer.unsubscribe();
    });
  }
}


