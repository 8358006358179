import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AppComponent } from 'src/app/app.component';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AlertType } from 'src/app/services/alert.service';
import { AuthUser } from 'src/app/models/auth-user';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DialogButton } from 'src/app/services/dialog.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { environment } from 'src/environments/environment';

export interface Invite {
  id: number;
  title: string;
  message: string;
  recipientEmail: string
  recipientFirstname: string
  recipientLastname: string;
  sender: string
  status: string
  webUrl: string
  referralCode: string
  category: string;
  sendEmail: boolean
  createdAt: number
  updatedAt: number
}

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css']
})
export class InvitationComponent implements OnInit, OnDestroy {

  public observers: Array<Subscription> = new Array<Subscription>();
  public authUser: AuthUser;
  public invitationFormGroup: FormGroup;
  public isLoading = false
  public invitationList$: Observable<Invite[]> = new Observable<Invite[]>();
  public invitationListBehaviour: BehaviorSubject<Invite[]> = new BehaviorSubject([]);
  public invitationList: Invite[] = [];
  public searchInputFormControl: FormControl;

  @ViewChild('collapseBtn') collapseBtn: ElementRef;

  constructor(private backend: BackendService,
    public localAuthService: LocalAuthService,
    public appComponent: AppComponent,
    private appMaterialComponent: AppMaterialDesignModule,
    public alertDialog: AlertDialogComponent,
    private formBuilder: FormBuilder,
    public sidebarService: SidebarService,
    public router: Router) {
    this.invitationList$ = this.invitationListBehaviour.asObservable();
  }

  ngOnInit(): void {
    this.authUser = new AuthUser();
    this.observers.push(
      this.localAuthService.getAuthUser().subscribe(data => {
        this.authUser = data;
      })
    );

    this.invitationFormGroup = new FormGroup({
      title: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
      recipientEmail: new FormControl('', [Validators.required, Validators.email]),
      recipientFirstname: new FormControl('', [Validators.required]),
      recipientLastname: new FormControl('', [Validators.required]),
      sender: new FormControl('SPZ', [Validators.nullValidator]),
      status: new FormControl('PENDING', [Validators.nullValidator]),
      webUrl: new FormControl(environment.spz.pages.signup_with_referralcode,
        [Validators.nullValidator]),
      category: new FormControl('', [Validators.required]),
      //referralCode: new FormControl('', [Validators.nullValidator]),
      sendEmail: new FormControl(true, [Validators.nullValidator])
    })

    this.getInvitations();
  }

  public getInvitations() {
    this.isLoading = true;
    this.observers.push(
      this.backend.getInvitations().subscribe({
        next: (response) => {
          this.isLoading = false;
          if (response.code == '000') {
            this.invitationList = response.data;
            this.invitationListBehaviour.next(this.invitationList);
          } else {
            this.appMaterialComponent.showAlertToaster(AlertType.error, response.message, 3).subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete getInvitations');
        }
      })
    )
  }

  public sendInvite(invitation) {
    this.observers.push(
      this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
        width: '400px',
        title: 'Caution',
        message: 'Are you sure you want to send this invite?'

      }).pipe(switchMap(result => {
        if (result.button === DialogButton.ok) {
          this.isLoading = true;
          this.appMaterialComponent.showProgressDialog('Sending invite. Please wait...');
          return this.backend.sendInvitation(invitation);
        }
      })).subscribe({
        next: (response) => {
          this.appMaterialComponent.hideProgressDialog();
          this.isLoading = false;
          if (response.code == '000') {
            this.appMaterialComponent.showAlertToaster(AlertType.success, 'Invite sent out successfully.', 3).subscribe();
          } else {
            this.appMaterialComponent.showAlertToaster(AlertType.error, response.message, 3).subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete deleteInvitation');
        }
      })
    )
  }

  public deleteInvite(invitation) {
    this.observers.push(
      this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
        width: '400px',
        title: 'Caution',
        message: 'Are you sure you want to delete this invite?'

      }).pipe(switchMap(result => {
        if (result.button === DialogButton.ok) {
          this.isLoading = true;
          this.appMaterialComponent.showProgressDialog('Deleting invite. Please wait...');
          return this.backend.deleteInvitation(invitation.id);
        }
      })).subscribe({
        next: (response) => {
          this.appMaterialComponent.hideProgressDialog();
          this.isLoading = false;
          if (response.code == '000') {
            var index = this.invitationList.indexOf(invitation);
            this.invitationList.splice(index, 1);
            this.invitationListBehaviour.next(this.invitationList);
          } else {
            this.appMaterialComponent.showAlertToaster(AlertType.error, response.message, 3).subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete deleteInvitation');
        }
      })
    )
  }

  onSubmit(): void {
    this.isLoading = true;
    let formData = this.invitationFormGroup.value
    this.observers.push(this.backend.createInvitation(formData).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.invitationFormGroup.reset();
        if (response.code === '000') {
          this.collapseBtn.nativeElement.click();
          this.invitationList.unshift(response.data)
          this.invitationListBehaviour.next(this.invitationList)
          this.appMaterialComponent.showAlertToaster(AlertType.success, 'Invite sent out successfully', 3).subscribe();
        } else {
          this.appMaterialComponent.showAlertToaster(AlertType.error, response.message, 3).subscribe();
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        console.log('on complete createInvitation');
      }
    }));
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }

}
