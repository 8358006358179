import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.css'],
})
export class ContactInfoComponent implements OnInit, OnDestroy {
  private unSubscriptioNotifier = new Subject();
  @Input('contactInfo') contactInfo: any;
  public contactInfoFormGroup: FormGroup;
  public page = false;
  public isLoading = false;
  private authUser = null;
  constructor(
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    public appMaterialModule: AppMaterialDesignModule
  ) {
    this.authUser = localAuthService.getUser();
    this.contactInfoFormGroup = new FormGroup({
      primaryPhonenumber: new FormControl(''),
      alternatePhonenumber: new FormControl(''),
      workPhonenumber: new FormControl(''),
      currentLocation: new FormControl(''),
      personalEmail: new FormControl(''),
      workEmail: new FormControl(''),
      residentialAddress: new FormControl(''),
      workplaceAddress: new FormControl(''),
      user: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.contactInfoFormGroup.patchValue({
      primaryPhonenumber: this.contactInfo.primaryPhonenumber,
      alternatePhonenumber: this.contactInfo.alternatePhonenumber,
      workPhonenumber: this.contactInfo.workPhonenumber,
      currentLocation: this.contactInfo.currentLocation,
      personalEmail: this.contactInfo.personalEmail,
      workEmail: this.contactInfo.workEmail,
      residentialAddress: this.contactInfo.residentialAddress,
      workplaceAddress: this.contactInfo.workplaceAddress,
      user: this.authUser.id,
    });
  }

  public togglePage() {
    this.page = !this.page;
  }

  updateUserContact(): void {
    this.isLoading = true;
    let userContactFormData = this.contactInfoFormGroup.value;
    console.log('userContactFormData ' + JSON.stringify(userContactFormData));
    this.backend
      .updateUserContact(userContactFormData)
      .pipe(take(1))
      .subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.appMaterialModule
              .showAlertToaster(
                AlertType.success,
                'Profile information updated successfully',
                3000
              )
              .subscribe();
          } else {
            this.appMaterialModule
              .showAlertToaster(AlertType.error, response.message, 3)
              .subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.page = false;
          this.isLoading = false;
          console.log('on complete updateUserContact');
        },
      });
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next('');
    this.unSubscriptioNotifier.complete();
  }
}
