<div class="container-fluid section-header pb-5">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-dark pb-4">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="container-fluid">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <a class="navbar-brand" href="/startingpointzone/home">
                        <img alt="temp image" src="/assets/img/spz-logo.svg" class="d-inline-block align-middle mr-3">
                    </a>
                    <div class="nav-scroller navbar-nav navbar-left">
                        <nav class="nav d-flex justify-content-start">
                            <!-- <a class="p-2 nav-link" href="#home" routerLinkActive="sidenav-menu-active">Home</a> -->
                            <a class="p-2 nav-link" href="#about" routerLinkActive="sidenav-menu-active">About SPZ</a>
                            <a class="p-2 nav-link" href="#features" routerLinkActive="sidenav-menu-active">Features</a>
                            <a class="p-2 nav-link" routerLink="/membership"
                                routerLinkActive="sidenav-menu-active">Membership Plan</a>
                            <a class="p-2 nav-link" href="#spz-fellowship" routerLinkActive="sidenav-menu-active">SPZ
                                Fellowship</a>
                            <a class="p-2 nav-link" href="#spz-fellowship"
                                routerLinkActive="sidenav-menu-active">Resources</a>
                            <!-- <a [hidden]="isAuthenticated" class="p-2 nav-link home-login-btn"
                                routerLink="/login">Login</a> -->
                            <button [hidden]="!isAuthenticated" class="btn btn-outline-success" routerLink="/login"
                                type="submit">Login</button>
                        </nav>
                    </div>
                </div>
            </div>
        </nav>
        <div class="center-content pt-4 row m-0 d-flex align-items-center">
            <div class="col-md-6">
                <h1 style="font-size: 55px;" class="color-spz-green">Building careers <br>through Mentoring.</h1>
                <p style="font-size: 18px;" class="color-white">Starting Point Zone (SPZ) is a social enterprise
                    membership <br>platform providing members with inspiration for growth and <br>innovation through
                    mentorships & career opportunities.</p>
                <a href="#tour" class="spz-button-green nav-link mb-5">Take a tour</a>
            </div>
            <div class="col-md-6">
                <img alt="temp image" src="/assets/img/spz-lady-image.svg" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid section-2 pb-5">
    <div class="container">
        <div class="row d-flex align-items-center" style="padding-top: 80px;">
            <div class="col-md-6">
                <img alt="temp image" src="/assets/img/spz-man-image.svg" class="img-fluid mb-4">
            </div>
            <div class="col-md-6">
                <h1 style="font-size: 34px;" class="color-white">Helping to provide opportunities for <br>advancement in
                    careers.</h1>
                <p style="font-size: 15px; color: #00213D;" class="mt-3">Starting Point Zone (SPZ) wants to be of your
                    career development because it <br>provides opportunities for personal growth, job advancement, and
                    professional <br>development.</p>
                <div class="m-0 p-0 mt-3">
                    <div class="md-6">
                        <div class="d-flex justify-content-start align-items-center">
                            <img alt="temp image" src="/assets/icons/spz-mentorship-icon.svg"
                                class="d-inline-block align-middle">
                            <h4 style="font-size: 20px;" class="color-white">Mentorship</h4>
                        </div>
                        <p style="font-size: 15px;" class="mt-3">Help more professionals benefit from the <br>career
                            advice and guidance of
                            mentors.
                        </p>
                    </div>
                    <div class="md-6 ml-auto">
                        <div class="d-flex justify-content-start align-items-center">
                            <img alt="temp image" src="/assets/icons/spz-networking-icon.svg"
                                class="d-inline-block align-middle">
                            <h4 style="font-size: 20px;" class="color-white">Networking</h4>
                        </div>
                        <p style="font-size: 15px;" class="mt-3">Empower careers professionals with <br>career-defining
                            social capital..
                        </p>
                    </div>
                    <div class="md-6">
                        <div class="d-flex justify-content-start align-items-center">
                            <img alt="temp image" src="/assets/icons/spz-career-exploration-icon.svg"
                                class="d-inline-block align-middle">
                            <h4 style="font-size: 20px;" class="color-white">Career Exploration</h4>
                        </div>
                        <p style="font-size: 15px;" class="mt-3">Let learners discover not just careers but <br>the jobs
                            that will make them
                            happy..
                        </p>
                    </div>
                </div>
                <a href="#learnmore" class="spz-button-outline-white nav-link color-white mt-4">Learn more</a>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid section-3 pb-5">
    <div class="container">
        <div class="row d-flex justify-content-center" style="padding-top: 80px;">
            <div class="text-center mb-5">
                <h1 class="color-spz-green" style="font-size: 34px;">SPZ Fellowship Programme</h1>
                <p style="font-size: 15px;">SPZ fellowship is a nine months employability, leadership, and entrepreneur
                    development program for <br>young people who are committed to becoming productive economically
                    through
                    learning and creating <br>positive social change in their communities.</p>
            </div>
            <div class="col-md-6">
                <h3 class="color-spz-green">Highlights</h3>
                <ul class="pl-2 pb-3">
                    <li>Specialized seminars/training focusing on Vision & Goal, Financial</li>
                    <li>Management Principles, and Innovation.</li>
                    <li>Mentoring and Counselling.</li>
                    <li>Schools' Financial Literacy outreaches</li>
                    <li>Conferences and Competitions</li>
                    <li>Local, National, and International Developmental Research</li>
                    <li>Digital magazine production (Online/Mobile)</li>
                    <li>SPZ Talk shows feature interviewing different professional personalities.</li>
                    <li>Membership in Starting Point Zone.</li>
                </ul>
                <a href="#applyhere" class="spz-button-outline-green nav-link mt-4 mb-5">Interested? Apply here</a>
            </div>
            <div class="col-md-6">
                <img alt="temp image" src="/assets/img/spz-man-smile-image.svg" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid section-3 pb-5">
    <div class="container">
        <div class="row d-flex d-flex align-items-center" style="padding-top: 80px;">
            <!-- <img style="position: absolute; margin-top: 100px;" src="/assets/img/spz-getstarted-bg-image.svg" class="img-fluid"> -->
            <div class="col-md-6">
                <img alt="temp image" src="/assets/img/spz-getstarted-image.svg" class="img-fluid mb-3">
            </div>
            <div class="col-md-6">
                <h1 style="font-size: 34px;" class="color-spz-green">Get Started with SPZ</h1>
                <p class="mt-4 mb-4">See how Starting Point Zone transforms professional career <br>
                    readiness and mentorship engagement.</p>
                <a href="#getstarted" class="spz-button-green nav-link">Get started</a>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid section-4 pb-5">
    <div class="container">
        <div class="row" style="padding-top: 80px;">
            <div class="col-md-12 mb-4 d-flex justify-content-between">
                <div>
                    <h2 class="color-spz-green">Featured Posts</h2>
                    <p style="font-size: 15px;">Browse thought leadership, research, and other resources for higher
                        education.</p>
                </div>
                <!-- <a href="#viewall" class="spz-button-outline-black nav-link">View All</a> -->
            </div>
            <div class="col-md-4 mt-3" *ngFor="let news of newsArticles | async; let i=index;">
                <img alt="{{news.multimedia[2].caption}}" src="{{news.multimedia[1].url}}" class="img-fluid">
                <h6 class="color-spz-green mt-3" style="font-size: 15px;">{{news.title}}</h6>
                <p style="font-size: 12px;">{{news.abstract}}</p>
                <div class="d-flex justify-content-between align-items-center">
                    <small class="text-muted">{{news.published_date | date}}</small>
                    <a class="nav-link" style="font-size: 13px;" href="{{news.url}}">read more</a>
                </div>
            </div>
            <div class="col-md-12 mt-4 d-flex justify-content-center">
                <mat-spinner [hidden]="!isLoadingArticles" class="align-self-center" mode="indeterminate"
                    [diameter]="35" [strokeWidth]="2">
                </mat-spinner>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid section-footer pb-5 pt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img alt="temp image" src="/assets/img/spz-logo.svg" class="d-inline-block align-middle mr-3">
            </div>
            <div class="col-md-6">
                <p style="font-size: 15px;" class="color-white mt-4">Get updates from SPZ</p>
                <div>
                    <form [formGroup]="newsLetterFormGroup" name="newsLetterFormGroup" #newsLetterForm="ngForm"
                        (ngSubmit)="onSubscribeToNewsLetter()">
                        <div class="form-group">
                            <input formControlName="email" style="width: 100%;" type="text" class="form-control"
                                id="email" name="email" placeholder="Enter your E-mail" required minlength="5"
                                autocomplete="off"
                                [ngClass]="{ 'is-invalid':newsLetterForm.submitted && newsLetterFormGroup.get('email').hasError('required') }"
                                aria-describedby="emailHint">
                            <div *ngIf="newsLetterForm.submitted && newsLetterFormGroup.get('email').hasError('required')"
                                class="invalid-feedback animate__animated animate__bounceIn">
                                <small *ngIf="newsLetterFormGroup.get('email').hasError('required')" id="emailHint"
                                    class="form-text">email
                                    is
                                    required</small>
                                <small *ngIf="newsLetterFormGroup.get('email').hasError('minlength')" id="emailHint"
                                    class="form-text">Please
                                    provide a minimum length of 5 characters</small>
                                <small *ngIf="newsLetterFormGroup.get('email').hasError('email')">Please provide a valid
                                    email</small>
                            </div>
                        </div>
                        <div class="col-md-12 d-flex justify-content-center mt-3">
                            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate"
                                [diameter]="35" [strokeWidth]="2">
                            </mat-spinner>
                        </div>
                        <div class="d-flex justify-content-end" [hidden]="isLoading">
                            <button style="font-size: 15px;" type="submit" [hidden]="isLoading"
                                class="spz-button-outline-green color-white"
                                [disabled]="!newsLetterForm.valid">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12 col-lg-6 col-xs-12">
                <p style="font-size: 15px;" class="color-white">©2023 Starting Point Zone</p>
            </div>
            <div class="col-md-12 col-lg-6 col-xs-12">
                <div class="d-flex justify-content-start">
                    <a href="#" class="mr-3">
                        <img src="/assets/icons/spz-instagram-icon.svg">
                    </a>
                    <a href="#" class="mr-3">
                        <img src="/assets/icons/spz-linkedin-icon.svg">
                    </a>
                    <a href="#" class="mr-3">
                        <img src="/assets/icons/spz-facebook-icon.svg">
                    </a>
                    <a href="#">
                        <img src="/assets/icons/spz-x-icon.svg">
                    </a>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xs-12">
                <div class="d-flex justify-content-start mt-3">
                    <a href="#" style="font-size: 14px;" class="mr-2">Contact us</a>
                    <a href="#" style="font-size: 14px;" class="mr-2">Privacy policy</a>
                    <a href="#" style="font-size: 14px;" class="">Terms of use</a>
                </div>
            </div>
        </div>
    </div>
</div>