import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.css'],
})
export class AccountInfoComponent implements OnInit {
  public userPasswordFormGroup: FormGroup;
  public userAccountFormGroup: FormGroup;
  @Input('userAccountInfo') userAccountInfo: any = null;
  public isLoading = false;
  private authUser = null;
  private unSubscriptioNotifier = new Subject();
  public isUserPasswordFormCollapsed = true;
  public isUserAccountFormCollapsed = true;

  @ViewChild('invalidConfirmPassword') invalidConfirmPassword: ElementRef;
  @ViewChild('usernameCheckProgress') usernameCheckProgress: ElementRef;
  @ViewChild('usernameExistError') usernameExistError: ElementRef;
  @ViewChild('emailExistError') emailExistError: ElementRef;

  constructor(
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    public appMaterialModule: AppMaterialDesignModule
  ) {
    this.authUser = localAuthService.getUser();
    this.userPasswordFormGroup = new FormGroup({
      password: new FormControl('', [Validators.required]),
      newpassword: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    });

    this.userAccountFormGroup = new FormGroup({
      username: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.userAccountFormGroup.patchValue({
      username: this.userAccountInfo.username,
      email: this.userAccountInfo.email,
    });
  }
  updateUserAccount(): void {
    this.isLoading = true;
    let userAccountFormData = this.userAccountFormGroup.value;
    this.backend
      .updateUserAccount({
        userId: this.authUser.id,
        username: userAccountFormData.username,
        email: userAccountFormData.email,
      })
      .subscribe({
        next: (response: any) => {
          this.authUser.username = response.username;
          this.authUser.email = response.email;
          this.authUser.username = response.username;
          this.authUser.email = response.email;
          this.localAuthService.setUser(this.authUser);
          this.dataSource.setData(this.authUser);
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isUserAccountFormCollapsed = true;
          this.isLoading = false;
          console.log('on complete updateUserAccount');
        },
      });
  }

  updateUserPassowrd(): void {
    if (!this.onConfirmPassword()) {
      return;
    }
    let userPasswordFormData = this.userPasswordFormGroup.value;
    this.isLoading = true;
    this.backend
      .changeUserPassword({
        userId: this.authUser.id,
        password: userPasswordFormData.password,
        newpassword: userPasswordFormData.newpassword,
      })
      .pipe(take(1))
      .subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            //log user out
            this.localAuthService.logout();
            this.router.navigate(['login'], {
              queryParams: {},
              skipLocationChange: false,
            });
            this.appMaterialModule
              .showAlertToaster(
                AlertType.success,
                'Password changed successful. Please login with your new password',
                3000
              )
              .subscribe();
          } else {
            this.appMaterialModule
              .showAlertToaster(AlertType.error, response.message, 3)
              .subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.isUserPasswordFormCollapsed = true;
          console.log('on complete updateUserPassowrd');
        },
      });
  }

  public chechIfUsernameExist(): void {
    //check if username exist
    const t = this;
    let userAccountFormData = this.userAccountFormGroup.value;
    this.usernameCheckProgress.nativeElement.style.display = 'block';
    this.backend
      .chechIfUsernameExist({ username: userAccountFormData.username })
      .subscribe({
        next: (data: any) => {
          console.log(data);
          if (data.code !== '000') {
            t.usernameExistError.nativeElement.style.display = 'block';
            this.userAccountFormGroup.patchValue({
              username: '',
            });
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          t.usernameCheckProgress.nativeElement.style.display = 'none';
        },
        complete: () => {
          t.usernameCheckProgress.nativeElement.style.display = 'none';
          console.log('on complete chechIfUsernameExist');
        },
      });
  }

  chechIfEmailExist(): void {
    //check if username exist
    const t = this;
    this.usernameCheckProgress.nativeElement.style.display = 'block';
    let userAccountFormData = this.userAccountFormGroup.value;
    this.backend
      .chechIfUsernameExist({ username: userAccountFormData.email })
      .subscribe({
        next: (data: any) => {
          console.log(data);
          if (data.code !== '000') {
            t.emailExistError.nativeElement.style.display = 'block';
            this.userAccountFormGroup.patchValue({
              email: '',
            });
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          t.usernameCheckProgress.nativeElement.style.display = 'none';
        },
        complete: () => {
          t.usernameCheckProgress.nativeElement.style.display = 'none';
          console.log('on complete chechIfUsernameExist');
        },
      });
  }

  public onConfirmPassword(): boolean {
    if (
      this.userPasswordFormGroup.get('newpassword').value !==
      this.userPasswordFormGroup.get('confirmPassword').value
    ) {
      this.invalidConfirmPassword.nativeElement.style.display = 'block';
      return false;
    }
    return true;
  }

  public openInputDialog(dialog) {}
}
