import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class BasicInfoComponent implements OnInit, OnDestroy,OnChanges {
  private unSubscriptioNotifier = new Subject();
  @Input('basicInfo') basicInfo: any;
  public basicInfoFormGroup: FormGroup;
  public filteredCountries: Observable<string[]>;
  public page = false;
  public isLoading = false;
  private authUser = null;
  constructor(
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    public appMaterialModule: AppMaterialDesignModule
  ) {
    this.authUser = localAuthService.getUser();
    this.basicInfoFormGroup = new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      othernames: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      dateOfBirth: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      user: new FormControl('', [Validators.required]),
    });
  }

  public togglePage() {
    this.page = !this.page;
  }


  ngOnChanges(){
    this.basicInfoFormGroup.patchValue({
      firstname: this.basicInfo.firstname,
      lastname: this.basicInfo.lastname,
      othernames: this.basicInfo.othernames,
      gender: this.basicInfo.gender,
      dateOfBirth: this.basicInfo.dateOfBirth,
      country: this.basicInfo.country,
      user: this.authUser.id,
    });
  }

  ngOnInit(): void {
    

    this.filteredCountries = this.basicInfoFormGroup.valueChanges.pipe(
      startWith(''),
      takeUntil(this.unSubscriptioNotifier),
      debounceTime(100),
      distinctUntilChanged(),
      tap(),
      map((value) => this._filter(value || ''))
    );
  }

  public countries = new Array<any>();

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  public updateUserProfile(): void {
    this.isLoading = true;
    const userProfileFormData = this.basicInfoFormGroup.value;
    this.backend.updateUserProfile(userProfileFormData).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          let scope = {
            authUser: {
              profile: userProfileFormData,
            },
          };
          this.authUser.fullname =
            userProfileFormData.firstname + ' ' + userProfileFormData.lastname;
          this.localAuthService.setUser(this.authUser);
          this.dataSource.setData(scope.authUser);
          this.appMaterialModule
            .showAlertToaster(
              AlertType.success,
              'Profile information updated successfully',
              3000
            )
            .pipe(take(1))
            .subscribe();
        } else {
          this.appMaterialModule
            .showAlertToaster(AlertType.error, response.message, 3)
            .pipe(take(1))
            .subscribe();
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
      },
      complete: () => {
        this.page = false;
        this.isLoading = false;
        console.log('on complete updateUserProfile');
      },
    });
  }

  ngOnDestroy(): void {}
}
