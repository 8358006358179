export class Employment {
    public id: string;
    public user: string;
    public title: string;
    public description: string;
    public workplace: string;
    public startDate: string = '';
    public endDate: string = '';
    public isCurrentEmployment:string;
}
