import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Scope } from 'src/app/models/scope';
import { Subscription, Subject, Observable, merge, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { Education } from 'src/app/models/education';
import { SocialMediaAccount } from 'src/app/models/social-media-account';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { Profile } from 'src/app/models/profile';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { Contact } from 'src/app/models/contact';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { DialogButton } from 'src/app/services/dialog.service';
import { Employment } from 'src/app/models/employment';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';

export class UserAccountFormData {
  public username: string;
  public email: string;
}

export class userPasswordFormData {
  public password: string;
  public newpassword: string;
  public confirmPassword: string = '';
}

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.css']
})
export class ProfileDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  public isEdit: boolean = false;
  public isEdited: boolean = false;
  public observers: Array<Subscription> = new Array<Subscription>();
  public userId = '';
  public authUser = null;
  public userAccountFormData = new UserAccountFormData();
  public userPasswordFormData = new userPasswordFormData();
  public userProfileFormData = new Profile();
  public userContactFormData = new Contact();
  public userEducationFormData = new Education();
  public userEmploymentFormData = new Employment();
  public userSocialMediaAccountFormData = new SocialMediaAccount();
  public filteredCountries: Observable<string[]>;
  public isUserEducationFormCollapsed = true;
  public isUserEmploymentFormCollapsed = true;
  public isUserAccountFormCollapsed = true;
  public isUserProfileFormCollapsed = true;
  public isUserPasswordFormCollapsed = true;
  public isUserContactFormCollapsed = true;
  public isUserSocialMediaAccountFormCollapsed = true;
  public userDetails:any = null;
  public action: string;
  public otp: string;

  public imageChangedEvent: any = '';
  public croppedImage: any = '';

  public socialMediaAccount: SocialMediaAccount;
  public addOrEditText = 'Add';
  public isLoading = false;

  @ViewChild('startDate') datepicker: ElementRef;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  @ViewChild('invalidConfirmPassword') invalidConfirmPassword: ElementRef;
  @ViewChild('usernameCheckProgress') usernameCheckProgress: ElementRef;
  @ViewChild('usernameExistError') usernameExistError: ElementRef;
  @ViewChild('emailExistError') emailExistError: ElementRef;
  //@ViewChild('instance') instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  public countries = new Array<any>();

  datePickerOptions = {
    //format: "YYYY-MM-DD h:mm:ss a",
    format: "YYYY-MM-DD",
    //maxDate: moment(),
    //minDate: moment(),
    // ...
  };

  // search = (text$: Observable<string>) => {
  //   const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
  //   const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
  //   const inputFocus$ = this.focus$;
  //   return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
  //     map(term => (term === '' ? this.countries
  //       : this.countries.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10)
  //     ),
  //   );
  // }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(option => option.toLowerCase().includes(filterValue));
  }

  public onCountryChange($value) {
    this.filteredCountries = of(this._filter($value));
  }

  constructor(private localAuthService: LocalAuthService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private backend: BackendService,
    private dataSource: DataService,
    public appMaterialModule: AppMaterialDesignModule) {
    this.authUser = this.localAuthService.getUser();
    this.socialMediaAccount = new SocialMediaAccount();
  }

  ngAfterViewInit(): void {
    //this.datepicker.nativeElement.style.fontSize = '25px';
    //this.datepicker.nativeElement.placeholder = 'placeholder';
    //this.datepicker.nativeElement.setAttribute('data-date-start-date', '2007-12-02');
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(params => {
      this.userId = params.rp || '0';
      this.action = params.ac || '0';
      this.otp = params.otp || '0';
    });

    if (this.action === 'verifyemail') {
      console.log('verifyemail');
      this.verifyUserStatus({ userId: this.userId, otp: this.otp });
    } //else {
    //this.isEdit = this.router.url.includes('profile/edit');
    this.localAuthService.getAuthUser().subscribe(data => {
      this.authUser = data;
    });

    this.userId = this.authUser.id;
    this.getUserDetails(this.userId);
    this.getCountries();
    //}
  }

  verifyUserStatus(formData: any): void {
    this.observers.push(
      this.backend.verifyUserStatus(formData).subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            //reload the page
            this.router.navigate(['profile'], { queryParams: {}, skipLocationChange: false });
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        },
        complete: () => {
          console.log('on complete verifyUserStatus');
        }
      })
    );
  }

  getCountries() {
    this.observers.push(this.backend.getCountries().subscribe({
      next: (data: any) => {
        this.countries = data;
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete getUserDetails');
      }
    }));
  }

  getUserDetails(formData: string) {
    this.backend.getUserDetails3(formData).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          if (response.data.status !== 'verified') {
            //verify email
            this.appMaterialModule.showAlertToaster(AlertType.info, 'Verify your email. Please check your email to verify your account', 3).subscribe();
      
          }
          this.userDetails = response.data;

          //console.log('this.mentor : '+JSON.stringify(this.userDetails))
          this.authUser.email = response.data.email;
          this.userAccountFormData.email = this.userDetails.email;
          this.userAccountFormData.username = this.userDetails.username;
          this.userProfileFormData = this.userDetails.profile;
          this.userContactFormData = this.userDetails.profile.contact;
          this.dataSource.setData(this.userDetails);
          this.localAuthService.setUser(this.authUser);
        } else {

        }

      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete getUserDetails');
      }
    });
  }

  onDateSelected(input: string, value: string): void {
    if (input === 'dateOfBirth')
      this.userProfileFormData.dateOfBirth = value;
    else if (input === 'eduStartDate')
      this.userEducationFormData.startDate = value;
    else if (input === 'eduEndDate')
      this.userEducationFormData.endDate = value;
    else if (input === 'empStartDate')
      this.userEmploymentFormData.startDate = value;
    else if (input === 'empEndDate')
      this.userEmploymentFormData.endDate = value;
  }

  onCheckboxChanged(value, event) {
    if (event.target.checked)
      // this.userEmploymentFormData.isCurrentEmployment = event.target.checked;
      this.userEmploymentFormData.isCurrentEmployment = 'true';

    else
      this.userEmploymentFormData.isCurrentEmployment = '';
  }

  onSaveBio(): void {
    if (this.userDetails.profile.biography) {
      this.isLoading = true;
      this.observers.push(this.backend.updateUserProfileBiography({ userId: this.authUser.id, biography: this.userDetails.profile.biography }).subscribe({
        next: (response: any) => {
          this.userDetails.profile.biography = response.data.biography;
          this.dataSource.setData(this.userDetails);
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.croppedImage = '';
          this.imageChangedEvent = null;
          console.log('on complete updateUserProfileBiography');
        }
      }));

    }
  }

  deleteUserEducation(education: any): void {
    this.observers.push(this.appMaterialModule.openDialog(ConfirmDialogComponent, {
      width: '400px',
      title: 'Please confirm',
      message: 'Are sure you want to delete! This cannot be undone.'

    }).pipe(switchMap((result) => {
      if (result.button == DialogButton.ok) {
        this.appMaterialModule.showProgressDialog('Deleting...')
        return this.backend.deleteUserEducation(education);
      }
    })).subscribe({
      next: (response: any) => {
        this.dataSource.setData(response.data);
        this.isLoading = false;
        this.appMaterialModule.hideProgressDialog();
        if (response.code === '000') {
          var index = this.userDetails.profile.education.indexOf(education);
          this.userDetails.profile.education.splice(index, 1);
          var observer2 = this.appMaterialModule.showAlertToaster(AlertType.success, 'successfully deleted').subscribe();
          this.observers.push(observer2);
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
        this.appMaterialModule.hideProgressDialog();
      },
      complete: () => {
        this.isLoading = false;
        this.appMaterialModule.hideProgressDialog();
        console.log('on complete deleteUserEducation');
      }
    }));
  }

  deleteUserEmployment(employment: any): void {
    this.observers.push(
      this.appMaterialModule.openDialog(ConfirmDialogComponent, {
        width: '400px',
        title: 'Please confirm',
        message: 'Are sure you want to delete! This cannot be undone.'

      }).pipe(switchMap((result) => {
        if (result.button == DialogButton.ok) {
          this.appMaterialModule.showProgressDialog('Deleting...')
          return this.backend.deleteUserEmployment(employment);
        }
      })).subscribe({
        next: (response: any) => {
          this.dataSource.setData(response.data);
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
          if (response.code === '000') {
            var index = this.userDetails.profile.employment.indexOf(employment);
            this.userDetails.profile.employment.splice(index, 1);
            var observer2 = this.appMaterialModule.showAlertToaster(AlertType.success, 'successfully deleted').subscribe();
            this.observers.push(observer2);
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
        },
        complete: () => {
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
          console.log('on complete deleteUserEmployment');
        }
      }));
  }


  deleteUserSocialMediaAccount(socialMedia: any): void {

    this.observers.push(
      this.appMaterialModule.openDialog(ConfirmDialogComponent, {
        width: '400px',
        title: 'Please confirm',
        message: 'Are sure you want to delete! This cannot be undone.'

      }).pipe(switchMap((result) => {
        if (result.button == DialogButton.ok) {
          this.appMaterialModule.showProgressDialog('Deleting...')
          return this.backend.deleteUserSocialMediaAccount(socialMedia);
        }
      })).subscribe({
        next: (response: any) => {
          this.dataSource.setData(response.data);
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
          if (response.code === '000') {
            var index = this.userDetails.profile.socialMediaAccounts.indexOf(socialMedia);
            this.userDetails.profile.socialMediaAccounts.splice(index, 1);
            var observer2 = this.appMaterialModule.showAlertToaster(AlertType.success, 'successfully deleted').subscribe();
            this.observers.push(observer2);
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
        },
        complete: () => {
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
          console.log('on complete deleteUserEmployment');
        }
      }));
  }

  public onSaveUserEducation(): void {
    this.isLoading = true;
    if (this.isEdited) {
      this.observers.push(this.backend.updateUserEducation(this.userEducationFormData).subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            var temp = this.userDetails.profile.education.find((item) => {
              return item.id == this.userEducationFormData.id;
            });
            this.userDetails.profile.education.splice(this.userDetails.profile.education.indexOf(temp), 1);
            this.userDetails.profile.education.unshift(response.data);
          } else {
            this.appMaterialModule.showAlertToaster(AlertType.error, response.message, 3);
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.isUserEducationFormCollapsed = true;
          this.isEdited = false;
          this.userEducationFormData = new Education();
        },
        complete: () => {
          this.isLoading = false;
          this.isUserEducationFormCollapsed = true;
          this.isEdited = false;
          this.userEducationFormData = new Education();
          console.log('on complete updateUserEducation');
        }
      }));
    } else {
      this.userEducationFormData.user = this.authUser.id;
      console.log('userEducationFormData ' + JSON.stringify(this.userEducationFormData));
      this.observers.push(this.backend.createUserEducation(this.userEducationFormData).subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.dataSource.setData(this.userDetails);
            this.userDetails.profile.education.unshift(response.data);
          } else {
            this.appMaterialModule.showAlertToaster(AlertType.error, response.message, 3);
          }

        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.isUserEducationFormCollapsed = true;
          this.userEducationFormData = new Education();
        },
        complete: () => {
          this.isLoading = false;
          this.isUserEducationFormCollapsed = true;
          this.userEducationFormData = new Education();
          console.log('on complete createUserEducation');
        }
      }));
    }
  }


  public onSaveUserEmployment(): void {
    this.isLoading = true;
    console.log('isEdited ' + this.isEdited);
    if (this.isEdited) {
      this.observers.push(this.backend.updateUserEmployment(this.userEmploymentFormData).subscribe({
        next: (response: any) => {
          var temp = this.userDetails.profile.employment.find((item) => {
            return item.id == this.userEmploymentFormData.id;
          });
          this.userDetails.profile.employment.splice(this.userDetails.profile.employment.indexOf(temp), 1);
          this.userDetails.profile.employment.unshift(response.data);
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.isEdited = false;
          this.userEmploymentFormData = new Employment();
        },
        complete: () => {
          this.isLoading = false;
          this.isUserEmploymentFormCollapsed = true;
          this.isEdited = false;
          this.userEmploymentFormData = new Employment();
          console.log('on complete updateUserEmployment');
        }
      }));
    } else {
      this.userEmploymentFormData.user = this.authUser.id;
      console.log('userEmploymentFormData ' + JSON.stringify(this.userEmploymentFormData));
      this.observers.push(this.backend.createUserEmployment(this.userEmploymentFormData).subscribe({
        next: (response: any) => {
          this.dataSource.setData(this.userDetails);
          this.userDetails.profile.employment.unshift(response.data);
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.isUserEmploymentFormCollapsed = true;
          this.userEmploymentFormData = new Employment();
        },
        complete: () => {
          this.isLoading = false;
          this.isUserEmploymentFormCollapsed = true;
          this.userEmploymentFormData = new Employment();
          console.log('on complete createUserEmployment');
        }
      }));
    }
  }

  public onSaveUserSocialMediaAccount(): void {
    this.isLoading = true;
    console.log('isEdited ' + this.isEdited);
    if (this.isEdited) {
      this.observers.push(this.backend.updateUserSocialMediaAccount(this.userSocialMediaAccountFormData).subscribe({
        next: (response: any) => {
          var temp = this.userDetails.profile.socialMediaAccounts.find((item) => {
            return item.id == this.userSocialMediaAccountFormData.id;
          });
          this.userDetails.profile.socialMediaAccounts.splice(this.userDetails.profile.socialMediaAccounts.indexOf(temp), 1);
          this.userDetails.profile.socialMediaAccounts.unshift(response.data);
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.isUserSocialMediaAccountFormCollapsed = true;
          this.isEdited = false;
          this.userSocialMediaAccountFormData = new SocialMediaAccount();
        },
        complete: () => {
          this.isLoading = false;
          this.isUserSocialMediaAccountFormCollapsed = true;
          this.isEdited = false;
          this.userSocialMediaAccountFormData = new SocialMediaAccount();
          console.log('on complete updateUserSocialMediaAccount');
        }
      }));
    } else {
      this.userSocialMediaAccountFormData.user = this.authUser.id;
      console.log('userSocialMediaAccountFormData ' + JSON.stringify(this.userSocialMediaAccountFormData));
      this.observers.push(this.backend.createUserSocialMediaAccount(this.userSocialMediaAccountFormData).subscribe({
        next: (response: any) => {
          this.dataSource.setData(this.userDetails);
          this.userDetails.profile.socialMediaAccounts.unshift(response.data);
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.isUserSocialMediaAccountFormCollapsed = true;
          this.userSocialMediaAccountFormData = new SocialMediaAccount();
        },
        complete: () => {
          this.isLoading = false;
          this.isUserSocialMediaAccountFormCollapsed = true;
          this.userSocialMediaAccountFormData = new SocialMediaAccount();
          console.log('on complete createUserSocialMediaAccount');
        }
      }));
    }
  }

  public editEducation(edu, isEdited) {
    this.userEducationFormData = edu;
    this.addOrEditText = 'Edit';
    console.log('edited ' + JSON.stringify(edu));
    if (isEdited) {
      //call api to save editted
      this.addOrEditText = 'Add';
    }
  }

  public editSocialMediaAccount(media, isEdited) {
    this.socialMediaAccount = media;
    this.addOrEditText = 'Edit';
    console.log('edited ' + JSON.stringify(media));
    if (isEdited) {
      //call api to save editted
      this.addOrEditText = 'Add';
    }
  }
  public openFilePickerDialog() {

  }


  public fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    //console.log('base64 image '+this.croppedImage);
  }
  public imageLoaded() {
    // show cropper
  }
  public cropperReady() {
    // cropper ready
  }
  public loadImageFailed() {
    // show message
  }


  public onSaveProfileImage(): void {
    if (this.croppedImage.length > 20) {
      this.isLoading = true;
      this.observers.push(this.backend.updateUserProfileImage({ userId: this.authUser.id, image: this.croppedImage }).subscribe({
        next: (data: any) => {
          this.authUser.image = this.croppedImage;
          this.localAuthService.setUser(this.authUser);
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.croppedImage = '';
          this.imageChangedEvent = null;

          console.log('on complete updateUserProfileImage');
        }
      }));
    }
  }

  public onConfirmPassword(): boolean {
    if (this.userPasswordFormData.newpassword !== this.userPasswordFormData.confirmPassword) {
      this.invalidConfirmPassword.nativeElement.style.display = 'block';
      return false;
    }
    return true;
  }

  public chechIfUsernameExist(): void {
    //check if username exist
    const t = this;
    this.usernameCheckProgress.nativeElement.style.display = 'block';
    this.observers.push(this.backend.chechIfUsernameExist({ username: this.userAccountFormData.username }).subscribe({
      next: (data: any) => {
        console.log(data);
        if (data.code !== '000') {
          t.usernameExistError.nativeElement.style.display = 'block';
          t.userAccountFormData.username = '';
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        t.usernameCheckProgress.nativeElement.style.display = 'none';
      },
      complete: () => {
        t.usernameCheckProgress.nativeElement.style.display = 'none';
        console.log('on complete chechIfUsernameExist');
      }
    }));
  }

  chechIfEmailExist(): void {
    //check if username exist
    const t = this;
    this.usernameCheckProgress.nativeElement.style.display = 'block';
    this.observers.push(this.backend.chechIfUsernameExist({ username: this.userAccountFormData.email }).subscribe({
      next: (data: any) => {
        console.log(data);
        if (data.code !== '000') {
          t.emailExistError.nativeElement.style.display = 'block';
          t.userAccountFormData.email = '';
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        t.usernameCheckProgress.nativeElement.style.display = 'none';
      },
      complete: () => {
        t.usernameCheckProgress.nativeElement.style.display = 'none';
        console.log('on complete chechIfUsernameExist');
      }
    }));
  }

  updateUserAccount(): void {
    this.isLoading = true;
    this.observers.push(this.backend.updateUserAccount({ userId: this.authUser.id, username: this.userAccountFormData.username, email: this.userAccountFormData.email }).subscribe({
      next: (response: any) => {
        this.userDetails.username = response.username;
        this.userDetails.email = response.email;
        this.authUser.username = response.username;
        this.authUser.email = response.email;
        this.localAuthService.setUser(this.authUser);
        this.dataSource.setData(this.userDetails);
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
      },
      complete: () => {
        this.isUserAccountFormCollapsed = true;
        this.isLoading = false;
        console.log('on complete updateUserAccount');
      }
    }));
  }

  updateUserProfile(): void {
    this.isLoading = true;
    this.userProfileFormData.user = this.authUser.id;
    this.observers.push(this.backend.updateUserProfile(this.userProfileFormData).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          this.userDetails.profile = this.userProfileFormData
          this.authUser.fullname = this.userProfileFormData.firstname + ' ' + this.userProfileFormData.lastname;
          this.localAuthService.setUser(this.authUser);
          this.dataSource.setData(this.userDetails);
          this.appMaterialModule.showAlertToaster(AlertType.success, 'Profile information updated successfully', 3).subscribe();
        }
        else {
          this.appMaterialModule.showAlertToaster(AlertType.error, response.message, 3).subscribe();
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
      },
      complete: () => {
        this.isUserProfileFormCollapsed = true;
        this.isLoading = false;
        console.log('on complete updateUserProfile');
      }
    }));
  }

  updateUserContact(): void {
    this.isLoading = true;
    this.userContactFormData.user = this.authUser.id;
    console.log('userContactFormData ' + JSON.stringify(this.userContactFormData));
    this.observers.push(this.backend.updateUserContact(this.userContactFormData).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          this.userDetails.profile.contact = this.userContactFormData
          this.localAuthService.setUser(this.authUser);
          this.dataSource.setData(this.userDetails);
          this.appMaterialModule.showAlertToaster(AlertType.success, 'Profile information updated successfully', 3).subscribe();
        }
        else {
          this.appMaterialModule.showAlertToaster(AlertType.error, response.message, 3).subscribe();
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
      },
      complete: () => {
        this.isUserContactFormCollapsed = true;
        this.isLoading = false;
        console.log('on complete updateUserContact');
      }
    }));
  }

  updateUserPassowrd(): void {
    if (!this.onConfirmPassword()) {
      return;
    }
    this.isLoading = true;
    this.observers.push(this.backend.changeUserPassword({ userId: this.authUser.id, password: this.userPasswordFormData.password, newpassword: this.userPasswordFormData.newpassword }).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          //log user out
          this.localAuthService.logout();
          this.router.navigate(['login'], { queryParams: {}, skipLocationChange: false });
          this.appMaterialModule.showAlertToaster(AlertType.success, 'Password changed successful. Please login with your new password', 3).subscribe();
        } else {
          this.appMaterialModule.showAlertToaster(AlertType.error, response.message, 3).subscribe();
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        this.isUserPasswordFormCollapsed = true;
        console.log('on complete updateUserPassowrd');
      }
    }));
  }

  onResetPassword() {
    this.isLoading = true;
    console.log('send an email to ' + this.authUser.email + ' to reset password');
    this.observers.push(
      this.backend.createPasswordReset({ email: this.userAccountFormData.email }).subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            //send email
            //this.modalService.dismissAll();
            this.sendPasswordResetMail({ email: this.userAccountFormData.email, otp: response.data.otp });
          } else {
            this.appMaterialModule.showAlertToaster(AlertType.error, response.message, 3).subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.isUserPasswordFormCollapsed = true;
        },
        complete: () => {
          //this.isLoading = false;
          this.isUserPasswordFormCollapsed = true;
          console.log('on complete createPasswordReset');
        }
      })
    );
  }

  sendPasswordResetMail(data: any): void {
    this.observers.push(
      this.backend.sendPasswordResetMail(data).subscribe({
        next: (response: any) => {
          console.log(JSON.stringify(response));
          if (response.code === '000') {
            this.appMaterialModule.showAlertToaster(AlertType.info, 'Please check your email for the link to reset your password.', 3).subscribe();
          } else {
            this.appMaterialModule.showAlertToaster(AlertType.error, response.message, 3).subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        },
        complete: () => {
          this.isLoading = false;
          this.isUserPasswordFormCollapsed = true;
          console.log('on complete sendPasswordReset');
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }

}
