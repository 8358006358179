<div class="container-fluid">
    <div class="row">
        <div *ngIf="action !=='verifyemail'" class="col-md-12 col-sm-12" style="margin-top: 8px;">
            <div class="bio bg-white mat-elevation-z0" style="margin-bottom: 5px; padding: 12px;">
                <div class="title-text">
                    <svg class="icon-speech-user small primary">
                        <use xlink:href="/assets/icons/icons-sheet.svg#icon-speech-user"></use>
                    </svg> bio card
                    <p class="text-left subtitle-text" style="font-size: 14px;">{{scope.authUser.profile.biography}}</p>
                </div>
                <button type="button" style="margin-bottom: 10px; margin-top:10px;" (click)="openInputDialog(bioContent)" class="btn btn-default btn-sm">
                    Edit Bio</button>
            </div>

            <mat-tab-group dynamicHeight mat-align-tabs="start" animationDuration="500ms">
                <mat-tab label="Profile">
                    <div class="bio bg-white animate__animated animate__fadeIn" style="margin-bottom: 5px; padding: 12px;">
                        <div class="media">
                            <img src="{{authUser.image}}" class="profile-image-medium mr-3" alt="...">
                            <div class="media-body">
                                <h4 class="mt-0 title-text">{{authUser.fullname}}</h4>
                                <h5 class="subtitle-text">{{authUser.status}}</h5>
                                <button type="button" style="margin-bottom: 10px; margin-top:10px;" (click)="openFilePickerDialog()" class="btn btn-default btn-sm">
                                    Update
                                    <svg class="icon-camera-retro small white">
                                        <use xlink:href="/assets/icons/icons-sheet.svg#icon-camera-retro"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="bio bg-white animate__animated animate__fadeIn" style="margin-bottom: 5px; padding: 12px;">
                        <h4 class="title-text">Basic information</h4>
                        <!--<mat-divider></mat-divider> -->
                        <table class="table" style="width:100%">
                            <tr>
                                <th class="subtitle-text">Firstname</th>
                                <td class="title-text">{{userProfileFormData.firstname}}</td>
                            </tr>
                            <tr>
                                <th class="subtitle-text">Lastname</th>
                                <td class="title-text">{{userProfileFormData.lastname}}</td>
                            </tr>
                            <tr>
                                <th class="subtitle-text">Othernames</th>
                                <td class="title-text">{{userProfileFormData.othernames}}</td>
                            </tr>
                            <tr>
                                <th class="subtitle-text">Gender</th>
                                <td class="title-text">{{userProfileFormData.gender}}</td>
                            </tr>
                            <tr>
                                <th class="subtitle-text">Date of birth</th>
                                <td class="title-text">{{userProfileFormData.dateOfBirth}}</td>
                            </tr>
                            <tr>
                                <th class="subtitle-text">Country</th>
                                <td class="title-text">{{userProfileFormData.country}}</td>
                            </tr>
                        </table>
                        <button type="button" style="margin-bottom: 10px; margin-top:10px;" data-toggle="collapse" data-target="#userProfileForm" class="btn btn-default btn-sm">
                            Edit</button>

                        <form class="animate__animated animate__fadeIn collapse" id="userProfileForm" style="margin-top: 10px;" name="userProfileForm" #userProfileForm="ngForm" (ngSubmit)="userProfileForm.form.valid">
                            <div class="row" style="margin: 0;">
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="firstname">Firstname</p>
                                    <input [(ngModel)]="userProfileFormData.firstname" #firstname="ngModel" type="text" class="form-control" id="firstname" name="firstname" [ngClass]="{ 'is-invalid': userProfileForm.submitted
                                && firstname.invalid }" required aria-describedby="firstnameHint" autocomplete="off">

                                    <div *ngIf="userProfileForm.submitted && firstname.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="firstname.errors.required" id="firstnameHint" class="form-text">firstname is
                                            required</small>
                                        <!-- <div *ngIf="firstname.errors.required"></div> -->
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="lastname">Lastname</p>
                                    <input [(ngModel)]="userProfileFormData.lastname" #lastname="ngModel" type="text" class="form-control" id="lastname" name="lastname" [ngClass]="{ 'is-invalid': userProfileForm.submitted
                                && lastname.invalid }" required aria-describedby="lastnameHint" autocomplete="off">
                                    <div *ngIf="userProfileForm.submitted && lastname.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="lastname.errors.required" id="lastnameHint" class="form-text">lastname
                                            is
                                            required</small>
                                        <!-- <div *ngIf="lastname.errors.required"></div> -->
                                        <small *ngIf="lastname.errors.lastname">lastname must be a valid</small>
                                    </div>
                                </div>
                                <div class="form-group col-md-8">
                                    <p class="subtitle-text" for="othernames">Othernames</p>
                                    <input [(ngModel)]="userProfileFormData.othernames" #othernames="ngModel" type="text" class="form-control" id="othernames" name="othernames" [ngClass]="{ 'is-invalid': userProfileForm.submitted
                                && othernames.invalid }" required aria-describedby="othernamesHint" autocomplete="off">
                                    <div *ngIf="userProfileForm.submitted && othernames.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="othernames.errors.required" id="othernamesHint" class="form-text">othernames is
                                            required</small>
                                        <!-- <div *ngIf="othernames.errors.required"></div> -->
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <p class="subtitle-text" for="gender">Gender</p>
                                    <select class="form-control" [(ngModel)]="userProfileFormData.gender" #gender="ngModel" type="text" id="gender" name="gender" [ngClass]="{ 'is-invalid': userProfileForm.submitted && gender.invalid }" required aria-describedby="genderHint" minlength="3">
                                        <option disabled selected>Please select gender</option>
                                        <option value="male">male</option>
                                        <option value="female">female</option>
                                        <option value="N/A">N/A</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6 date">
                                    <p class="subtitle-text" for="dateOfBirth">Date of birth</p>
                                    <div class="input-group date">
                                        <input [(ngModel)]="userProfileFormData.dateOfBirth" #dateOfBirth="ngModel" type="text" [matDatepicker]="dateOfBirthPicker" class="form-control date" id="dateOfBirth" name="dateOfBirth" minlength="3" [options]="datePickerOptions" [ngClass]="{ 'is-invalid': userProfileForm.submitted && dateOfBirth.invalid }"
                                            autocomplete="off" aria-describedby="dateOfBirthHint">
                                        <mat-datepicker #dateOfBirthPicker></mat-datepicker>
                                        <div class="input-group-append">
                                            <div (click)="dateOfBirthPicker.open()" class="input-group-text"><i class="fa fa-calendar"></i></div>
                                        </div>
                                        <div *ngIf="userProfileForm.submitted && dateOfBirth.invalid" class="invalid-feedback animated bounceIn">
                                            <small *ngIf="dateOfBirth.errors.dateOfBirth">
                                                Please provide your date of birth</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="country">Country</p>
                                    <input [(ngModel)]="userProfileFormData.country" (change)="onCountryChange($event.target.value)" #country="ngModel" type="text" class="form-control" id="country" name="country" [matAutocomplete]="auto" [ngClass]="{ 'is-invalid': userProfileForm.submitted
                                && country.invalid }" required aria-describedby="countryHint" autocomplete="off">
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                                            {{country}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <div *ngIf="userProfileForm.submitted && country.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="country.errors.required" id="countryHint" class="form-text">country is
                                            required</small>
                                        <!-- <div *ngIf="country.errors.required"></div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin: 0;">
                                <div class="col-12">
                                    <button type="submit" style="margin-bottom: 10px;" (click)="updateUserProfile()" class="btn btn-default ml-auto pull-right">Save
                                        <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif"
                                            class="" width="24">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="bio bg-white animate__animated animate__fadeIn" style="margin-bottom: 5px; padding: 12px;">
                        <h4 class="title-text">Contact information</h4>
                        <svg class="icon-vcard small primary">
                            <use xlink:href="/assets/icons/icons-sheet.svg#icon-vcard"></use>
                        </svg>
                        <div class="row">
                            <div class="col">
                                <p class="subtitle-text">Primary phone number</p>
                                <p class="title-text">
                                    {{userContactFormData.primaryPhonenumber}}</p>
                                <mat-divider></mat-divider>

                                <p class="subtitle-text">Secondary phone number</p>
                                <p class="title-text">
                                    {{userContactFormData.alternatePhonenumber}}</p>
                                <mat-divider></mat-divider>

                                <p class="subtitle-text">Work phone number</p>
                                <p class="title-text">
                                    {{userContactFormData.workPhonenumber}}</p>
                                <mat-divider></mat-divider>

                                <p class="subtitle-text">Personal Email</p>
                                <p class="title-text">
                                    {{userContactFormData.personalEmail}}</p>
                                <!--<mat-divider></mat-divider> -->
                            </div>
                            <div class="col">
                                <p class="subtitle-text">Work Email</p>
                                <p class="title-text">
                                    {{userContactFormData.workEmail}}</p>
                                <mat-divider></mat-divider>

                                <p class="subtitle-text">Current Location
                                </p>
                                <p class="title-text">
                                    {{userContactFormData.currentLocation}}</p>
                                <mat-divider></mat-divider>

                                <p class="subtitle-text">Residential Address</p>
                                <p class="title-text">
                                    {{userContactFormData.residentialAddress}}</p>
                                <mat-divider></mat-divider>

                                <p class="subtitle-text">Workplace Address</p>
                                <address class="title-text">
                                    {{userContactFormData.workplaceAddress}}
                                </address>
                                <!--<mat-divider></mat-divider> -->
                            </div>
                        </div>
                        <button type="button" style="margin-bottom: 10px; margin-top:10px;" data-toggle="collapse" data-target="#userContactForm" class="btn btn-default btn-sm">
                            Edit</button>

                        <form class="collapse" name="userContactForm" id="userContactForm" #userContactForm="ngForm" (ngSubmit)="userContactForm.form.valid">
                            <div class="row" style="margin: 0;">
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="primaryPhonenumber">Primary phone number</p>
                                    <input autocomplete="off" [(ngModel)]="userContactFormData.primaryPhonenumber" #primaryPhonenumber="ngModel" type="text" class="form-control" id="primaryPhonenumber" name="primaryPhonenumber" minlength="3" [ngClass]="{ 'is-invalid': userContactForm.submitted && primaryPhonenumber.invalid }"
                                        aria-describedby="primaryPhonenumberHint" autocomplete="off">
                                    <div *ngIf="userContactForm.submitted && primaryPhonenumber.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="primaryPhonenumber.errors.primaryPhonenumber">
                                            Please provide a primary phone number</small>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="alternatePhonenumber">Alternate phone number</p>
                                    <input [(ngModel)]="userContactFormData.alternatePhonenumber" #alternatePhonenumber="ngModel" type="text" class="form-control" id="alternatePhonenumber" name="alternatePhonenumber" [ngClass]="{ 'is-invalid': userContactForm.submitted && alternatePhonenumber.invalid }"
                                        aria-describedby="alternatePhonenumberHint" minlength="3" autocomplete="off">
                                    <div *ngIf="userContactForm.submitted && alternatePhonenumber.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="alternatePhonenumber.errors.alternatePhonenumber">Please
                                            provide a
                                            valid alternate phone number</small>
                                    </div>
                                </div>
                            </div>

                            <div class="row" style="margin: 0;">
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="workPhonenumber">Work phone number</p>
                                    <input [(ngModel)]="userContactFormData.workPhonenumber" #workPhonenumber="ngModel" type="text" class="form-control" id="workPhonenumber" name="workPhonenumber" minlength="3" [ngClass]="{ 'is-invalid': userContactForm.submitted && workPhonenumber.invalid }"
                                        aria-describedby="workPhonenumberHint" autocomplete="off">
                                    <div *ngIf="userContactForm.submitted && workPhonenumber.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="workPhonenumber.errors.workPhonenumber">
                                            Please provide a work phone number</small>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="currentLocation">Current Location</p>
                                    <input [(ngModel)]="userContactFormData.currentLocation" #currentLocation="ngModel" type="text" class="form-control" id="currentLocation" name="currentLocation" [ngClass]="{ 'is-invalid': userContactForm.submitted && currentLocation.invalid }" aria-describedby="currentLocationHint"
                                        minlength="3" autocomplete="off">
                                    <div *ngIf="userContactForm.submitted && currentLocation.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="currentLocation.errors.currentLocation">Please provide a
                                            valid location</small>
                                    </div>
                                </div>
                            </div>

                            <div class="row" style="margin: 0;">
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="personalEmail">Personal email</p>
                                    <input [(ngModel)]="userContactFormData.personalEmail" #personalEmail="ngModel" type="email" class="form-control" id="personalEmail" name="personalEmail" minlength="3" [ngClass]="{ 'is-invalid': userContactForm.submitted && personalEmail.invalid }" aria-describedby="personalEmailHint"
                                        autocomplete="off">
                                    <div *ngIf="userContactForm.submitted && personalEmail.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="personalEmail.errors.personalEmail">
                                            Please provide a personal email</small>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="workEmail">Work email</p>
                                    <input [(ngModel)]="userContactFormData.workEmail" #workEmail="ngModel" type="email" class="form-control" id="workEmail" name="workEmail" [ngClass]="{ 'is-invalid': userContactForm.submitted && workEmail.invalid }" aria-describedby="workEmailHint" minlength="3"
                                        autocomplete="off">
                                    <div *ngIf="userContactForm.submitted && workEmail.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="workEmail.errors.workEmail">Please provide a
                                            valid work email</small>
                                    </div>
                                </div>
                            </div>

                            <div class="row" style="margin: 0;">
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="residentialAddress">Residential address</p>
                                    <textarea class="form-control" id="residentialAddress" rows="2" name="residentialAddress" [(ngModel)]="userContactFormData.residentialAddress" #residentialAddress="ngModel"></textarea>
                                    <div *ngIf="userContactForm.submitted && residentialAddress.invalid" class="invalid-feedback animated bounceIn" maxlength="255" [ngClass]="{ 'is-invalid': userContactForm.submitted && residentialAddress.invalid }" aria-describedby="residentialAddressHint">
                                        <small *ngIf="residentialAddress.errors.maxlength" id="residentialAddressHint" class="form-text">
                                            Please provide a maximum length of 255 characters</small>
                                        <small *ngIf="residentialAddress.errors.residentialAddress">Please provide a
                                            valid
                                            address</small>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="workplaceAddress">workplace address</p>
                                    <textarea class="form-control" id="workplaceAddress" rows="2" name="workplaceAddress" [(ngModel)]="userContactFormData.workplaceAddress" #workplaceAddress="ngModel"></textarea>
                                    <div *ngIf="userContactForm.submitted && workplaceAddress.invalid" class="invalid-feedback animated bounceIn" maxlength="255" [ngClass]="{ 'is-invalid': userContactForm.submitted && workplaceAddress.invalid }" aria-describedby="workplaceAddressHint">
                                        <small *ngIf="workplaceAddress.errors.maxlength" id="workplaceAddress" class="form-text">
                                            Please provide a maximum length of 255 characters</small>
                                        <small *ngIf="workplaceAddress.errors.workplaceAddress">Please provide a
                                            valid
                                            address</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin: 0;">
                                <div class="col-12">
                                    <button type="submit" style="margin-bottom: 10px;" (click)="updateUserContact()" class="btn btn-default ml-auto pull-right">Save
                                        <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif"
                                            class="" width="24">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="bg-white animate__animated animate__fadeIn" style="padding: 15px; margin-bottom: 10px;">
                        <h4 class="title-text">Social media links</h4>
                        <div style="margin-top:20px;">
                            <div *ngFor="let media of scope.authUser.profile.socialMediaAccounts">
                                <div class="media align-items-center" style="height: 50px;">
                                    <div class="media-left" style="padding: 4px;">
                                        <svg class="icon-social-media big primary">
                                            <use xlink:href="/assets/icons/icons-sheet.svg#icon-social-media"></use>
                                        </svg>
                                    </div>
                                    <div class="media-body row">
                                        <div class="col-sm-10">
                                            <p class="media-heading title-text" style="margin: 0;">{{media.title}}
                                            </p>
                                            <a class="link" href="{{media.linkToAccount}}" style="margin: 0; font-size: 13px;">{{media.username}}</a>
                                        </div>
                                        <div class="col-sm-2">
                                            <ul class="delete-and-edit-list">
                                                <li>
                                                    <span (click)="deleteUserSocialMediaAccount(media)" class="" style="margin: 0px;" class="pull-right">
                                                        <svg class="icon-cancel-circled small red clickable">
                                                            <use
                                                                xlink:href="/assets/icons/icons-sheet.svg#icon-cancel-circled">
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </li>
                                                <li class="divider-vertical"></li>
                                                <li>
                                                    <span (click)="isUserSocialMediaAccountFormCollapsed=! isUserSocialMediaAccountFormCollapsed; userSocialMediaAccountFormData=media; isEdited = true;" class="" style="margin: 0px;" class="pull-right">
                                                        <svg class="icon-pencil-square small primary clickable">
                                                            <use
                                                                xlink:href="/assets/icons/icons-sheet.svg#icon-pencil-square">
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div data-toggle="collapse" data-target="#userSocialMediaAccountForm" (click)="isEdited = false;" style="padding:0 10px 0 10px;">
                            <svg class="icon-plus-square-o big primary clickable">
                                <use xlink:href="/assets/icons/icons-sheet.svg#icon-plus-square-o">
                                </use>
                            </svg>
                            <small class="title-text">{{addOrEditText}} social media account</small>
                        </div>

                        <form class="collapse" id="userSocialMediaAccountForm" name="userSocialMediaAccountForm" style="margin-top: 10px;" #userSocialMediaAccountForm="ngForm" (ngSubmit)="userSocialMediaAccountForm.form.valid">
                            <div class="row" style="margin: 0;">
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="title">Social media name</p>
                                    <select autocomplete="off" [(ngModel)]="userSocialMediaAccountFormData.title" #title="ngModel" type="text" class="form-control" id="title" name="title" minlength="3" [ngClass]="{ 'is-invalid': userSocialMediaAccountForm.submitted && title.invalid }" aria-describedby="titleHint">
                                        <option disabled selected>Please select social media</option>
                                        <option value="FACEBOOK">Facebook</option>
                                        <option value="TWITTER">Twitter</option>
                                        <option value="GMAIL">G-Mail</option>
                                        <option value="LINKEDIN">Linked In</option>
                                        <option value="SKYPE">Skype</option>
                                        <option value="TELEGRAM">Telegram</option>
                                    </select>

                                    <div *ngIf="userSocialMediaAccountForm.submitted && title.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="title.errors.title">
                                            Please provide social media name</small>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="username">Social media account username</p>
                                    <input [(ngModel)]="userSocialMediaAccountFormData.username" #username="ngModel" type="text" class="form-control" id="username" name="username" minlength="3" [ngClass]="{ 'is-invalid': userSocialMediaAccountForm.submitted && username.invalid }" aria-describedby="usernameHint"
                                        autocomplete="off">
                                    <div *ngIf="userSocialMediaAccountForm.submitted && username.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="username.errors.username">
                                            Please provide {{socialMediaAccount.title}} username</small>
                                    </div>
                                </div>

                                <div class="form-group col-md-12">
                                    <p class="subtitle-text" for="linkToAccount">Link to account</p>
                                    <input autocomplete="off" [(ngModel)]="userSocialMediaAccountFormData.linkToAccount" #linkToAccount="ngModel" type="text" class="form-control" id="linkToAccount" name="linkToAccount" minlength="3" [ngClass]="{ 'is-invalid': userSocialMediaAccountForm.submitted && linkToAccount.invalid }"
                                        aria-describedby="linkToAccountHint">
                                    <div *ngIf="userSocialMediaAccountForm.submitted && linkToAccount.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="linkToAccount.errors.linkToAccount">
                                            Please provide link to {{userSocialMediaAccountFormData.title}}
                                            account</small>
                                    </div>
                                </div>
                                <div class="col">
                                    <button type="submit" style="margin-bottom: 10px;" class="btn btn-default ml-auto pull-right" (click)="onSaveUserSocialMediaAccount()" [disabled]="!userSocialMediaAccountForm.form.valid">Save
                                        <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif"
                                            class="" width="24">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab label="Account">
                    <div class="bg-white animate__animated animate__fadeIn" style="margin-bottom: 5px; padding: 12px;">
                        <h4 class="title-text">Account information</h4>
                        <!--<mat-divider></mat-divider> -->
                        <table class="table" style="width:100%">
                            <tr>
                                <th class="subtitle-text">Username</th>
                                <td class="title-text">{{userAccountFormData.username}}</td>
                                <td class=""><span data-toggle="collapse" data-target="#userAccountForm" role="button" (click)="isUserPasswordFormCollapsed=true" class="" style="margin: 0px;" class="pull-right">
                                        <svg class="icon-edit-modify-streamline small primary clickable">
                                            <use xlink:href="/assets/icons/icons-sheet.svg#icon-edit-modify-streamline">
                                            </use>
                                        </svg>
                                    </span></td>
                            </tr>
                            <tr>
                                <th class="subtitle-text">Email</th>
                                <td class="title-text">{{userAccountFormData.email}}</td>
                                <td class=""><span data-toggle="collapse" data-target="#userAccountForm" role="button" (click)="isUserPasswordFormCollapsed=false" class="" style="margin: 0px;" class="pull-right">
                                        <svg class="icon-edit-modify-streamline small primary clickable">
                                            <use xlink:href="/assets/icons/icons-sheet.svg#icon-edit-modify-streamline">
                                            </use>
                                        </svg>
                                    </span></td>
                            </tr>

                        </table>

                        <form id="userAccountForm" class="animate__animated animate__fadeIn collapse" style="margin-top: 10px;" name="userAccountForm" #userAccountForm="ngForm" (ngSubmit)="userAccountForm.form.valid">
                            <div class="row" style="margin: 0;">
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="username">Username</p>
                                    <div class="input-group">
                                        <input [(ngModel)]="userAccountFormData.username" #username="ngModel" (blur)="chechIfUsernameExist()" type="text" class="form-control" id="username" name="username" [ngClass]="{ 'is-invalid': userAccountForm.submitted
                            && username.invalid }" required aria-describedby="emailHint" autocomplete="off">
                                        <div class="input-group-append">
                                            <div #usernameCheckProgress class="input-group-text" style="background: transparent !important; display: none;">
                                                <img alt="temp image" src="/assets/img/ripple.gif" class="" width="20">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="userAccountForm.submitted && username.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="username.errors.required" id="usernameHint" class="form-text">username is
                                            required</small>
                                        <!-- <div *ngIf="username.errors.required"></div> -->
                                        <small *ngIf="username.errors.username">this username has been
                                            taken</small>
                                    </div>
                                    <small #usernameExistError style="display: none;" class="invalid-feedback animated bounceIn form-text">this username has been
                                        taken</small>
                                </div>
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="email">Email address</p>
                                    <input [(ngModel)]="userAccountFormData.email" #email="ngModel" type="email" (blur)="chechIfEmailExist()" class="form-control" id="email" name="email" [ngClass]="{ 'is-invalid': userAccountForm.submitted
                            && email.invalid }" required email aria-describedby="emailHint" autocomplete="off">
                                    <div *ngIf="userAccountForm.submitted && email.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="email.errors.required" id="emailHint" class="form-text">Email
                                            is
                                            required</small>
                                        <!-- <div *ngIf="email.errors.required"></div> -->
                                        <small *ngIf="email.errors.email">Email must be a valid email
                                            address</small>
                                    </div>
                                    <small #emailExistError style="display: none;" class="invalid-feedback animated bounceIn form-text">this email has been
                                        taken</small>
                                </div>
                            </div>
                            <div class="row" style="margin: 0;">
                                <div class="col-12">
                                    <button type="submit" style="margin-bottom: 10px;" (click)="updateUserAccount()" class="btn btn-default ml-auto pull-right">Save
                                        <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif"
                                            class="" width="24">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="bg-white animate__animated animate__fadeIn" style="margin-bottom: 5px; padding: 12px;">
                        <div class="media" style="height: 50px; margin-bottom: 10px;">
                            <div class="media-left mr-3" style="padding: 4px;">
                                <svg class="icon-key big primary clickable">
                                    <use xlink:href="/assets/icons/icons-sheet.svg#icon-key">
                                    </use>
                                </svg>
                            </div>
                            <div class="media-body row">
                                <div class="col-sm-10">
                                    <p class="media-heading title-text" style="margin: 0;">
                                        Change password</p>
                                    <p class="subtitle-text" style="margin: 0; font-size: 13px;">
                                        It's a good idea to use a strong password that you're not using elsewhere
                                    </p>
                                </div>
                                <div class="col-sm-2">
                                    <ul class="delete-and-edit-list">
                                        <li>
                                            <span class="" style="margin: 0px;" class="mr-auto">
                                                <button data-toggle="collapse" data-target="#userPasswordForm"
                                                    type="button" style="margin-bottom: 10px; margin-top:10px;"
                                                    class="btn btn-default btn-sm">
                                                    Edit</button>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <form id="userPasswordForm" class="animate__animated animate__fadeIn collapse" style="margin-top: 10px;" name="userPasswordForm" #userPasswordForm="ngForm" (ngSubmit)="userPasswordForm.form.valid">
                            <div class="row" style="margin: 0;">
                                <div class="form-group col-md-4">
                                    <p class="subtitle-text" for="password">Current password</p>
                                    <input [(ngModel)]="userPasswordFormData.password" #password="ngModel" type="password" class="form-control" [ngClass]="{ 'is-invalid': userPasswordForm.submitted && password.invalid }" id="password" name="password" required minlength="8" password aria-describedby="passwordHint"
                                        autocomplete="off">
                                    <div *ngIf="userPasswordForm.submitted && password.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="password.errors.required" id="passwordHint" class="form-text">password is
                                            required</small>
                                        <small *ngIf="password.errors.minlength" id="passwordHint" class="form-text">Please
                                            provide a minimum length of 8 characters</small>
                                        <small *ngIf="password.errors.password">Please provide a valid
                                            password</small>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <p class="subtitle-text" for="password">New password</p>
                                    <input [(ngModel)]="userPasswordFormData.newpassword" #newpassword="ngModel" type="password" class="form-control" [ngClass]="{ 'is-invalid': userPasswordForm.submitted && newpassword.invalid }" id="newpassword" name="newpassword" required minlength="8"
                                        password aria-describedby="newpasswordHint" autocomplete="off">
                                    <div *ngIf="userPasswordForm.submitted && password.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="newpassword.errors.required" id="newpasswordHint" class="form-text">password is
                                            required</small>
                                        <small *ngIf="newpassword.errors.minlength" id="newpasswordHint" class="form-text">Please
                                            provide a minimum length of 8 characters</small>
                                        <small *ngIf="newpassword.errors.password">Please provide a valid
                                            password</small>
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <p class="subtitle-text" for="confirmPassword">Confirm password</p>
                                    <input [(ngModel)]="userPasswordFormData.confirmPassword" #confirmPassword="ngModel" type="password" class="form-control" [ngClass]="{ 'is-invalid': userPasswordForm.submitted && confirmPassword.invalid
                            }" id="confirmPassword" name="confirmPassword" confirmPassword required minlength="8" autocomplete="off">
                                    <div *ngIf="userPasswordForm.submitted && confirmPassword.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="confirmPassword.errors.required" id="confirmPasswordHint" class="form-text">Please confirm password</small>
                                        <small *ngIf="confirmPassword.errors.minlength" id="confirmPasswordHint" class="form-text">Please provide a minimum length of 8
                                            characters</small>
                                        <small *ngIf="confirmPassword.errors.confirmPassword">Please provide a
                                            valid
                                            password</small>
                                    </div>
                                    <small #invalidConfirmPassword style="display: none;" class="invalid-feedback animated bounceIn form-text">Please be sure both
                                        passwords are the same</small>
                                </div>

                                <div class="form-group col-md-6">
                                    <span class="link" (click)="openInputDialog(resetPasswordDialog)">forgotten
                                        password?</span>
                                </div>
                                <div class="col-md-6">
                                    <button type="submit" style="margin-bottom: 10px;" (click)="updateUserPassowrd()" class="btn btn-default ml-auto pull-right">Save
                                        <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif"
                                            class="" width="24">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab label="School & Education">
                    <div class="bg-white animate__animated animate__fadeIn" style="padding: 15px; margin-bottom: 10px;">
                        <h4 class="title-text">School and Education</h4>
                        <div style="margin-top: 20px;">
                            <div *ngFor="let edu of scope.authUser.profile.education" style="padding: 10px 0 10px 0;">
                                <div class="media align-items-center" style="height: 50px; margin-bottom: 10px;">
                                    <div class="media-left mr-3" style="padding: 4px;">
                                        <i class="fa fa-institution fa-fw fa-2x color-primary"></i>
                                    </div>
                                    <div class="media-body row">
                                        <div class="col-sm-10">
                                            <p class="media-heading title-text" style="margin: 0;">
                                                {{edu.institutionName}}</p>
                                            <p class="subtitle-text" style="margin: 0; font-size: 13px;">
                                                {{edu.fieldOfStudy}}
                                            </p>
                                            <mat-divider></mat-divider>
                                            <small class="text-muted">{{edu.startDate}} - {{edu.endDate}}</small>
                                        </div>
                                        <div class="col-sm-2">
                                            <ul class="delete-and-edit-list">
                                                <li>
                                                    <span (click)="deleteUserEducation(edu)" class="" style="margin: 0px;" class="pull-right">
                                                        <svg class="icon-cancel-circled small red clickable">
                                                            <use
                                                                xlink:href="/assets/icons/icons-sheet.svg#icon-cancel-circled">
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </li>
                                                <li class="divider-vertical"></li>
                                                <li>
                                                    <span data-toggle="collapse" data-target="#userEducationForm" (click)="userEducationFormData=edu; isEdited = true;" class="" style="margin: 0px;" class="mr-auto">
                                                        <svg class="icon-pencil-square small primary clickable">
                                                            <use
                                                                xlink:href="/assets/icons/icons-sheet.svg#icon-pencil-square">
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!--<mat-divider></mat-divider> -->
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div data-toggle="collapse" data-target="#userEducationForm" (click)="isEdited = false;" style="padding:0 10px 0 10px;">
                            <svg class="icon-plus-square-o big primary clickable">
                                <use xlink:href="/assets/icons/icons-sheet.svg#icon-plus-square-o">
                                </use>
                            </svg>
                            <small class="title-text">{{addOrEditText}} education details</small>
                        </div>
                        <mat-divider></mat-divider>
                        <form id="userEducationForm" class="animate__animated animate__fadeIn collapse" style="margin-top: 10px;" name="userEducationForm" #userEducationForm="ngForm" (ngSubmit)="userEducationForm.form.valid">
                            <div class="row" style="margin: 0;">
                                <div class="form-group col-md-12">
                                    <p class="subtitle-text" for="institutionName">Institution</p>
                                    <input [(ngModel)]="userEducationFormData.institutionName" #institutionName="ngModel" type="text" class="form-control" id="institutionName" name="institutionName" minlength="3" [ngClass]="{ 'is-invalid': userEducationForm.submitted
                            && institutionName.invalid }" aria-describedby="institutionNameHint" autocomplete="off">
                                    <div *ngIf="userEducationForm.submitted && institutionName.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="institutionName.errors.institutionName">
                                            Please provide an institution name</small>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="fieldOfStudy">what did you study?</p>
                                    <input autocomplete="off" [(ngModel)]="userEducationFormData.fieldOfStudy" #fieldOfStudy="ngModel" type="text" class="form-control" id="fieldOfStudy" name="fieldOfStudy" minlength="3" [ngClass]="{ 'is-invalid': userEducationForm.submitted
                            && fieldOfStudy.invalid }" aria-describedby="fieldOfStudyHint" autocomplete="off">
                                    <div *ngIf="userEducationForm.submitted && fieldOfStudy.invalid" class="invalid-feedback animated bounceIn">
                                        <small class="text-muted" *ngIf="fieldOfStudy.errors.fieldOfStudy">
                                            Please provide the course you studied</small>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="certificate">Certificate awarded</p>
                                    <input autocomplete="off" [(ngModel)]="userEducationFormData.certificate" #certificate="ngModel" type="text" class="form-control" id="certificate" name="certificate" minlength="3" [ngClass]="{ 'is-invalid': userEducationForm.submitted
                            && certificate.invalid }" aria-describedby="certificateHint">
                                    <div *ngIf="userEducationForm.submitted && certificate.invalid" class="invalid-feedback animated bounceIn">
                                        <small *ngIf="certificate.errors.certificate">
                                            Please provide name of the certificate awarded</small>
                                    </div>
                                </div>

                                <div class="form-group col-md-6 date">
                                    <p class="subtitle-text" for="startDate">Start date</p>
                                    <div class="input-group date" data-target-input="nearest" NgTempusdominusBootstrap>
                                        <input autocomplete="off" [(ngModel)]="userEducationFormData.startDate" #startDate="ngModel" type="text" [matDatepicker]="educationStartDatePicker" class="form-control date" id="startDate" name="startDate" minlength="3" [ngClass]="{ 'is-invalid': userEducationForm.submitted && startDate.invalid }"
                                            aria-describedby="startDateHint">
                                        <mat-datepicker #educationStartDatePicker></mat-datepicker>
                                        <div class="input-group-append">
                                            <div (click)="educationStartDatePicker.open()" class="input-group-text"><i class="fa fa-calendar"></i></div>
                                        </div>
                                        <div *ngIf="userEducationForm.submitted && startDate.invalid" class="invalid-feedback animated bounceIn">
                                            <small *ngIf="startDate.errors.startDate">
                                                Please provide the start date</small>
                                        </div>

                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="endDate">Completed date</p>
                                    <div class="input-group date">
                                        <input autocomplete="off" [(ngModel)]="userEducationFormData.endDate" #endDate="ngModel" type="text" [matDatepicker]="educationEndDatePicker" class="form-control date" id="endDate" name="endDate" minlength="3" [ngClass]="{ 'is-invalid': userEducationForm.submitted && endDate.invalid }"
                                            aria-describedby="endDateHint">
                                        <mat-datepicker #educationEndDatePicker></mat-datepicker>
                                        <div class="input-group-append">
                                            <div (click)="educationEndDatePicker.open()" class="input-group-text"><i class="fa fa-calendar"></i></div>
                                        </div>
                                        <div *ngIf="userEducationForm.submitted && endDate.invalid" class="invalid-feedback animated bounceIn">
                                            <small *ngIf="endDate.errors.endDate">
                                                Please provide the completed date</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <button type="submit" style="margin-bottom: 10px;" class="btn btn-default ml-auto pull-right" (click)="onSaveUserEducation()" [disabled]="!userEducationForm.form.valid">Save
                                        <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif"
                                            class="" width="24">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab label="Experiences & Employment">
                    <div class="bg-white animate__animated animate__fadeIn" style="padding: 15px; margin-bottom: 10px;">
                        <h4 class="title-text">Employment details</h4>
                        <!--<mat-divider></mat-divider> -->
                        <div style="margin-top: 20px;">
                            <div *ngFor="let job of scope.authUser.profile.employment" style="padding: 10px 0 10px 0;">
                                <div class="media align-items-center" style="height: 50px; margin-bottom: 10px;">
                                    <div class="media-left mr-3" style="padding: 4px;">
                                        <svg class="icon-engineer big primary">
                                            <use xlink:href="/assets/icons/icons-sheet.svg#icon-engineer"></use>
                                        </svg>
                                    </div>
                                    <div class="media-body row">
                                        <div class="col-sm-10">
                                            <p class="media-heading title-text" style="margin: 0;">{{job.title}}</p>
                                            <p class="subtitle-text" style="margin: 0; font-size: 13px;">
                                                {{job.workplace}}</p>
                                            <p *ngIf="job.isCurrentEmployment === 'true'" class="subtitle-text" style="font-size: 13px;">
                                                {{job.startDate}} - till date</p>
                                            <p *ngIf="job.isCurrentEmployment === ''" class="subtitle-text" style="font-size: 13px;">
                                                {{job.startDate}} - {{job.endDate}}</p>
                                        </div>
                                        <div class="col-sm-2">
                                            <ul class="delete-and-edit-list">
                                                <li>
                                                    <span (click)="deleteUserEmployment(job)" class="" style="margin: 0px;" class="pull-right">
                                                        <svg class="icon-cancel-circled small red clickable">
                                                            <use
                                                                xlink:href="/assets/icons/icons-sheet.svg#icon-cancel-circled">
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </li>
                                                <li class="divider-vertical"></li>
                                                <li>
                                                    <span data-toggle="collapse" data-target="#userEmploymentForm" (click)="userEmploymentFormData=job; isEdited = true;" class="" style="margin: 0px;" class="pull-right">
                                                        <svg class="icon-pencil-square small primary clickable">
                                                            <use
                                                                xlink:href="/assets/icons/icons-sheet.svg#icon-pencil-square">
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div data-toggle="collapse" data-target="#userEmploymentForm" style="padding:0 10px 0 10px;">
                            <svg class="icon-plus-square-o big primary clickable">
                                <use xlink:href="/assets/icons/icons-sheet.svg#icon-plus-square-o">
                                </use>
                            </svg>
                            <small class="title-text">{{addOrEditText}} employment details</small>
                        </div>
                        <mat-divider></mat-divider>
                        <form id="userEmploymentForm" class="animate__animated animate__fadeIn collapse" style="margin-top: 10px;" name="userEmploymentForm" #userEmploymentForm="ngForm" (ngSubmit)="userEmploymentForm.form.valid">
                            <div class="row" style="margin: 0;">
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="title">Job title</p>
                                    <input autocomplete="off" [(ngModel)]="userEmploymentFormData.title" #title="ngModel" type="text" class="form-control" id="title" name="title" minlength="3" [ngClass]="{ 'is-invalid': userEmploymentForm.submitted && title.invalid
                            }" aria-describedby="titleHint" required>
                                    <div *ngIf="userEmploymentForm.submitted && title.invalid" class="invalid-feedback animated bounceIn">
                                        <small class="text-muted" *ngIf="title.errors.title">
                                            Please provide a job title</small>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="workplace">Workplace</p>
                                    <input autocomplete="off" [(ngModel)]="userEmploymentFormData.workplace" #workplace="ngModel" type="text" class="form-control" id="workplace" name="workplace" minlength="3" [ngClass]="{ 'is-invalid': userEmploymentForm.submitted
                            && workplace.invalid }" aria-describedby="workplaceHint" required>
                                    <div *ngIf="userEmploymentForm.submitted && workplace.invalid" class="invalid-feedback animated bounceIn">
                                        <small class="text-muted" *ngIf="workplace.errors.workplace">
                                            Please provide workplace</small>
                                    </div>
                                </div>

                                <div class="form-group col-md-12">
                                    <p class="subtitle-text" for="description">Job description</p>
                                    <textarea class="form-control" id="description" rows="2" name="description" [(ngModel)]="userEmploymentFormData.description" #description="ngModel"></textarea>
                                    <div *ngIf="userEmploymentForm.submitted && description.invalid" class="invalid-feedback animated bounceIn" maxlength="255" [ngClass]="{ 'is-invalid': userEmploymentForm.submitted && description.invalid }" aria-describedby="descriptionHint">
                                        <small *ngIf="description.errors.maxlength" id="description" class="form-text">
                                            Please provide a maximum length of 255 characters</small>
                                        <small class="text-muted" *ngIf="description.errors.description">
                                            Please provide a description</small>
                                    </div>
                                </div>
                                <div class="form-group col-md-6 date">
                                    <p class="subtitle-text" for="startDateAndTime">Start Date</p>
                                    <div class="input-group date" data-target-input="nearest">
                                        <input (ngModelChange)="onDateSelected('empStartDate',startDate.value)" autocomplete="off" [(ngModel)]="userEmploymentFormData.startDate" #startDate="ngModel" [matDatepicker]="startDateAndTimePicker" type="text" class="form-control date" id="startDate"
                                            name="startDate" minlength="3" [ngClass]="{ 'is-invalid': userEmploymentForm.submitted && startDate.invalid }" aria-describedby="startDateHint">
                                        <mat-datepicker #startDateAndTimePicker></mat-datepicker>
                                        <div class="input-group-append">
                                            <div (click)="startDateAndTimePicker.open()" class="input-group-text"><i class="fa fa-calendar"></i></div>
                                        </div>
                                        <div *ngIf="userEmploymentForm.submitted && startDate.invalid" class="invalid-feedback animated bounceIn">
                                            <small class="text-muted" *ngIf="startDate.errors.startDate">
                                                Please provide the start date</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-6">
                                    <p class="subtitle-text" for="endDate">End date</p>
                                    <div class="input-group date">
                                        <input autocomplete="off" [(ngModel)]="userEmploymentFormData.endDate" #endDate="ngModel" type="text" [matDatepicker]="endDateAndTimePicker" class="form-control date" id="endDate" name="endDate" minlength="3" [options]="datePickerOptions" (ngModelChange)="onDateSelected('empEndDate',endDate.value)"
                                            [ngClass]="{ 'is-invalid': userEmploymentForm.submitted && endDate.invalid }" aria-describedby="endDateHint">
                                        <mat-datepicker #endDateAndTimePicker></mat-datepicker>
                                        <div class="input-group-append">
                                            <div (click)="endDateAndTimePicker.open()" class="input-group-text"><i class="fa fa-calendar"></i></div>
                                        </div>
                                        <div *ngIf="userEmploymentForm.submitted && endDate.invalid" class="invalid-feedback animated bounceIn">
                                            <small *ngIf="endDate.errors.endDate">
                                                Please provide the end date</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="form-check form-check-inline">
                                        <input value="{{userEmploymentFormData.isCurrentEmployment}}" class="form-check-input position-static" style="font-size: 18px;" #isCurrentEmployment [(ngModel)]="userEmploymentFormData.isCurrentEmployment" name="isCurrentEmployment" type="checkbox" id="isCurrentEmployment"
                                            (change)="onCheckboxChanged(isCurrentEmployment.value,$event)" aria-label="...">
                                        <p class="subtitle-text form-check-label" for="isCurrentEmployment">I current work here</p>
                                    </div>
                                </div>
                                <div class="col">
                                    <button type="submit" style="margin-bottom: 10px;" class="btn btn-default ml-auto pull-right" (click)="onSaveUserEmployment()" [disabled]="!userEmploymentForm.form.valid">Save
                                        <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif"
                                            class="" width="24">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab label="Membership">
                    <div class="bg-white animate__animated animate__fadeIn" style="padding: 15px; margin-bottom: 10px;">
                        <h4 class="title-text">Membership information</h4>
                        <div class="row">
                            <div class="col">
                                <div class="card-1" style="padding: 10px; margin-bottom: 10px; background-color: #C0C0C0;">
                                    <h4 class="title-text">SILVER</h4>
                                    <p class="subtitle-text">Silver is a chemical element with the symbol Ag (from the Latin argentum</p>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div class="col">
                                <div class="card-1" style="padding: 10px; margin-bottom: 10px; background-color: #d4af37;">
                                    <h4 class="title-text">GOLD</h4>
                                    <p class="subtitle-text">Gold is a chemical element with the symbol Au (from Latin: aurum) and atomic</p>

                                </div>
                                <mat-divider></mat-divider>
                            </div>
                            <div class="col">
                                <div class="card-1" style="padding: 10px; margin-bottom: 10px; background-color: #e5e4e2;">
                                    <h4 class="title-text">PLATINUM</h4>
                                    <p class="subtitle-text">Platinum is a chemical element with the symbol Pt and atomic number 78.</p>
                                </div>
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
<ng-template #bioContent let-modal>
    <div class="modal-header">
        <h4 class="title-text" class="modal-title" id="modal-basic-title">Update biography</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss( 'Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form name="bioForm" #bioForm="ngForm" (ngSubmit)="bioForm.form.valid">
            <div class="row">
                <div class="form-group col-md-12">
                    <p class="subtitle-text" for="biography">Biography</p>
                    <textarea class="form-control" id="biography" rows="3" name="biography" [(ngModel)]="scope.authUser.profile.biography" #biography="ngModel"></textarea>
                    <div *ngIf="bioForm.submitted && biography.invalid" class="invalid-feedback animated bounceIn" required minlength="3" maxlength="255" [ngClass]="{ 'is-invalid': bioForm.submitted && biography.invalid }" aria-describedby="biographyHint">
                        <small *ngIf="biography.errors.required" id="biographyHint" class="form-text">
                            biography is required</small>
                        <small *ngIf="biography.errors.minlength" id="biographyHint" class="form-text">Please provide
                            a
                            minimum length of 10 characters</small>
                        <small *ngIf="biography.errors.maxlength" id="biographyHint" class="form-text">
                            Please provide a maximum length of 255 characters</small>
                        <small *ngIf="biography.errors.biography">Please provide a valid
                            biography</small>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" ngbAutofocus class="btn btn-outline-dark" (click)="onSaveBio()">Save
            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
        </button>
    </div>
</ng-template>

<ng-template #profileImageContent let-modal>
    <div class="modal-header">
        <h4 class="title-text" class="modal-title" id="modal-basic-title">Update profile image</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss( 'Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form name="profileImageForm" #profileImageForm="ngForm" (ngSubmit)="profileImageForm.form.valid">
            <div class="row">
                <div class="form-group col-12">
                    <div class="input-group">
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" (change)="fileChangeEvent($event)" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row" style="margin-top: 4%;">
                <div class="text-center col-12">
                    <h5 class="title-text">Crop Image</h5>
                    <image-cropper #imageCropper name="imageCropper" [imageChangedEvent]="imageChangedEvent" [outputType]="base64" [imageQuality]="60" [autoCrop]="true" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" [resizeToWidth]="256" format="jpeg" (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>
               
            </div> -->
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" ngbAutofocus class="btn btn-outline-dark" (click)="onSaveProfileImage()">Save
            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
        </button>
    </div>
</ng-template>

<ng-template #resetPasswordDialog let-modal>
    <div class="modal-header">
        <h4 class="title-text" class="modal-title" id="modal-basic-title">Reset password</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss( 'Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="title-text">A link to reset your password will be sent to the email associated with this account</p>
        <form name="loginForm" #loginForm="ngForm" (ngSubmit)="loginForm.form.valid">
            <div class="form-group">
                <p class="subtitle-text" for="email">Please confirm email</p>
                <input [(ngModel)]="userAccountFormData.email" #email="ngModel" type="email" class="form-control" id="email" name="email" required autofocus minlength="5" [ngClass]="{ 'is-invalid': loginForm.submitted && email.invalid }" aria-describedby="emailHint">
                <div *ngIf="loginForm.submitted && email.invalid" class="invalid-feedback animated bounceIn">
                    <small *ngIf="email.errors.required" id="emailHint" class="form-text">email
                        is
                        required</small>
                    <!-- <small *ngIf="email.errors.minlength" id="emailHint" class="form-text">Please
                        provide a minimum length of 5 characters</small> -->
                    <small *ngIf="email.errors.email">Please provide a valid email</small>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" ngbAutofocus class="btn btn-outline-dark" (click)="onResetPassword()">Reset
            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
        </button>
    </div>
</ng-template>