import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, OnDestroy {

  public events: string[] = [];
  public opened: boolean = true;
  public sidebarToggle = 'on';
  public observers: Array<Subscription> = new Array<Subscription>();
public   authUser = null;
  ngOnInit(): void {
  }

  constructor(public sidebarService: SidebarService,
    private localAuthService: LocalAuthService,
    private backend: BackendService,
    private router: Router,
    private activateRoute: ActivatedRoute,) {

      this.authUser =  localAuthService.getUser();
    this.observers.push(sidebarService.toggleState.subscribe(data => {
      //console.log('happened')
      //this.sidenav.toggle()
      this.opened = !this.opened;
    }));
  }
  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }

  public toggleSidebar() {
    if (this.sidebarToggle == 'on') this.sidebarToggle = 'off';
    else this.sidebarToggle = 'on';
  }

  signout(): void {
    if (this.localAuthService.logout()) {
      this.localAuthService.signOut()
        .then((onfulfilled) => {
          console.log('onfulfilled... ' + onfulfilled);
        }, (onrejected) => {
          console.log('onrejected... ' + onrejected);
        }).catch((onrejected) => {
          console.log('catch onrejected... ' + onrejected);
        }).finally(() => {
          console.log('onfinally...');
          this.router.navigate(['/login']);
        });
    }
  }

}
