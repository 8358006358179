import { Component, OnInit, OnDestroy } from '@angular/core';
import { Scope } from 'src/app/models/scope';
import { LocalAuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public scope: Scope = new Scope();
  private userId: string = '';
  public observers: Array<Subscription> = new Array<Subscription>();
  constructor(private localAuthService: LocalAuthService,
    private backend: BackendService,
    public sidebarService: SidebarService,
    private dataSource: DataService) {
      this.scope.authUser = new User();
    }

  ngOnInit() {
    this.userId = localStorage.getItem('userId');
    this.getUserDetails(this.userId);
  }

  getUserDetails(formData: any) {
    this.observers.push(this.backend.getUserDetails3(formData).subscribe({
      next: (data: User) => {
        //console.log('From file ' + JSON.stringify(data));
        this.scope.authUser = data;
        this.dataSource.setData(data);
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete getUserDetails');
      }
    }));
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }

}
