import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Goal } from 'src/app/models/goal';
import { Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { ActionPlan } from 'src/app/models/action-plan';
import { Subscription } from 'rxjs';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogButton, DialogOptions, DialogService } from 'src/app/services/dialog.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-setup-action-plan-details',
  templateUrl: './setup-action-plan.component.html',
  styleUrls: ['./action-plan.component.css']
})
export class SetupActionPlanComponent implements OnInit, OnDestroy {

  public goal: Goal = new Goal();
  public actionPlanFormData: ActionPlan = new ActionPlan();
  observers = new Array<Subscription>();
  authUser = null;
  public isLoading: boolean = false;
  public isEdited = false;
  public actionPlanFormGroup: FormGroup

  dateTimePickerOptions = {
    format: "YYYY-MM-DD h:mm:ss a",
    //format: "YYYY-MM-DD",
    //maxDate: moment(),
    //minDate: moment(),
    // ...
  };

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<SetupActionPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
    private backend: BackendService,
    private dialogService:DialogService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    private appMaterialDesignModule: AppMaterialDesignModule) { }

  ngOnInit() {
    const observer = this.localAuthService.getAuthUser().subscribe(data => {
      this.authUser = data;
    });
    this.observers.push(observer);
    
    this.dataSource.currentdata.subscribe(data => {
      this.goal = data.goal;
      this.actionPlanFormData = data?.actionPlan
    });

    this.setupActionPlanFormGroup(this.actionPlanFormData);

    if (this.goal.id == null) {
      this.router.navigate(['goals'], { queryParams: {}, skipLocationChange: false });
    } 
  }

  public setupActionPlanFormGroup(actionPlan: ActionPlan) {
    this.actionPlanFormGroup = new FormGroup({
      id: new FormControl(actionPlan?.id,[Validators.nullValidator]),
      goal: new FormControl(actionPlan?.goal,[Validators.nullValidator]),
      status: new FormControl(actionPlan?.status,[Validators.nullValidator]),
      user: new FormControl(actionPlan?.user,[Validators.nullValidator]),
      dateOfExecution: new FormControl(actionPlan?.dateOfExecution,[Validators.required]),
      title: new FormControl(actionPlan?.title,[Validators.required]),
      description: new FormControl(actionPlan?.description,[Validators.required])
    })
  }

  onSaveActionPlan(): void {
    this.actionPlanFormData = {...this.actionPlanFormGroup.value}
    this.isLoading = true;
    if (this.isEdited) {
      this.observers.push(
        this.backend.updateActionPlan(this.actionPlanFormData).subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              this.dialogService.sendMessage({text:'ok button clicked',button:DialogButton.ok,actionPlan:response.data});
              this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.info, 'Action plan created sucessfully.', 3).subscribe());
            } else {
              this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.info, 'Failed to save Action plan.', 3).subscribe());
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
              this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.error, err.error.message, 3).subscribe());
              
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isEdited = false;
          },
          complete: () => {
            this.isLoading = false;
            this.isEdited = false;
            this.actionPlanFormGroup.reset({})
            this.dialogRef.close();
            console.log('on complete updateActionPlan');
          }
        })
      );

    } else {
      this.actionPlanFormData.user = this.authUser.id;
      this.actionPlanFormData.goal = this.goal.id;
      this.observers.push(
        this.backend.createActionPlan(this.actionPlanFormData).subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              this.dialogService.sendMessage({text:'ok button clicked',button:DialogButton.ok,actionPlan:response.data});
            } else {
              this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.info, 'Action plan created sucessfully', 3).subscribe());
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
              this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.error, err.error.message, 3).subscribe());
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isEdited = false;
          },
          complete: () => {
            this.isLoading = false;
            this.isEdited = false;
            this.actionPlanFormGroup.reset({})
            this.dialogRef.close();
            console.log('on complete createActionPlan');
          }
        })
      );
    }
  }


  public cancel(): void {
    this.isLoading = false;
    this.dialogService.sendMessage({text:'cancel button clicked',button:DialogButton.cancel});
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.observers.forEach((observer) => {
      observer.unsubscribe();
    });
  }
}
