<div class="container-fluid bg-white pt-5 pb-5">
    <div class="container">
        <a class="navbar-brand" href="/startingpointzone/home">
            <img alt="temp image" src="/assets/img/spz-logo-dark.svg" class="img-fluid">
        </a>
        <div class="row mt-5">
            <div class="col-md-6">
                <div class="d-flex justify-content-start">
                    <a class="nav-link mr-5" routerLinkActive="spz-button-green" routerLink="/signup">Sign up</a>
                    <a class="nav-link ml-2" routerLinkActive="spz-button-green" routerLink="/login">Login</a>
                </div>
                <div class="mt-5">
                    <form [formGroup]="loginFormGroup" #loginForm="ngForm" name="loginFormGroup"
                        (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <p class="subtitle-text" for="username">username / email</p>
                            <input formControlName="username" type="text" class="form-control" id="username"
                                name="username" required autofocus minlength="5" autocomplete="off"
                                [ngClass]="{ 'is-invalid':loginForm.submitted && loginFormGroup.get('username').hasError('required') }"
                                aria-describedby="usernameHint">
                            <div *ngIf="loginForm.submitted && loginFormGroup.get('username').hasError('required')"
                                class="invalid-feedback animate__animated animate__bounceIn">
                                <small *ngIf="loginFormGroup.get('username').hasError('required')" id="usernameHint"
                                    class="form-text">Username
                                    is
                                    required</small>
                                <small *ngIf="loginFormGroup.get('username').hasError('minlength')" id="usernameHint"
                                    class="form-text">Please
                                    provide a minimum length of 5 characters</small>
                                <small *ngIf="loginFormGroup.get('username').hasError('username')">Please provide a
                                    valid username</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <p class="subtitle-text" for="password">Password</p>
                            <div class="input-group">
                                <input formControlName="password" #password type="password" class="form-control"
                                    autocomplete="off"
                                    [ngClass]="{ 'is-invalid':loginForm.submitted && loginFormGroup.get('password').hasError('required') }"
                                    id="password" name="password" required minlength="8" password
                                    aria-describedby="passwordHint">
                                <div class="input-group-append" (click)="showPassword()">
                                    <div class="input-group-text">
                                        <i class="fa fa-fw fa-eye"></i>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="loginForm.submitted && loginFormGroup.get('password').hasError('required')"
                                class="invalid-feedback  animate__animated animate__bounceIn">
                                <small *ngIf="loginFormGroup.get('password').hasError('required')" id="passwordHint"
                                    class="form-text">password is
                                    required</small>
                                <small *ngIf="loginFormGroup.get('password').hasError('minlength')" id="passwordHint"
                                    class="form-text">Please
                                    provide a minimum length of 8 characters</small>
                                <small *ngIf="loginFormGroup.get('password').hasError('password')">Please provide a
                                    valid password</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <span class="link" (click)="openComfirmEmailDialog()">forgotten password?</span>
                        </div>
                        <div class="form-group" [hidden]="!loginFormGroup.valid">
                            <ngx-recaptcha2 #captchaElem [siteKey]="captchaConfigs.siteKey" (reset)="handleReset()"
                                (expire)="handleExpire()" (load)="handleLoad()" (success)="handleSuccess($event)"
                                [useGlobalDomain]="captchaConfigs.useGlobalDomain" [size]="captchaConfigs.size"
                                [hl]="captchaConfigs.lang" [theme]="captchaConfigs.theme" [type]="captchaConfigs.type"
                                formControlName="captcha" name="captchaElem">
                            </ngx-recaptcha2>
                        </div>
                        <small class="text-muted">By sigining up, You agree to the spz User Agreement, Privacy Policy,
                            and
                            Cookie Policy.</small>
                        <button type="submit"
                            class="btn spz-button-green btn-block mt-3 mb-3" [hidden]="!captchaSuccess"
                            [disabled]="!loginFormGroup.valid && !captchaSuccess">Sign in
                            <img #progressRipple style="display: none;" alt="" src="/assets/img/ripple.gif" class=""
                                width="24">
                        </button>
                        <small class="text-with-line subtitle-text mt-1">OR login with your socail account</small>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <button type="button" (click)="signInWithGoogle()" class="btn btn-block google-button">
                                    <span class="google-button__icon" style="margin-top: 0px;">
                                        <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
                                                id="Shape" fill="#EA4335" />
                                            <path
                                                d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
                                                id="Shape" fill="#FBBC05" />
                                            <path
                                                d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
                                                id="Shape" fill="#4285F4" />
                                            <path
                                                d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
                                                fill="#34A853" />
                                        </svg>
                                    </span>
                                    <span class="google-button__text">Sign in with Google</span>
                                </button>
                            </div>
                            <div class="col-md-6">
                                <button type="button" (click)="signInWithFB()" class="btn btn-block facebook-button">
                                    <span class="google-button__icon" style="margin-top: 0px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"
                                            viewBox="0 0 20 20">
                                            <path
                                                d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"
                                                id="Shape" fill="#ffffff" />
                                        </svg>
                                    </span>
                                    <span class="google-button__text">Sign in with Facebook</span>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <button type="button" class="btn btn-block linkedIn-button">
                                    <span class="socail-button__icon" style="margin-top: 0px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 20 20">
                                            <path
                                                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                                                id="Shape" fill="#ffffff" />
                                        </svg>
                                    </span>
                                    <span class="google-button__text">Sign in with LinkedIn</span>
                                </button>
                            </div>

                            <div class="col-md-6">
                                <button type="button" class="btn btn-block twitter-button">
                                    <span class="socail-button__icon" style="margin-top: 0px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 20 20">
                                            <path
                                                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-.139 9.237c.209 4.617-3.234 9.765-9.33 9.765-1.854 0-3.579-.543-5.032-1.475 1.742.205 3.48-.278 4.86-1.359-1.437-.027-2.649-.976-3.066-2.28.515.098 1.021.069 1.482-.056-1.579-.317-2.668-1.739-2.633-3.26.442.246.949.394 1.486.411-1.461-.977-1.875-2.907-1.016-4.383 1.619 1.986 4.038 3.293 6.766 3.43-.479-2.053 1.08-4.03 3.199-4.03.943 0 1.797.398 2.395 1.037.748-.147 1.451-.42 2.086-.796-.246.767-.766 1.41-1.443 1.816.664-.08 1.297-.256 1.885-.517-.439.656-.996 1.234-1.639 1.697z"
                                                id="Shape" fill="#ffffff" />
                                        </svg>
                                    </span>
                                    <span class="google-button__text">Sign in with Twitter</span>
                                </button>
                            </div>
                        </div>
                        <p style="margin-top: 10px;">New to SPZ? <a class="link" href="/startingpointzone/signup">Sign
                                up</a></p>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <img alt="temp image" src="/assets/img/spz-login-image.svg" class="img-fluid">
            </div>
        </div>
    </div>
</div>