import { ActionPlan } from './action-plan';

export class Goal {
    public id:string;
    public title:string;
    public description:string;
    public status:string;
    public user:string;
    public category:string;
    public duration:string;
    public progress:number;
    public actionPlans:Array<ActionPlan>;

    constructor(){
        this.actionPlans = new Array<ActionPlan>();
    }
}
