import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { AdminComponent } from './pages/admin/admin.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { InvitationComponent } from './pages/invitation/invitation.component';
import { MentorComponent } from './pages/mentoring-engagement/mentor/mentor.component';
import { MentoringRequestDetailsComponent } from './pages/mentoring-engagement/mentoring-request-details/mentoring-request-details.component';
import { MentoringRequestComponent } from './pages/mentoring-engagement/mentoring-request/mentoring-request.component';
import { UserComponent } from './pages/user/user.component';

const routes: Routes = [
  {
    path: "admin", component: AdminComponent, children: [
      {
        path: 'dashboard', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard], data: {
          role: 'ADMIN'
        }
      },
      {
        path: 'users', component: UserComponent, canActivate: [AuthGuard], data: {
          role: 'ADMIN'
        }
      },
      {
        path: 'invitations', component: InvitationComponent, canActivate: [AuthGuard], data: {
          role: 'ADMIN'
        }
      },
      {
        path: 'engagements/mentors', component: MentorComponent, canActivate: [AuthGuard], data: {
          role: 'ADMIN'
        }
      },
      {
        path: 'engagements/mentoring-requests', component: MentoringRequestComponent, canActivate: [AuthGuard], data: {
          role: 'ADMIN'
        }
      },
      {
        path: 'engagements/mentoring-requests/:id', component: MentoringRequestDetailsComponent, canActivate: [AuthGuard], data: {
          role: 'ADMIN'
        }
      }
    ], data: {
      role: 'ADMIN'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
export const AdminRoutingComponents = [
  DashboardComponent,
  UserComponent,
  InvitationComponent,
  MentorComponent,
  MentoringRequestComponent,
  AdminComponent,
  MentoringRequestDetailsComponent
]
