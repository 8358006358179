<!-- <h1 mat-dialog-title>{{dialogOptions.title}}</h1> -->
<div mat-dialog-content>
    <div class="d-flex justify-content-center mb-2">
        <img alt="temp image" src="/assets/img/spz-globelock.svg" class="img-fluid">
    </div>
    <h3 class="title-text text-center color-spz-green">Reset Password?</h3>
    <form [formGroup]="comfirmEmailFormGroup" name="comfirmEmailFormGroup" #comfirmEmailForm="ngForm"
        (ngSubmit)="comfirmEmailFormGroup.valid && onResetPassword()">
        <div class="form-group">
            <!-- <p class="subtitle-text" for="email">Email address</p> -->
            <div class="input-group">
                <input formControlName="email" type="email" placeholder="Enter your email." class="form-control"
                    id="email" name="email"
                    [ngClass]="{ 'is-invalid': comfirmEmailForm.submitted && comfirmEmailFormGroup.get('email').hasError('email') }"
                    required email aria-describedby="emailHint" autocomplete="off">
            </div>
            <div *ngIf="comfirmEmailForm.submitted && comfirmEmailFormGroup.get('email').hasError('email')"
                class="invalid-feedback animate__animated animate__bounceIn">
                <small *ngIf="comfirmEmailFormGroup.get('email').hasError('required')" id="emailHint"
                    class="form-text">Email is
                    required</small>
                <!-- <div *ngIf="email.errors.required"></div> -->
                <small *ngIf="comfirmEmailFormGroup.get('email').hasError('email')">Email must be a valid
                    email address</small>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <button type="button" style="margin-bottom: 10px;" class="btn spz-button-outline-green" (click)="cancel();">
                Cancel
            </button>
            <button type="button" style="margin-bottom: 10px;" [disabled]="!comfirmEmailFormGroup.valid"
                class="btn spz-button-green" (click)="onResetPassword();">
                Reset
            </button>
        </div>
        <div class="col-md-12 d-flex justify-content-center mt-3 ">
            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35"
                [strokeWidth]="2">
            </mat-spinner>
        </div>
    </form>
</div>