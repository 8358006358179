import { Component, OnInit, Input, Inject } from '@angular/core';
import { DialogService, DialogButton, DialogOptions } from 'src/app/services/dialog.service';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export const enum DialogType {
  image = 'image',
  document = 'document',
  video = 'video',
  default = 'default'
}

@Component({
  selector: 'app-fileupload-dialog',
  templateUrl: './fileupload-dialog.component.html',
  styleUrls: ['./fileupload-dialog.component.css']
})
export class FileuploadDialogComponent implements OnInit {

  @Input() title:string;
  @Input() message:string;
  @Input() dialogType:DialogType;

  constructor(
    public dialogRef: MatDialogRef<FileuploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
    private dialogService:DialogService,) { }

  ngOnInit() {
  }


  public close(): void {
    this.dialogRef.close();
  }
}
