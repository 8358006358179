import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { Mentor } from 'src/app/models/mentor';
import { MentorRequestData } from 'src/app/models/mentor-request-data';
import { User } from 'src/app/models/user';
import { BecomeMentorComponent } from 'src/app/pages/mentoring-engagement/become-mentor/become-mentor.component';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { DialogButton, DialogOptions, DialogService } from 'src/app/services/dialog.service';
import { FormBuilderService } from 'src/app/services/form-builder.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assign-mentor',
  templateUrl: './assign-mentor.component.html',
  styleUrls: ['./assign-mentor.component.css']
})
export class AssignMentorComponent implements OnInit, OnDestroy {

  public isLoading = false;
  public user: User;
  public authUser = null;
  public observers: Array<Subscription> = new Array<Subscription>();
  public requestMentorFormData = new MentorRequestData();
  public assignMentorFormGroup: FormGroup;
  public mentors: Mentor[] = [];
  constructor(private localAuthService: LocalAuthService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private backend: BackendService,
    private dataSource: DataService,
    public dialogRef: MatDialogRef<BecomeMentorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
    private dialogService: DialogService,
    public appMaterialModule: AppMaterialDesignModule,
    public formBuilderService: FormBuilderService) {
    this.requestMentorFormData = new MentorRequestData();
    this.requestMentorFormData = data['requestMentorFormData']

    this.assignMentorFormGroup = new FormGroup({
      requestId: new FormControl(this.requestMentorFormData.id, [Validators.nullValidator]),
      mentorId: new FormControl(this.requestMentorFormData.mentor?.id)
    })


  }


  ngOnInit(): void {
    const observer = this.localAuthService.getAuthUser().subscribe(data => {
      this.authUser = data;
    });

    this.getMentors();
    this.observers.push(observer);
  }

  ngOnDestroy(): void {
    this.observers.forEach((o) => {
      o.unsubscribe()
    })
  }

  public cancel(): void {
    this.isLoading = false;
    this.dialogService.sendMessage({ text: 'cancel button clicked', button: DialogButton.cancel });
    this.dialogRef.close();
  }

  public selectMentor(mentor) {
    this.assignMentorFormGroup.patchValue({
      requestId: this.requestMentorFormData.id,
      mentorId: mentor.id
    })
  }

  public unSelectMentor() {
    this.assignMentorFormGroup.patchValue({
      requestId: this.requestMentorFormData.id,
      mentorId: ''
    })
  }

  public onAssignMentor() {
    this.isLoading = true;
    console.log('this.assignMentorFormGroup.value :' + JSON.stringify(this.assignMentorFormGroup.value))
    this.observers.push(
      this.backend.assignMentor(this.assignMentorFormGroup.value)
        .pipe(switchMap((response) => {
          if (response.code == '000') {
            return this.backend.getMentorshipRequest(response.data.id);
          }
        }))
        .subscribe({
          next: (response) => {
            if (response.code == '000') {
              this.requestMentorFormData = response.data;
              if (this.requestMentorFormData.mentor != undefined) {
                let mentorMail = this.backend.sendMail({
                  subject: 'New Mentoring Request',
                  email: this.requestMentorFormData.mentor?.email || environment.mail.spzEmail,
                  recipient: this.requestMentorFormData.mentor?.firstname + ' ' + this.requestMentorFormData.mentor?.lastname,
                  href: 'https://startingpointzone.herokuapp.com/startingpointzone/engagements',
                  body: `You have been assigned to mentor ${this.requestMentorFormData.user.username}. Kindly log on and check.`
                });
              
              let userMail = this.backend.sendMail({
                subject: 'New Mentoring Request',
                email: this.requestMentorFormData.user.email,
                recipient: this.requestMentorFormData.user.username,
                href: 'https://startingpointzone.herokuapp.com/startingpointzone/engagements',
                body: `Congratulations! ${this.requestMentorFormData.mentor?.firstname + ' ' + this.requestMentorFormData.mentor?.lastname} has been assigned as your mentor. Kindly log on and check.`
              });
              this.observers.push(forkJoin([mentorMail.pipe(take(1)), userMail.pipe(take(1))]).subscribe(
                {
                  error: (err: any) => {
                    if (err.error instanceof Error) {
                      // A client-side or network error occurred.
                      console.log('An error occurred:', err.error.message);
                    } else {
                      // Backend returns unsuccessful response codes such as 404, 500 etc.
                      console.log('Backend returned status code: ', err.status);
                      console.log('Response body:', err.error);
                    }
                  },
                }
              ));
            }
              //this.observers.push(
              this.appMaterialModule.showAlertToaster(AlertType.success, 'Successfully assigned a mentor.', 3).subscribe()
              //)
              this.dialogService.sendMessage({ text: 'ok button clicked', button: DialogButton.ok, requestMentorFormData: response.data });
            } else {
              //this.observers.push(
              this.appMaterialModule.showAlertToaster(AlertType.error, response.message, 3).subscribe()
              //)
            }
            this.isLoading = false
            this.cancel();
          }, error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false
            this.cancel();
          },
          complete: () => {
            console.log('on complete onAssignMentor');
            this.isLoading = false
            this.cancel();
          }
        })
    );
  }

  public getMentors() {
    this.isLoading = true
    this.observers.push(
      this.backend.getAllMentors().subscribe({
        next: (response) => {
          if (response.code === '000') {
            this.mentors = response.data;
          }
          this.isLoading = false;
        }, error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false
        }
        , complete: () => {
          console.log('on complete getMentors');
        }
      })
    )
  }

}
