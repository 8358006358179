<div class="container-fluid bg-white pt-5 pb-5">
    <div class="container">
        <a class="navbar-brand" href="/startingpointzone/home">
            <img alt="temp image" src="/assets/img/spz-logo-dark.svg" class="img-fluid">
        </a>

        <div class="mt-5 d-flex justify-content-center align-items-center">
            <img alt="temp image" src="/assets/img/spz-pagenotfound-image.svg" class="img-fluid">
        </div>
        <div class="mt-3 d-flex justify-content-center align-items-center">
            <a class="spz-button-outline-green p-3 nav-link" routerLink="/home">Back to home page</a>
        </div>
    </div>
</div>