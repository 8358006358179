import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Inject,
} from '@angular/core';
import { Event } from 'src/app/models/event';
import { Subscription, Subject, Observable } from 'rxjs';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  take,
  takeUntil,
} from 'rxjs/operators';
import {
  DialogService,
  DialogButton,
  DialogOptions,
} from 'src/app/services/dialog.service';
import { AlertType } from 'src/app/services/alert.service';
import {
  FilePickerDialogComponent,
  FileType,
} from 'src/app/components/file-picker-dialog/file-picker-dialog.component';
import { UploadFile } from 'src/app/models/upload-file';
import { AppMaterialDesignModule } from '../../../app-material-design.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ImageCropperDialogComponent } from 'src/app/components/image-cropper-dialog/image-cropper-dialog.component';

@Component({
  selector: 'app-setup-events',
  templateUrl: './setup.events.component.html',
  styleUrls: ['./setup.events.component.css'],
})
export class SetupEventComponent implements OnInit, AfterViewInit, OnDestroy {
  private unSubscriptioNotifier = new Subject();
  authUser = null;
  public isEdited: boolean = false;
  public eventTags = [];
  public eventFormData: Event;
  public eventFormGroup: FormGroup;
  public eventCategories: Array<string>;
  public filteredCategories: Observable<string[]>;
  public isLoading = false;
  //@ViewChild('instance') instance: NgbTypeahead;
  @ViewChild('eventPoster') eventPosterInput: ElementRef;
  @ViewChild('eventForm') eventForm: NgForm;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  addEventTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.eventTags.push(value.trim());
      this.eventFormGroup.patchValue({
        tags: this.eventTags.join('|'),
      });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeEventTag(tag: string): void {
    const index = this.eventTags.indexOf(tag);
    if (index >= 0) {
      this.eventTags.splice(index, 1);
      this.eventFormGroup.patchValue({
        tags: this.eventTags.join('|'),
      });
    }
  }

  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router,
    private localAuthService: LocalAuthService,
    public dialogRef: MatDialogRef<SetupEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
    public appMaterialDesignModule: AppMaterialDesignModule,
    private backend: BackendService
  ) {
    this.authUser = this.localAuthService.getUser();
    this.eventFormData = new Event();
    this.eventFormData.host = this.authUser.fullname;
    this.eventCategories = new Array<string>();
  }

  ngOnInit() {
    this.setupEventFormGroup(this.eventFormData);
    this.getEventCategories();
    this.eventFormData.tags = '';

    // this.activateRoute.params.subscribe(params => {

    // });

    this.activateRoute.paramMap.subscribe((params: ParamMap) => {
      let eventId = params.get('id') || '0';
      this.isEdited = eventId != '0';
      if (this.isEdited) {
        this.getEventDetails(eventId);
      }
    });

    this.filteredCategories = this.eventFormGroup
      .get('category')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
  }

  public setupEventFormGroup(event: Event) {
    event.postedBy = this.authUser.id;
    this.eventFormGroup = new FormGroup({
      title: new FormControl(event.title, [Validators.required]),
      description: new FormControl(event.description, [
        Validators.required,
        Validators.minLength(5),
      ]),
      startDateAndTime: new FormControl(event.startDateAndTime, [
        Validators.required,
      ]),
      endDateAndTime: new FormControl(event.endDateAndTime, [
        Validators.required,
      ]),
      posterUrl: new FormControl(event.posterUrl, [Validators.nullValidator]),
      postedBy: new FormControl(event.postedBy, [Validators.nullValidator]),
      category: new FormControl(event.category, [Validators.nullValidator]),
      host: new FormControl(event.host, [Validators.nullValidator]),
      location: new FormControl(event.location, [Validators.nullValidator]),
      ticketUrl: new FormControl(event.ticketUrl, [Validators.nullValidator]),
      tags: new FormControl(event.tags, [Validators.nullValidator]),
      occurance: new FormControl(event.occurance, [Validators.nullValidator]),
    });

    if (undefined != event.tags) {
      this.eventTags = event.tags.split('|');
    }
  }

  public getEventCategories() {
    this.backend
      .getEventCategories()
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.eventCategories = response.data;
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        },
        complete: () => {
          console.log('on complete getEventCategories');
        },
      });
  }
  ngAfterViewInit(): void {}

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.eventCategories.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  public removeImageFile(value: UploadFile) {
    var temp = this.eventFormData.imageFiles.find((item) => {
      return item.name == value.name;
    });
    var index = this.eventFormData.imageFiles.indexOf(temp);
    console.log('upload : ' + index);
    this.eventFormData.imageFiles.splice(index, 1);
  }

  showFilePicker(): void {
    this.appMaterialDesignModule
      .openDialog(ImageCropperDialogComponent, {
        width: '400px',
        title: 'File Upload',
        message: 'File size less than 20Kb',
        data: {
          fileType: FileType.image,
          overrideFilename: true,
          aspectRatio: 16 / 9,
          imageQuality:90,
        },
      })
      .pipe(take(1))
      .subscribe((result) => {
        if (result.button === DialogButton.upload) {
          console.log('result.files.length : ' + result.files.length);
          if (result.files.length > 0) {
            result.files.forEach((element) => {
              //console.log(JSON.stringify(element))
              this.eventFormData.imageFiles.unshift(element);
            });
          }
        }
      });
  }
  public onSaveEvent(): void {
    let event = this.eventFormData;
    this.eventFormData = { ...this.eventFormGroup.value };
    this.eventFormData.imageFiles = event.imageFiles;
    this.isLoading = true;
    if (this.isEdited) {
      this.backend
        .updateEvent(this.eventFormData)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              this.router.navigate(['/events'], {
                queryParams: {},
                skipLocationChange: false,
              });
            } else {
              this.appMaterialDesignModule
                .showAlertToaster(AlertType.error, response.message, 3)
                .pipe(take(1))
                .subscribe();
            }
            this.isLoading = false;
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isEdited = false;
            //this.eventFormData = new Event();
          },
          complete: () => {
            this.isLoading = false;
            this.isEdited = false;
            //this.eventFormData = new Event();
            console.log('on complete updateEvent');
          },
        });
    } else {
      this.backend
        .createEvent(this.eventFormData)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              // this.events.unshift(response.data);
              this.router.navigate(['/events'], {
                queryParams: {},
                skipLocationChange: false,
              });
            } else {
              this.appMaterialDesignModule
                .showAlertToaster(AlertType.error, response.message, 3)
                .pipe(take(1))
                .subscribe();
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
              this.appMaterialDesignModule
                .showAlertToaster(AlertType.error, err.error, 3)
                .pipe(take(1))
                .subscribe();
            }
            this.isLoading = false;
            this.isEdited = false;
          },
          complete: () => {
            this.isLoading = false;
            this.isEdited = false;
            //this.eventFormData = new Event();
            console.log('on complete createEvent');
          },
        });
    }
  }

  getEventDetails(eventId: string): void {
    this.isLoading = true;
    this.backend
      .getEvent(eventId)
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.eventFormData = response.data;
            this.setupEventFormGroup(this.eventFormData);
          }
          this.isLoading = false;
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          console.log('on complete getEventDetails');
          this.isLoading = false;
        },
      });
  }

  public signout(): void {
    if (this.localAuthService.logout()) {
      this.localAuthService
        .signOut()
        .then(
          (onfulfilled) => {
            console.log('onfulfilled... ' + onfulfilled);
          },
          (onrejected) => {
            console.log('onrejected... ' + onrejected);
          }
        )
        .catch((onrejected) => {
          console.log('catch onrejected... ' + onrejected);
        })
        .finally(() => {
          console.log('onfinally...');
          this.router.navigate(['/login']);
        });
    }
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next('');
    this.unSubscriptioNotifier.complete();
  }
}
