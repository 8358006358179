<div class="container-fluid">
    <div class="row">
        <div class="col-12" style="margin-top: 8px;">
            <div class="mini-bar-menu bg-white p-2 nav d-flex justify-content-start" style="margin-bottom: 5px;">
                <a class="p-1 text-muted link" routerLink="/engagements/request-mentor" routerLinkActive="sidenav-menu-active">Request A Mentor</a>
                <div class="p-1 text-muted link" role="button" (click)="openBecomeAMentorDialog()">Become A Mentor</div>
            </div>
        </div>
        <div class="col-md-12">
            <mat-tab-group dynamicHeight mat-align-tabs="start" animationDuration="500ms">
                <mat-tab label="Recent Engagements">
                    <div class="container bootstrap snippets bootdeys mt-3">
                        <div class="col-md-12">
                            <div class="timeline-centered timeline-sm">
                                <article class="timeline-entry">
                                    <div class="timeline-entry-inner">
                                        <time datetime="2014-01-10T03:45" class="timeline-time"><span>12:45 AM</span><span>Today</span></time>
                                        <div class="timeline-icon bg-violet"><i class="fa fa-exclamation"></i></div>
                                        <div class="timeline-label">
                                            <h4 class="timeline-title">New Project</h4>
                                            <p>Tolerably earnestly middleton extremely distrusts she boy now not. Add and offered prepare how cordial.</p>
                                        </div>
                                    </div>
                                </article>
                                <article class="timeline-entry left-aligned">
                                    <div class="timeline-entry-inner">
                                        <time datetime="2014-01-10T03:45" class="timeline-time"><span>9:15 AM</span><span>Today</span></time>
                                        <div class="timeline-icon bg-green"><i class="fa fa-group"></i></div>
                                        <div class="timeline-label bg-green">
                                            <h4 class="timeline-title">Job Meeting</h4>
                                            <p>Caulie dandelion maize lentil collard greens radish arugula sweet pepper water spinach kombu courgette.</p>
                                        </div>
                                    </div>
                                </article>
                                <article class="timeline-entry">
                                    <div class="timeline-entry-inner">
                                        <time datetime="2014-01-09T13:22" class="timeline-time"><span>8:20 PM</span><span>04/03/2013</span></time>
                                        <div class="timeline-icon bg-orange"><i class="fa fa-paper-plane"></i></div>
                                        <div class="timeline-label bg-orange">
                                            <h4 class="timeline-title">Daily Feeds</h4>
                                            <p><img src="https://via.placeholder.com/45x45/" alt="" class="timeline-img pull-left">Parsley amaranth tigernut silver beet maize fennel spinach ricebean black-eyed. Tolerably earnestly middleton extremely distrusts
                                                she boy now not. Add and offered prepare how cordial.</p>
                                        </div>
                                    </div>
                                    <div class="timeline-entry-inner">
                                        <div style="-webkit-transform: rotate(-90deg); -moz-transform: rotate(-90deg);" class="timeline-icon"><i class="fa fa-plus"></i></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Mentoring Requests">
                    <div class="row mr-auto ml-auto">
                        <div *ngFor="let request of mentorshipRequests" class="col-md-4 p-1 mt-2">
                            <div class="media bg-white p-3" style="border-radius: 5px; max-height: 160px; overflow: hidden; border: 0.3px solid #e1e5ee;">
                                <img class="mr-3 align-self-center img-responsive img-circle profile-image-small" style="width: 75px; height:75px" src="{{request.user.image}}" alt="Generic placeholder image">
                                <div class="media-body">
                                    <h5 class="mt-0">{{request.user.username}}</h5>
                                    <p class="mt-0 mb-1">{{request.mentoringArea}}</p>
                                    <p class="mb-0 subtitle-text">{{request.objectiveOfMentorship | slice:0:80}}...
                                    </p>
                                </div>
                                <!-- <mat-divider class="mt-2"></mat-divider> -->
                                <div class="d-flex justify-content-end">
                                    <div *ngIf="request.user.id==authUser.id && request.user.role!='MENTOR'">
                                        <button mat-icon-button [matMenuTriggerFor]="menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button (click)="editRequest(request)" mat-menu-item>
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit</span>
                                            </button>
                                            <button (click)="WithdrawRequest(request)" mat-menu-item>
                                                <mat-icon>block</mat-icon>
                                                <span>Withdraw Request</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <div *ngIf="request.user.role=='MENTOR'">
                                        <button mat-icon-button [matMenuTriggerFor]="menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button (click)="editRequest(request)" mat-menu-item>
                                                <mat-icon>check</mat-icon>
                                                <span>Reject</span>
                                            </button>
                                            <button (click)="WithdrawRequest(request)" mat-menu-item>
                                                <mat-icon>block</mat-icon>
                                                <span>Reject</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 d-flex justify-content-center mt-3">
                        <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                        </mat-spinner>
                    </div>
                </mat-tab>
                <mat-tab label="Mentors">
                    <div class="row mr-auto ml-auto">
                        <div *ngFor="let mentor of mentors" class="col-md-4 p-1">
                            <a class="media link bg-white p-2" routerLink="/engagements/mentor/{{mentor.id}}" style="border-radius: 5px; min-height: 160px; max-height: 160px; overflow: hidden; border: 0.1px solid #e4e8f0;">
                                <img class="mr-3 align-self-start img-responsive rounded" style="width: 100px; height:100px; border-radius: 5px; object-fit: cover; background: transparent;" src="{{mentor.image}}" alt="">
                                <div class="media-body">
                                    <h4 class="mt-0">{{mentor?.firstname+' '+mentor?.lastname}}</h4>
                                    <p class="mt-0 mb-1 subtitle-text">{{mentor?.industry}}</p>
                                    <!-- <p class="mb-0 subtitle-text">{{mentor?.biography | slice:1:80}}...
                                    </p> -->
                                    <div class="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                        <!-- <div class="d-flex flex-column"> <span class="articles">Articles</span> <span class="number1">38</span> </div> -->
                                        <div class="d-flex flex-column"> <span class="followers">Followers</span> <span class="number2">980</span> </div>
                                        <div class="d-flex flex-column"> <span class="rating">Rating</span> <span class="number3">{{mentor.rating || 0}}</span> </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-12 d-flex justify-content-center mt-3">
                            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                            </mat-spinner>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>