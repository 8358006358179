<div class="file-picker-dialog">
    <h1 mat-dialog-title>{{dialogOptions.title}}</h1>
    <div mat-dialog-content>
        <p class="subtitle-text">{{dialogOptions.message}}!</p>
        <input class="mt-3 mb-4" (click)="addFiles()" type="file" #fileInput style="display: block" (change)="onFilesAdded($event.target.files,(dialogOptions.data.limit || 3))" accept="{{dialogOptions.data.fileType}}" [attr.multiple]="dialogOptions.data.multiple ? true : null">
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="cancel()">Cancel</button>
        <button mat-button (click)="upload();" cdkFocusInitial>Ok</button>
    </div>
</div>