<h1 mat-dialog-reference>Become A Mentor</h1>
<div mat-dialog-content>
    <form [formGroup]="mentorRequestFormGroup" class="animated fadeIn" style="margin-top: 10px;" (ngSubmit)="onSubmit()">
        <div class="swiper-container" [swiper]="swiperConfig" (indexChange)="onIndexChange($event)">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="form-group " style="margin-bottom: 1px; margin-top: 15px;">
                        <mat-form-field floatLabel="auto" hideRequiredMarker appearance="standard" style="width: 100%">
                            <mat-label style="font-size: 16px;">In which area of mentoring do you want to act?
                            </mat-label>
                            <mat-select [(value)]="requestMentorFormData.mentoringArea" formControlName="mentoringArea" name="mentoringArea">
                                <mat-option selected>-- Please select --</mat-option>
                                <mat-optgroup *ngFor="let group of mentoringAreaGroups" [label]="group.name" [disabled]="group.disabled">
                                    <mat-option *ngFor="let mentoringArea of group.mentoringArea" [value]="mentoringArea.value">
                                        {{mentoringArea.viewValue}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="form-group " style="margin-bottom: 1px;">
                        <mat-form-field floatLabel="auto" appearance="standard" style="width: 100%">
                            <mat-label style="font-size: 16px;">Please state any Mentoring objective you have
                            </mat-label>
                            <textarea matInput formControlName="objectiveOfMentorship" class="form-control" id="objectiveOfMentorship" rows="3" name="objectiveOfMentorship"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="form-group " style="margin-bottom: 1px; margin-top: 15px;">
                        <p class="subtitle">Select a date range</p>
                        <mat-form-field floatLabel="auto" appearance="standard" style="width: 100%">
                            <mat-label style="font-size: 16px;">How long do you plan for the mentoring relationship?
                            </mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate formControlName="startDate" placeholder="When are you available to start?">
                                <input matEndDate formControlName="endDate" placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker [startAt]="mentorRequestFormGroup.get('minDate').value" #picker>
                                <mat-date-range-picker-actions>
                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                </mat-date-range-picker-actions>
                            </mat-date-range-picker>

                            <mat-error *ngIf="mentorRequestFormGroup.get('startDate').hasError('matStartDateInvalid')">
                                Invalid start date
                            </mat-error>
                            <mat-error *ngIf="mentorRequestFormGroup.get('endDate').hasError('matEndDateInvalid')">
                                Invalid end date
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="form-group " style="margin-bottom: 1px; margin-top: 15px;">
                        <p class="subtitle-text" style="font-size: 16px;">Have you been a mentor before?</p>
                        <mat-radio-group formControlName="isFirstTimeMentee" aria-label="Have you had any Mentor before?">
                            <mat-radio-button value="YES">Yes</mat-radio-button>
                            <mat-radio-button value="NO">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="form-group " style="margin-bottom: 1px; margin-top: 15px;">
                        <mat-form-field floatLabel="auto" appearance="standard" style="width: 100%">
                            <mat-label style="font-size: 16px;">Please select how you would like to be contacted
                            </mat-label>
                            <mat-select formControlName="preferredModeOfCommunication" multiple>
                                <mat-option value="SPZ application in-chat">SPZ application in-chat</mat-option>
                                <mat-option value="Skype chat/call">Skype chat/call</mat-option>
                                <mat-option value="Whatsapp chat/call">Whatsapp chat/call</mat-option>
                                <mat-option value="Facebook chat">Facebook chat</mat-option>
                                <mat-option value="Zoom meeting">Zoom meeting</mat-option>
                                <mat-option value="Google meet">Google meet</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="p-2 nav d-flex justify-content-between" style="margin-bottom: 5px;">
                <div class="p-1 text-muted link" role="button" (click)="previousSlide()">Previous</div>
                <div class="p-1 text-muted link" role="button" (click)="nextSlide()">Next</div>
            </div>
            <div class="swiper-pagination" style="margin-top: 20px;"></div>
            <div class="col-md-12 d-flex justify-content-center mt-3">
                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                </mat-spinner>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel() ">Cancel</button>
    <button type="submit " mat-button (click)="onSubmit(); " [disabled]="!mentorRequestFormGroup.valid || isLoading">Submit</button>
</div>