import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingComponents, AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleLoginProvider, FacebookLoginProvider, SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RelativeTimePipe } from './utils/pipes/relative-time.pipe';
import { TruncateTextPipe } from './utils/pipes/truncate-text.pipe';
import { AuthGuard } from './guards/auth.guard';
import { MembershipGuard } from './guards/membership.guard';
import { DialogService } from './services/dialog.service';
import { FormBuilderService } from './services/form-builder.service';
import { AppMaterialDesignModule } from './app-material-design.module';
import { ProgressbarsComponent } from './components/progressbars/progressbars.component';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FileuploadDialogComponent } from './components/fileupload-dialog/fileupload-dialog.component';
import { FilePickerDialogComponent } from './components/file-picker-dialog/file-picker-dialog.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmEmailDialogComponent } from './components/confirm-email-dialog/confirm-email-dialog.component';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { ActionPlanComponent } from './pages/action-plan/action-plan.component';
import { SetupActionPlanComponent } from './pages/action-plan/setup-action-plan.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { BecomeMentorComponent } from './pages/mentoring-engagement/become-mentor/become-mentor.component';
import { AdminModule } from './admin/admin.module';
import { SidebarService } from './services/sidebar.service';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { UserDashboardModule } from './pages/user-dashboard/user-dashboard.module';
import { BasicInfoComponent } from './pages/profile/basic-info/basic-info.component';
import { ContactInfoComponent } from './pages/profile/contact-info/contact-info.component';
import { SocialMediaAccountsInfoComponent } from './pages/profile/social-media-accounts-info/social-media-accounts-info.component';
import { AccountInfoComponent } from './pages/profile/account-info/account-info.component';
import { SchoolAndEducationInfoComponent } from './pages/profile/school-and-education-info/school-and-education-info.component';
import { ExperienceAndEmploymentInfoComponent } from './pages/profile/experience-and-employment-info/experience-and-employment-info.component';
import { MentorshipInfoComponent } from './pages/profile/mentorship-info/mentorship-info.component';
import { MembershipInfoComponent } from './pages/profile/membership-info/membership-info.component';
import { MaterialAlertToasterComponent } from './components/material-alert-toaster/material-alert-toaster.component';
import { AlertService } from './services/alert.service';
import { ImageCropperDialogComponent } from './components/image-cropper-dialog/image-cropper-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

let socialAuthServiceConfig = {
  provide: 'SocialAuthServiceConfig',
  useValue: {
    autoLogin: false,
    providers: [
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(
          '787145079028-ld6fm1couiks00v7gfjp7lhrbghpsrfq.apps.googleusercontent.com'
        )
      },
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('546227595948383')
      }
    ]
  } as SocialAuthServiceConfig,
}


export function authProviderConfig() {
  return socialAuthServiceConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    AppRoutingComponents,
    RelativeTimePipe,
    TruncateTextPipe,
    PageNotFoundComponent,
    MaterialAlertToasterComponent,
    BasicInfoComponent,
    ContactInfoComponent,
    SocialMediaAccountsInfoComponent,
    AccountInfoComponent,
    SchoolAndEducationInfoComponent,
    ExperienceAndEmploymentInfoComponent,
    MentorshipInfoComponent,
    MembershipInfoComponent,
    ImageCropperDialogComponent,
  ],
  imports: [
    BrowserModule,
    AdminModule,
    UserDashboardModule,
    AppRoutingModule,
    AppMaterialDesignModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    HttpClientModule,
    SwiperModule,
    SatPopoverModule,
    ImageCropperModule,
  ],
  providers: [
    AuthGuard, 
    MembershipGuard,
    socialAuthServiceConfig,
    DialogService,
    AlertService,
    SidebarService,
    FormBuilderService,
    ProgressbarsComponent,
    InputDialogComponent,
    ConfirmDialogComponent,
    ImageCropperDialogComponent,
    AlertDialogComponent,
    FileuploadDialogComponent,
    FilePickerDialogComponent,
    ConfirmEmailDialogComponent,
    ActionPlanComponent,
    SetupActionPlanComponent,
    BecomeMentorComponent,
    { provide: MatDialogRef, useValue: {} },
  { provide: MAT_DIALOG_DATA, useValue: {} },
  {
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ProgressbarsComponent,
    InputDialogComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    FileuploadDialogComponent,
    FilePickerDialogComponent,
    ImageCropperDialogComponent,
    ConfirmEmailDialogComponent,
    ActionPlanComponent,
    SetupActionPlanComponent,
    BecomeMentorComponent,
    MaterialAlertToasterComponent,
  ],
})
export class AppModule { }
