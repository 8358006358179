<div class="bio bg-white p-4 mt-3 mb-3 rounded animate__animated animate__fadeIn">
    <div>
        <h4 class="title-text">Social media links</h4>
        <div class="mt-3">
            <div *ngFor="let media of socialMediaAccounts || []">
                <div class="media bg-white p-2">
                    <div class="mr-3 align-self-center">
                        <svg class="icon-social-media big primary">
                            <use xlink:href="/assets/icons/icons-sheet.svg#icon-social-media">
                            </use>
                        </svg>
                    </div>
                    <div class="media-body">
                        <p class="mt-0 mb-1">{{media.title}}
                        </p>
                        <a class="link mt-0 mb-1" href="{{media.linkToAccount}}"
                            style="font-size: 13px;">{{media.username}}</a>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div>
                            <span (click)="deleteUserSocialMediaAccount(media)" class="" style="margin: 0px;">
                                <svg class="icon-cancel-circled small red clickable">
                                    <use xlink:href="/assets/icons/icons-sheet.svg#icon-cancel-circled">
                                    </use>
                                </svg>
                            </span>
                        </div>
                        <div>
                            <span
                                (click)="editSocialMediaAccount(media);"
                                class="" style="margin: 0px;">
                                <svg class="icon-pencil-square small primary clickable">
                                    <use xlink:href="/assets/icons/icons-sheet.svg#icon-pencil-square">
                                    </use>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>
        <div (click)="addSocialMediaAccount()" class="mt-2 mr-3" style="padding:0 8px 0 8px;">
            <svg class="icon-plus-square-o big primary clickable">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-plus-square-o">
                </use>
            </svg>
            <!-- <small class="title-text">{{addOrEditText}} social media account</small> -->
        </div>
        <div *ngIf="page">
            <form [formGroup]="socialMediaAccountInfoFormGroup" class="form" id="socialMediaAccountInfoFormGroup" name="socialMediaAccountInfoFormGroup"
                style="margin-top: 10px;" #userSocialMediaAccountForm="ngForm"
                (ngSubmit)="socialMediaAccountInfoFormGroup.valid">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="title">Social media name</p>
                        <select formControlName="title" autocomplete="off" type="text" class="form-control" name="title"
                            minlength="3"
                            [ngClass]="{ 'is-invalid': userSocialMediaAccountForm.submitted && socialMediaAccountInfoFormGroup.get('title').hasError('required')}"
                            aria-describedby="titleHint">
                            <option disabled selected>Please select social media</option>
                            <option value="FACEBOOK">Facebook</option>
                            <option value="TWITTER">X</option>
                            <option value="LINKEDIN">Linked In</option>
                            <option value="SKYPE">Skype</option>
                            <option value="TELEGRAM">Telegram</option>
                        </select>

                        <div *ngIf="userSocialMediaAccountForm.submitted && socialMediaAccountInfoFormGroup.get('title').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="title.errors.title">
                                Please provide social media name</small>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="username">Social media account username
                        </p>
                        <input formControlName="username" type="text" class="form-control" id="username" name="username"
                            minlength="3"
                            [ngClass]="{ 'is-invalid': userSocialMediaAccountForm.submitted && socialMediaAccountInfoFormGroup.get('username').hasError('required') }"
                            aria-describedby="usernameHint" autocomplete="off">
                        <div *ngIf="userSocialMediaAccountForm.submitted && socialMediaAccountInfoFormGroup.get('username').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="username.errors.username">
                                Please provide {{socialMediaAccount.title}} username</small>
                        </div>
                    </div>

                    <div class="form-group col-md-12">
                        <p class="subtitle-text" for="linkToAccount">Link to account</p>
                        <input formControlName="linkToAccount" autocomplete="off" type="text" class="form-control"
                            id="linkToAccount" name="linkToAccount" minlength="3"
                            [ngClass]="{ 'is-invalid': userSocialMediaAccountForm.submitted && socialMediaAccountInfoFormGroup.get('linkToAccount').hasError('required') }"
                            aria-describedby="linkToAccountHint">
                        <div *ngIf="userSocialMediaAccountForm.submitted && socialMediaAccountInfoFormGroup.get('linkToAccount').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="linkToAccount.errors.linkToAccount">
                                Please provide link to {{socialMediaAccountInfoFormGroup.get('title').value}}
                                account</small>
                        </div>
                    </div>
                    <div class="col">
                        <button type="submit" class="btn spz-button-green ml-auto"
                            (click)="onSaveUserSocialMediaAccount()"
                            [disabled]="socialMediaAccountInfoFormGroup.invalid">Save
                            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" width="24">
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>