<h1 mat-dialog-reference>Add Inspirational Quote</h1>
<div mat-dialog-content>
    <!-- <blockquote cite="" class="subtitle-text">Action plans, when executed properly, helps you achieve your goals. - David Thompson</blockquote> -->
    <form [formGroup]="inspirationFormGroup" name="inspirationFormGroup" #inspirationForm="ngForm" (ngSubmit)="inspirationForm.valid && onSubmitInspirationalQuote()">
        <div class="form-row">
            <div class="form-group col-md-12">
                <p class="subtitle-text" for="description">Quote</p>
                <textarea formControlName="description" type="text" class="form-control" id="description" name="description" required minlength="5" [ngClass]="{ 'is-invalid': inspirationForm.submitted && inspirationFormGroup.get('description').hasError('description') }"
                    aria-describedby="descriptionHint" rows="3" autocomplete="off">
                </textarea>
                <div *ngIf="inspirationForm.submitted && inspirationFormGroup.get('description').hasError('description')" class="invalid-feedback animate__animated animate__bounceIn">
                    <small *ngIf="inspirationFormGroup.get('description').hasError('required')" id="descriptionHint" class="form-text">description
                        is required</small>
                    <small *ngIf="inspirationFormGroup.get('description').hasError('minlength')" id="descriptionHint" class="form-text">Please
                        provide a minimum length of 5 characters</small>
                    <small *ngIf="inspirationFormGroup.get('description').hasError('description')">Please
                        provide a valid description</small>
                </div>
            </div>
            <div class="form-group col-md-12">
                <p class="subtitle-text" for="reference">Reference</p>
                <input formControlName="reference" type="reference" class="form-control" id="reference" name="reference" required minlength="5" [ngClass]="{ 'is-invalid': inspirationForm.submitted && inspirationFormGroup.get('reference').hasError('reference') }" aria-describedby="referenceHint"
                    autocomplete="off">
                <div *ngIf="inspirationForm.submitted && inspirationFormGroup.get('reference').hasError('reference')" class="invalid-feedback animate__animated animate__bounceIn">
                    <small *ngIf="inspirationFormGroup.get('reference').hasError('required')" id="referenceHint" class="form-text">reference
                        is
                        required</small>
                    <small *ngIf="inspirationFormGroup.get('reference').hasError('minlength')" id="referenceHint" class="form-text">Please
                        provide a minimum length of 5 characters</small>
                    <small *ngIf="inspirationFormGroup.get('reference').hasError('reference')">Please provide a valid
                        reference</small>
                </div>
            </div>
            <div class="form-group col-md-12">
                <p class="subtitle-text" for="category">Category</p>
                <input type="text" placeholder="quote category" matInput formControlName="category" class="form-control" id="category" name="category" [matAutocomplete]="auto" [ngClass]="{ 'is-invalid': inspirationForm.submitted
                && inspirationFormGroup.get('category').hasError('category')}" required aria-describedby="categoryHint" autocomplete="on">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredCategories | async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
                <div *ngIf="inspirationForm.submitted && inspirationFormGroup.get('category').hasError('category')" class="invalid-feedback animate__animated animate__bounceIn">
                    <small *ngIf="inspirationFormGroup.get('category').hasError('required')" id="categoryHint" class="form-text">category is
                        required</small>
                    <!-- <div *ngIf="category.errors.required"></div> -->
                </div>
            </div>
            <div class="col-md-12 d-flex justify-content-center mt-3">
                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                </mat-spinner>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel() ">Cancel</button>
    <button type="submit " mat-button (click)="onSubmitInspirationalQuote(); " [disabled]="!inspirationForm.valid || isLoading">Submit</button>
</div>