import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingComponents, AdminRoutingModule } from './admin-routing.module';
import { AuthGuard } from '../guards/auth.guard';
import { MembershipGuard } from '../guards/membership.guard';
import { AppMaterialDesignModule } from '../app-material-design.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MentoringRequestDetailsComponent } from './pages/mentoring-engagement/mentoring-request-details/mentoring-request-details.component';
import { AssignMentorComponent } from './pages/mentoring-engagement/assign-mentor/assign-mentor.component';


@NgModule({
  declarations: [
   AdminRoutingComponents,
   MentoringRequestDetailsComponent,
   AssignMentorComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    AppMaterialDesignModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers:[
      AuthGuard, 
      MembershipGuard,
      AssignMentorComponent
  ],
  entryComponents: [
    AssignMentorComponent
  ]

})
export class AdminModule { }
