import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent,LoadedImage } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { UploadFile } from 'src/app/models/upload-file';
import {
  DialogButton,
  DialogOptions,
  DialogService,
} from 'src/app/services/dialog.service';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.css'],
})
export class ImageCropperDialogComponent implements OnInit, OnDestroy {
  private unSubscriptioNotifier = new Subject();
  public imageChangedEvent: any = '';
  public croppedImage: any = '';
  private file: UploadFile;
  @ViewChild('fileInput') fileInput: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<ImageCropperDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions,
    public appMaterialDesignModule: AppMaterialDesignModule,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer
  ) {
    //this.file = new UploadFile();
  }

  ngOnInit(): void {}

  public fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    //console.log('base64 image '+this.croppedImage);
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
}
  public cropperReady() {
    // cropper ready
    //base64 to  file
    const fileToReturn = this.base64ToFile(
      this.croppedImage,
      this.imageChangedEvent.target.files[0].name
    );

    let fileItem = fileToReturn;
    let filename = fileItem.name;

    this.file = new UploadFile(
      filename,
      fileItem.type,
      fileItem.size,
      fileItem
    );

    //console.log('yuyu  ' + fileToReturn.name);
  }
  public loadImageFailed() {
    // show message
  }

  public upload(): void {
    this.dialogService.sendMessage({
      text: 'upload button clicked',
      files: [this.file],
      button: DialogButton.upload,
    });
    this.fileInput.nativeElement.value = '';
    this.dialogRef.close();
  }

  public cancel(): void {
    this.file = null;
    this.dialogRef.close();
    this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }
  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  public blobToFile = (theBlob: Blob, fileName:string): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;
      
    //Cast to a File() type
    return b as File;
  }
}
