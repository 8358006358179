import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { LocalAuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthUser } from 'src/app/models/auth-user';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { AppMaterialDesignModule } from '../../app-material-design.module';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { Invite } from 'src/app/admin/pages/invitation/invitation.component';
import { FormInput, InputDialogComponent } from 'src/app/components/input-dialog/input-dialog.component';
import { DialogButton } from 'src/app/services/dialog.service';
import { AlertType } from 'src/app/services/alert.service';


export class FormData {
  firstname: string = '';
  lastname: string = '';
  email: string = '';
  username: string = '';
  password: string = '';
  confirmPassword: string = '';
  provider: string = 'spz';
};

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

export class SignupComponent implements OnInit, OnDestroy {
  public observers: Array<Subscription> = new Array<Subscription>();
  public formData: FormData = new FormData();
  public authUser: AuthUser;
  public signupFormGroup: FormGroup;
  public invite: Invite;

  @ViewChild('invalidConfirmPassword') invalidConfirmPassword: ElementRef;
  @ViewChild('usernameCheckProgress') usernameCheckProgress: ElementRef;
  @ViewChild('emailCheckProgress') emailCheckProgress: ElementRef;
  @ViewChild('usernameExistError') usernameExistError: ElementRef;
  @ViewChild('emailExistError') emailExistError: ElementRef;
  @ViewChild('progressRipple') progressRipple: ElementRef;

  constructor(private backend: BackendService,
    public localAuthService: LocalAuthService,
    public appComponent: AppComponent,
    private appMaterialComponent: AppMaterialDesignModule,
    public alertDialog: AlertDialogComponent,
    private formBuilder: FormBuilder,
    private activateRoute: ActivatedRoute,
    public router: Router) {
  }

  ngOnInit() {
    this.authUser = new AuthUser();
    this.signupFormGroup = new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required]),
      provider: new FormControl('spz', [Validators.nullValidator]),
      referralCode: new FormControl('', [Validators.nullValidator]),
      useReferralCode: new FormControl(true, [Validators.nullValidator])
    }, {
      validators: (group: FormGroup) => {
        const password = group.get('password').value;
        const confirmPassword = group.get('confirmPassword').value;
        return password === confirmPassword ? null : { notSame: true }
      }
    });


    this.activateRoute.queryParams.subscribe(params => {
      let action = params.ac || ''
      if (action == 'referral') {
        //open input dialog for referral
        this.openInputDialog();
      }
    });
  }

  public openInputDialog() {
    let formInputs: FormInput[] = [
      {
        sequence: 1,
        title: 'Referral Code',
        dataType: 'string',
        name: 'referralCode',
        value: '',
        required: true,
      }
    ];

    this.appMaterialComponent.openDialog(InputDialogComponent, {
      title: 'Referral Code',
      message: 'Kinly check your mail and enter Referal code here.',
      width: '700px',
      data: formInputs
    }).pipe(switchMap((inputResult) => {
      if (inputResult.button === DialogButton.ok) {
        let referralData = inputResult['formInput'].map((m) => {
          return { referralCode: m.value }
        })
        this.appMaterialComponent.showProgressDialog('Verifying referral code. Please wait...')
        return this.backend.verifyInvitation(referralData[0]);
      }
    })).subscribe({
      next: (response) => {
        this.appMaterialComponent.hideProgressDialog()
        if (response.code == '000') {
          this.invite = response.data
          console.log('invite : ' + JSON.stringify(this.invite));
          this.signupFormGroup.patchValue({
            firstname: this.invite.recipientFirstname,
            lastname: this.invite.recipientLastname,
            email: this.invite.recipientEmail,
            referralCode:this.invite.referralCode
          });
        } else {
          this.appMaterialComponent.showAlertToaster(AlertType.error, response.message, 3);
        }
      }, error: (error) => {
        this.appMaterialComponent.hideProgressDialog()
        //this.appMaterialComponent.showAlertToaster(AlertType.error, 'Sorry an error Occured. Please try again later.', 3).subscribe();
      }
    })
  }

  ngAfterViewInit(): void {
  }

  onSubmit(): void {
    if (!this.onConfirmPassword()) {
      return;
    }
    //hash password
    this.showProgressRipple();
    let formData = this.signupFormGroup.value
    this.observers.push(this.backend.createUser(formData)
    .subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          this.authUser = response.data;
          this.sendVerifyAccountMail({ email: formData.email });
          this.appMaterialComponent.showAlertToaster(AlertType.success, 'Please check your email to verify your account', 3);
          if (this.authUser.isAuthenticated === true) {
            this.localAuthService.setUser(this.authUser);
            if (this.authUser.membershipId == null || this.authUser.membershipId == '') {
              this.router.navigate(['membership'], { queryParams: {}, skipLocationChange: false });
            }
            else {
              this.router.navigate(['profile'], { queryParams: {}, skipLocationChange: false });
            }
          }
        } else {
          console.log('message ' + response.message)
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.hideProgressRipple();
      },
      complete: () => {
        this.hideProgressRipple();
        this.formData = new FormData();
        console.log('on complete createUser');
      }
    }));
  }

  sendVerifyAccountMail(formData) {
    //this.observers.push(
    this.backend.sendVerifyAccountMail(formData).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
        } else {
          this.appMaterialComponent.showAlertToaster(AlertType.success, response.message,5000);
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete sendVerifyAccountMail');
      }
    })
    //);
  }

  onConfirmPassword(): boolean {
    if (this.formData.password !== this.formData.confirmPassword) {
      this.invalidConfirmPassword.nativeElement.style.display = 'block';
      return false;
    }
    return true;
  }

  showProgressRipple(): void {
    this.progressRipple.nativeElement.style.display = 'inline';
  }
  hideProgressRipple(): void {
    this.progressRipple.nativeElement.style.display = 'none';
  }

  chechIfUsernameExist(): void {
    //check if username exist
    const t = this;
    this.usernameCheckProgress.nativeElement.style.display = 'block';
    this.observers.push(this.backend.chechIfUsernameExist({ username: this.signupFormGroup.value.username }).subscribe({
      next: (data: any) => {
        console.log(data);
        if (data.code !== '000') {
          t.usernameExistError.nativeElement.style.display = 'block';
          t.formData.username = '';
          t.signupFormGroup.patchValue({
            username: ''
          })
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        t.usernameCheckProgress.nativeElement.style.display = 'none';
      },
      complete: () => {
        t.usernameCheckProgress.nativeElement.style.display = 'none';
        console.log('on complete chechIfUsernameExist');
      }
    }));

  }

  chechIfEmailExist(): void {
    //check if username exist
    const t = this;
    this.emailCheckProgress.nativeElement.style.display = 'block';
    this.observers.push(this.backend.chechIfUsernameExist({ username: this.signupFormGroup.value.email }).subscribe({
      next: (data: any) => {
        console.log(data);
        if (data.code !== '000') {
          t.emailExistError.nativeElement.style.display = 'block';
          t.formData.email = '';
          t.signupFormGroup.patchValue({
            username: ''
          })
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        t.emailCheckProgress.nativeElement.style.display = 'none';
      },
      complete: () => {
        t.emailCheckProgress.nativeElement.style.display = 'none';
        console.log('on complete chechIfUsernameExist');
      }
    }));
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }
}
// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

