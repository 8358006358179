import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-progressbars',
  templateUrl: './progressbars.component.html',
  styleUrls: ['./progressbars.component.css']
})
export class ProgressbarsComponent implements OnInit {

  public isLoading: boolean;
  constructor(private dataSource: DataService) {
  }

  ngOnInit() {
    this.dataSource.listen().subscribe((m: any) => {
      console.log(m);
      if (m.includes('isLoading=true')) {
        this.showProgressbar();
      } else if (m.includes('isLoading=false')) {
        this.hideProgressbar();
      }
    });
  }

  private showProgressbar(): void {
    setTimeout(a => {
      console.log('showProgressbar');
      this.isLoading = true;
    }, 4000);
  }

  private hideProgressbar(): void {
    setTimeout(a => {
      console.log('hideProgressbar');
      this.isLoading = false;
    }, 4000);
  }

}
