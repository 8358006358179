import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalAuthService } from 'src/app/services/auth.service';
import { AuthUser } from 'src/app/models/auth-user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit,OnDestroy {

  private observers: Array<Subscription> = new Array<Subscription>();
  public authUser: AuthUser;
  constructor(private authService: LocalAuthService,
    private localAuthService: LocalAuthService, ) { }

  ngOnInit() {
    this.authUser = this.authService.getUser();
    const observer = this.localAuthService.getAuthUser().subscribe(user => {
      this.authUser = user;
    });
    this.observers.push(observer);

  }
  ngOnDestroy(): void {
    this.observers.forEach(observer=>{
      observer.unsubscribe();
    });
  }
}
