import { Component, OnInit, Input, Inject } from '@angular/core';
import { DialogService, DialogButton, DialogOptions } from 'src/app/services/dialog.service';
import { Observable } from 'rxjs';
import { AlertType } from 'src/app/models/alert';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {

  @Input() title:string;
  @Input() message:string;
  @Input() alertType:AlertType;
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
    private dialogService:DialogService,) { }

  ngOnInit() {
  }


  public close(): void {
    this.dialogService.sendMessage({text:'close button clicked',button:DialogButton.close});
    this.dialogRef.close();
  }
}
