<div class="bg-white p-4 mb-3 rounded animate__animated animate__fadeIn">
    <div>
        <h4 class="title-text">Employment details</h4>
        <!--<mat-divider></mat-divider> -->
        <div class="mt-4">
            <div *ngFor="let job of employmentInfo || []" style="padding: 10px 0 10px 0;">
                <div class="media align-items-center" style="height: 50px; margin-bottom: 10px;">
                    <div class="media-left mr-3" style="padding: 4px;">
                        <svg class="icon-engineer big primary">
                            <use xlink:href="/assets/icons/icons-sheet.svg#icon-engineer">
                            </use>
                        </svg>
                    </div>
                    <div class="media-body">
                        <p class="media-heading title-text" style="margin: 0;">{{job.title}}
                        </p>
                        <p class="subtitle-text" style="margin: 0; font-size: 13px;">
                            {{job.workplace}}</p>
                        <p *ngIf="job.isCurrentEmployment === 'true'" class="subtitle-text" style="font-size: 13px;">
                            {{job.startDate | date}} - till date</p>
                        <p *ngIf="job.isCurrentEmployment === ''" class="subtitle-text" style="font-size: 13px;">
                            {{job.startDate | date}} - {{job.endDate | date}}</p>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div>
                            <span (click)="deleteUserEmployment(job)" class="" style="margin: 0px;">
                                <svg class="icon-cancel-circled small red clickable">
                                    <use xlink:href="/assets/icons/icons-sheet.svg#icon-cancel-circled">
                                    </use>
                                </svg>
                            </span>
                        </div>
                        <div>
                            <span data-toggle="collapse" data-target="#userEmploymentForm"
                                (click)="editEmploymentInfo(job)" class="" style="margin: 0px;">
                                <svg class="icon-pencil-square small primary clickable">
                                    <use xlink:href="/assets/icons/icons-sheet.svg#icon-pencil-square">
                                    </use>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div (click)="addEmploymentInfo()" class="mt-2" data-toggle="collapse" data-target="#userEmploymentForm">
            <svg class="icon-plus-square-o big primary clickable">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-plus-square-o">
                </use>
            </svg>
            <!-- <small class="title-text">{{addOrEditText}} employment details</small> -->
        </div>
    </div>
    <!-- <pre>{{employmentInfo  |  json}}</pre> -->
    <form [formGroup]="employmentInfoFormGroup" id="userEmploymentForm"
        class="animate__animated animate__fadeIn collapse" style="margin-top: 10px;" name="employmentInfoFormGroup"
        #userEmploymentForm="ngForm" (ngSubmit)="employmentInfoFormGroup.valid">
        <div class="form-row">
            <div class="form-group col-md-6">
                <p class="subtitle-text" for="title">Job title</p>
                <input formControlName="title" autocomplete="off" type="text" class="form-control" name="title"
                    minlength="3" [ngClass]="{ 'is-invalid': userEmploymentForm.submitted && employmentInfoFormGroup.get('title').hasError('required')
                                                }" aria-describedby="titleHint" required>
                <div *ngIf="userEmploymentForm.submitted && employmentInfoFormGroup.get('title').hasError('required')"
                    class="invalid-feedback animated bounceIn">
                    <small class="text-muted" *ngIf="employmentInfoFormGroup.get('title').hasError('required')">
                        Please provide a job title</small>
                </div>
            </div>
            <div class="form-group col-md-6">
                <p class="subtitle-text" for="workplace">Workplace</p>
                <input formControlName="workplace" autocomplete="off" type="text" class="form-control" id="workplace"
                    name="workplace" minlength="3" [ngClass]="{ 'is-invalid': userEmploymentForm.submitted
                                                && employmentInfoFormGroup.get('workplace').hasError('required') }"
                    aria-describedby="workplaceHint" required>
                <div *ngIf="userEmploymentForm.submitted && employmentInfoFormGroup.get('workplace').hasError('required')"
                    class="invalid-feedback animated bounceIn">
                    <small class="text-muted"
                        *ngIf="workplace.employmentInfoFormGroup.get('workplace').hasError('required')">
                        Please provide workplace</small>
                </div>
            </div>

            <div class="form-group col-md-12">
                <p class="subtitle-text" for="description">Job description</p>
                <textarea formControlName="description" class="form-control" id="description" rows="2"
                    name="description"></textarea>
                <div *ngIf="userEmploymentForm.submitted && employmentInfoFormGroup.get('description').hasError('required')"
                    class="invalid-feedback animated bounceIn" maxlength="255"
                    [ngClass]="{ 'is-invalid': userEmploymentForm.submitted && employmentInfoFormGroup.get('description').hasError('required') }"
                    aria-describedby="descriptionHint">
                    <small *ngIf="description.errors.maxlength" id="description" class="form-text">
                        Please provide a maximum length of 255 characters</small>
                    <small class="text-muted" *ngIf="employmentInfoFormGroup.get('description').hasError('required')">
                        Please provide a description</small>
                </div>
            </div>
            <div class="form-group col-md-6 date">
                <p class="subtitle-text" for="startDateAndTime">Start Date</p>
                <div class="input-group date" data-target-input="nearest">
                    <input formControlName="startDate" autocomplete="off" [matDatepicker]="startDateAndTimePicker"
                        type="text" class="form-control date" name="startDate" minlength="3"
                        [ngClass]="{ 'is-invalid': userEmploymentForm.submitted && employmentInfoFormGroup.get('startDate').hasError('required') }"
                        aria-describedby="startDateHint">
                    <mat-datepicker #startDateAndTimePicker></mat-datepicker>
                    <div class="input-group-append">
                        <div (click)="startDateAndTimePicker.open()" class="input-group-text"><i
                                class="fa fa-calendar"></i></div>
                    </div>
                    <div *ngIf="userEmploymentForm.submitted && employmentInfoFormGroup.get('startDate').hasError('required')"
                        class="invalid-feedback animated bounceIn">
                        <small class="text-muted" *ngIf="employmentInfoFormGroup.get('startDate').hasError('required')">
                            Please provide the start date</small>
                    </div>
                </div>
            </div>

            <div class="form-group col-md-6">
                <p class="subtitle-text" for="endDate">End date</p>
                <div class="input-group date">
                    <input formControlName="endDate" autocomplete="off" type="text"
                        [matDatepicker]="endDateAndTimePicker" class="form-control date" name="endDate" minlength="3"
                        [ngClass]="{ 'is-invalid': userEmploymentForm.submitted && employmentInfoFormGroup.get('endDate').hasError('required') }"
                        aria-describedby="endDateHint">
                    <mat-datepicker #endDateAndTimePicker></mat-datepicker>
                    <div class="input-group-append">
                        <div (click)="endDateAndTimePicker.open()" class="input-group-text">
                            <i class="fa fa-calendar"></i>
                        </div>
                    </div>
                    <div *ngIf="userEmploymentForm.submitted && employmentInfoFormGroup.get('endDate').hasError('required')"
                        class="invalid-feedback animated bounceIn">
                        <small *ngIf="employmentInfoFormGroup.get('endDate').hasError('required')">
                            Please provide the end date</small>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6">
                <div class="form-check form-check-inline">
                    <input formControlName="isCurrentEmployment"
                        value="{{employmentInfoFormGroup.get('isCurrentEmployment').value}}"
                        class="form-check-input position-static" style="font-size: 18px;" #isCurrentEmployment
                        type="checkbox" id="isCurrentEmployment"
                        (change)="onCheckboxChanged(employmentInfoFormGroup.get('isCurrentEmployment').value,$event)"
                        aria-label="...">
                    <p class="subtitle-text form-check-label" for="isCurrentEmployment">I
                        current work here</p>
                </div>
            </div>
            <div class="col-md-12">
                <button type="submit" style="margin-bottom: 10px;" class="btn spz-button-green ml-auto"
                    (click)="onSaveUserEmployment()" [disabled]="!employmentInfoFormGroup.valid">Save
                    <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
                </button>
            </div>
        </div>
    </form>
</div>

<div class="bg-white p-4 mb-3 rounded animate__animated animate__fadeIn">
    <div>
        <h4 class="title-text">Skills and Experience</h4>
        <div class="mt-3 mb-3 ml-0">
            <div class="d-flex justify-content-between align-items-center mb-2" *ngFor="let skill of skillsInfo || []">
                <div>
                    <p class="m-0 p-0" style="font-size: 16px;">{{skill?.title}}</p>
                    <small class="text-muted">{{skill.fromDate | date}} - {{skill.toDate | date}}</small>
                </div>
                <i style="font-size: 18px;" class="fa fa-pencil-square-o" (click)="editUserSkills(skill)"></i>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div (click)="addUserSkills()" class="mt-2" data-toggle="collapse" data-target="#userSkillsForm">
            <svg class="icon-plus-square-o big primary clickable">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-plus-square-o">
                </use>
            </svg>
        </div>
    </div>

    <form [formGroup]="userSkillsFormGroup" id="userSkillsForm" name="userSkillsFormGroup"
        class="animate__animated animate__fadeIn collapse" style="margin-top: 10px;" #userSkillsForm="ngForm"
        (ngSubmit)="userSkillsFormGroup.valid">
        <div class="form-row">
            <div class="form-group col-md-12">
                <p class="subtitle-text" for="title">Title</p>
                <input formControlName="title" autocomplete="off" type="text" class="form-control" name="title"
                    id="skill_title" minlength="3" [ngClass]="{ 'is-invalid': userSkillsForm.submitted && userSkillsFormGroup.get('title').hasError('required')
                                                }" aria-describedby="titleHint" required>
                <div *ngIf="userSkillsForm.submitted && userSkillsFormGroup.get('title').hasError('required')"
                    class="invalid-feedback animated bounceIn">
                    <small class="text-muted" *ngIf="userSkillsFormGroup.get('title').hasError('required')">
                        Please provide a skill title</small>
                </div>
            </div>

            <div class="form-group col-md-12">
                <p class="subtitle-text" for="description">Description</p>
                <textarea formControlName="description" class="form-control" id="skill_description" rows="2"
                    name="description"></textarea>
                <div *ngIf="userSkillsForm.submitted && userSkillsFormGroup.get('description').hasError('required')"
                    class="invalid-feedback animated bounceIn" maxlength="255"
                    [ngClass]="{ 'is-invalid': userSkillsForm.submitted && userSkillsFormGroup.get('description').hasError('required') }"
                    aria-describedby="descriptionHint">
                    <small *ngIf="description.errors.maxlength" id="description" class="form-text">
                        Please provide a maximum length of 255 characters</small>
                    <small class="text-muted" *ngIf="userSkillsFormGroup.get('description').hasError('required')">
                        Please provide a description</small>
                </div>
            </div>
            <div class="form-group col-md-6 date">
                <p class="subtitle-text" for="startDateAndTime">From Date</p>
                <div class="input-group date" data-target-input="nearest">
                    <input formControlName="fromDate" autocomplete="off" [matDatepicker]="fromDateAndTimePicker"
                        type="text" class="form-control date" name="fromDate" minlength="3" id="fromDate"
                        [ngClass]="{ 'is-invalid': userSkillsForm.submitted && userSkillsFormGroup.get('fromDate').hasError('required') }"
                        aria-describedby="startDateHint">
                    <mat-datepicker #fromDateAndTimePicker></mat-datepicker>
                    <div class="input-group-append">
                        <div (click)="fromDateAndTimePicker.open()" class="input-group-text"><i
                                class="fa fa-calendar"></i></div>
                    </div>
                    <div *ngIf="userSkillsForm.submitted && userSkillsFormGroup.get('fromDate').hasError('required')"
                        class="invalid-feedback animated bounceIn">
                        <small class="text-muted" *ngIf="userSkillsFormGroup.get('fromDate').hasError('required')">
                            Please provide the start date</small>
                    </div>
                </div>
            </div>
            <div class="form-group col-md-6">
                <p class="subtitle-text" for="endDate">To date</p>
                <div class="input-group date">
                    <input formControlName="toDate" autocomplete="off" type="text" [matDatepicker]="toDateAndTimePicker"
                        class="form-control date" name="toDate" minlength="3"
                        [ngClass]="{ 'is-invalid': userSkillsForm.submitted && userSkillsFormGroup.get('toDate').hasError('required') }"
                        aria-describedby="endDateHint">
                    <mat-datepicker #toDateAndTimePicker></mat-datepicker>
                    <div class="input-group-append">
                        <div (click)="toDateAndTimePicker.open()" class="input-group-text">
                            <i class="fa fa-calendar"></i>
                        </div>
                    </div>
                    <div *ngIf="userSkillsForm.submitted && userSkillsFormGroup.get('toDate').hasError('required')"
                        class="invalid-feedback animated bounceIn">
                        <small *ngIf="userSkillsFormGroup.get('toDate').hasError('required')">
                            Please provide the end date</small>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <button type="submit" style="margin-bottom: 10px;" class="btn spz-button-green ml-auto"
                    (click)="onSaveUserSkills()" [disabled]="!userSkillsFormGroup.valid">Save
                    <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
                </button>
            </div>
        </div>
    </form>
</div>