<div class="container-fluid">
    
    <div>
        <button type="button" #collapseBtn style="margin-bottom: 10px; margin-top:5px;" data-toggle="collapse" data-target="#invitationForm" class="btn btn-default btn-sm">
            Create Invite
        </button>
        <!-- <pre>{{invitationFormGroup.value | json}}</pre> -->
        <form class="card p-3 mb-3 animate__animated animate__fadeIn collapse" [formGroup]="invitationFormGroup" id="invitationForm" name="invitationForm" #invitationForm="ngForm" (ngSubmit)="invitationFormGroup.valid && onSubmit()">
            <div class="form-row justify-content-center card-shadow bg-white mb-2 pl-2 pr-2">
                <div class="col-md-6">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="standard" style="width: 100%">
                        <mat-label style="font-size: 16px;">Title</mat-label>
                        <input matInput required formControlName="title" type="text" name="title" id="title" autocomplete="off" style="font-size: 16px;" />
                        <!-- <mat-hint>name or mobile number here</mat-hint> -->
                        <div *ngIf="invitationForm.submitted && invitationFormGroup.get('title').invalid && (invitationFormGroup.get('title').dirty || invitationFormGroup.get('title').touched)" class="animate__animated animate__bounceIn">
                            <mat-error *ngIf="invitationFormGroup.get('title').hasError('minlength')">
                                enter minimum of 6 characters
                            </mat-error>
                            <mat-error *ngIf="invitationFormGroup.get('title').hasError('required')">
                                Title is required
                            </mat-error>
                        </div>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="standard" style="width: 100%">
                        <mat-label style="font-size: 16px;">Recipient Email</mat-label>
                        <input matInput required formControlName="recipientEmail" type="text" name="recipientEmail" id="recipientEmail" autocomplete="off" style="font-size: 16px;" />
                        <!-- <mat-hint>name or mobile number here</mat-hint> -->
                        <div *ngIf="invitationForm.submitted && invitationFormGroup.get('recipientEmail').invalid && (invitationFormGroup.get('recipientEmail').dirty || invitationFormGroup.get('recipientEmail').touched)" class="animate__animated animate__bounceIn">
                            <mat-error *ngIf="invitationFormGroup.get('recipientEmail').hasError('minlength')">
                                enter minimum of 6 characters
                            </mat-error>
                            <mat-error *ngIf="invitationFormGroup.get('recipientEmail').hasError('required')">
                                Recipient Firstname is required
                            </mat-error>
                        </div>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-row justify-content-center card-shadow bg-white mb-2 pl-2 pr-2">
                <div class="col-md-6">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="standard" style="width: 100%">
                        <mat-label style="font-size: 16px;">Recipient Firstname</mat-label>
                        <input matInput required formControlName="recipientFirstname" type="text" name="recipientFirstname" id="recipientFirstname" autocomplete="off" style="font-size: 16px;" />
                        <!-- <mat-hint>name or mobile number here</mat-hint> -->
                        <div *ngIf="invitationForm.submitted && invitationFormGroup.get('recipientFirstname').invalid && (invitationFormGroup.get('recipientFirstname').dirty || invitationFormGroup.get('recipientFirstname').touched)" class="animate__animated animate__bounceIn">
                            <mat-error *ngIf="invitationFormGroup.get('recipientFirstname').hasError('minlength')">
                                enter minimum of 6 characters
                            </mat-error>
                            <mat-error *ngIf="invitationFormGroup.get('recipientFirstname').hasError('required')">
                                Recipient Firstname is required
                            </mat-error>
                        </div>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="standard" style="width: 100%">
                        <mat-label style="font-size: 16px;">Recipient Lastname</mat-label>
                        <input matInput required formControlName="recipientLastname" type="text" name="recipientLastname" id="recipientLastname" autocomplete="off" style="font-size: 16px;" />
                        <!-- <mat-hint>name or mobile number here</mat-hint> -->
                        <div *ngIf="invitationForm.submitted && invitationFormGroup.get('recipientLastname').invalid && (invitationFormGroup.get('recipientLastname').dirty || invitationFormGroup.get('recipientLastname').touched)" class="animate__animated animate__bounceIn">
                            <mat-error *ngIf="invitationFormGroup.get('recipientLastname').hasError('minlength')">
                                enter minimum of 6 characters
                            </mat-error>
                            <mat-error *ngIf="invitationFormGroup.get('recipientLastname').hasError('required')">
                                Recipient Firstname is required
                            </mat-error>
                        </div>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-row justify-content-center card-shadow bg-white mb-2 pl-2 pr-2">
                <div class="col-md-8">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="standard" style="width: 100%">
                        <mat-label style="font-size: 16px;">Message</mat-label>
                        <textarea matInput required formControlName="message" type="text" name="message" id="message" autocomplete="off" style="font-size: 16px;"></textarea>
                        <!-- <mat-hint>Role message here</mat-hint> -->
                        <div *ngIf="invitationForm.submitted && invitationFormGroup.get('message').invalid && (invitationFormGroup.get('message').dirty || invitationFormGroup.get('message').touched)" class="animate__animated animate__bounceIn">
                            <mat-error *ngIf="invitationFormGroup.get('message').hasError('minlength')">
                                Enter minimum of 6 characters
                            </mat-error>
                            <mat-error *ngIf="invitationFormGroup.get('message').hasError('required')">
                                Message is required
                            </mat-error>
                        </div>
                    </mat-form-field>
                </div>
                <div class="col-md-4" style="margin-top: 18px;">
                    <mat-form-field floatLabel="auto" hideRequiredMarker appearance="standard" style="width: 100%">
                        <mat-label style="font-size: 16px;">Select Category</mat-label>
                        <mat-select formControlName="category" name="category" id="category" style="font-size: 16px;">
                            <mat-option value="SILVER">
                                SILVER
                            </mat-option>
                            <mat-option value="GOLD">
                                GOLD
                            </mat-option>
                            <mat-option value="PLATINUM">
                                PLATINUM
                            </mat-option>
                        </mat-select>
                        <mat-hint>This category correspond to the Membership category the will be assigned</mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-row justify-content-between pl-2 pr-2 mt-4">
                <mat-slide-toggle formControlName="sendEmail">Send Email</mat-slide-toggle>
                <button type="submit " style="margin-bottom: 10px; " [disabled]="!invitationFormGroup.valid " class="btn btn-default ml-auto pull-right ">
                    Save
                </button>
            </div>

            <div class="col-md-12 d-flex justify-content-center mt-3">
                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                </mat-spinner>
            </div>
        </form>
    </div>
    <div class="card table-responsive bg-white p-1" style="max-height: 620px;">
        <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
            <thead>
                <tr>
                    <th scope="col">Recipient Name</th>
                    <th scope="col">Recipient Email</th>
                    <th scope="col">Recipient Status</th>
                    <th scope="col">Category</th>
                    <th scope="col">Referral Code</th>
                    <th scope="col">Date Created</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr class="animate__animated animate__fadeIn" *ngFor="let invite of invitationList$ | async" style="border: .5px solid #EDEDED; font-size: 15px;">
                    <td class="align-middle">{{invite.recipientFirstname+' '+invite.recipientLastname }}</td>
                    <td class="align-middle">{{invite.recipientEmail}}</td>
                    <td class="align-middle">{{invite.status}}</td>
                    <td class="align-middle">{{invite.category}}</td>
                    <td class="align-middle">{{invite.referralCode}}</td>
                    <td class="align-middle">{{invite.createdAt | date}}</td>
                    <td class="align-middle">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button (click)="sendInvite(invite)" mat-menu-item>
                                <mat-icon>send</mat-icon>
                                <span>Send</span>
                            </button>
                            <button (click)="deleteInvite(invite)" mat-menu-item>
                                <mat-icon>delete_outline</mat-icon>
                                <span>Delete</span>
                            </button>
                        </mat-menu>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
            </mat-spinner>
        </div>
    </div>
</div>