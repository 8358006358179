<div class="container">
    <div class="row">
        <div class="col-md-12 col-sm-12" style="margin-top: 8px;">
            <div class="col mini-bar-menu bg-white card-shadow" style="margin-bottom: 5px; padding: 10px;">
                <ul class="horizontal-list">
                    <li>
                        <button class="btn btn-sm btn-outline-primary" (click)="openInspirationalQuoteDialog()">Add quote</button>
                    </li>
                    <li>
                        <a href="/inspirations/blogs">Read blogs</a>
                    </li>
                    <li>
                        <a href="/inspirations/watchtalks">Watch talks</a>
                    </li>
                    <li>
                        <a href="/inspirations/magazines">Read magazines</a>
                    </li>
                </ul>
            </div>
            <div class="row">
                <div class="col-md-7 order-md-1">
                    <div *ngFor="let inspiration of inspirationalQuotes">
                        <div class="col bg-white card-shadow" style="margin: 0 1px 5px 1px; padding: 5px;">
                            <div *ngIf="inspiration.user.id === authUser.id">
                                <span (click)="deleteInspirationalQuote(inspiration)" style="margin: 0px;" class="pull-right">
                                        <svg class="icon-cancel-circled small grey clickable">
                                            <use xlink:href="/assets/icons/icons-sheet.svg#icon-cancel-circled">
                                            </use>
                                        </svg>
                                    </span>
                                <span (click)="inspirationFormData=inspiration;openInspirationalQuoteDialog();" class="" style="margin: 0px;" class="pull-right">
                                        <svg class="icon-edit-modify-streamline small grey clickable">
                                            <use xlink:href="/assets/icons/icons-sheet.svg#icon-edit-modify-streamline">
                                            </use>
                                        </svg>
                                    </span>
                            </div>
                            <div class="media animated fadeIn">
                                <div class="media-left" style="padding: 5px;">
                                    <img src="{{inspiration.user.image}}" class="media-object profile-image-small">
                                </div>
                                <div class="media-body">
                                    <p class="media-heading title-text" style="font-size:13px; line-height: 1.3; display: inline; padding: 2px;">
                                        {{inspiration.user.username}}</p>
                                    <div>
                                        <p class="subtitle-text" style="margin-top: 2px; font-size: 12px;">
                                            {{inspiration.description}}</p>
                                        <span class="">
                                                <svg class="icon-at small grey">
                                                    <use xlink:href="/assets/icons/icons-sheet.svg#icon-at"></use>
                                                </svg>
                                                <p class="subtitle-text"
                                                    style="margin: 0; font-size: 13px; display: inline;">
                                                    -{{inspiration.reference}}</p>
                                            </span>
                                        <span class="">
                                                <svg class="icon-clock-streamline-time small grey">
                                                    <use
                                                        xlink:href="/assets/icons/icons-sheet.svg#icon-clock-streamline-time">
                                                    </use>
                                                </svg>
                                                <small class="text-muted">{{inspiration.datePosted | relativeTime}}</small>
                                            </span>
                                        <span class="category">
                                                <svg class="icon-filter small grey">
                                                    <use xlink:href="/assets/icons/icons-sheet.svg#icon-filter">
                                                    </use>
                                                </svg>
                                                <a class="subtitle-text"
                                                    style="margin: 0; text-decoration: none; font-size: 12px; display: inline;"
                                                    [routerLink]="['/startingpointzone/inspirations']"
                                                    [queryParams]="{category:inspiration.category}"
                                                    routerLinkActive="active-link">
                                                    {{inspiration.category}}
                                                </a>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 d-flex justify-content-center mt-3">
                        <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                        </mat-spinner>
                    </div>
                </div>
                <div class="col-md-5 order-md-2">
                    <div class="card-1" style="padding: 10px; margin-bottom: 10px;">
                        <p class="title-text">Suggested Feeds</p>
                        <hr class="divider">
                        <ul class="" style="padding: 0; margin: 0;">
                            <li class="media">
                                <div class="media-left media-middle">
                                    <img src="https://placekitten.com/100/100" class="media-object" style="width:45px">
                                </div>
                                <div class="media-body title-text">
                                    <p class="media-heading" style="font-size: 13px; padding: 0; margin: 0px;">Media Middle
                                    </p>
                                    <small class="subtitle-text" style="padding: 0; margin: 0px;">Lorem ipsum...</small>
                                    <button style="display: inline;" class="btn btn-default btn-sm pull-right" (click)="subscribeToFeed()">
                                            subscribe</button>
                                </div>
                            </li>
                            <li class="media">
                                <div class="media-left media-middle">
                                    <img src="https://placekitten.com/100/100" class="media-object" style="width:45px">
                                </div>
                                <div class="media-body">
                                    <p class="media-heading title-text" style="font-size: 13px; padding: 0; margin: 0px;">Media Middle
                                    </p>
                                    <small class="subtitle-text" style="padding: 0; margin: 0px;">Lorem ipsum...</small>
                                    <button style="display: inline;" class="btn btn-default btn-sm pull-right" (click)="subscribeToFeed()">
                                            subscribe</button>
                                </div>
                            </li>
                            <li class="media">
                                <div class="media-left media-middle">
                                    <img src="https://placekitten.com/100/100" class="media-object" style="width:45px">
                                </div>
                                <div class="media-body">
                                    <p class="media-heading title-text" style="font-size: 13px; padding: 0; margin: 0px;">Media Middle
                                    </p>
                                    <small class="subtitle-text" style="padding: 0; margin: 0px;">Lorem ipsum...</small>
                                    <button style="display: inline;" class="btn btn-sm btn-default pull-right" (click)="subscribeToFeed()">
                                            subscribe</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="sticky-top">
                            <p class="title-text" style="padding-top: 10px;">Overview</p>
                            <div class="card-1" style="padding: 10px; margin-bottom: 10px;">
                                <div style="margin: 0px;" class="pull-right">
                                    <svg class="icon-plus-square-o small grey clickable">
                                        <use xlink:href="/assets/icons/icons-sheet.svg#icon-plus-square-o">
                                        </use>
                                    </svg>
                                </div>
                                <p class="text-with-line subtitle-text">Next week's action</p>
                                <ul class="subtitle-text">
                                    <li>Visit the UK</li>
                                    <li>Go to this conference</li>
                                    <li>Watch inspirational movies</li>
                                </ul>
                            </div>
    
                            <div class="card-1" style="padding: 10px; margin-bottom: 10px;">
                                <div style="margin: 0px;" class="pull-right">
                                    <svg class="icon-plus-square-o small grey clickable">
                                        <use xlink:href="/assets/icons/icons-sheet.svg#icon-plus-square-o">
                                        </use>
                                    </svg>
                                </div>
                                <p class="text-with-line subtitle-text">My 1 year goals</p>
                                <ul class="subtitle-text">
                                    <li>Visit the UK</li>
                                    <li>Go to this conference</li>
                                    <li>Watch inspirational movies</li>
                                </ul>
    
                            </div>
                            <div class="card-1" style="padding: 10px; margin-bottom: 10px;">
                                <div style="margin: 0px;" class="pull-right">
                                    <svg class="icon-plus-square-o small grey clickable">
                                        <use xlink:href="/assets/icons/icons-sheet.svg#icon-plus-square-o">
                                        </use>
                                    </svg>
                                </div>
                                <p class="text-with-line subtitle-text">My 5 years goals</p>
                                <ul class="subtitle-text">
                                    <li>Visit the UK</li>
                                    <li>Go to this conference</li>
                                    <li>Watch inspirational movies</li>
                                </ul>
                            </div>
                        </div> -->
                </div>
            </div>
        </div>
    </div>
</div>