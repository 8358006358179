<h1 mat-dialog-title>Setup an action plan</h1>
<div mat-dialog-content>
    <blockquote cite="" class="subtitle-text">Action plans, when executed properly, helps you achieve your goals. - David Thompson</blockquote>
    <form [formGroup]="actionPlanFormGroup" name="actionPlanFormGroup" #actionPlanForm="ngForm" (ngSubmit)="actionPlanForm.valid && onSaveActionPlan()">
        <div class="form-row">
            <div class="form-group col-md-12">
                <p class="subtitle-text" for="title">Title</p>
                <input formControlName="title" type="title" class="form-control" id="title" name="title" required autofocus minlength="5" [ngClass]="{ 'is-invalid': actionPlanForm.submitted && actionPlanFormGroup.get('title').hasError('title') }" aria-describedby="titleHint"
                    autocomplete="off">
                <div *ngIf="actionPlanForm.submitted && actionPlanFormGroup.get('title').hasError('title')" class="invalid-feedback animate__animated animate__bounceIn">
                    <small *ngIf="actionPlanFormGroup.get('title').hasError('required')" id="titleHint" class="form-text">title
                        is
                        required</small>
                    <small *ngIf="actionPlanFormGroup.get('title').hasError('minlength')" id="titleHint" class="form-text">Please
                        provide a minimum length of 5 characters</small>
                    <small *ngIf="actionPlanFormGroup.get('title').hasError('title')">Please provide a valid
                        title</small>
                </div>
            </div>
            <div class="form-group col-md-12">
                <p class="subtitle-text" for="description">Description</p>
                <textarea formControlName="description" type="text" class="form-control" id="description" name="description" required autofocus minlength="5" [ngClass]="{ 'is-invalid': actionPlanForm.submitted && actionPlanFormGroup.get('description').hasError('description') }"
                    aria-describedby="descriptionHint" rows="3" autocomplete="off">
                </textarea>
                <div *ngIf="actionPlanForm.submitted && actionPlanFormGroup.get('description').hasError('description')" class="invalid-feedback animate__animated animate__bounceIn">
                    <small *ngIf="actionPlanFormGroup.get('description').hasError('required')" id="descriptionHint" class="form-text">description
                        is required</small>
                    <small *ngIf="actionPlanFormGroup.get('description').hasError('minlength')" id="descriptionHint" class="form-text">Please
                        provide a minimum length of 5 characters</small>
                    <small *ngIf="actionPlanFormGroup.get('description').hasError('description')">Please
                        provide a valid description</small>
                </div>
            </div>
            <div class="form-group col-12 date">
                <p class="subtitle-text" for="dateOfExecution">Date Of Execution</p>
                <div class="input-group date" data-target-input="nearest">
                    <input [matDatepicker]="dateOfExecutionPicker" autocomplete="off" formControlName="dateOfExecution" type="text" class="form-control date" id="dateOfExecution" name="dateOfExecution" minlength="3" [ngClass]="{ 'is-invalid': actionPlanForm.submitted && actionPlanFormGroup.get('dateOfExecution').hasError('dateOfExecution') }"
                        aria-describedby="dateOfExecutionHint">
                    <!-- <mat-datepicker-toggle matSuffix [for]="dateOfExecutionPicker">
                    </mat-datepicker-toggle> -->
                    <mat-datepicker #dateOfExecutionPicker></mat-datepicker>
                    <div class="input-group-append">
                        <div (click)="dateOfExecutionPicker.open()" class="input-group-text"><i class="fa fa-calendar"></i></div>
                    </div>
                    <div *ngIf="actionPlanForm.submitted && actionPlanFormGroup.get('dateOfExecution').hasError('required')" class="invalid-feedback  animate__animated animate__bounceIn">
                        <small *ngIf="actionPlanFormGroup.get('dateOfExecution').hasError('dateOfExecution')">
                            Please provide date</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 d-flex justify-content-center mt-3">
            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
            </mat-spinner>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel() ">Cancel</button>
    <button type="submit " mat-button (click)="onSaveActionPlan(); " [disabled]="!actionPlanForm.valid || isLoading">Submit</button>
</div>