import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewEncapsulation,
} from '@angular/core';
import { Scope } from 'src/app/models/scope';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { Mentor } from 'src/app/models/mentor';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, OnDestroy {
  public events: string[] = [];
  public opened: boolean = true;
  public authUser = null;
  private observer: Subscription;
  public sidebarToggle = 'off';
  private userId: string = '';
  private isLoading = false;
  isLoadingArticles = false;
  private unSubscriptioNotifier = new Subject();
  public objectiveSearchInput: FormControl;
  public newsArticles: Observable<any[]>;
  private newsArticleBehaviorSubject: BehaviorSubject<any[]>;
  public mentorList$: Observable<Mentor[]> = new Observable<Mentor[]>();
  public mentorList: Mentor[] = [];
  constructor(
    private localAuthService: LocalAuthService,
    private backend: BackendService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private dataSource: DataService
  ) {
    this.authUser = this.localAuthService.getUser();
    this.objectiveSearchInput = new FormControl('', [Validators.nullValidator]);

    this.newsArticles = new Observable<any[]>();
    this.newsArticleBehaviorSubject = new BehaviorSubject<any>([]);
    this.newsArticles = this.newsArticleBehaviorSubject.asObservable();
  }

  ngOnInit() {
    this.userId = localStorage.getItem('userId');
    //this.getUserDetails(this.userId);
    this.activateRoute.queryParams.subscribe((params) => {
      this.sidebarToggle = params.sb || 'off';
    });
    this.getNyNewsArticle();
    this.getMentorList();
  }

  public getMentorList() {
    this.isLoading = true;
    this.backend
      .getAllMentors()
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          if (response.code === '000') {
            this.mentorList = response.data;
            this.mentorList$ = new BehaviorSubject(response.data);
          }
          this.isLoading = false;
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          console.log('on complete getMentors');
        },
      });
  }

  getNyNewsArticle() {
    this.isLoadingArticles = true;
    const sections = [
      'arts',
      'automobiles',
      'books/review',
      'business',
      'fashion',
      'food',
      'health',
      'home',
      'insider',
      'magazine',
      'movies',
      'nyregion',
      'obituaries',
      'opinion',
      'politics',
      'realestate',
      'science',
      'sports',
      'sundayreview',
      'technology',
      'theater',
      't-magazine',
      'travel',
      'upshot',
      'us',
      'world',
    ];
    this.backend
      .getnytimes_topstories(
        sections[Math.floor(Math.random() * sections.length)]
      )
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.isLoadingArticles = false;
          if ('OK' == response['status']) {
            const results = response['results'];
            this.newsArticleBehaviorSubject.next(results.slice(0, 5));
          }
        },
      });
  }

  getUserDetails(formData: any) {
    this.observer = this.backend.getUserDetails3(formData).subscribe({
      next: (data: User) => {
        this.authUser = data;
        this.dataSource.setData(data);
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete getUserDetails');
      },
    });
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next('');
    this.unSubscriptioNotifier.complete();
  }

  public toggleSidebar() {
    if (this.sidebarToggle == 'on') this.sidebarToggle = 'off';
    else this.sidebarToggle = 'on';
  }

  public signout(): void {
    if (this.localAuthService.logout()) {
      this.localAuthService
        .signOut()
        .then(
          (onfulfilled) => {
            console.log('onfulfilled... ' + onfulfilled);
          },
          (onrejected) => {
            console.log('onrejected... ' + onrejected);
          }
        )
        .catch((onrejected) => {
          console.log('catch onrejected... ' + onrejected);
        })
        .finally(() => {
          console.log('onfinally...');
          this.router.navigate(['/login']);
        });
    }
  }
}
