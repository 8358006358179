<div class="p-3 bg-white rounded border mb-3 animate__animated animate__fadeIn p-3" style="margin-bottom: 5px;">
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="title-text">Account information</h4>
            <div data-toggle="collapse" data-target="#userAccountFormGroup" (click)="isUserPasswordFormCollapsed = !isUserPasswordFormCollapsed">
                <i style="font-size: 24px;" class="fa fa-pencil-square-o"></i>
            </div>
        </div>
        <!--<mat-divider></mat-divider> -->
        <table class="table" style="width:100%">
            <tr>
                <th class="subtitle-text">Username</th>
                <td class="title-text">{{userAccountInfo.username}}</td>
            </tr>
            <tr>
                <th class="subtitle-text">Email</th>
                <td class="title-text">{{userAccountInfo.email}}</td>
            </tr>
        </table>
    </div>

    <form [formGroup]="userAccountFormGroup" id="userAccountFormGroup"
        class="animate__animated animate__fadeIn collapse" name="userAccountFormGroup" #userAccountForm="ngForm"
        (ngSubmit)="userAccountFormGroup.valid">
        <div class="form-row">
            <div class="form-group col-md-6">
                <p class="subtitle-text" for="username">Username</p>
                <div class="input-group">
                    <input formControlName="username" id="username" (blur)="chechIfUsernameExist()" type="text"
                        class="form-control" name="username" [ngClass]="{ 'is-invalid': userAccountForm.submitted
                    && userAccountFormGroup.get('username').hasError('required') }" required
                        aria-describedby="emailHint" autocomplete="off">
                    <div class="input-group-append">
                        <div #usernameCheckProgress class="input-group-text"
                            style="background: transparent !important; display: none;">
                            <img alt="temp image" src="/assets/img/ripple.gif" class="" width="20">
                        </div>
                    </div>
                </div>
                <div *ngIf="userAccountForm.submitted && userAccountFormGroup.get('username').hasError('required')"
                    class="invalid-feedback animated bounceIn">
                    <small *ngIf="username.errors.required" id="usernameHint" class="form-text">username
                        is
                        required</small>
                    <!-- <div *ngIf="username.errors.required"></div> -->
                    <small *ngIf="username.errors.username">this username has been
                        taken</small>
                </div>
                <small #usernameExistError style="display: none;"
                    class="invalid-feedback animated bounceIn form-text">this username has
                    been
                    taken</small>
            </div>
            <div class="form-group col-md-6">
                <p class="subtitle-text" for="email">Email address</p>
                <input formControlName="email" (blur)="chechIfEmailExist()" class="form-control" id="email" name="email"
                    [ngClass]="{ 'is-invalid': userAccountForm.submitted
                    && userAccountFormGroup.get('username').hasError('required') }" required email
                    aria-describedby="emailHint" autocomplete="off">
                <div *ngIf="userAccountForm.submitted && userAccountFormGroup.get('email').hasError('required')"
                    class="invalid-feedback animated bounceIn">
                    <small *ngIf="userAccountFormGroup.get('email').hasErrors('required')" id="emailHint"
                        class="form-text">Email
                        is
                        required</small>
                    <!-- <div *ngIf="email.errors.required"></div> -->
                    <small *ngIf="email.errors.email">Email must be a valid email
                        address</small>
                </div>
                <small #emailExistError style="display: none;" class="invalid-feedback animated bounceIn form-text">this
                    email has been
                    taken</small>
            </div>
            <div class="col-12">
                <button type="submit" (click)="updateUserAccount()"
                    class="btn spz-button-green ml-auto">Save
                    <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
                </button>
            </div>
        </div>
    </form>
</div>
<div class="p-3 rounded mb-3 bg-white border animate__animated animate__fadeIn">
    <div class="mb-3">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <p class="title-text" style="margin: 0;">
                    Change password</p>
                <p class="subtitle-text" style="margin: 0; font-size: 13px;">
                    It's a good idea to use a strong password that you're not using
                    elsewhere
                </p>
            </div>
            <div data-toggle="collapse" data-target="#userPasswordFormGroup" (click)="isUserPasswordFormCollapsed = !isUserPasswordFormCollapsed">
                <i style="font-size: 24px;" class="fa fa-pencil-square-o"></i>
            </div>
        </div>
    </div>
    <form [formGroup]="userPasswordFormGroup" id="userPasswordFormGroup"
        class="animate__animated animate__fadeIn collapse" name="userPasswordFormGroup" #userPasswordForm="ngForm"
        (ngSubmit)="userPasswordFormGroup.valid">
        <div class="form-row">
            <div class="form-group col-md-4">
                <p class="subtitle-text" for="password">Current password</p>
                <input formControlName="password" id="password" type="password" class="form-control"
                    [ngClass]="{ 'is-invalid': userPasswordForm.submitted && userPasswordFormGroup.get('password').hasError('required')}"
                    id="password" name="password" required minlength="8" password aria-describedby="passwordHint"
                    autocomplete="off">
                <div *ngIf="userPasswordForm.submitted && userPasswordFormGroup.get('password').hasError('required')"
                    class="invalid-feedback animated bounceIn">
                    <small *ngIf="password.errors.required" id="passwordHint" class="form-text">password
                        is
                        required</small>
                    <small *ngIf="password.errors.minlength" id="passwordHint" class="form-text">Please
                        provide a minimum length of 8 characters</small>
                    <small *ngIf="password.errors.password">Please provide a valid
                        password</small>
                </div>
            </div>
            <div class="form-group col-md-4">
                <p class="subtitle-text" for="password">New password</p>
                <input formControlName="newpassword" id="newpassword" type="password" class="form-control"
                    [ngClass]="{ 'is-invalid': userPasswordForm.submitted && userPasswordFormGroup.get('newpassword').hasError('required') }"
                    id="newpassword" name="newpassword" required minlength="8" password
                    aria-describedby="newpasswordHint" autocomplete="off">
                <div *ngIf="userPasswordForm.submitted && userPasswordFormGroup.get('newpassword').hasError('required')"
                    class="invalid-feedback animated bounceIn">
                    <small *ngIf="newpassword.errors.required" id="newpasswordHint" class="form-text">password is
                        required</small>
                    <small *ngIf="newpassword.errors.minlength" id="newpasswordHint" class="form-text">Please
                        provide a minimum length of 8 characters</small>
                    <small *ngIf="newpassword.errors.password">Please provide a valid
                        password</small>
                </div>
            </div>
            <div class="form-group col-md-4">
                <p class="subtitle-text" for="confirmPassword">Confirm password</p>
                <input formControlName="confirmPassword" type="password" class="form-control" [ngClass]="{ 'is-invalid': userPasswordForm.submitted && userPasswordFormGroup.get('confirmPassword').hasError('required')
                    }" id="confirmPassword" name="confirmPassword" confirmPassword required minlength="8"
                    autocomplete="off">
                <div *ngIf="userPasswordForm.submitted && userPasswordFormGroup.get('confirmPassword').hasError('required')"
                    class="invalid-feedback animated bounceIn">
                    <small *ngIf="confirmPassword.errors.required" id="confirmPasswordHint" class="form-text">Please
                        confirm password</small>
                    <small *ngIf="confirmPassword.errors.minlength" id="confirmPasswordHint" class="form-text">Please
                        provide a minimum length of 8
                        characters</small>
                    <small *ngIf="confirmPassword.errors.confirmPassword">Please provide a
                        valid
                        password</small>
                </div>
                <small #invalidConfirmPassword style="display: none;"
                    class="invalid-feedback animated bounceIn form-text">Please be sure both
                    passwords are the same</small>
            </div>

            <div class="form-group col-md-6">
                <span class="link" (click)="openInputDialog(resetPasswordDialog)">forgotten
                    password?</span>
            </div>
            <div class="col-md-12">
                <button type="submit" style="margin-bottom: 10px;" (click)="updateUserPassowrd()"
                    class="btn spz-button-green">Save
                    <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
                </button>
            </div>
        </div>
    </form>
</div>
<ng-template #resetPasswordDialog let-modal>
    <div class="modal-header">
        <h4 class="title-text" class="modal-title" id="modal-basic-title">Reset password</h4>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="title-text">A link to reset your password will be sent to the email associated with this account</p>
        <form name="loginForm" #loginForm="ngForm" (ngSubmit)="loginForm.form.valid">
            <div class="form-group">
                <p class="subtitle-text" for="email">Please confirm email</p>
                <input [(ngModel)]="userAccountFormData.email" #email="ngModel" type="email" class="form-control"
                    id="email" name="email" required autofocus minlength="5"
                    [ngClass]="{ 'is-invalid': loginForm.submitted && email.invalid }" aria-describedby="emailHint">
                <div *ngIf="loginForm.submitted && email.invalid" class="invalid-feedback animated bounceIn">
                    <small *ngIf="email.errors.required" id="emailHint" class="form-text">email
                        is
                        required</small>
                    <!-- <small *ngIf="email.errors.minlength" id="emailHint" class="form-text">Please
                        provide a minimum length of 5 characters</small> -->
                    <small *ngIf="email.errors.email">Please provide a valid email</small>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark" (click)="onResetPassword()">Reset
            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
        </button>
    </div>
</ng-template>