import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AuthUser } from 'src/app/models/auth-user';
import { Mentor } from 'src/app/models/mentor';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DialogButton } from 'src/app/services/dialog.service';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-mentor',
  templateUrl: './mentor.component.html',
  styleUrls: ['./mentor.component.css']
})
export class MentorComponent implements OnInit, OnDestroy {

  public observers: Array<Subscription> = new Array<Subscription>();
  public formData: FormData = new FormData();
  public authUser: AuthUser;
  public isLoading = false
  public mentorList$: Observable<Mentor[]> = new Observable<Mentor[]>();
  public mentorList: Mentor[] = [];
  public searchInputFormControl: FormControl;
  public sortAscOrDesc = false;

  constructor(private backend: BackendService,
    public localAuthService: LocalAuthService,
    public sidebarService: SidebarService,
    private appMaterialComponent: AppMaterialDesignModule,
    private formBuilder: FormBuilder,
    public router: Router) { }

  ngOnInit(): void {
    this.authUser = new AuthUser();
    this.observers.push(
      this.localAuthService.getAuthUser().subscribe(data => {
        this.authUser = data;
      })
    );

    this.getMentorList();

    this.searchInputFormControl = new FormControl('');

    this.searchInputFormControl.valueChanges
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
      )
      .subscribe(term => {
        this._filter(term)
      })
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    let mentors = this.mentorList.filter((mentor) => {
      return (mentor.firstname.toLowerCase().includes(filterValue)
        || mentor.lastname.toLowerCase().includes(filterValue))
    });
    this.mentorList$ = new BehaviorSubject(mentors);
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }

  public getMentorList() {
    this.isLoading = true
    this.observers.push(
      this.backend.getAllMentors().subscribe({
        next: (response) => {
          if (response.code === '000') {
            this.mentorList = response.data;
            this.mentorList$ = new BehaviorSubject(response.data);
          }
          this.isLoading = false;
        }, error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false
        }
        , complete: () => {
          console.log('on complete getMentors');
        }
      })
    )
  }
  public deleteMentor(mentor) {
    this.observers.push(
      this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
        width: '350px',
        title: 'Caution',
        message: 'Are you sure you want to delete this mentor'

      }).subscribe({
        next: (result) => {
          if (result.button === DialogButton.ok) {

          }
        }
      })
    )
  }

  sortUserList(field) {
    if (field == 'firstname') {
      if (this.sortAscOrDesc) {
        this.mentorList = this.mentorList.sort((a, b) => (a.firstname > b.firstname) ? 1 : -1);
        //(a.profile.firstname === a.profile.firstname) ? ((a.profile.lastname > b.profile.lastname) ? 1 : -1) : -1);
      } else {
        this.mentorList = this.mentorList.sort((a, b) => (a.firstname < b.firstname) ? 1 : -1);
      }
    }
    this.mentorList$ = new BehaviorSubject(this.mentorList);
    this.sortAscOrDesc = !this.sortAscOrDesc
  }
}
