<div class="container-fluid">
    <div class="form-group">
        <input class="form-control" id="name" type="text" [formControl]="searchInputFormControl" placeholder="search mentor">
    </div>
    <div class="card table-responsive bg-white" style="max-height: 620px;">
        <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
            <thead>
                <tr>
                    <th></th>
                    <th class="d-flex justify-content-start" scope="col">
                        First Name
                        <mat-icon (click)="sortUserList('firstname')" class="ml-2" role="button">sort_by_alpha
                        </mat-icon>
                    </th>
                    <th scope="col">Last Name</th>
                    <!-- <th scope="col">Mobile Number</th> -->
                    <th scope="col">Industry</th>
                    <th scope="col">Rating</th>
                    <th scope="col">Followers</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr class="animate__animated animate__fadeIn" *ngFor="let mentor of mentorList$ | async" style="border: .5px solid #EDEDED; font-size: 15px;">
                    <td>
                        <a class="link" routerLink="/engagements/mentor/{{mentor.id}}">
                            <img src="{{mentor.image}}" height="36" width="36" style="border-radius: 50%; padding: 5px; object-fit: cover;">
                        </a>
                    </td>
                    <td class="align-middle">
                        <a class="link" routerLink="/engagements/mentor/{{mentor.id}}">
                        {{mentor.firstname}}
                        </a>
                    </td>
                    <td class="align-middle">
                        <a class="link" routerLink="/engagements/mentor/{{mentor.id}}">
                        {{mentor.lastname}}
                        </a>
                    </td>
                    <td class="align-middle">
                        <a class="link" routerLink="/engagements/mentor/{{mentor.id}}">
                        {{mentor.industry}}
                        </a>
                    </td>
                    <td class="align-middle">{{mentor?.rating}}</td>
                    <td class="align-middle">{{mentor?.followers}}</td>
                </tr>
            </tbody>
        </table>
        <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
            </mat-spinner>
        </div>
    </div>
</div>