import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { DialogService, DialogButton } from 'src/app/services/dialog.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { Questionnaire, Settings } from 'src/app/models/questionnaire';
import { BackendService } from 'src/app/services/backend.service';
import { SwiperConfigInterface, SwiperScrollbarInterface, SwiperPaginationInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { QuestionnaireFormService } from 'src/app/services/questionnaire-form.service';
import { QuestionPage } from 'src/app/models/question-page';
import { Question } from 'src/app/models/question';
import { Membership } from 'src/app/models/membership';
import { QuestionnaireAnswer } from 'src/app/models/questionnaire-answer';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-membership-questionnaire',
  templateUrl: './membership.questionnaire.component.html',
  styleUrls: ['./membership.questionnaire.component.css']
})
export class MembershipQuestionnaireComponent implements OnInit, OnDestroy, AfterViewInit {

  observers = new Array<Subscription>();
  authUser = null;
  public showQuestionnaire = false;
  public membershipQuestion: Questionnaire
  public currentQuestionIndex: number = 0;
  public currentPageIndex: number = 0;
  public isLoading = false;
  public swiperConfig: SwiperConfigInterface = {};
  swiperVirtualSlides: any[] = [];
  virtualData: any = { slides: [] };
  public questionnaireFormGroup: FormGroup;
  public membershipCategories: Array<Membership> = [];
  public membershipId: string = '';
  public membershipTitle = '';
  public buttonOptions = {
    showGetStartedButton: true,
    showFinishButton: false,
    showNextButton: false,
    showPreviousButton: false,
  };

  public questionnaireAnswers: Array<QuestionnaireAnswer>;

  constructor(
    private backend: BackendService,
    private router: Router,
    private dialogService: DialogService,
    private localAuthService: LocalAuthService,
    private formBuilder: FormBuilder,
    public questionnaireFormBuilder: QuestionnaireFormService,
    public appMaterialDesignModule: AppMaterialDesignModule,
    private dataSource: DataService
  ) {

    this.questionnaireAnswers = new Array<QuestionnaireAnswer>();
    this.membershipQuestion = new Questionnaire().createWithDefaults();

    this.questionnaireFormGroup = this.formBuilder.group({
      id: '',
      createdAt: new Date().getTime().toString(),
      createdBy: '',
      type: '',
      title: ['', Validators.required],
      footer: this.formBuilder.group({
        title: ['', Validators.required],
        description: ['', Validators.required],
      }),
      settings: this.questionnaireFormBuilder.createQuestionnaireSettings(new Settings()),
      pages: this.formBuilder.array([
        this.questionnaireFormBuilder.createPageItem(new QuestionPage())
      ])
    });
  }

  ngOnInit() {
    const observer = this.localAuthService.getAuthUser().subscribe(data => {
      this.authUser = data;
    });
    this.observers.push(observer);

    const dialog = this.appMaterialDesignModule.openDialog(ConfirmDialogComponent, {
      width: '450px',
      title: 'Welcome',
      message: 'Please take a few moment to answer the following questions. This will help us deteremine your membership (SLIVER, GOLD, PLATINUM)'

    }).subscribe();
    this.observers.push(dialog);
  }

  getFormControls(controlName: string): FormArray {
    return (this.questionnaireFormGroup.get(controlName) as FormArray);
  }

  ngAfterViewInit(): void {
    //this.isLoading = true;
    this.observers.push(forkJoin([this.backend.getMembershipQuestionnaire(), this.backend.getMembership()]).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.membershipQuestion = response[0];
        //console.log('membership question ' + JSON.stringify(this.membershipQuestion));

        this.membershipCategories = response[1].data;
        //console.log('membership category ' + JSON.stringify(this.membershipCategories));
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
      },
      complete: () => {

        console.log('on complete getMembershipQuestionnaire');
        this.questionnaireFormGroup = this.questionnaireFormBuilder.buildForm(this.membershipQuestion);

        //this.swiperVirtualSlides.push(this.membershipQuestion.pages[0].questions[0]);
        this.membershipQuestion.pages[0].questions.forEach(data => {
          this.swiperVirtualSlides.push(data);
          //this.swiperDirectiveRef.swiper().virtual.appendSlide(data);
          //console.log('index of the first item ' + this.swiperDirectiveRef.swiper().virtual.from);
        });
        this.swiperDirectiveRef.update();
        this.buttonOptions.showGetStartedButton = true;
      }
    }));

    this.swiperConfig = {
      a11y: true,
      direction: 'horizontal',
      slidesPerView: 1,
      observer: true,
      threshold: 10,
      speed: 500,
      spaceBetween: 30,
      centeredSlides: true,
      grabCursor: true,
      keyboard: false,
      allowTouchMove: true,
      longSwipes: false,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: this.pagination,
      roundLengths: true,
      effect: "coverflow",
      cubeEffect: {
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      },
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      },
      virtual: {
        slides: this.swiperVirtualSlides,
        renderExternal: data => {
          this.virtualData = data;
        }
      }
    };

    this.buttonOptions.showGetStartedButton = true;
    this.buttonOptions.showFinishButton = false;
    this.buttonOptions.showNextButton = false;
    this.buttonOptions.showPreviousButton = false;
  }

  private scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true
  };

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false,
    dynamicBullets: true,
    type: 'bullets'
  };

  showProgressRipple(): void {
    this.progressRipple.nativeElement.style.display = 'inline-block';
  }
  hideProgressRipple(): void {
    this.progressRipple.nativeElement.style.display = 'none';
  }

  @ViewChild(SwiperDirective, { static: false }) swiperDirectiveRef: SwiperDirective;
  @ViewChild('progressRipple') progressRipple: ElementRef;

  public onIndexChange(index: number) {
    this.currentQuestionIndex = index;
    this.swiperDirectiveRef.swiper();
    this.buttonOptions.showPreviousButton = index > 0;
    this.buttonOptions.showNextButton = index !== this.swiperVirtualSlides.length - 1;
    this.buttonOptions.showFinishButton = index === this.swiperVirtualSlides.length - 1;
    //this.buttonOptions.showGetStartedButton = index < 1;
  }

  private setMembership() {
    this.isLoading = true;
    this.observers.push(
      this.backend.setUserMembership({ userId: this.authUser.id, membershipId: this.membershipId }).subscribe(
        {
          next: (response: any) => {
            this.isLoading = false;
            this.authUser.membershipId = response.data.membershipId;
            this.localAuthService.setUser(this.authUser);
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
          },
          complete: () => {
            console.log('on complete setUserMembership');
          }
        }
      )
    );
  }

  private saveQuestionnaireAnswers(answers: any[]) {
    this.isLoading = true;
    this.observers.push(
      this.backend.saveQuestionnaireAnswers(
        answers
        //{ userId: this.authUser.id, membershipId: this.membershipId }
      ).subscribe(
        {
          next: (response: any) => {
            this.isLoading = false;
            this.authUser.membershipId = response.data.membershipId;
            this.localAuthService.setUser(this.authUser);
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
          },
          complete: () => {
            console.log('on complete saveQuestionnaireAnswers');
          }
        }
      )
    );
  }

  previousQuestion() {
    let swiper = this.swiperDirectiveRef.swiper();
    this.swiperDirectiveRef.prevSlide();
  }

  onAnswerSelected(question: Question, answer: string) {
    console.log('answer ' + answer);
    console.log('question ' + JSON.stringify(question));

    let logic = question.logic.find(element => {
      return element.answer == answer;
    });

    console.log('logic ' + JSON.stringify(logic));

    if (logic.operator === 'NextQuestion') { //&& !answer.includes('Others')
      this.nextQuestion(logic.nextIndex);
    } else if (logic.operator === 'Finish') {
      //question finished
      this.saveQuestionnaireAnswers(this.questionnaireAnswers);

      const dialog = this.appMaterialDesignModule.openDialog(ConfirmDialogComponent, {
        width: '450px',
        title: 'Success',
        message: `Congratulations! You have earned ${this.membershipTitle} membership. Click OK to find out more about the membership categories or click cancel to go your profile.`

      }).subscribe({
        next: (result) => {
          if (result.button == DialogButton.ok) {
            this.router.navigate(['membership'], { skipLocationChange: false });
          } else if (result.button == DialogButton.cancel) {
            this.router.navigate(['profile'], { skipLocationChange: false });
          }
        }
      });
      this.observers.push(dialog);

    } else if (logic.operator === 'Custom') {
      //assign membership here
      console.log('membershipCategories ' + JSON.stringify(this.membershipCategories));
      let membership = this.membershipCategories.find(element => {
        return element.title.toUpperCase() === logic.valueToCompare.toUpperCase();
      });
      console.log('membership ' + JSON.stringify(membership));
      this.membershipId = membership.id;
      this.membershipTitle = membership.title; //---rework here
      if (this.membershipId !== '') {
        this.setMembership();
        if (logic.nextIndex) {
          if (logic.nextIndex !== 'finish') {
            this.nextQuestion(logic.nextIndex);
          } else {
            //question finished
            this.saveQuestionnaireAnswers(this.questionnaireAnswers);

            const dialog = this.appMaterialDesignModule.openDialog(ConfirmDialogComponent, {
              width: '450px',
              title: 'Success',
              message: `Congratulations! You have earned ${this.membershipTitle} membership. Click OK to find out more about the membership categories or click cancel to go your profile.`

            }).subscribe({
              next: (result) => {
                if (result.button == DialogButton.ok) {
                  this.router.navigate(['membership'], { skipLocationChange: false });
                } else if (result.button == DialogButton.cancel) {
                  this.router.navigate(['profile'], { skipLocationChange: false });
                }
              }
            });
            this.observers.push(dialog);
          }
        }

      } else {
        console.log('could not find membership with  specified name..');
      }
    }
    //save selected answer
    let selectedAnswer = new QuestionnaireAnswer();
    selectedAnswer.id = new Date().getTime().toString();
    selectedAnswer.userId = this.authUser.id;
    selectedAnswer.questionnaireId = '1'; //change later to question loaded from db
    selectedAnswer.question = question.text;
    selectedAnswer.answer = answer;
    selectedAnswer.options = question.options;
    console.log('selectedAnswer ' + JSON.stringify(selectedAnswer));
    this.questionnaireAnswers.push(selectedAnswer);
  }

  nextQuestion(next?: number) {
    //this.swiperVirtualSlides.push('side '+this.swiperVirtualSlides.length+1);
    if (next) {
      console.log('next == ' + next);
      this.swiperDirectiveRef.setIndex(next);
    } else {
      this.swiperDirectiveRef.nextSlide();
    }
  }

  public onSubmitQuestionnaire() {

  }

  ngOnDestroy(): void {
    this.observers.forEach((observer) => {
      observer.unsubscribe();
    });
  }
}

