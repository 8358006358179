import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, Inject } from '@angular/core';
import { DialogService, DialogButton, DialogOptions } from 'src/app/services/dialog.service';
import { Observable, Subject, Subscription, pipe } from 'rxjs';
import { UploadFile } from 'src/app/models/upload-file';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { take } from 'rxjs/operators';

export const enum FileType {
  image = 'image/*',
  document = 'document',
  video = 'video',
  default = 'default'
}

export interface DialogData {
  title: string;
  message: string;
  fileType: FileType;
  multiple: boolean;
  limit: number,
  maxSize: number
  overrideFilename: boolean;
}

@Component({
  selector: 'app-file-picker-dialog',
  templateUrl: './file-picker-dialog.component.html',
  styleUrls: ['./file-picker-dialog.component.css']
})
export class FilePickerDialogComponent implements OnInit, OnDestroy {

  @Input() title: string;
  @Input() message: string;
  @Input() fileType: FileType;
  @Input() multiple: boolean = false;

  @ViewChild('fileInput') fileInput: ElementRef;
  public files: Array<UploadFile> = new Array<UploadFile>();
  private unSubscriptioNotifier = new Subject();
  public isLoading = false;
  constructor(
    public dialogRef: MatDialogRef<FilePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions,
    public appMaterialDesignModule: AppMaterialDesignModule,
    private dialogService: DialogService,) { 
      this.files = new Array<UploadFile>();
    }

  ngOnInit() {
    //console.log(JSON.stringify(this.dialogOptions))
  }

  public cancel(): void {
    this.isLoading = false;
    this.files = new Array<UploadFile>();
    this.dialogRef.close();
  }

  public upload(): void {
    if (this.files.length > 0) {
      this.isLoading = true;
      this.dialogService.sendMessage({ text: 'upload button clicked', files: this.files, button: DialogButton.upload });
      this.fileInput.nativeElement.value = '';
      this.files = new Array<UploadFile>();
      this.dialogRef.close();
    } else {
      console.log('no files selected...');
    }
  }

  addFiles() {
    //this.fileInput.nativeElement.click();
  }

  onFilesAdded(fileList: FileList, limit: number) {
    if (limit < fileList.length) {
      this.appMaterialDesignModule.showAlertToaster(AlertType.warn, 'upload a maximum of 3 files', 3)
      .pipe(take(1))
      .subscribe();
      fileList = null;
      return;
    }

    if (fileList.length > 0) {
      for (var i = 0; i < fileList.length; i++) {
        let fileItem = fileList.item(i);
        let filename = fileItem.name;
        if(this.dialogOptions.data.overrideFilename || false){
          //console.log(fileItem.name);
          filename = `${new Date().getTime()+11}_${i+1}.png`;// new Date().getTime()+'_'+i+'.png'
          //console.log(filename);
        }
        let uploadFile = new UploadFile(filename, fileItem.type, fileItem.size, fileItem);
        this.files.push(uploadFile);
      }
    } else {
      console.log('no files selected...')
    }
  }

  ngOnDestroy(): void {
    this.files = null;
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }
}
