<div class="bg-white p-4  mb-3 rounded animate__animated animate__fadeIn" style="padding: 15px; margin-bottom: 10px;">
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="title-text">Mentorship</h4>
            <div (click)="togglePage()">
                <i style="font-size: 24px;" class="fa fa-pencil-square-o"></i>
            </div>
        </div>
        <div *ngIf="!page">
            <table class="table table-striped table-sm">
                <tr>
                    <th class="subtitle-text">Mentoring Area</th>
                    <td class="title-text">{{mentorDetails?.mentoringArea}}</td>
                </tr>
                <tr>
                    <th class="subtitle-text">Industry</th>
                    <td class="title-text">{{mentorDetails?.industry}}</td>
                </tr>
                <tr>
                    <th class="subtitle-text">Rating</th>
                    <td class="title-text">{{mentorDetails?.rating | number}}</td>
                </tr>
                <tr>
                    <th class="subtitle-text">Preferred Mode Of Communication</th>
                    <td class="title-text">
                        <mat-chip-list>
                            <mat-chip *ngFor="let com of mentorDetails?.preferredModeOfCommunication">
                                {{com}}</mat-chip>
                        </mat-chip-list>
                    </td>
                </tr>
            </table>
        </div>
        <div *ngIf="page">
            <form [formGroup]="mentorInfoFormGroup" class="p-3 mb-3 animate__animated animate__fadeIn"
                [formGroup]="mentorInfoFormGroup" id="mentorUpdateForm" #mentorUpdateForm="ngForm"
                (ngSubmit)="mentorInfoFormGroup.valid && onUpdateMentor()">
                <div class="form-row">
                    <div class="form-group col-md-12" style="margin-bottom: 1px; margin-top: 15px;">
                        <mat-form-field floatLabel="auto" hideRequiredMarker appearance="standard" style="width: 100%">
                            <mat-label style="font-size: 16px;">In which area of mentoring do you
                                want to act?
                            </mat-label>
                            <mat-select formControlName="mentoringArea" name="mentoringArea">
                                <!-- <mat-option selected>-- Please select --</mat-option> -->
                                <mat-optgroup *ngFor="let group of mentoringAreaGroups" [label]="group.name"
                                    [disabled]="group.disabled">
                                    <mat-option *ngFor="let mentoringArea of group.mentoringArea"
                                        [value]="mentoringArea.value">
                                        {{mentoringArea.viewValue}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-md-12" style="margin-bottom: 1px; margin-top: 15px;">
                        <mat-form-field floatLabel="auto" appearance="standard" style="width: 100%">
                            <mat-label style="font-size: 16px;">Please select how you would like to
                                be
                                contacted
                            </mat-label>
                            <mat-select formControlName="preferredModeOfCommunication" multiple>
                                <mat-option value="SPZ in-chat">SPZ application in-chat
                                </mat-option>
                                <mat-option value="Skype chat/call">Skype chat/call</mat-option>
                                <mat-option value="Whatsapp chat/call">Whatsapp
                                    chat/call</mat-option>
                                <mat-option value="Facebook chat">Facebook chat</mat-option>
                                <mat-option value="Zoom meeting">Zoom meeting</mat-option>
                                <mat-option value="Google meet">Google meet</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12 d-flex justify-content-center mt-3">
                        <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate"
                            [diameter]="35" [strokeWidth]="2">
                        </mat-spinner>
                    </div>
                    <div class="col-md-12 d-flex justify-content-end" [hidden]="isLoading"
                        style="margin-bottom: 15px; padding-top: 10px;">
                        <button type="submit" [hidden]="isLoading" class="btn spz-button-green" [disabled]="!mentorInfoFormGroup.valid">Save</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>