<div style="background-color: #f9f9f9;">
    <div class="container-fluid bg-white mb-3">
        <div class="d-flex justify-content-between align-items-center pt-3">
            <h1>Setup an event</h1>
            <div class="d-flex justify-content-end align-items-center">
                <!-- <div class="nav-link" (click)="signout()">
            <i class="fa fa-sign-out" style="font-size: 18px !important; color: #00213D;"></i>
            <span>Sign out</span>
        </div> -->
                <div class="nav-link" [matMenuTriggerFor]="menu">
                    <img src="{{authUser.image}}" height="36" width="36"
                        style="border-radius: 50%; padding: 5px; object-fit: cover;">
                    <!-- <i class="fa fa-user-circle-o" style="font-size: 24px !important; color: #00213D;"></i> -->
                </div>
                <mat-menu #menu="matMenu" class="spz-profile-menu">
                    <div matRipple routerLink="/profile" class="media p-3">
                        <img src="{{authUser.image}}" class="align-self-center mr-2 profile-image-small"
                            alt="{{authUser.fullname}}">
                        <div class="media-body">
                            <h6 class="mt-0 mb-0">{{authUser.fullname}}</h6>
                            <p class="text-muted p-0 m-0" style="font-size: 14px;">View public profile</p>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div matRipple routerLink="/user/bookings" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-clock-o color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">Bookings</p>
                            <small class="text-muted p-0 m-0">View and manage all your bookings</small>
                        </div>
                    </div>
                    <div matRipple routerLink="/user/connections" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-users color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">My connections</p>
                            <small class="text-muted p-0 m-0">Manage your connections</small>
                        </div>
                    </div>
                    <div matRipple routerLink="/user/calendar" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-calendar-check-o color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">Calendar</p>
                            <small class="text-muted p-0 m-0">Setup your availability</small>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="p-3">
                        <div matRipple routerLink="/settings" class="d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-cog color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Settings</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>

                        <div matRipple class="d-flex justify-content-between align-items-center">
                            <div routerLink="/support" class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-life-ring color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Support</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>

                        <div matRipple class="d-flex justify-content-between align-items-center">
                            <div (click)="signout()" class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-sign-out color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Logout</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
        <!-- <hr> -->
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-10 col-sm-10 mx-auto">
                <div class="mb-3 bg-white animate__animated animate__fadeIn" style="padding: 12px;">
                    <!-- <pre>{{eventFormGroup.value | json}}</pre> -->
                    <form class="form" [formGroup]="eventFormGroup" #eventForm="ngForm"
                        (ngSubmit)="eventForm.form.valid && onSaveEvent()" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <p class="subtitle-text" for="title">Title</p>
                                        <input formControlName="title" type="title" class="form-control" id="title"
                                            name="title" required minlength="5"
                                            [ngClass]="{ 'is-invalid': eventForm.submitted && eventFormGroup.get('title').hasError('title') }"
                                            aria-describedby="titleHint" autocomplete="off">
                                        <div *ngIf="eventForm.submitted && eventFormGroup.get('title').hasError('title')"
                                            class="invalid-feedback animate__animated animate__bounceIn">
                                            <small *ngIf="eventFormGroup.get('title').hasError('required')"
                                                id="titleHint" class="form-text">title
                                                is
                                                required</small>
                                            <small *ngIf="eventFormGroup.get('title').hasError('minlength')"
                                                id="titleHint" class="form-text">Please
                                                provide a minimum length of 5 characters</small>
                                            <small *ngIf="eventFormGroup.get('title').hasError('title')">Please provide
                                                a valid title</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <p class="subtitle-text" for="location">Location</p>
                                        <input formControlName="location" type="text" class="form-control" id="location"
                                            name="location" required minlength="5"
                                            [ngClass]="{ 'is-invalid': eventForm.submitted && eventFormGroup.get('location').hasError('location') }"
                                            aria-describedby="locationHint" autocomplete="off">
                                        <div *ngIf="eventForm.submitted && eventFormGroup.get('location').hasError('location')"
                                            class="invalid-feedback animate__animated animate__bounceIn">
                                            <small *ngIf="eventFormGroup.get('location').hasError('required')"
                                                id="locationHint" class="form-text">location name
                                                is
                                                required</small>
                                            <small *ngIf="eventFormGroup.get('location').hasError('minlength')"
                                                id="locationHint" class="form-text">Please
                                                provide a minimum length of 5 characters</small>
                                            <small *ngIf="eventFormGroup.get('location').hasError('location')">Please
                                                provide a valid location
                                                name</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <p class="subtitle-text" for="description">Description</p>
                                        <textarea formControlName="description" type="text" class="form-control"
                                            id="description" name="description" required minlength="5"
                                            [ngClass]="{ 'is-invalid': eventForm.submitted && eventFormGroup.get('description').hasError('description') }"
                                            aria-describedby="descriptionHint" rows="3" autocomplete="off">
                                    </textarea>
                                        <div *ngIf="eventForm.submitted && eventFormGroup.get('description').hasError('description')"
                                            class="invalid-feedback animate__animated animate__bounceIn">
                                            <small *ngIf="eventFormGroup.get('description').hasError('required')"
                                                id="descriptionHint" class="form-text">description
                                                is
                                                required</small>
                                            <small *ngIf="eventFormGroup.get('description').hasError('minlength')"
                                                id="descriptionHint" class="form-text">Please
                                                provide a minimum length of 5 characters</small>
                                            <small
                                                *ngIf="eventFormGroup.get('description').hasError('description')">Please
                                                provide a valid description</small>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="form-row">
                                            <div class="form-group col-6 date">
                                                <p class="subtitle-text" for="startDateAndTime">Start Date</p>
                                                <div class="input-group date" data-target-input="nearest">
                                                    <input [matDatepicker]="startDateAndTimePicker" autocomplete="off"
                                                        formControlName="startDateAndTime" type="text"
                                                        class="form-control date" id="startDateAndTime"
                                                        name="startDateAndTime" minlength="3"
                                                        [ngClass]="{ 'is-invalid': eventForm.submitted && eventFormGroup.get('startDateAndTime').hasError('startDateAndTime') }"
                                                        aria-describedby="startDateAndTimeHint">
                                                    <!-- <mat-datepicker-toggle matSuffix [for]="startDateAndTimePicker">
                                                </mat-datepicker-toggle> -->
                                                    <mat-datepicker #startDateAndTimePicker></mat-datepicker>
                                                    <div class="input-group-append">
                                                        <div (click)="startDateAndTimePicker.open()"
                                                            class="input-group-text"><i class="fa fa-calendar"></i>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="eventForm.submitted && eventFormGroup.get('startDateAndTime').hasError('required')"
                                                        class="invalid-feedback  animate__animated animate__bounceIn">
                                                        <small
                                                            *ngIf="eventFormGroup.get('startDateAndTime').hasError('startDateAndTime')">
                                                            Please provide date</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-6 date">
                                                <p class="subtitle-text" for="endDateAndTime">End Date</p>
                                                <div class="input-group date" data-target-input="nearest">
                                                    <input [matDatepicker]="endDateAndTimePicker" autocomplete="off"
                                                        formControlName="endDateAndTime" type="text"
                                                        class="form-control date" id="endDateAndTime"
                                                        name="endDateAndTime" minlength="3"
                                                        [ngClass]="{ 'is-invalid': eventForm.submitted && eventFormGroup.get('endDateAndTime').hasError('endDateAndTime') }"
                                                        aria-describedby="endDateAndTimeHint">
                                                    <!-- <mat-datepicker-toggle matSuffix [for]="endDateAndTimePicker">
                                                </mat-datepicker-toggle> -->
                                                    <mat-datepicker #endDateAndTimePicker></mat-datepicker>
                                                    <div class="input-group-append">
                                                        <div (click)="endDateAndTimePicker.open()"
                                                            class="input-group-text"><i class="fa fa-calendar"></i>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="eventForm.submitted && eventFormGroup.get('endDateAndTime').hasError('required')"
                                                        class="invalid-feedback animate__animated animate__bounceIn">
                                                        <small
                                                            *ngIf="eventFormGroup.get('endDateAndTime').hasError('endDateAndTime')">
                                                            Please provide date</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-md-12">
                                        <p class="subtitle-text" for="category">Category</p>
                                        <input type="text" placeholder="event category" matInput
                                            formControlName="category" type="text" class="form-control" id="category"
                                            name="category" [matAutocomplete]="auto" [ngClass]="{ 'is-invalid': eventForm.submitted
                                    && eventFormGroup.get('category').hasError('category')}" required
                                            aria-describedby="categoryHint" autocomplete="off">
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option value="Mentoring session">
                                                Mentoring session
                                            </mat-option>
                                            <mat-option *ngFor="let cat of filteredCategories | async" [value]="cat">
                                                {{cat}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <div *ngIf="eventForm.submitted && eventFormGroup.get('category').hasError('category')"
                                            class="invalid-feedback animate__animated animate__bounceIn">
                                            <small *ngIf="eventFormGroup.get('category').hasError('required')"
                                                id="categoryHint" class="form-text">category is
                                                required</small>
                                            <!-- <div *ngIf="category.errors.required"></div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <p class="subtitle-text" for="occurance">Occurance</p>
                                        <select class="form-control" formControlName="occurance" type="text"
                                            id="occurance" name="occurance"
                                            [ngClass]="{ 'is-invalid': eventForm.submitted && eventFormGroup.get('occurance').hasError('occurance') }"
                                            required aria-describedby="occuranceHint" minlength="3">
                                            <option disabled selected>Please select occurance</option>
                                            <option value="once">Once</option>
                                            <option value="dialy">Dialy</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="monthly">Monthly</option>
                                            <option value="yearly">Annually</option>
                                        </select>
                                        <div *ngIf="eventForm.submitted && eventFormGroup.get('occurance').hasError('occurance')"
                                            class="invalid-feedback  animate__animated animate__bounceIn">
                                            <small *ngIf="eventFormGroup.get('occurance').hasError('required')"
                                                id="occuranceHint" class="form-text">occurance is
                                                required</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <p class="subtitle-text" for="host">Host</p>
                                        <input formControlName="host" type="text" class="form-control" id="host"
                                            name="host"
                                            [ngClass]="{ 'is-invalid': eventForm.submitted && eventFormGroup.get('host').hasError('host') }"
                                            aria-describedby="hostHint" autocomplete="off">
                                        <div *ngIf="eventForm.submitted && eventFormGroup.get('host').hasError('host')"
                                            class="invalid-feedback animate__animated animate__bounceIn">
                                            <small *ngIf="eventFormGroup.get('host').hasError('required')" id="hostHint"
                                                class="form-text">host name
                                                is
                                                required</small>
                                            <small *ngIf="eventFormGroup.get('host').hasError('minlength')"
                                                id="hostHint" class="form-text">Please
                                                provide a minimum length of 5 characters</small>
                                            <small *ngIf="eventFormGroup.get('host').hasError('host')">Please provide a
                                                valid host name</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <p class="subtitle-text" for="ticketUrl">Ticket Url</p>
                                        <input formControlName="ticketUrl" type="text" class="form-control"
                                            id="ticketUrl" name="ticketUrl"
                                            [ngClass]="{ 'is-invalid': eventForm.submitted && eventFormGroup.get('ticketUrl').hasError('ticketUrl') }"
                                            aria-describedby="ticketUrlHint" autocomplete="off">
                                        <div *ngIf="eventForm.submitted && eventFormGroup.get('ticketUrl').hasError('ticketUrl')"
                                            class="invalid-feedback  animate__animated animate__bounceIn">
                                            <small *ngIf="eventFormGroup.get('ticketUrl').hasError('required')"
                                                id="ticketUrlHint" class="form-text">ticket Url
                                                is
                                                required</small>
                                            <small *ngIf="eventFormGroup.get('ticketUrl').hasError('minlength')"
                                                id="ticketUrlHint" class="form-text">Please
                                                provide a minimum length of 5 characters</small>
                                            <small *ngIf="eventFormGroup.get('ticketUrl').hasError('ticketUrl')">Please
                                                provide a valid ticket
                                                Url</small>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <p class="subtitle-text" for="tags">Hash Tags</p>
                                        <mat-chip-list #tagList aria-label="tag selection">
                                            <mat-chip *ngFor="let tag of eventTags" [selectable]="true"
                                                [removable]="true" (removed)="removeEventTag(tag)">
                                                {{tag}}
                                                <mat-icon matChipRemove>cancel</mat-icon>
                                            </mat-chip>
                                            <input placeholder="Tag this event" [matChipInputFor]="tagList"
                                                class="form-control" id="tags" name="tags" formControlName="tags"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                [matChipInputAddOnBlur]="true"
                                                (matChipInputTokenEnd)="addEventTag($event)">
                                        </mat-chip-list>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <p style="font-size: 15px;" class="text-muted">Upload photos</p>
                                        <div class="photo-upload-container mb-3"
                                            [hidden]="eventFormData.imageFiles == undefined || eventFormData.imageFiles.length > 2"
                                            (click)="showFilePicker();">
                                            <img class="img-fluid" src="/assets/icons/image-upload-icon.png" height="32"
                                                width="32" />
                                            <p class="m-0 p-0">Click to upload</p>
                                            <small class="text-muted">Max file size: 20Kb</small>
                                        </div>

                                        <!-- <div class="row pl-2 pr-2"> -->
                                            <div class="d-flex justify-content-start">
                                                <div *ngFor="let image of eventFormData.imageFiles"
                                                    class="animate__animated animate__fadeIn mr-2">
                                                    <img style="object-fit: cover;" height="90" width="120"
                                                        class="event-poster mb-1 img-fluid rounded img-thumbnail"
                                                        [src]="image.path || '/assets/img/img-placeholder.png'">
                                                    <div (click)="removeImageFile(image);" class="text-center p-1 rounded border" style="cursor: pointer;">
                                                        <i class="fa fa-trash-o fa-fw mr-1" style="font-size: 15px;"></i>
                                                        delete
                                                    </div>
                                                </div>
                                            </div>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                            <!--input progress bar here-->

                            <div class="col-md-12 d-flex justify-content-center mt-3">
                                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate"
                                    [diameter]="25" [strokeWidth]="2">
                                </mat-spinner>
                            </div>
                            <div class="col-md-12 d-flex justify-content-end" [hidden]="isLoading"
                                style="margin-bottom: 15px; padding-top: 10px;">
                                <button type="submit" [hidden]="isLoading" class="btn spz-button-green"
                                    [disabled]="!eventForm.valid">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>