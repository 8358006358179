<div class="container-fluid">
    <div *ngIf="!isLoading" class="animate__animated animate__fadeIn">
        <div class="card">
            <div class="card-body">
                <div class="media p-0">
                    <img class="mr-3 align-self-start img-responsive rounded" style="width: 120px; height:120px; border-radius: 5px; object-fit: cover; background: transparent;" src="{{mentorRequest.user?.image}}" alt="">
                    <div class="media-body">
                        <div class="row">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Full Name</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                {{mentorRequest.user?.profile?.firstname+' '+mentorRequest.user?.profile?.lastname}}
                            </div>
                        </div>
                        <mat-divider class="mt-2 mb-2"></mat-divider>
                        <div class="row">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Email</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                {{mentorRequest.user?.email}}
                            </div>
                        </div>
                        <mat-divider class="mt-2 mb-2"></mat-divider>
                        <div class="row">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Address</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                {{mentorRequest.user?.profile?.contact.workplaceAddress}}
                            </div>
                        </div>
                        <mat-divider class="mt-2 mb-2"></mat-divider>
                        <div class="row">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Primary Phone Number</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                {{mentorRequest.user?.profile?.contact.primaryPhonenumber}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-4">
                <div class="card mb-2">
                    <div class="card-body">
                        <div class="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                            <div class="d-flex flex-column"> <span style="font-size: 16px;" class="rating">Is First Time Mentee</span> <span class="number3">{{mentorRequest.isFirstTimeMentee}}</span> </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-2">
                    <div class="card-body">
                        <div class="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                            <div class="d-flex flex-column"> <span style="font-size: 16px;" class="rating">Status</span> <span class="number3">{{mentorRequest.status}}</span> </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-2">
                    <div class="card-body">
                        <h5>Actions</h5>
                        <div class="d-flex justify-content-between">
                            <button *ngIf="mentorRequest.requestType=='BECOME_MENTOR'" (click)="acceptBecomeMentorRequest(mentorRequest)" class="btn btn-default btn-sm">Accept Request</button>
                            <button *ngIf="mentorRequest.requestType=='REQUEST_MENTOR'" (click)="openAssignMentorDialog()" class="btn btn-default btn-sm">Assign Mentor</button>
                            <button class="btn btn-default btn-sm" (click)="declineMentorshipRequest(mentorRequest)">Decline Request</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="card mb-2">
                    <div class="card-body">
                        <h6 class="mb-2">Mentoring Objective</h6>
                        <mat-divider class="mb-3"></mat-divider>
                        <div class="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                            <div class="d-flex flex-column"> <span class="articles"></span> <span class="number1">{{mentorRequest.objectiveOfMentorship}}</span> </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-2">
                    <div class="card-body">
                        <h6 class="mb-2">Mentoring Areas</h6>
                        <mat-divider class="mb-3"></mat-divider>
                        <mat-chip-list>
                            <mat-chip selected>{{mentorRequest.mentoringArea}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
                <div class="card mb-2">
                    <div class="card-body">
                        <h6 class="mb-2">Mentoring Timeline</h6>
                        <mat-divider class="mb-3"></mat-divider>
                        <div class="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                            <!-- <div class="d-flex flex-column"> <span class="articles">Articles</span> <span class="number1">38</span> </div> -->
                            <div class="d-flex flex-column"> <span class="followers">Start Date</span> <span class="number2">{{mentorRequest.startDate | date}}</span> </div>
                            <div class="d-flex flex-column"> <span class="rating">End Date</span> <span class="number3">{{mentorRequest.endDate | date}}</span> </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-2">
                    <div class="card-body">
                        <h6 class="mb-2">Preferred Mode Of Communication</h6>
                        <mat-divider class="mb-3"></mat-divider>
                        <mat-chip-list>
                            <mat-chip *ngFor="let com of mentorRequest.preferredModeOfCommunication">{{com}}</mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 d-flex justify-content-center mt-3 ">
        <mat-spinner [hidden]="!isLoading " class="align-self-center " mode="indeterminate " [diameter]="35 " [strokeWidth]="2 ">
        </mat-spinner>
    </div>
</div>