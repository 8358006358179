import { Question } from './question';

export class QuestionPage {
    public title: string;
    public description: string;
    public index: number;
    public questions : Array<Question>;

    constructor(){
        this.title = 'new page';
        this.description='new page'; 
        this.questions = new Array<Question>();
    }

    public addQuestion():void{
        let question = new Question();
        question.text = 'new question for this '+this.title;
        question.id = '111';
        question.index  = this.questions.length + 1;
        question.options = 'answer1\nnanswer2\nnanswer3\nnanswer4'

        this.questions.push(question);
    }

    public addQuestionToCurrentPage(question:Question):void{
        question.text = 'new question for this '+this.title;
        question.id = '111';
        question.index  = this.questions.length + 1;
        question.options = 'answer1\nnanswer2\nnanswer3\nnanswer4'

        this.questions.push(question);
    }
}
