import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
    private listners = new BehaviorSubject('default message');
    private dataSource = new BehaviorSubject('default message');
    public toggleState: Observable<any> = this.dataSource.asObservable();
  
    constructor() { }
  
    public toggle(data?) {
      this.dataSource.next(data);
    }
  
  
    listen(): Observable<any> {
       return this.listners.asObservable();
    }
}