import { SocialMediaAccount } from './social-media-account';

export class Contact {
    public user: string;
    public primaryPhonenumber: string;
    public alternatePhonenumber: string;
    public workPhonenumber: string;
    public residentialAddress: string;
    public workplaceAddress: string;
    public currentLocation: string;
    public personalEmail: string;
    public workEmail: string;   
}
