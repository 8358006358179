import { Education } from './education';
import { Job } from './job';
import { Membership } from './membership';
import { Contact } from './contact';
import { SocialMediaAccount } from './social-media-account';
import { Employment } from './employment';

export class Profile {
    public user: string;
    public firstname: string;
    public lastname: string;
    public othernames: string;
    public fullname: string;
    public dateOfBirth: string;
    public image: string;
    public country: string;
    public biography: string;
    public favoriteQuote: string;
    public gender:string;
    constructor(){
    }
}
