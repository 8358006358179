import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';

interface mentoringArea {
  value: string;
  viewValue: string;
}

interface mentoringAreaGroup {
  disabled?: boolean;
  name: string;
  mentoringArea: mentoringArea[];
}

@Component({
  selector: 'app-mentorship-info',
  templateUrl: './mentorship-info.component.html',
  styleUrls: ['./mentorship-info.component.css'],
})
export class MentorshipInfoComponent implements OnInit, OnDestroy, OnChanges {
  @Input('mentorDetails') mentorDetails: any;
  private unSubscriptioNotifier = new Subject();
  public mentorInfoFormGroup: FormGroup;
  public page = false;
  public isLoading = false;
  private authUser = null;
  constructor(
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    public appMaterialModule: AppMaterialDesignModule
  ) {
    this.authUser = localAuthService.getUser();

    this.mentorInfoFormGroup = new FormGroup({
      userId: new FormControl(''),
      industry: new FormControl(''),
      mentoringArea: new FormControl('', [Validators.required]),
      preferredModeOfCommunication: new FormControl('', [Validators.required]),
      rating: new FormControl(''),
    });
  }

  public mentoringAreaGroups: mentoringAreaGroup[] = [
    {
      name: 'Career',
      mentoringArea: [
        { value: 'Banking', viewValue: 'Banking' },
        { value: 'Telecommunications', viewValue: 'Telecommunications' },
        { value: 'Technology', viewValue: 'Technology' },
        { value: 'Medical Sciences', viewValue: 'Medical Sciences' },
        { value: 'Public Services', viewValue: 'Public Services' },
        { value: 'Retail', viewValue: 'Retail' },
        { value: 'Financial Services', viewValue: 'Financial Services' },
        { value: 'Consultancy', viewValue: 'Consultancy' },
        { value: 'Social Services', viewValue: 'Social Services' },
        { value: 'Engineering', viewValue: 'Engineering' },
        { value: 'Oil and Gas', viewValue: 'Oil and Gas' },
      ],
    },
    {
      name: 'Business',
      mentoringArea: [
        { value: 'SME', viewValue: 'SME' },
        { value: 'Fashion Designing', viewValue: 'Fashion Designing' },
        { value: 'Video/Photography ', viewValue: 'Video/Photography ' },
        {
          value: 'Journalism /Online Publishing/Blogging',
          viewValue: 'Journalism /Online Publishing/Blogging',
        },
        {
          value: 'IT Coding/Webdesigning',
          viewValue: 'IT Coding/Webdesigning',
        },
        {
          value: 'Financial Markets Trading',
          viewValue: 'Financial Markets Trading',
        },
        {
          value: 'Trade - Importing/Exporting ',
          viewValue: 'Trade - Importing/Exporting ',
        },
        { value: 'Plumbing', viewValue: 'Plumbing' },
        {
          value: 'Contracting/Purchasing/Supply chain',
          viewValue: 'Contracting/Purchasing/Supply chain',
        },
      ],
    },
    {
      name: 'Education or Training',
      disabled: false,
      mentoringArea: [
        {
          value: 'Ordinary National Diploma',
          viewValue: 'Ordinary National Diploma',
        },
        {
          value: 'Higher National Diploma',
          viewValue: 'Higher National Diploma',
        },
        {
          value: 'University Postgraduate',
          viewValue: 'University Postgraduate',
        },
        {
          value: 'University Undergraduate',
          viewValue: 'University Undergraduate',
        },
        { value: 'Specialist Education', viewValue: 'Specialist Education' },
        {
          value: 'Fashion Designing Training',
          viewValue: 'Fashion Designing Training',
        },
        {
          value: 'Video/Photography Training',
          viewValue: 'Video/Photography Training',
        },
        { value: 'Journalism Training', viewValue: 'Journalism Training' },
        {
          value: 'IT Coding/Webdesigning/Database Training',
          viewValue: 'IT Coding/Webdesigning/Database Training',
        },
      ],
    },
    {
      name: 'Family',
      mentoringArea: [
        { value: 'New Relationship', viewValue: 'New Relationship' },
        { value: 'Marriage', viewValue: 'Marriage' },
        { value: 'Journalism Training', viewValue: 'Journalism Training' },
        { value: 'Parenting', viewValue: 'Parenting' },
      ],
    },
  ];

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.mentorInfoFormGroup
      .get('mentoringArea')
      .valueChanges.subscribe((val) => {
        this.mentorDetails.industry = val;
        this.mentorInfoFormGroup.get('industry').setValue(val);
      });
    this.mentorInfoFormGroup.patchValue({
      userId: this.mentorDetails.user,
      mentoringArea: this.mentorDetails.mentoringArea,
      preferredModeOfCommunication:
        this.mentorDetails.preferredModeOfCommunication,
      rating: this.mentorDetails.rating,
      industry: this.mentorDetails.mentoringArea,
    });
  }

  public onUpdateMentor() {
    this.isLoading = true;
    let formData = this.mentorInfoFormGroup.value;
    this.backend
      .updateMentor(formData)
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          if (response.code == '000') {
            this.mentorDetails = response.data;
            this.togglePage();
            this.appMaterialModule
              .showAlertToaster(
                AlertType.success,
                'Successfully updated mentoring details.',
                3000
              )
              .subscribe();
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next('');
    this.unSubscriptioNotifier.complete();
  }

  public togglePage() {
    this.page = !this.page;
  }
}
