<div style="background-color: #ffffff;">
    <div class="container-fluid bg-white mb-3">
        <div class="d-flex justify-content-between align-items-center pt-3">
            <h1>My Goals and action plans</h1>
            <div class="d-flex justify-content-end align-items-center">
                
                <div class="nav-link" [matMenuTriggerFor]="menu">
                    <img src="{{authUser.image}}" height="36" width="36"
                        style="border-radius: 50%; padding: 5px; object-fit: cover;">
                    <!-- <i class="fa fa-user-circle-o" style="font-size: 24px !important; color: #00213D;"></i> -->
                </div>
                <mat-menu #menu="matMenu" class="spz-profile-menu">
                    <div matRipple routerLink="/profile" class="media p-3">
                        <img src="{{authUser.image}}" class="align-self-center mr-2 profile-image-small"
                            alt="{{authUser.fullname}}">
                        <div class="media-body">
                            <h6 class="mt-0 mb-0">{{authUser.fullname}}</h6>
                            <p class="text-muted p-0 m-0" style="font-size: 14px;">View public profile</p>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div matRipple routerLink="/user/bookings" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-clock-o color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">Bookings</p>
                            <small class="text-muted p-0 m-0">View and manage all your bookings</small>
                        </div>
                    </div>
                    <div matRipple routerLink="/user/connections" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-users color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">My connections</p>
                            <small class="text-muted p-0 m-0">Manage your connections</small>
                        </div>
                    </div>
                    <div matRipple routerLink="/user/calendar" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-calendar-check-o color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">Calendar</p>
                            <small class="text-muted p-0 m-0">Setup your availability</small>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="p-3">
                        <div matRipple routerLink="/settings" class="d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-cog color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Settings</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>

                        <div matRipple class="d-flex justify-content-between align-items-center">
                            <div routerLink="/support" class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-life-ring color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Support</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>

                        <div matRipple class="d-flex justify-content-between align-items-center">
                            <div (click)="signout()" class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-sign-out color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Logout</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>
                    </div>
                    <!-- <button mat-menu-item>Item 1</button> -->

                    <!-- <button mat-menu-item>Item 2</button> -->
                </mat-menu>
            </div>
        </div>
        <hr>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-5 mx-auto col-sm-12 col-xs-12">
                <div class="d-flex justify-content-start align-items-center mb-3">
                    <img height="32" width="32" src="/assets/icons/check-grey.png"
                        class="align-self-center mr-3 img-fluid" alt="check-grey">
                    <h5 class="mt-0 mb-0" style="font-size: 16px;">{{goal.title}}</h5>
                </div>
                <div class="rounded bg-spz-grey-2 p-2 mb-3">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <p class="color-spz-grey" style="font-size: 15px;">Categoty</p>
                        <div class="d-flex justify-content-between align-items-center bg-white goal-card p-2"
                            style="border-radius: 10px;">
                            <img height="18" width="18" src="/assets/icons/category-icon.png"
                                class="align-self-center mr-2 img-fluid" alt="category-icon">
                            <small class="text-muted">{{goal.category}}</small>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center  mb-2">
                        <p class="color-spz-grey" style="font-size: 15px;">Due date</p>
                        <div class="d-flex justify-content-between align-items-center bg-white goal-card p-2"
                            style="border-radius: 10px;">
                            <img height="22" width="22" src="/assets/icons/duration-icon.png"
                                class="align-self-center mr-2 img-fluid" alt="duration-icon">
                            <small class="text-muted">{{goal.duration | date}}</small>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <p class="color-spz-grey" style="font-size: 15px;">Progress</p>
                        <div class="d-flex justify-content-between align-items-center bg-white goal-card p-2"
                            style="border-radius: 10px;">
                            <i class="fa fa-percent mr-2" style="font-size: 12px; color: #8b8fa7;"></i>
                            <small class="text-muted">{{goal.progress}}</small>
                        </div>
                    </div>
                </div>
                <div class="rounded alert-warning p-2 mb-3">
                    <small class="text-muted">Description</small>
                    <p>{{goal.description}}</p>
                </div>
                <div class="rounded alert-info p-2 mb-3">
                    <small class="text-muted">Action items</small>
                    <div *ngFor="let plan of actionPlans; let i = index">
                        <!-- <pre>{{plan  | json}}</pre> -->
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-start align-items-center">
                                <div class="form-check form-check-inline mt-2">
                                    <input value="{{plan.status}}" class="align-self-center"
                                        style="height: 18px; width: 18px;" #status [(ngModel)]="plan.status"
                                        name="status" type="checkbox" id="status"
                                        (change)="onCheckboxChanged(plan,$event)">
                                </div>
                                <div class="mt-2">
                                    <h5 [ngClass]="{'strike-through': plan.status == 'completed' }"
                                        class="mb-0 align-self-center" style="font-size: 15px;">{{plan.title}}</h5>
                                    <!-- <small style="max-height: 55px; overflow: hidden;" class="text-muted m-0 mb-2 p-0">
                                        {{plan.description
                                        | truncateText:120}}
                                    </small> -->
                                </div>
                            </div>
                            <div class="d-flex justify-content-end align-items-center">
                                <div (click)="deleteActionPlan(plan)" style="margin: 0px;" class="pull-right">
                                    <svg class="icon-cancel-circled small red clickable">
                                        <use xlink:href="/assets/icons/icons-sheet.svg#icon-cancel-circled">
                                        </use>
                                    </svg>
                                </div>
                                <div (click)="actionPlanFormData=plan; isEdited = true; openSetupActionPlanAsDialog();"
                                    style="margin: 0px;" class="pull-right">
                                    <svg class="icon-pencil-square small primary clickable">
                                        <use xlink:href="/assets/icons/icons-sheet.svg#icon-pencil-square">
                                        </use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 d-flex justify-content-center mt-3">
                        <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate"
                            [diameter]="25" [strokeWidth]="2">
                        </mat-spinner>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                        <div (click)="actionPlanFormData=null; isEdited = false;openSetupActionPlanAsDialog()"
                            class="d-flex justify-content-between align-items-center spz-button-outline-green p-2">
                            <i class="fa fa-plus-circle mr-2"></i>
                            <small>Add action plan</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #actionPlanDialog let-modal>
    <div class="modal-header">
        <h4 class="title-text" class="modal-title" id="modal-basic-title">Create an action plan</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss( 'Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <blockquote cite="" class="subtitle-text">Action plans, when executed properly, helps you achieve your goals. -
            David Thompson</blockquote>
        <form class="animated fadeIn" style="margin-top: 10px;" name="actionPlanForm" #actionPlanForm="ngForm"
            (ngSubmit)="actionPlanForm.form.valid">
            <div class="row" style="margin: 0;">
                <div class="form-group col-12">
                    <p class="subtitle-text" for="title">Title</p>
                    <input [(ngModel)]="actionPlanFormData.title" #title="ngModel" type="text" class="form-control"
                        id="title" name="title" [ngClass]="{ 'is-invalid': actionPlanForm.submitted
                && title.invalid }" required aria-describedby="titleHint" autocomplete="off">

                    <div *ngIf="actionPlanForm.submitted && title.invalid" class="invalid-feedback animated bounceIn">
                        <small *ngIf="title.errors.required" id="titleHint" class="form-text">title is
                            required</small>
                        <!-- <div *ngIf="title.errors.required"></div> -->
                    </div>
                </div>
                <div class="form-group col-12">
                    <p class="subtitle-text" for="description">Description</p>
                    <textarea [(ngModel)]="actionPlanFormData.description" #description="ngModel" type="text"
                        class="form-control" id="description" name="description" required autofocus minlength="5"
                        [ngClass]="{ 'is-invalid': actionPlanForm.submitted && description.invalid }"
                        aria-describedby="descriptionHint" rows="4">
                    </textarea>
                    <div *ngIf="actionPlanForm.submitted && description.invalid"
                        class="invalid-feedback animated bounceIn">
                        <small *ngIf="description.errors.required" id="descriptionHint" class="form-text">description
                            is
                            required</small>
                        <small *ngIf="description.errors.minlength" id="descriptionHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="description.errors.description">Please provide a valid description</small>
                    </div>
                </div>

                <div class="form-group col-12 date">
                    <p class="subtitle-text" for="dateOfExecution">Date of execution</p>
                    <div class="input-group date" data-target-input="nearest" NgTempusdominusBootstrap>
                        <input autocomplete="off" [(ngModel)]="actionPlanFormData.dateOfExecution"
                            #dateOfExecution="ngModel" type="text" NgTempusdominusBootstrapInput
                            class="form-control date" id="dateOfExecution" name="dateOfExecution" minlength="3"
                            [options]="dateTimePickerOptions"
                            [ngClass]="{ 'is-invalid': actionPlanForm.submitted && dateOfExecution.invalid }"
                            aria-describedby="dateOfExecutionHint">
                        <div class="input-group-append" NgTempusdominusBootstrapToggle>
                            <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                        </div>
                        <div *ngIf="actionPlanForm.submitted && dateOfExecution.invalid"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="dateOfExecution.errors.dateOfExecution">
                                Please provide date</small>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button type="submit" ngbAutofocus class="btn btn-outline-dark" (click)="onSaveActionPlan()">Save
            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
        </button>
    </div>
</ng-template>