<div class="container-fluid">
    <div class="">
        <button type="button" style="margin-bottom: 10px; margin-top:10px;" data-toggle="collapse" data-target="#signupForm" class="btn btn-default btn-sm">
            Add User
        </button>
        <form class="card p-3 mb-3 animate__animated animate__fadeIn collapse" [formGroup]="signupFormGroup" id="signupForm" name="signupForm" #signupForm="ngForm" (ngSubmit)="signupFormGroup.valid && onSubmit()">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <p class="subtitle-text" for="firstname">First name</p>
                    <input formControlName="firstname" type="text" class="form-control" id="firstname" name="firstname" required autofocus minlength="3" [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('firstname').hasError('firstname')  }" aria-describedby="firstnameHint">
                    <div *ngIf="signupForm.submitted && signupFormGroup.get('firstname').hasError('firstname')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="signupFormGroup.get('firstname').hasError('required')" id="firstnameHint" class="form-text">First name
                            is
                            required</small>
                        <small *ngIf="signupFormGroup.get('firstname').hasError('minlength')" id="firstnameHint" class="form-text">Please
                            provide a minimum length of 3 characters</small>
                        <small *ngIf="signupFormGroup.get('firstname').hasError('firstname')">Please provide a valid
                            first name</small>
                    </div>
                </div>

                <div class="form-group col-md-6">
                    <p class="subtitle-text" for="lastname">Last name</p>
                    <input formControlName="lastname" type="text" class="form-control" id="lastname" name="lastname" [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('lastname').hasError('lastname') }" required lastname aria-describedby="lastnameHint"
                        minlength="3">
                    <div *ngIf="signupForm.submitted && signupFormGroup.get('lastname').hasError('lastname')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="signupFormGroup.get('lastname').hasError('required')" id="lastnameHint" class="form-text">Last name is
                            required</small>
                        <small *ngIf="signupFormGroup.get('lastname').hasError('minlength')" id="lastnameHint" class="form-text">Please
                            provide a minimum length of 3 characters</small>
                        <small *ngIf="signupFormGroup.get('lastname').hasError('lastname')">Please provide a valid last
                            name</small>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <p class="subtitle-text" for="email">Email address</p>
                    <div class="input-group">
                        <input formControlName="email" type="email" class="form-control" (blur)="chechIfEmailExist()" id="email" name="email" [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('email').hasError('email') }" required email aria-describedby="emailHint"
                            autocomplete="off">
                        <div class="input-group-append">
                            <div #emailCheckProgress class="input-group-text" style="background: transparent !important; display: none;">
                                <img alt="temp image" src="/assets/img/ripple.gif" class="" width="20">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="signupForm.submitted && signupFormGroup.get('email').hasError('email')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="signupFormGroup.get('email').hasError('required')" id="emailHint" class="form-text">Email is
                            required</small>
                        <!-- <div *ngIf="email.errors.required"></div> -->
                        <small *ngIf="signupFormGroup.get('email').hasError('email')">Email must be a valid email
                            address</small>
                    </div>
                    <small #emailExistError style="display: none;" class="invalid-feedback animate__animated animate__bounceIn form-text">this email has been
                        taken</small>
                </div>
                <div class="form-group col-md-6">
                    <p class="subtitle-text" for="username">Choose a username</p>
                    <div class="input-group">
                        <input formControlName="username" (blur)="chechIfUsernameExist()" type="text" class="form-control" id="username" name="username" [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('username').hasError('username') }" required aria-describedby="emailHint"
                            autocomplete="off">
                        <div class="input-group-append">
                            <div #usernameCheckProgress class="input-group-text" style="background: transparent !important; display: none;">
                                <img alt="temp image" src="/assets/img/ripple.gif" class="" width="20">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="signupForm.submitted && signupFormGroup.get('username').hasError('username')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="signupFormGroup.get('username').hasError('required')" id="usernameHint" class="form-text">username is
                            required</small>
                        <!-- <div *ngIf="username.errors.required"></div> -->
                        <small *ngIf="signupFormGroup.get('username').hasError('username')">this username has been
                            taken</small>
                    </div>
                    <small #usernameExistError style="display: none;" class="invalid-feedback animate__animated animate__bounceIn form-text">this username has been
                        taken</small>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <p class="subtitle-text" for="password">Password (8 characters or more)</p>
                    <input formControlName="password" type="password" class="form-control" [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('password').hasError('password') }" id="password" autocomplete="off" name="password" required minlength="8" aria-describedby="passwordHint">
                    <div *ngIf="signupForm.submitted && signupFormGroup.get('password').hasError('password')" class="invalid-feedback animate__animated animate__bounceIn">
                        <small *ngIf="signupFormGroup.get('password').hasError('required')" id="passwordHint" class="form-text">password is
                            required</small>
                        <small *ngIf="signupFormGroup.get('password').hasError('minlength')" id="passwordHint" class="form-text">Please
                            provide a minimum length of 8 characters</small>
                        <small *ngIf="signupFormGroup.get('password').hasError('password')">Please provide a valid
                            password</small>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <p class="subtitle-text" for="role">Role</p>
                    <select class="form-control" formControlName="role" type="text" id="role" name="role" [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('role').hasError('role') }" required aria-describedby="roleHint" minlength="3">
                        <option disabled selected>Please select role</option>
                        <option value="once">ADMIN</option>
                        <option value="dialy">MENTOR</option>
                        <option value="weekly">REGULAR</option>
                    </select>
                    <div *ngIf="signupForm.submitted && signupFormGroup.get('role').hasError('role')" class="invalid-feedback  animate__animated animate__bounceIn">
                        <small *ngIf="signupFormGroup.get('role').hasError('required')" id="roleHint" class="form-text">role is
                            required</small>
                    </div>
                </div>
            </div>
            <button type="submit " style="margin-bottom: 10px; " [disabled]="!signupFormGroup.valid " class="btn btn-default ml-auto pull-right ">
                Save
                <img #progressRipple style="display: none; " alt=" " src="/assets/img/ripple.gif " class=" "
                    width="24 ">
            </button>
        </form>
    </div>
    <div class="form-group">
        <input class="form-control" id="name" type="text" [formControl]="searchInputFormControl" placeholder="search user">
    </div>
    <div class="card table-responsive bg-white" style="max-height: 620px;">
        <table style="font-size: 16px;" class="table bg-white table-borderless table-striped table-sm">
            <thead>
                <tr>
                    <th></th>
                    <th class="d-flex justify-content-start" scope="col">
                        First Name
                        <mat-icon (click)="sortUserList('firstname')" class="ml-2" role="button">sort_by_alpha
                        </mat-icon>
                    </th>
                    <th scope="col">Last Name</th>
                    <!-- <th scope="col">Mobile Number</th> -->
                    <th scope="col">Email</th>
                    <th scope="col">Role</th>
                    <th scope="col">Membership</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr class="animate__animated animate__fadeIn" *ngFor="let user of userList$ | async" style="border: .5px solid #EDEDED; font-size: 15px;">
                    <td><img src="{{user.image}}" height="36" width="36" style="border-radius: 50%; padding: 5px; object-fit: cover;"></td>
                    <td class="align-middle">{{user.profile.firstname}}</td>
                    <td class="align-middle">{{user.profile.lastname}}</td>
                    <!-- <td class="align-middle">{{user.profile.contact?.primaryPhonenumber}}</td> -->
                    <td class="align-middle">{{user.email}}</td>
                    <td class="align-middle">{{user?.role}}</td>
                    <td class="align-middle">{{user.profile.membership?.title}}</td>
                    <td class="align-middle">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button (click)="editUser(user)" data-toggle="collapse" data-target="#signupForm" mat-menu-item>
                                <mat-icon>edit</mat-icon>
                                <span>Edit</span>
                            </button>
                            <button (click)="deleteUser(user)" mat-menu-item>
                                <mat-icon>delete_outline</mat-icon>
                                <span>Delete</span>
                            </button>
                            <button mat-menu-item [matMenuTriggerFor]="membershipSubMenu">
                                <mat-icon>account_box</mat-icon>
                                <span>Change Membership</span>
                            </button>
                            <button mat-menu-item [matMenuTriggerFor]="roleSubMenu">
                                <mat-icon>account_circle</mat-icon>
                                <span>Change Role</span>
                            </button>
                            <button (click)="askToBeAMentor(user)" mat-menu-item>
                                <mat-icon>account_box</mat-icon>
                                <span>Ask To Be A Mentor</span>
                            </button>
                        </mat-menu>
                        <mat-menu #roleSubMenu="matMenu">
                            <button (click)="changeUserRole(user,'ADMIN')" mat-menu-item>ADMIN</button>
                            <button (click)="changeUserRole(user,'REGULAR')" mat-menu-item>REGULAR</button>
                            <button (click)="changeUserRole(user,'MENTOR')" mat-menu-item>MENTOR</button>
                        </mat-menu>

                        <mat-menu #membershipSubMenu="matMenu">
                            <button (click)="changeUserMembership(user,'GOLD')" mat-menu-item>GOLD</button>
                            <button (click)="changeUserMembership(user,'SILVER')" mat-menu-item>SILVER</button>
                            <button (click)="changeUserMembership(user,'PLATINUM')" mat-menu-item>PLATINUM</button>
                        </mat-menu>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="col-md-12 d-flex justify-content-center mt-3 mb-3">
            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
            </mat-spinner>
        </div>
    </div>
</div>