<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 col-sm-12" style="margin-top: 8px;">
            <div class="mini-bar-menu bg-white card-shadow" style="margin-bottom: 5px; padding: 10px;">
                <ul class="horizontal-list">
                    <li>
                        Request a new mentor
                    </li>
                </ul>
            </div>
            <!-- <pre>Selected range: {{mentorRequestFormGroup.value | json}}</pre> -->
            <div class="bg-white animated fadeIn" style="margin-bottom: 5px; padding: 12px;">
                <form [formGroup]="mentorRequestFormGroup" class="animated fadeIn" style="margin-top: 10px;" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="form-group col-md-6" style="margin-bottom: 1px; margin-top: 15px;">
                            <mat-form-field floatLabel="auto" hideRequiredMarker appearance="standard" style="width: 100%">
                                <mat-label style="font-size: 16px;">What area of mentoring do you need?</mat-label>
                                <mat-select [(value)]="requestMentorFormData.mentoringArea" formControlName="mentoringArea" name="mentoringArea">
                                    <mat-option selected>-- Please select --</mat-option>
                                    <mat-optgroup *ngFor="let group of mentoringAreaGroups" [label]="group.name" [disabled]="group.disabled">
                                        <mat-option *ngFor="let mentoringArea of group.mentoringArea" [value]="mentoringArea.value">
                                            {{mentoringArea.viewValue}}
                                        </mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-6" style="margin-bottom: 1px;">
                            <mat-form-field floatLabel="auto" appearance="standard" style="width: 100%">
                                <mat-label style="font-size: 16px;">Please state the objective of your Mentoring request
                                </mat-label>
                                <textarea matInput formControlName="objectiveOfMentorship" class="" id="objectiveOfMentorship" rows="3" name="objectiveOfMentorship"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-6" style="margin-bottom: 1px; margin-top: 15px;">
                            <mat-form-field floatLabel="auto" appearance="standard" style="width: 100%">
                                <mat-label style="font-size: 16px;">Enter a date range</mat-label>
                                <mat-date-range-input [rangePicker]="picker">
                                    <input matStartDate formControlName="startDate" placeholder="Start date">
                                    <input matEndDate formControlName="endDate" placeholder="End date">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker [startAt]="mentorRequestFormGroup.get('minDate').value" #picker>
                                    <mat-date-range-picker-actions>
                                        <button mat-button matDateRangePickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                    </mat-date-range-picker-actions>
                                </mat-date-range-picker>

                                <mat-error *ngIf="mentorRequestFormGroup.get('startDate').hasError('matStartDateInvalid')">
                                    Invalid start date
                                </mat-error>
                                <mat-error *ngIf="mentorRequestFormGroup.get('endDate').hasError('matEndDateInvalid')">
                                    Invalid end date
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group col-md-6" style="margin-bottom: 1px; margin-top: 15px;">
                            <p class="subtitle-text" style="font-size: 16px;">Have you had any Mentor before?</p>
                            <mat-radio-group formControlName="isFirstTimeMentee" aria-label="Have you had any Mentor before?">
                                <mat-radio-button value="YES">Yes</mat-radio-button>
                                <mat-radio-button value="NO">No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="form-group col-md-6" style="margin-bottom: 1px; margin-top: 15px;">
                            <mat-form-field floatLabel="auto" appearance="standard" style="width: 100%">
                                <mat-label style="font-size: 16px;">Please select how you would like your mentor to contact and engage with you</mat-label>
                                <mat-select formControlName="preferredModeOfCommunication" multiple>
                                    <mat-option value="SPZ application in-chat">SPZ application in-chat</mat-option>
                                    <mat-option value="Skype chat/call">Skype chat/call</mat-option>
                                    <mat-option value="Whatsapp chat/call">Whatsapp chat/call</mat-option>
                                    <mat-option value="Facebook chat">Facebook chat</mat-option>
                                    <mat-option value="Zoom meeting">Zoom meeting</mat-option>
                                    <mat-option value="Google meet">Google meet</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!--input progress bar here-->
                        <div class="col-md-12 d-flex justify-content-center mt-3">
                            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                            </mat-spinner>
                        </div>
                        <div class="col-md-12 d-flex justify-content-end" [hidden]="isLoading" style="margin-bottom: 15px; padding-top: 10px;">
                            <button mat-flat-button color="primary" type="submit" [hidden]="isLoading" class="btn btn-lg color-white" [disabled]="!mentorRequestFormGroup.valid">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>