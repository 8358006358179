<div class="container-fluid bg-white pt-5 pb-5">
    <div class="container">
        <a class="navbar-brand" href="/startingpointzone/home">
            <img alt="temp image" src="/assets/img/spz-logo-dark.svg" class="img-fluid">
        </a>
        <div class="row mt-5">
            <div class="col-md-6">
                <h2 class="title-text color-spz-green">Reset password</h2>
                <div class="mt-4">
                    <form [formGroup]="userPasswordFormGroup" class="animated fadeIn" style="margin-top: 10px;"
                        name="userPasswordFormGroup" #userPasswordForm="ngForm" (ngSubmit)="onResetPassword();">
                        <div class="form-group">
                            <p class="subtitle-text" for="password">password</p>
                            <div class="input-group">
                                <input formControlName="password" #password type="password" class="form-control"
                                    autocomplete="off"
                                    [ngClass]="{ 'is-invalid':userPasswordForm.submitted && userPasswordFormGroup.get('password').hasError('required') }"
                                    id="password" name="password" required minlength="8" password
                                    aria-describedby="passwordHint">
                                <div class="input-group-append" (click)="showPassword()">
                                    <div class="input-group-text">
                                        <i class="fa fa-fw fa-eye"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <p class="subtitle-text" for="confirmPassword">Confirm password</p>
                            <input formControlName="confirmPassword" type="password" class="form-control"
                                [ngClass]="{ 'is-invalid': userPasswordForm.submitted && userPasswordFormGroup.get('confirmPassword').hasError('confirmPassword') }"
                                autocomplete="off" id="confirmPassword" name="confirmPassword" required minlength="8">
                            <div *ngIf="userPasswordForm.submitted && userPasswordFormGroup.get('confirmPassword').hasError('confirmPassword')"
                                class="invalid-feedback animate__animated animate__bounceIn">
                                <small *ngIf="userPasswordFormGroup.get('confirmPassword').hasError('required')"
                                    id="confirmPasswordHint" class="form-text">Please confirm password</small>
                                <small *ngIf="userPasswordFormGroup.get('confirmPassword').hasError('minlength')"
                                    id="confirmPasswordHint" class="form-text ">Please provide a minimum length of 8
                                    characters</small>
                                <small
                                    *ngIf="userPasswordFormGroup.get('confirmPassword').hasError( 'confirmPassword')">Please
                                    provide a valid
                                    password</small>
                            </div>
                            <small #invalidConfirmPassword style="display: none; "
                                class="invalid-feedback animate__animated animate__bounceIn form-text ">Please be
                                sure both passwords are
                                the same</small>
                        </div>
                        <button type="submit" class="btn spz-button-green mt-4 mb-3 btn-block" [disabled]="!userPasswordFormGroup.valid">Save
                            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class=""
                                width="24">
                        </button>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <img alt="temp image" src="/assets/img/spz-login-image.svg" class="img-fluid">
            </div>
        </div>
    </div>
</div>