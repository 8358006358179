<div class="container-fluid">
    <div class="row mt-2" *ngIf="mentor">
        <div class="col-12" style="margin-top: 8px;">
            <div class="mini-bar-menu bg-white p-2 card" style="margin-bottom: 5px;">
                <ul class="horizontal-list">
                    <li>
                        <a routerLink="/engagements/request-mentor">Request a mentor</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-4 mb-2">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex flex-column align-items-center text-center">
                        <img src="{{mentor?.image}}" alt="Admin" class="rounded-circle" width="150">
                        <div class="mt-3">
                            <h4>{{mentor?.firstname+' '+mentor?.lastname}}</h4>
                            <p class="text-secondary mb-1">{{mentor?.industry}}</p>
                            <p class="text-muted font-size-sm">{{mentor?.email}}</p>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-2" *ngIf="mentor?.socialMediaAccount.length > 0">
                <ul class="list-group list-group-flush">
                    <li *ngFor="let socailAccount of mentor?.socialMediaAccount" class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                        <h6 *ngIf="socailAccount.title=='TWITTER'" class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter mr-2 icon-inline text-info">
                                <path
                                    d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z">
                                </path>
                            </svg>{{socailAccount.title}}</h6>
                        <h6 *ngIf="socailAccount.title=='FACEBOOK'" class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook mr-2 icon-inline text-primary">
                                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                            </svg>{{socailAccount.title}}</h6>
                        <!-- <h6 *ngIf="socailAccount.title=='FACEBOOK'" class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter mr-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>{{socailAccount.title}}</h6> -->
                        <span class="text-secondary">{{socailAccount.username | truncateText:15}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card mb-2">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Full Name</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                            {{mentor?.firstname+' '+mentor?.lastname}}
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Email</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                            {{mentor?.email}}
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm-3">
                            <h6 class="mb-0">Address</h6>
                        </div>
                        <div class="col-sm-9 text-secondary">
                            {{mentor?.contact.workplaceAddress}}
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body">
                    <h6 class="mb-2">Skills & Experiences</h6>
                    <div *ngFor="let skill of mentor.skillsAndExperience" style="padding: 10px 0 10px 0;">
                        <div class="media align-items-center" style="height: 50px; margin-bottom: 10px;">
                            <div class="media-left mr-3" style="padding: 4px;">
                                <svg class="icon-engineer big primary">
                                    <use xlink:href="/assets/icons/icons-sheet.svg#icon-engineer"></use>
                                </svg>
                            </div>
                            <div class="media-body">
                                <p class="media-heading title-text" style="margin: 0;">{{skill.title}}</p>
                                <p class="subtitle-text" style="font-size: 13px;">
                                    {{skill.fromDate | date}} - {{skill.toDate | date}}</p>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body">
                    <h6 class="mb-2">Mentees & Reviews</h6>
                    <mat-divider></mat-divider>
                    <div id="grid_groups_wrapper" class="pt-2">
                        <div id="grid_groups" role="grid">
                            <div role="row group">
                                <div class="route d-flex">
                                    <div *ngFor="let mentee of mentor?.mentees; let i = index;">
                                        <div class="hover-anchor" [satPopoverAnchor]="p" (mouseenter)="p.open()" (click)="p.toggle()" class="rounded-circle default-avatar member-overlap-item" style="background: url({{mentee.image}}) 0 0 no-repeat; background-size: cover;">
                                            <sat-popover #p [autoFocus]="false" hasBackdrop scrollStrategy="close" openTransition="100ms ease-out" closeTransition="100ms ease-in" verticalAlign="below">
                                                <div class="card">
                                                    <div class="card-body p-3">
                                                        <div class="media bg-white p-0" style="border-radius: 5px; max-height: 160px; overflow: hidden;">
                                                            <img class="mr-3 align-self-start img-responsive rounded" style="width: 100px; height:100px; border-radius: 5px; object-fit: cover; background: transparent;" src="{{mentee.image}}" alt="">
                                                            <div class="media-body pr-1">
                                                                <h5 class="mt-0 title-text">{{mentee?.firstname+' '+mentee?.lastname}}</h5>
                                                                <p class="mt-0 mb-1 subtitle-text">{{mentee?.mentoring_area}}</p>
                                                                <a class="p-2 text-center link mt-2 bg-primary d-flex justify-content-center rounded" style="color: white;" routerLink="/users/profile/{{mentee.id}}">
                                                                    View profile
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </sat-popover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body">
                    <h6 class="mb-2">Preferred Mode Of Communication</h6>
                    <mat-divider class="mb-3"></mat-divider>
                    <mat-chip-list>
                        <mat-chip *ngFor="let com of mentor?.preferredModeOfCommunication">{{com}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="card mb-2">
                <div class="card-body">
                    <h6 class="mb-2">Badges, Rating & Honourings</h6>
                    <mat-divider class="mb-3"></mat-divider>
                    <div class="row">
                        <div class="col-md-3 col-xs-6">
                            <img src="https://res.cloudinary.com/profaust-ent/image/upload/v1625358049/spz-badges/badge9_wpqrby.png" class="rounded-circle" width="100">
                            <p class="text-muted text-center">Badge1</p>
                        </div>
                        <div class="col-md-3 col-xs-6">
                            <img src="https://res.cloudinary.com/profaust-ent/image/upload/v1625358049/spz-badges/badge4_pdlp7l.png" class="rounded-circle" width="100">
                            <p class="text-muted">Badge2</p>
                        </div>
                        <div class="col-md-3 col-xs-6">
                            <img src="https://res.cloudinary.com/profaust-ent/image/upload/v1625358049/spz-badges/badge5_dcgd21.png" class="rounded-circle" width="100">
                            <p class="text-muted">Badge3</p>
                        </div>
                        <div class="col-md-3 col-xs-6">
                            <img src="https://res.cloudinary.com/profaust-ent/image/upload/v1625358049/spz-badges/badge10_opvjqt.png" class="rounded-circle" width="100">
                            <p class="text-muted">Badge4</p>
                        </div>
                        <div class="col-md-3 col-xs-6">
                            <img src="https://res.cloudinary.com/profaust-ent/image/upload/v1625358049/spz-badges/badge11_n1fpza.png" class="rounded-circle" width="100">
                            <p class="text-muted">Badge5</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 d-flex justify-content-center mt-3">
        <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
        </mat-spinner>
    </div>
</div>