<div [ngClass]="sidebarToggle == 'on' ? 'page-wrapper chiller-theme toggled' : 'page-wrapper chiller-theme'">
    <div id="show-sidebar" class="btn btn-sm btn-dark" (click)="toggleSidebar()">
        <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
    <nav id="sidebar" class="sidebar-wrapper">
        <div class="sidebar-content">
            <div class="sidebar-brand p-4">
                <a href="/startingpointzone/home">
                    <img alt="temp image" src="/assets/img/spz-logo.svg" class="img-fluid">
                </a>
                <div id="close-sidebar" (click)="toggleSidebar()">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                </div>
            </div>
            <div class="sidebar-menu">
                <ul class="mt-3">
                    <li class="mb-2">
                        <a routerLink="/home">
                            <i class="fa fa-home color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Home</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/membership">
                            <i class="fa fa-address-card-o color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Membership plan</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/goals">
                            <i class="fa fa-trophy color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Goals & Action Plans</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/events">
                            <i class="fa fa-calendar color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Events</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/engagements">
                            <i class="fa fa-laptop color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Mentoring Engagements</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/trainings">
                            <i class="fa fa-graduation-cap color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Mentoring Engagements</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/Questionnaires">
                            <i class="fa fa-question-circle color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Questionnaires</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/polls">
                            <i class="fa fa-thumbs-o-up color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Polls</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/messages">
                            <i class="fa fa-comments color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Messages</span>
                        </a>
                    </li>
                </ul>
                <div class="p-3">
                    <button class="btn btn-block spz-button-green">
                        Create session
                        <span><i class="fa fa-angle-right color-white" style="font-size: 18px !important;"></i></span>
                    </button>
                    <button class="btn btn-block spz-button-outline-white color-white mt-3">
                        Request for a mentor
                    </button>
                </div>
            </div>
            <!-- sidebar-menu  -->
        </div>
        <!-- sidebar-content  -->
        <div class="sidebar-footer">
            <!-- <a href="#">
              <i class="fa fa-bell"></i>
              <span class="badge badge-pill badge-warning notification">3</span>
            </a>
            <a href="#">
              <i class="fa fa-envelope"></i>
              <span class="badge badge-pill badge-success notification">7</span>
            </a>
            <a href="#">
              <i class="fa fa-cog"></i>
              <span class="badge-sonar"></span>
            </a> -->
            <a href="#" (click)="signout()">
                Sign out
                <span><i class="fa fa-power-off"></i></span>
            </a>
        </div>
    </nav>
    <!-- sidebar-wrapper  -->
    <main class="page-content bg-white">
        <div class="container-fluid p-0 pr-3 pl-3">
            <div class="d-flex justify-content-between align-items-center p-0 m-0">
                <h1>Dashboard</h1>
                <!-- <div class="d-flex justify-content-between">
                    <a class="p-2 nav-link" routerLink="/home" routerLinkActive="sidenav-menu-active">Home</a>
                    <a class="p-2 nav-link" routerLink="/spz-fellowship" routerLinkActive="sidenav-menu-active">SPZ
                        Fellowship</a>
                    <a class="p-2 nav-link" routerLink="/inspirations" routerLinkActive="sidenav-menu-active">Find
                        inspiration</a>
                </div> -->
                <div class="d-flex justify-content-end align-items-center">
                    <!-- <div class="nav-link" (click)="signout()">
                        <i class="fa fa-sign-out" style="font-size: 18px !important; color: #00213D;"></i>
                        <span>Sign out</span>
                    </div> -->
                    <div class="nav-link" [matMenuTriggerFor]="menu">
                        <img src="{{authUser.image}}" height="36" width="36"
                            style="border-radius: 50%; padding: 5px; object-fit: cover;">
                        <!-- <i class="fa fa-user-circle-o" style="font-size: 24px !important; color: #00213D;"></i> -->
                    </div>
                    <mat-menu #menu="matMenu" class="spz-profile-menu">
                        <div matRipple routerLink="/profile" class="media p-3">
                            <img src="{{authUser.image}}" class="align-self-center mr-2 profile-image-small"
                                alt="{{authUser.fullname}}">
                            <div class="media-body">
                                <h6 class="mt-0 mb-0">{{authUser.fullname}}</h6>
                                <p class="text-muted p-0 m-0" style="font-size: 14px;">View public profile</p>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div matRipple routerLink="/user/bookings" class="media p-3">
                            <div class="align-self-center mr-2">
                                <i class="fa fa-clock-o color-spz-grey" style="font-size: 28px !important;"></i>
                            </div>
                            <div class="media-body">
                                <p style="font-size: 14px;" class="mt-0 mb-0">Bookings</p>
                                <small class="text-muted p-0 m-0">View and manage all your bookings</small>
                            </div>
                        </div>
                        <div matRipple routerLink="/user/connections" class="media p-3">
                            <div class="align-self-center mr-2">
                                <i class="fa fa-users color-spz-grey" style="font-size: 28px !important;"></i>
                            </div>
                            <div class="media-body">
                                <p style="font-size: 14px;" class="mt-0 mb-0">My connections</p>
                                <small class="text-muted p-0 m-0">Manage your connections</small>
                            </div>
                        </div>
                        <div matRipple routerLink="/user/calendar" class="media p-3">
                            <div class="align-self-center mr-2">
                                <i class="fa fa-calendar-check-o color-spz-grey"
                                    style="font-size: 28px !important;"></i>
                            </div>
                            <div class="media-body">
                                <p style="font-size: 14px;" class="mt-0 mb-0">Calendar</p>
                                <small class="text-muted p-0 m-0">Setup your availability</small>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="p-3">
                            <div matRipple routerLink="/settings" class="d-flex justify-content-between align-items-center">
                                <div class="d-flex justify-content-start align-items-center">
                                    <i class="fa fa-cog color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                    <p class="mt-3" style="font-size: 14px;">Settings</p>
                                </div>
                                <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                            </div>

                            <div matRipple class="d-flex justify-content-between align-items-center">
                                <div routerLink="/support" class="d-flex justify-content-start align-items-center">
                                    <i class="fa fa-life-ring color-spz-grey mr-2"
                                        style="font-size: 24px !important;"></i>
                                    <p class="mt-3" style="font-size: 14px;">Support</p>
                                </div>
                                <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                            </div>

                            <div matRipple class="d-flex justify-content-between align-items-center">
                                <div (click)="signout()" class="d-flex justify-content-start align-items-center">
                                    <i class="fa fa-sign-out color-spz-grey mr-2"
                                        style="font-size: 24px !important;"></i>
                                    <p class="mt-3" style="font-size: 14px;">Logout</p>
                                </div>
                                <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                            </div>
                        </div>
                        <!-- <button mat-menu-item>Item 1</button> -->

                        <!-- <button mat-menu-item>Item 2</button> -->
                    </mat-menu>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-8">
                    <div class="mb-3">
                        <h3>Welcome back, <strong>{{authUser.fullname||''}}</strong></h3>
                        <div class="objective-card mt-4">
                            <p style="font-size: 15px;">What is your objective for learning today?</p>
                            <div class="d-flex justify-content-between align-items-center">
                                <input [formControl]="objectiveSearchInput" name="objectiveSearchInput"
                                    id="objectiveSearchInput" class="form-control objective-search-input"
                                    placeholder="Career Transition, Growth, Job Search etc">
                                <button class="btn spz-button-green ml-3">Search</button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Upcoming sessions</h5>
                            <div class="mt-2 p-3 border rounded mb-3 table-responsive bg-white">
                                <table class="table bg-white table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Date/Time</th>
                                            <th>Sessions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="animate__animated animate__fadeIn">
                                            <td>
                                                <p class="m-0 p-0">Mon. 30 Jan</p>
                                                <small class="text-muted">9:00am</small>
                                            </td>
                                            <td>
                                                <p class="m-0 p-0">Peter Benson</p>
                                                <a href="#"><small>view details</small></a>

                                            </td>
                                        </tr>
                                        <tr class="animate__animated animate__fadeIn">
                                            <td>
                                                <p class="m-0 p-0">Mon. 30 Jan</p>
                                                <small class="text-muted">9:00am</small>
                                            </td>
                                            <td>
                                                <p class="m-0 p-0">Peter Benson</p>
                                                <a href="#"><small>view details</small></a>
                                            </td>
                                        </tr>
                                        <tr class="animate__animated animate__fadeIn">
                                            <td>
                                                <p class="m-0 p-0">Mon. 30 Jan</p>
                                                <small class="text-muted">9:00am</small>
                                            </td>
                                            <td>
                                                <p class="m-0 p-0">Peter Benson</p>
                                                <a href="#"><small>view details</small></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>
                                    <a class="spz-button-green btn" style="font-size: 16px;"
                                        routerLink="/engagements/sessions">View sessions</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="media mb-3 col-md-12" *ngFor="let mentor of mentorList$ | async">
                                    <img height="90" width="90" src="{{mentor.image}}" class="mr-3 img-fluid rounded img-thumbnail" alt="{{mentor.firstname}}">
                                    <div class="media-body">
                                      <h5 class="mt-0 mb-0">{{mentor.firstname+' '+mentor.lastname}}</h5>
                                      <p class="m-0 p-0">{{mentor.biography | truncateText}}</p>
                                      <small class="text-muted">{{mentor.industry}}</small>
                                    </div>
                                  </div>
                                <!-- <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6 mb-2"
                                    *ngFor="let mentor of mentorList$ | async">
                                    <pre>{{mentor  | json}}</pre>
                                    <a style="border-radius: 15px; min-height: 176px;" class="nav-link p-0 m-0"
                                        routerLink="/engagements/mentor/{{mentor.id}}">
                                        <img src="{{mentor.image}}" style="border-radius: 8px;" class="img-fluid">
                                        <h5 class="color-spz-green p-0 m-0">
                                            {{mentor.firstname+' '+mentor.lastname}}
                                        </h5>
                                        <p class="p-0 m-0" style="font-size: 12px;">{{mentor.industry}}</p>
                                    </a>
                                </div> -->
                                <div class="col-md-6">
                                    <img height="90" width="90" class="img-fluid" src="/assets/img/spz-discovermentor-image.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="mt-3">
                        <h5>Recent news</h5>
                        <div class="row" *ngIf="(newsArticles | async).length > 0">
                            <div class="col-md-12 mt-3" *ngFor="let news of newsArticles | async; let i=index;">
                                <img alt="{{news.multimedia[2].caption||''}}" src="{{news.multimedia[1].url}}"
                                    class="img-fluid" style="border-radius: 10px;">
                                <h6 class="color-spz-green mt-3" style="font-size: 15px;">{{news.title}}</h6>
                                <p style="font-size: 12px;">{{news.abstract}}</p>
                                <div class="d-flex justify-content-between align-items-center">
                                    <small class="text-muted">{{news.published_date | date}}</small>
                                    <a class="nav-link" style="font-size: 13px;" href="{{news.url}}">read more</a>
                                </div>
                            </div>
                            <div class="col-md-12 mt-4 d-flex justify-content-center">
                                <mat-spinner [hidden]="!isLoadingArticles" class="align-self-center"
                                    mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                                </mat-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- page-content" -->
</div>