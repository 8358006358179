import { Component, OnInit } from '@angular/core';
import { Goal } from 'src/app/models/goal';
import { Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { ActionPlan } from 'src/app/models/action-plan';
import { Subject, Subscription } from 'rxjs';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { SetupActionPlanComponent } from '../action-plan/setup-action-plan.component';
import { DialogButton } from 'src/app/services/dialog.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-goal-details',
  templateUrl: './goal-details.component.html',
  styleUrls: ['./goal-details.component.css'],
})
export class GoalDetailsComponent implements OnInit {
  public goal: Goal = new Goal();
  public actionPlanFormData: ActionPlan = new ActionPlan();
  public actionPlans: Array<ActionPlan> = new Array<ActionPlan>();
  closeResult: string;
  private unSubscriptioNotifier = new Subject();
  authUser = null;
  public isLoading: boolean = false;
  public isEdited = false;

  dateTimePickerOptions = {
    format: 'YYYY-MM-DD h:mm:ss a',
    //format: "YYYY-MM-DD",
    //maxDate: moment(),
    //minDate: moment(),
    // ...
  };

  constructor(
    private router: Router,
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    private appMaterialDesignModule: AppMaterialDesignModule
  ) {
    this.authUser  = this.localAuthService.getUser();
  }

  ngOnInit() {
    this.dataSource.currentdata.pipe(take(1)).subscribe((data) => {
      this.goal = data;
    });

    if (this.goal.id == null) {
      this.router.navigate(['goals'], {
        queryParams: {},
        skipLocationChange: false,
      });
    } else {
      this.getActionPlans(this.goal.id);
    }
  }

  getActionPlans(goal: string): void {
    this.isLoading = true;
    this.backend
      .getActionPlans(goal)
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.actionPlans = response.data;
          } else {
            this.appMaterialDesignModule
              .showAlertToaster(
                AlertType.info,
                'You do not have any action plan for this goal yet. Please click on Set up an action plan button to get started.',
                3
              )
              .pipe(take(1))
              .subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete getActionPlans');
        },
      });
  }

  updateGoalProgress() {
    const totalCompleted = this.actionPlans.filter((e) => {
      return e.status == 'completed';
    }).length;
    const totalActionPlan = this.actionPlans.length;
    const progress = Math.round(totalCompleted / totalActionPlan * 100)
    this.goal.progress = progress;
    if(progress  == 100){
      this.appMaterialDesignModule.showAlertToaster(AlertType.success,'Congratulations 🎉🎊. You have reached your goal.',5).subscribe()
    }
  }

  onCheckboxChanged(value: ActionPlan, event) {
    if (event.target.checked) {
      value.status = 'completed';
      value.dateOfExecution = new Date().toISOString();
    } else {
      value.status = '';
    }
    this.actionPlanFormData = value;
    this.isEdited = true;
    this.onSaveActionPlan();
  }

  public openSetupActionPlanAsDialog(): void {
    this.dataSource.setData({
      goal: this.goal,
      actionPlan: this.actionPlanFormData,
    });
    this.appMaterialDesignModule
      .openDialog(SetupActionPlanComponent, {
        width: '600px',
        data: this.actionPlanFormData,
      })
      .subscribe({
        next: (result) => {
          if (result.button === DialogButton.ok) {
            let resultActionPlan: ActionPlan = result.actionPlan;
            var index = this.actionPlans.findIndex((plan) => {
              return plan.id == resultActionPlan.id;
            });
            console.log('index of result action plan : ' + index);
            if (index == -1) {
              this.actionPlans.unshift(resultActionPlan);
            } else {
              this.actionPlans[index] = resultActionPlan;
            }
          }
        },
      });
  }

  onSaveActionPlan(): void {
    this.isLoading = true;
    if (this.isEdited) {
      this.backend
        .updateActionPlan(this.actionPlanFormData)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              var temp = this.actionPlans.find((item) => {
                return item.id == this.actionPlanFormData.id;
              });
              var index = this.actionPlans.indexOf(temp);
              //this.actionPlans.splice(this.actionPlans.indexOf(temp), 1);
              this.actionPlans.splice(index, 1);
              this.actionPlans.unshift(response.data);
            } else {
              //this.appComponent.showAlertToaster(AlertType.success, response.message, 10000);
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isEdited = false;
            this.actionPlanFormData = new ActionPlan();
            //this.modalService.dismissAll();
          },
          complete: () => {
            this.isLoading = false;
            this.isEdited = false;
            this.actionPlanFormData = new ActionPlan();
            this.updateGoalProgress();
            //this.modalService.dismissAll();
            console.log('on complete updateActionPlan');
          },
        });
    } else {
      this.actionPlanFormData.user = this.authUser.id;
      this.actionPlanFormData.goal = this.goal.id;
      this.backend
        .createActionPlan(this.actionPlanFormData)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              this.actionPlans.push(response.data);
            } else {
              //this.appComponent.showAlertToaster(AlertType.success, response.message, 10000);
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isEdited = false;
            this.actionPlanFormData = new ActionPlan();
            //this.modalService.dismissAll();
          },
          complete: () => {
            this.isLoading = false;
            this.isEdited = false;
            this.actionPlanFormData = new ActionPlan();
            this.updateGoalProgress()
            //this.modalService.dismissAll();
            console.log('on complete createActionPlan');
          },
        });
    }
  }

  deleteActionPlan(goal: any): void {
    this.appMaterialDesignModule
      .openDialog(ConfirmDialogComponent, {
        width: '400px',
        title: 'Caution',
        message: 'Are sure you want to delete! This cannot be undone.',
      })
      .pipe(
        switchMap((result) => {
          if (result.button === DialogButton.ok) {
            this.isLoading = true;
            this.appMaterialDesignModule.showProgressDialog('Deleting...');
            return this.backend.deleteActionPlan(goal);
          }
        })
      )
      .subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.isLoading = false;
            this.appMaterialDesignModule.hideProgressDialog();
            var index = this.actionPlans.indexOf(goal);
            this.actionPlans.splice(index, 1);
            this.appMaterialDesignModule
              .showAlertToaster(AlertType.success, 'Successfully delete.', 3)
              .pipe(take(1))
              .subscribe();
          } else {
            this.appMaterialDesignModule
              .showAlertToaster(AlertType.error, response.message, 3)
              .pipe(take(1))
              .subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.updateGoalProgress();
          console.log('on complete deleteActionPlan');
        },
      });
  }

  public signout(): void {
    if (this.localAuthService.logout()) {
      this.localAuthService
        .signOut()
        .then(
          (onfulfilled) => {
            console.log('onfulfilled... ' + onfulfilled);
          },
          (onrejected) => {
            console.log('onrejected... ' + onrejected);
          }
        )
        .catch((onrejected) => {
          console.log('catch onrejected... ' + onrejected);
        })
        .finally(() => {
          console.log('onfinally...');
          this.router.navigate(['/login']);
        });
    }
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next('');
    this.unSubscriptioNotifier.complete();
  }
}
