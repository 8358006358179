import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AuthUser } from 'src/app/models/auth-user';
import { MentorRequestData } from 'src/app/models/mentor-request-data';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { DialogButton } from 'src/app/services/dialog.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { AssignMentorComponent } from '../assign-mentor/assign-mentor.component';

@Component({
  selector: 'app-mentoring-request-details',
  templateUrl: './mentoring-request-details.component.html',
  styleUrls: ['./mentoring-request-details.component.css']
})
export class MentoringRequestDetailsComponent implements OnInit, OnDestroy {

  public isLoading = false;
  public requestId = ''
  public mentorRequest: MentorRequestData
  public authUser: AuthUser;
  public observers: Array<Subscription> = new Array<Subscription>();
  constructor(private localAuthService: LocalAuthService,
    private router: Router,
    private backend: BackendService,
    private dataSource: DataService,
    public sidebarService: SidebarService,
    private activateRoute: ActivatedRoute,
    public appMaterialModule: AppMaterialDesignModule) {
    this.mentorRequest = new MentorRequestData()
  }

  ngOnInit(): void {
    this.authUser = new AuthUser();
    this.observers.push(
      this.localAuthService.getAuthUser().subscribe(data => {
        this.authUser = data;
      })
    );

    this.activateRoute.params.subscribe(params => {
      //this.getUserDetails(this.authUser.id);
      this.requestId = params.id || '0';
      if (this.requestId != '') {
        //this.getMentorById(this.selectedMentorId);
      }
    });

    this.dataSource.currentdata.subscribe(data => {
      this.mentorRequest = data;
      if (this.mentorRequest.user == undefined) {
        this.router.navigate(['/admin/engagements/mentoring-requests'], { queryParams: {}, skipLocationChange: false })
        return
      }
      this.getUserDetails(this.mentorRequest.user?.id)
    });
  }

  getUserDetails(formData: string) {
    this.isLoading = true;
    this.observers.push(this.backend.getUserDetails3(formData).subscribe({
      next: (response: any) => {
        this.isLoading = false;
        if (response.code === '000') {
          this.mentorRequest.user = response.data;
          this.localAuthService.setUser(this.authUser);
        } else {

        }

      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        console.log('on complete getUserDetails');
      }
    }));
  }

  public openAssignMentorDialog() {
    this.appMaterialModule.openDialog(AssignMentorComponent, {
      width: '500px',
      data: {
        requestMentorFormData: this.mentorRequest
      }
    }).subscribe({
      next: (result) => {
        if (result.button === DialogButton.ok) {
          this.mentorRequest = result['requestMentorFormData']
          this.getUserDetails(this.mentorRequest.user.id);
        }
      }
    });
  }

  public acceptBecomeMentorRequest(request: MentorRequestData) {
    this.appMaterialModule.openDialog(ConfirmDialogComponent, {
      width: '400px',
      title: 'Please Confirm',
      message: 'Are you sure you want to accept this request?'
    }).pipe(switchMap((result) => {
      if (result.button == DialogButton.ok) {
        this.appMaterialModule.showProgressDialog('Processing request. Please wait...');
        request['status'] = 'ACCEPTED'
        let updateMentorshipRequest = this.backend.requestMentor(request);
        let createMentor = this.backend.createMentor({
          userId: request.user.id,
          industry: request.mentoringArea,
          mentoringArea: request.mentoringArea,
          preferredModeOfCommunication: request.preferredModeOfCommunication,
          rating: 0
        });
        return forkJoin([updateMentorshipRequest, createMentor]).pipe(switchMap((response) => {
          if (response[1].code == '000') {
            return this.backend.sendMail({
              subject: 'Mentoring Request',
              email: request.user.email,
              recipient: request.user.username,
              href: 'https://startingpointzone.herokuapp.com/startingpointzone/engagements',
              body: `Congratulations! ${request.user.username}! Your request to be a mentor on SPZ has been accepted. Kindly log on and check.`
            });
          }
        }))
      }
    })).subscribe({
      next: (response) => {
        this.appMaterialModule.hideProgressDialog();
        if (response.code == '000') {
          this.mentorRequest.status = 'ACCEPTED';
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
        this.appMaterialModule.hideProgressDialog();
      },
      complete: () => {
        this.isLoading = false;
        console.log('on complete acceptBecomeMentorRequest');
      }
    })
  }

  public declineMentorshipRequest(request) {
    this.appMaterialModule.openDialog(ConfirmDialogComponent, {
      width: '400px',
      title: 'Please Confirm',
      message: 'Are you sure you want to decline this request?'
    }).pipe(switchMap((result) => {
      if (result.button == DialogButton.ok) {
        this.appMaterialModule.showProgressDialog('Processing request. Please wait...');
        request['status'] = 'DECLINED'
        return this.backend.requestMentor(request);
      }
    })).subscribe({
      next: (response) => {
        this.appMaterialModule.hideProgressDialog();
        if (response.code == '000') {
          this.mentorRequest.status = 'DECLINED';
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
        this.appMaterialModule.hideProgressDialog();
      },
      complete: () => {
        this.isLoading = false;
        console.log('on complete declineMentorshipRequest');
      }
    })
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }

}
