<div class="bio bg-white p-4 rounded animate__animated animate__fadeIn">
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="title-text">Basic information</h4>
            <div (click)="togglePage()">
                <i style="font-size: 24px;" class="fa fa-pencil-square-o"></i>
            </div>
        </div>
        <div *ngIf="!page">
            <table class="table table-striped table-sm">
                <tr>
                    <th class="subtitle-text">Firstname</th>
                    <td class="title-text">{{basicInfo.firstname}}</td>
                </tr>
                <tr>
                    <th class="subtitle-text">Lastname</th>
                    <td class="title-text">{{basicInfo.lastname}}</td>
                </tr>
                <tr>
                    <th class="subtitle-text">Othernames</th>
                    <td class="title-text">{{basicInfo.othernames}}</td>
                </tr>
                <tr>
                    <th class="subtitle-text">Gender</th>
                    <td class="title-text">{{basicInfo.gender}}</td>
                </tr>
                <tr>
                    <th class="subtitle-text">Date of birth</th>
                    <td class="title-text">{{basicInfo.dateOfBirth | date}}</td>
                </tr>
                <tr>
                    <th class="subtitle-text">Country</th>
                    <td class="title-text">{{basicInfo.country}}</td>
                </tr>
            </table>
        </div>
        <div *ngIf="page">
            <form [formGroup]="basicInfoFormGroup" class="animate__animated animate__fadeIn"
                id="basicInfoFormGroup" name="basicInfoFormGroup" #basicInfoForm="ngForm"
                (ngSubmit)="basicInfoFormGroup.valid">
                <div class="row pr-3 pl-3">
                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="firstname">Firstname</p>
                        <input formControlName="firstname" type="text" class="form-control" id="firstname"
                            name="firstname"
                            [ngClass]="{ 'is-invalid': basicInfoFormGroup.invalid && basicInfoFormGroup.get('firstname').hasError('required')}"
                            required aria-describedby="firstnameHint" autocomplete="off">

                        <div *ngIf="basicInfoForm.submitted && basicInfoFormGroup.get('firstname').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="basicInfoFormGroup.get('firstname').hasError('required')" id="firstnameHint"
                                class="form-text">firstname is
                                required</small>
                            <!-- <div *ngIf="firstname.errors.required"></div> -->
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="lastname">Lastname</p>
                        <input formControlName="lastname" type="text" class="form-control" id="lastname" name="lastname"
                            [ngClass]="{ 'is-invalid': basicInfoForm.submitted
                                                    && basicInfoFormGroup.get('lastname').hasError('required')}"
                            required aria-describedby="lastnameHint" autocomplete="off">
                        <div *ngIf="basicInfoForm.submitted && basicInfoFormGroup.get('lastname').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="basicInfoFormGroup.get('lastname').hasError('required')" id="lastnameHint"
                                class="form-text">lastname is required</small>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="othernames">Other names</p>
                        <input formControlName="othernames" type="text" class="form-control" id="othernames"
                            name="othernames"
                            [ngClass]="{ 'is-invalid': basicInfoForm.submitted && basicInfoFormGroup.get('othernames').hasError('required')}"
                            required aria-describedby="othernamesHint" autocomplete="off">
                        <div *ngIf="basicInfoForm.submitted && basicInfoFormGroup.get('othernames').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="basicInfoFormGroup.get('lastname').hasError('required')" id="othernamesHint"
                                class="form-text">lastname is required</small>
                        </div>
                    </div>
                    <div class="form-group col-md-4">
                        <p class="subtitle-text" for="gender">Gender</p>
                        <select class="form-control" formControlName="gender" type="text" id="gender" name="gender"
                            [ngClass]="{ 'is-invalid': basicInfoForm.submitted && basicInfoFormGroup.get('gender').hasError('required') }"
                            required aria-describedby="genderHint" minlength="3">
                            <option disabled selected>Please select gender</option>
                            <option value="male">male</option>
                            <option value="female">female</option>
                            <option value="N/A">N/A</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6 date">
                        <p class="subtitle-text" for="dateOfBirth">Date of birth</p>
                        <div class="input-group date">
                            <input formControlName="dateOfBirth" type="text" [matDatepicker]="dateOfBirthPicker"
                                class="form-control date" id="dateOfBirth" name="dateOfBirth" minlength="3"
                                [ngClass]="{ 'is-invalid': basicInfoForm.submitted && basicInfoFormGroup.get('dateOfBirth').hasError('required')}"
                                autocomplete="off" aria-describedby="dateOfBirthHint">
                            <mat-datepicker #dateOfBirthPicker></mat-datepicker>

                            <div *ngIf="basicInfoForm.submitted && basicInfoFormGroup.get('dateOfBirth').hasError('required')"
                                class="invalid-feedback animated bounceIn">
                                <small *ngIf="dateOfBirth.errors.dateOfBirth">
                                    Please provide your date of birth</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="country">Country</p>
                        <input formControlName="country" type="text" class="form-control" id="country" name="country"
                            [matAutocomplete]="auto" [ngClass]="{ 'is-invalid': basicInfoForm.submitted
                                                    && basicInfoFormGroup.get('country').hasError('required') }"
                            required aria-describedby="countryHint" autocomplete="off">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                                {{country}}
                            </mat-option>
                        </mat-autocomplete>
                        <div *ngIf="basicInfoForm.submitted && basicInfoFormGroup.get('country').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="country.errors.required" id="countryHint" class="form-text">country is
                                required</small>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button type="submit" style="margin-bottom: 10px;" (click)="updateUserProfile()"
                            class="btn spz-button-green">Save
                            <img #progressRipple [hidden]="!isLoading" src="/assets/img/ripple.gif" class="" width="24">
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>