<div class="bg-white p-4 mb-3 rounded animate__animated animate__fadeIn">
    <div>
        <h4 class="title-text">School and Education</h4>
        <div class="mt-3">
            <div *ngFor="let edu of educationInfo || []" style="padding: 10px 10 10px 0;">
                <div class="media align-items-center" style="height: 50px; margin-bottom: 10px;">
                    <div class="media-left mr-3" style="padding: 4px;">
                        <i class="fa fa-institution fa-fw fa-2x color-primary"></i>
                    </div>
                    <div class="media-body">
                        <p class="mt-0 mb-0 title-text">
                            {{edu.institutionName}}</p>
                        <p class="subtitle-text" style="margin: 0; font-size: 13px;">
                            {{edu.fieldOfStudy}}
                        </p>
                        <small class="text-muted">{{edu.startDate | date}} - {{edu.endDate |
                            date}}</small>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div>
                            <span (click)="deleteUserEducation(edu)" class="" style="margin: 0px;">
                                <svg class="icon-cancel-circled small red clickable">
                                    <use xlink:href="/assets/icons/icons-sheet.svg#icon-cancel-circled">
                                    </use>
                                </svg>
                            </span>
                        </div>
                        <div>
                            <span data-toggle="collapse" data-target="#userEducationForm"
                                (click)="editEducationInfo(edu)" class="" style="margin: 0px;"
                                class="mr-auto">
                                <svg class="icon-pencil-square small primary clickable">
                                    <use xlink:href="/assets/icons/icons-sheet.svg#icon-pencil-square">
                                    </use>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div (click)="addEducationInfo()" class="mt-2" data-toggle="collapse" data-target="#userEducationForm">
            <svg class="icon-plus-square-o big primary clickable">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-plus-square-o">
                </use>
            </svg>
            <!-- <small class="title-text">{{addOrEditText}} education details</small> -->
        </div>
    </div>
    <!-- <mat-divider></mat-divider> -->
    <form [formGroup]="educationInfoFormGroup" id="userEducationForm" class="animate__animated animate__fadeIn collapse"
        name="educationInfoFormGroup" #userEducationForm="ngForm" (ngSubmit)="educationInfoFormGroup.valid">
        <div class="form-row">
            <div class="form-group col-md-12">
                <p class="subtitle-text" for="institutionName">Institution</p>
                <input formControlName="institutionName" type="text" class="form-control" id="institutionName"
                    name="institutionName" minlength="3"
                    [ngClass]="{ 'is-invalid': userEducationForm.submitted && educationInfoFormGroup.get('institutionName').hasError('required') }"
                    aria-describedby="institutionNameHint" autocomplete="off">
                <div *ngIf="userEducationForm.submitted && educationInfoFormGroup.get('institutionName').hasError('required')"
                    class="invalid-feedback animated bounceIn">
                    <small *ngIf="educationInfoFormGroup.get('institutionName').hasError('required')">
                        Please provide an institution name</small>
                </div>
            </div>
            <div class="form-group col-md-6">
                <p class="subtitle-text" for="fieldOfStudy">what did you study?</p>
                <input formControlName="fieldOfStudy" autocomplete="off" type="text" class="form-control"
                    id="fieldOfStudy" name="fieldOfStudy" minlength="3" [ngClass]="{ 'is-invalid': userEducationForm.submitted
                                                && educationInfoFormGroup.get('fieldOfStudy').hasError('required') }"
                    aria-describedby="fieldOfStudyHint" autocomplete="off">
                <div *ngIf="userEducationForm.submitted && educationInfoFormGroup.get('fieldOfStudy').hasError('required')"
                    class="invalid-feedback animated bounceIn">
                    <small class="text-muted" *ngIf="educationInfoFormGroup.get('fieldOfStudy').hasError('required')">
                        Please provide the course you studied</small>
                </div>
            </div>

            <div class="form-group col-md-6">
                <p class="subtitle-text" for="certificate">Certificate awarded</p>
                <input formControlName="certificate" autocomplete="off" type="text" class="form-control"
                    id="certificate" name="certificate" minlength="3" [ngClass]="{ 'is-invalid': userEducationForm.submitted
                                                && educationInfoFormGroup.get('certificate').hasError('required') }"
                    aria-describedby="certificateHint">
                <div *ngIf="userEducationForm.submitted && educationInfoFormGroup.get('certificate').hasError('required')"
                    class="invalid-feedback animated bounceIn">
                    <small *ngIf="certificate.errors.certificate">
                        Please provide name of the certificate awarded</small>
                </div>
            </div>

            <div class="form-group col-md-6 date">
                <p class="subtitle-text" for="startDate">Start date</p>
                <div class="input-group date" data-target-input="nearest" NgTempusdominusBootstrap>
                    <input formControlName="startDate" autocomplete="off" type="text"
                        [matDatepicker]="educationStartDatePicker" class="form-control date" name="startDate"
                        minlength="3"
                        [ngClass]="{ 'is-invalid': userEducationForm.submitted && educationInfoFormGroup.get('startDate').hasError('required') }"
                        aria-describedby="startDateHint">
                    <mat-datepicker #educationStartDatePicker></mat-datepicker>
                    <div class="input-group-append">
                        <div (click)="educationStartDatePicker.open()" class="input-group-text">
                            <i class="fa fa-calendar"></i>
                        </div>
                    </div>
                    <div *ngIf="userEducationForm.submitted && educationInfoFormGroup.get('startDate').hasError('required')"
                        class="invalid-feedback animated bounceIn">
                        <small *ngIf="educationInfoFormGroup.get('startDate').hasError('required')">
                            Please provide the start date</small>
                    </div>

                </div>
            </div>

            <div class="form-group col-md-6">
                <p class="subtitle-text" for="endDate">Completed date</p>
                <div class="input-group date">
                    <input formControlName="endDate" autocomplete="off" type="text"
                        [matDatepicker]="educationEndDatePicker" class="form-control date" name="endDate" minlength="3"
                        [ngClass]="{ 'is-invalid': userEducationForm.submitted && educationInfoFormGroup.get('endDate').hasError('required') }"
                        aria-describedby="endDateHint">
                    <mat-datepicker #educationEndDatePicker></mat-datepicker>
                    <div class="input-group-append">
                        <div (click)="educationEndDatePicker.open()" class="input-group-text"><i
                                class="fa fa-calendar"></i></div>
                    </div>
                    <div *ngIf="userEducationForm.submitted && educationInfoFormGroup.get('endDate').hasError('required')"
                        class="invalid-feedback animated bounceIn">
                        <small *ngIf="educationInfoFormGroup.get('endDate').hasError('required')">
                            Please provide the completed date</small>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <button type="submit" style="margin-bottom: 10px;" class="btn spz-button-green ml-auto"
                    (click)="onSaveUserEducation()" [disabled]="!educationInfoFormGroup.valid">Save
                    <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
                </button>
            </div>
        </div>
    </form>
</div>