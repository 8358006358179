import { Component, OnInit, OnDestroy } from '@angular/core';
import { Goal } from 'src/app/models/goal';
import { Subject, Subscription } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { DialogButton } from 'src/app/services/dialog.service';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { SetupActionPlanComponent } from '../action-plan/setup-action-plan.component';

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.css']
})
export class GoalsComponent implements OnInit, OnDestroy {
  closeResult: string;
  goalFormData: Goal = new Goal();
  goals: Array<Goal> = new Array<Goal>();
  goalCategories: Array<string> = new Array<string>();
  authUser = null;
  isLoading = false;
private unSubscriptioNotifier = new Subject();
  constructor(
    private router: Router,
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    private appMaterialDesignModule: AppMaterialDesignModule) { }

  ngOnInit() {
    this.localAuthService.getAuthUser()
    .pipe(take(1))
    .subscribe(data => {
      this.authUser = data;
    });
    this.getGoals(this.authUser.id);
  }
  getGoals(user: string): void {
    this.isLoading = true;
      this.backend.getGoals(user)
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.goals = response.data
          } else {
            //this.appComponent.showAlertToaster(AlertType.success, 'You do not have any goals yet. Please click on Set up a goal button to get started.', 10000);
          }
          this.isLoading = false;
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;

        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete getGoals');
        }
      });
  }

  deleteGoal(goal: any): void {
    this.appMaterialDesignModule.openDialog(ConfirmDialogComponent, {
      width: '400px',
      title: 'Caution',
      message: 'Are you sure you want to this goal?'

    }).pipe(
      takeUntil(this.unSubscriptioNotifier),
      switchMap((result) => {
      if (result.button === DialogButton.ok) {
        this.isLoading = true;
        this.appMaterialDesignModule.showProgressDialog('Deleting...');
        return this.backend.deleteGoal(goal);
      }
    })).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          this.isLoading = false;
          this.appMaterialDesignModule.hideProgressDialog();
          var index = this.goals.indexOf(goal);
          this.goals.splice(index, 1);
        }
      }, error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
          this.appMaterialDesignModule.showAlertToaster(AlertType.error, err.error, 3).subscribe();
        }
      }, complete: () => {
        console.log('on complete delete goal');
      }
    })
  }

  goalDetails(goal: any): void {
    this.dataSource.setData(goal);
    this.router.navigate(['goals/details'], { queryParams: {}, skipLocationChange: false });
  }

  public signout(): void {
    if (this.localAuthService.logout()) {
      this.localAuthService
        .signOut()
        .then(
          (onfulfilled) => {
            console.log('onfulfilled... ' + onfulfilled);
          },
          (onrejected) => {
            console.log('onrejected... ' + onrejected);
          }
        )
        .catch((onrejected) => {
          console.log('catch onrejected... ' + onrejected);
        })
        .finally(() => {
          console.log('onfinally...');
          this.router.navigate(['/login']);
        });
    }
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next('');
    this.unSubscriptioNotifier.complete();
  }
}


