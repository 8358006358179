import { Profile } from './profile';

export class User {
    public isAuthenticated: boolean = false;
    public image: string;
    public role:string;
    public id: string = '0';
    public username: string;
    public fullname: string;
    public email: string;
    public password: string;
    public dateRegistered: string;
    public lastSeen: string;
    public status:string;
    public profile: Profile;
    public provider : string;
    public membershipId:string;
    public membership:any;

    constructor(){
        this.profile = new Profile();
    }
}
