import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { MentorRequestData } from 'src/app/models/mentor-request-data';
import { User } from 'src/app/models/user';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { DialogOptions, DialogService } from 'src/app/services/dialog.service';
import { FormBuilderService } from 'src/app/services/form-builder.service';

interface mentoringArea {
  value: string;
  viewValue: string;
}

interface mentoringAreaGroup {
  disabled?: boolean;
  name: string;
  mentoringArea: mentoringArea[];
}

@Component({
  selector: 'app-request-mentor',
  templateUrl: './request-mentor.component.html',
  styleUrls: ['./request-mentor.component.css']
})
export class RequestMentorComponent implements OnInit, OnDestroy {

  public requestMentorFormData = new MentorRequestData();
  public authUser = null;
  public user: User;
  public observers: Array<Subscription> = new Array<Subscription>();
  public userId = '';
  public isLoading = false;
  public mentorRequestFormGroup: FormGroup;
  constructor(private localAuthService: LocalAuthService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private backend: BackendService,
    private dataSource: DataService,
    public dialogRef: MatDialogRef<RequestMentorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
    private dialogService: DialogService,
    public appMaterialModule: AppMaterialDesignModule,
    public formBuilderService: FormBuilderService) {
    this.user = new User();
    this.requestMentorFormData = new MentorRequestData();
    //this.hideProgressRipple();
    this.mentorRequestFormGroup = this.formBuilderService.createRequestMentorFormGroup(this.requestMentorFormData);
  }
  ngOnDestroy(): void {

  }

  //@ViewChild('progressRipple') progressRipple: ElementRef;

  ngOnInit(): void {
    const observer = this.localAuthService.getAuthUser().subscribe(data => {
      this.authUser = data;
    });

    this.observers.push(observer);

    this.dataSource.currentdata.subscribe(data => {

    });

    this.userId = this.authUser.id;
    this.getUserDetails(this.userId);

    this.activateRoute.params.pipe(map(p => p.id || '')).subscribe(id => {
      console.log('id : ' + id);
      this.requestMentorFormData = new MentorRequestData();
      if ('' != id) {

        this.isLoading = true;
        this.backend.getMentorshipRequest(id).subscribe({
          next: (response: any) => {
            this.isLoading = false;
            this.requestMentorFormData = response.data
            //console.log('this.requestMentorFormData : ' + JSON.stringify(this.requestMentorFormData));
            this.mentorRequestFormGroup = this.formBuilderService.createRequestMentorFormGroup(this.requestMentorFormData);
          }
        });
      }
    });

  }

  getUserDetails(formData: string) {
    this.observers.push(this.backend.getUserDetails3(formData).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          if (response.data.status !== 'verified') {
            //verify email
            var observer = this.appMaterialModule.showAlertToaster(AlertType.info, 'Verify your email. Please check your email to verify your account', 10000).subscribe(alert => {
            });
            this.observers.push(observer);
          }
          this.user = response.data;
          //console.log(JSON.stringify(this.user))
          this.authUser.email = response.data.email;
          this.dataSource.setData(this.user);
          this.localAuthService.setUser(this.authUser);

        } else {

        }

      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete getUserDetails');
      }
    }));
  }

  public onSubmit(): void {
    let formValues: MentorRequestData = this.mentorRequestFormGroup.value;
    let tempUser = new User();
    delete tempUser.profile;
    delete tempUser.isAuthenticated;
    tempUser.id = this.user.id;
    formValues.user = tempUser;
    formValues.id = this.requestMentorFormData.id || '';
    formValues.requestType = 'REQUEST_MENTOR'
    if ('' == formValues.id) {
      delete formValues.id;
    }
    console.log('form values : ' + JSON.stringify(formValues));
    this.isLoading = true;

    let adminMail = this.backend.sendMail({
      subject: 'New Mentoring Request',
      email: 'spzdev19@gmail.com',
      recipient: 'Admin',
      href: 'https://startingpointzone.herokuapp.com/startingpointzone/engagements',
      body: `You have received new request from ${this.user.fullname}. Kindly log on and check.`
    });

    let userMail = this.backend.sendMail({
      subject: 'New Mentoring Request',
      email: this.user.email,
      recipient: this.user.fullname,
      href: 'https://startingpointzone.herokuapp.com/startingpointzone/engagements',
      body: 'We Acknowledge the Receipt of your mentoring request. The Admin will review and get back to you Shortly. Kindly log on and check.'
    });

    let tempSub = this.backend.requestMentor(formValues)
      .subscribe({
        next: (response: any) => {
          this.isLoading = false;
          if (response.code == '000') {
            if (undefined == formValues.id) {
              forkJoin({
                adminMail: adminMail,
                userMail: userMail
              }).subscribe();
            }
            this.router.navigate(['/engagements'], { queryParams: {}, skipLocationChange: false });
            this.appMaterialModule.showAlertToaster(AlertType.success, 'saved successfully', 3).subscribe();
          } else {
            this.appMaterialModule.showAlertToaster(AlertType.info, response.message, 3).subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        },
        complete: () => {
          console.log('on complete getUserDetails');
        }
      });
    this.observers.push(tempSub);

    this.mentorRequestFormGroup.reset();
  }
  // showProgressRipple(): void {
  //   this.progressRipple.nativeElement.style.display = 'inline';
  // }
  // hideProgressRipple(): void {
  //   this.progressRipple.nativeElement.style.display = 'none';
  // }

  mentoringAreaGroups: mentoringAreaGroup[] = [
    {
      name: 'Career',
      mentoringArea: [
        { value: 'Banking', viewValue: 'Banking' },
        { value: 'Telecommunications', viewValue: 'Telecommunications' },
        { value: 'Technology', viewValue: 'Technology' },
        { value: 'Medical Sciences', viewValue: 'Medical Sciences' },
        { value: 'Public Services', viewValue: 'Public Services' },
        { value: 'Retail', viewValue: 'Retail' },
        { value: 'Financial Services', viewValue: 'Financial Services' },
        { value: 'Consultancy', viewValue: 'Consultancy' },
        { value: 'Social Services', viewValue: 'Social Services' },
        { value: 'Engineering', viewValue: 'Engineering' },
        { value: 'Oil and Gas', viewValue: 'Oil and Gas' },
      ]
    },
    {
      name: 'Business',
      mentoringArea: [
        { value: 'SME', viewValue: 'SME' },
        { value: 'Fashion Designing', viewValue: 'Fashion Designing' },
        { value: 'Video/Photography ', viewValue: 'Video/Photography ' },
        { value: 'Journalism /Online Publishing/Blogging', viewValue: 'Journalism /Online Publishing/Blogging' },
        { value: 'IT Coding/Webdesigning', viewValue: 'IT Coding/Webdesigning' },
        { value: 'Financial Markets Trading', viewValue: 'Financial Markets Trading' },
        { value: 'Trade - Importing/Exporting ', viewValue: 'Trade - Importing/Exporting ' },
        { value: 'Plumbing', viewValue: 'Plumbing' },
        { value: 'Contracting/Purchasing/Supply chain', viewValue: 'Contracting/Purchasing/Supply chain' },

      ]
    },
    {
      name: 'Education or Training',
      disabled: false,
      mentoringArea: [
        { value: 'Ordinary National Diploma', viewValue: 'Ordinary National Diploma' },
        { value: 'Higher National Diploma', viewValue: 'Higher National Diploma' },
        { value: 'University Postgraduate', viewValue: 'University Postgraduate' },
        { value: 'University Undergraduate', viewValue: 'University Undergraduate' },
        { value: 'Specialist Education', viewValue: 'Specialist Education' },
        { value: 'Fashion Designing Training', viewValue: 'Fashion Designing Training' },
        { value: 'Video/Photography Training', viewValue: 'Video/Photography Training' },
        { value: 'Journalism Training', viewValue: 'Journalism Training' },
        { value: 'IT Coding/Webdesigning/Database Training', viewValue: 'IT Coding/Webdesigning/Database Training' },
      ]
    },
    {
      name: 'Family',
      mentoringArea: [
        { value: 'New Relationship', viewValue: 'New Relationship' },
        { value: 'Marriage', viewValue: 'Marriage' },
        { value: 'Journalism Training', viewValue: 'Journalism Training' },
        { value: 'Parenting', viewValue: 'Parenting' },
      ]
    }
  ];

}
