import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { Inspiration } from 'src/app/models/inspiration';
import { Subscription, forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { DialogService, DialogButton } from 'src/app/services/dialog.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { SetupInspirationsComponent } from './setup-inspiration/setup-inspirations.component';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { switchMap } from 'rxjs/operators';
import { AlertType } from 'src/app/services/alert.service';

@Component({
  selector: 'app-inspirations',
  templateUrl: './inspirations.component.html',
  styleUrls: ['./inspirations.component.css']
})
export class InspirationsComponent implements OnInit, OnDestroy {

  public inspirationFormData: Inspiration = new Inspiration();
  public inspirationalCategories = new Array<any>();
  public inspirationalQuotes = new Array<Inspiration>();
  public observers: Array<Subscription> = new Array<Subscription>();
  public authUser = null;
  closeResult: string;
  public isLoading = false;
  public category = '0';

  @ViewChild('progressRipple') progressRipple: ElementRef;

  constructor(private localAuthService: LocalAuthService,
    private backend: BackendService,
    private dataSource: DataService,
    private activateRoute: ActivatedRoute,
    private confirmDialog: ConfirmDialogComponent,
    private alertDialog: AlertDialogComponent,
    private dialogService: DialogService,
    private appMaterialDesignModule: AppMaterialDesignModule) {
  }

  ngOnInit() {
    this.authUser = this.localAuthService.getUser();
    this.activateRoute.queryParams
      .subscribe(params => {
        this.category = params.category || 'all';
      });
    this.isLoading = true;
    this.observers.push(forkJoin([this.backend.getInspirationalQuotes(this.category), this.backend.getInspirationalCategories()]).subscribe({
      next: (response: any) => {
        if (response[0].code === '000') {
          this.inspirationalQuotes = response[0].data;
        }
        if (response[1].code === '000') {
          this.inspirationalCategories = response[1].data
        }
        this.isLoading = false;
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
        this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.error, err.error.message, 3).subscribe());
      }, complete: () => {
        console.log('on complete getInspirationalQuotes');
        this.isLoading = false;
      }
    }));
  }

  ngAfterViewInit(): void {

  }

  deleteInspirationalQuote(quote: any): void {
    this.observers.push(this.appMaterialDesignModule.openDialog(ConfirmDialogComponent, {
      width: '400px',
      title: 'Caution',
      message: 'Are sure you want to delete! This cannot be undone.'

    }).pipe(switchMap((result) => {
      if (result.button === DialogButton.ok) {
        this.isLoading = true;
        this.appMaterialDesignModule.showProgressDialog('Deleting...');
        return this.backend.deleteInspirationalQuote(quote.id);
      }
    })).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          this.isLoading = false;
          this.appMaterialDesignModule.hideProgressDialog()
          var index = this.inspirationalQuotes.indexOf(quote);
          this.inspirationalQuotes.splice(index, 1);
          this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.success, 'Successfully deleted.', 3).subscribe());
        } else {
          this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.error, response.message, 3).subscribe());
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
        this.appMaterialDesignModule.hideProgressDialog();
      },
      complete: () => {
        this.isLoading = false;
        this.appMaterialDesignModule.hideProgressDialog();
        console.log('on complete deleteInspirationalQuote');
      }
    }));
  }

  subscribeToFeed(): void {
    //
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }


  public openInspirationalQuoteDialog() {
    this.dataSource.setData({
      inspirationQuote: this.inspirationFormData
    });
    this.appMaterialDesignModule.openDialog(SetupInspirationsComponent, {
      width: '500px',
      data: this.inspirationFormData
    }).subscribe({
      next: (result) => {
        if (result.button === DialogButton.ok) {
          let resultInspirationalQuote: Inspiration = result.inspirationalQuote
          var index = this.inspirationalQuotes.findIndex((quote) => {
            return quote.id == resultInspirationalQuote.id
          });
          console.log('index of result inspirationalQuote : ' + index)
          if (index == -1) {
            this.inspirationalQuotes.unshift(resultInspirationalQuote)
          } else {
            this.inspirationalQuotes[index] = resultInspirationalQuote;
          }
        }
      }
    });
  }

}
