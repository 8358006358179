import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { Observable, from, interval, of, timer, } from 'rxjs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaterialProgressDialogComponent } from './components/material-progress-dialog/material-progress-dialog.component';
import { MaterialAlertDialogComponent } from './components/material-alert-dialog/material-alert-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { scan, takeWhile, map, switchMap, finalize, take } from 'rxjs/operators';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogOptions, DialogService } from './services/dialog.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import {MatListModule} from '@angular/material/list';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AlertService, AlertType } from './services/alert.service';

const materialDesignComponents = [
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatInputModule,
    MatTabsModule,
    MatDividerModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatListModule,
    MatRippleModule,
    MatSliderModule,
    MatSlideToggleModule,
];

export const enum DialogType {
    success = 'success',
    error = 'error'
}

@NgModule({
    imports: [materialDesignComponents],
    exports: [materialDesignComponents]
})
export class AppMaterialDesignModule {
    public dialogRef: MatDialogRef<any>;
    constructor(public matDialog: MatDialog,
        public dialogService: DialogService,
        public alertService: AlertService,) { }

    showProgressDialog(title: string): MatDialogRef<any> {
        this.dialogRef = this.matDialog.open(MaterialProgressDialogComponent, {
            width: '380px',
            height: '80px',
            data: { dialogTitle: title },
            disableClose: true,
            autoFocus: true
        });
        //console.log('this.dialogRef.getState '+this.dialogRef.getState);
        this.dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });

        /* setTimeout(() => {
             this.hideProgressDialog();
         }, 3);*/

        return this.dialogRef;
    }

    public updateProgressDialogTitle(title: string) {
        this.dialogRef.componentInstance.data = { dialogTitle: title }
    }

    showAlertDialog(type: DialogType, title: string, message: string): Observable<any> {
        this.dialogRef = this.matDialog.open(MaterialAlertDialogComponent, {
            width: '400px',
            height: '280px',
            data: { dialogTitle: title, dialogMessage: message, dialogType: type },
            disableClose: true,
            autoFocus: true
        });

        this.dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });

        return this.dialogRef.afterClosed();
    }

    hideProgressDialog(): void {
        if (undefined != this.dialogRef)
            this.dialogRef.close();
    }

    openDialog(dialogToOpen, dialogOptions?: DialogOptions): Observable<any> {
        const dialogRef = this.matDialog.open(dialogToOpen, {
            width: dialogOptions.width || '250px',
            data: dialogOptions,
            disableClose: true,
            autoFocus: false,
            maxHeight:'90vh'
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
        return this.dialogService.getMessage();
    }

    public showAlertToaster(type: AlertType, message: string, duration: number = 4): Observable<any> {
        //console.log('show this message : ' + message);
        //console.log('start the countdown : ' + duration);
        const counter$ = interval(1000);
        const numberOfSeconds = duration;
        //return
        this.alertService.showAlert(type,message);
        counter$.pipe(
            scan((accumulator, _current) => accumulator - 1, numberOfSeconds + 1),
            take(numberOfSeconds + 1),
            finalize(() => {
                //console.log('dismiss alert toaster')
                this.alertService.dismissAlert();
            })
        ).subscribe()

        return of(null)
    }
}
