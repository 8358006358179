<div [ngClass]="sidebarToggle == 'on' ? 'page-wrapper chiller-theme toggled' : 'page-wrapper chiller-theme'">
    <div id="show-sidebar" class="btn btn-sm btn-dark" (click)="toggleSidebar()">
        <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
    <nav id="sidebar" class="sidebar-wrapper">
        <div class="sidebar-content">
            <div class="sidebar-brand p-4">
                <a href="/startingpointzone/home">
                    <img alt="temp image" src="/assets/img/spz-logo.svg" class="img-fluid">
                </a>
                <div id="close-sidebar" (click)="toggleSidebar()">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                </div>
            </div>

            <div class="sidebar-menu">
                <ul class="nav nav-pills mt-3" id="pills-tab" role="tablist">
                    <li class="mb-2">
                        <a routerLink="/home">
                            <i class="fa fa-home color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Home</span>
                        </a>
                    </li>
                    <li class="mb-2 nav-item">
                        <a class="nav-link active" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile"
                            role="tab" aria-controls="v-pills-profile" aria-selected="true">
                            <i class="fa fa-user-circle-o color-spz-green" style="font-size: 24px !important;"></i>
                            <span>profile</span>
                        </a>
                    </li>
                    <li class="mb-2 nav-item">
                        <a class="nav-link" id="v-pills-contact-tab" data-toggle="pill" href="#v-pills-contact"
                            role="tab" aria-controls="v-pills-contact" aria-selected="false">
                            <i class="fa fa-phone-square color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Contact</span>
                        </a>
                    </li>
                    <li class="mb-2 nav-item">
                        <a class="nav-link" id="v-pills-account-tab" data-toggle="pill" href="#v-pills-account"
                            role="tab" aria-controls="v-pills-account" aria-selected="false">
                            <i class="fa fa-id-badge color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Account</span>
                        </a>
                    </li>
                    <li class="mb-2 nav-item">
                        <a class="nav-link" id="v-pills-school-and-education-tab" data-toggle="pill"
                            href="#v-pills-school-and-education" role="tab" aria-controls="v-pills-school-and-education"
                            aria-selected="false">
                            <i class="fa fa-graduation-cap color-spz-green" style="font-size: 24px !important;"></i>
                            <span>School And Education</span>
                        </a>
                    </li>
                    <li class="mb-2 nav-item">
                        <a class="nav-link" id="v-pills-experiences-and-employment-tab" data-toggle="pill"
                            href="#v-pills-experiences-and-employment" role="tab"
                            aria-controls="v-pills-experiences-and-employment" aria-selected="false">
                            <i class="fa fa-address-card-o color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Experiences & Employment</span>
                        </a>
                    </li>
                    <li *ngIf="mentorDetails" class="mb-2 nav-item">
                        <a class="nav-link" id="v-pills-mentoring-tab" data-toggle="pill" href="#v-pills-mentoring"
                            role="tab" aria-controls="v-pills-mentoring" aria-selected="false">
                            <i class="fa fa-user-md color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Mentorship</span>
                        </a>
                    </li>
                    <li class="mb-2 nav-item">
                        <a class="nav-link" id="v-pills-membership-tab" data-toggle="pill" href="#v-pills-membership"
                            role="tab" aria-controls="v-pills-membership" aria-selected="false">
                            <i class="fa fa-meetup color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Membership</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/messages">
                            <i class="fa fa-comments color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Messages</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- sidebar-menu  -->
        </div>
        <!-- sidebar-content  -->
        <div class="sidebar-footer">
            <!-- <a href="#">
              <i class="fa fa-bell"></i>
              <span class="badge badge-pill badge-warning notification">3</span>
            </a>
            <a href="#">
              <i class="fa fa-envelope"></i>
              <span class="badge badge-pill badge-success notification">7</span>
            </a>
            <a href="#">
              <i class="fa fa-cog"></i>
              <span class="badge-sonar"></span>
            </a> -->
            <a href="#" (click)="signout()">
                Sign out
                <span><i class="fa fa-power-off"></i></span>
            </a>
        </div>
    </nav>
    <!-- sidebar-wrapper  -->
    <main class="page-content" style="background-color: #f6f5f3;">
        <div class="container p-0 pr-3 pl-3">
            <div class="row">
                <div class="col-md-8">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active mb-3" id="v-pills-profile" role="tabpanel"
                            aria-labelledby="v-pills-profile-tab">
                            <app-basic-info [basicInfo]="userDetails?.profile" *ngIf="userDetails"></app-basic-info>
                        </div>
                        <div class="tab-pane fade" id="v-pills-contact" role="tabpanel"
                            aria-labelledby="v-pills-contact-tab">
                            <app-contact-info [contactInfo]="userDetails?.contact"
                                *ngIf="userDetails"></app-contact-info>
                            <app-social-media-accounts-info [socialMediaAccountsInfo]="userDetails.socialMediaAccounts"
                                *ngIf="userDetails"></app-social-media-accounts-info>
                        </div>
                        <div class="tab-pane fade" id="v-pills-account" role="tabpanel"
                            aria-labelledby="v-pills-account-tab">
                            <app-account-info [userAccountInfo]="userDetails.account"
                                *ngIf="userDetails"></app-account-info>
                        </div>
                        <div class="tab-pane fade" id="v-pills-school-and-education" role="tabpanel"
                            aria-labelledby="v-pills-school-and-education-tab">
                            <app-school-and-education-info [educationInfo]="userDetails.education"
                                *ngIf="userDetails"></app-school-and-education-info>
                        </div>
                        <div class="tab-pane fade" id="v-pills-experiences-and-employment" role="tabpanel"
                            aria-labelledby="v-pills-experiences-and-employment-tab">
                            <app-experience-and-employment-info [employmentInfo]="userDetails.employment"
                                [skillsInfo]="userDetails.skillsAndExperience"
                                *ngIf="userDetails"></app-experience-and-employment-info>
                        </div>
                        <div class="tab-pane fade" id="v-pills-mentoring" role="tabpanel"
                            aria-labelledby="v-pills-mentoring-tab">
                            <app-mentorship-info [mentorDetails]="mentorDetails"
                                *ngIf="mentorDetails"></app-mentorship-info>
                        </div>
                        <div class="tab-pane fade" id="v-pills-membership" role="tabpanel"
                            aria-labelledby="v-pills-membership-tab">
                            <div class="bg-white animate__animated animate__fadeIn"
                                style="padding: 15px; margin-bottom: 10px;">
                                <h4 class="title-text">Membership information</h4>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="card-1"
                                            style="padding: 10px; margin-bottom: 10px; background-color: #C0C0C0;">
                                            <h4 class="title-text">SILVER</h4>
                                            <p class="subtitle-text">Silver is a chemical element with the symbol Ag
                                                (from the
                                                Latin argentum</p>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="card-1"
                                            style="padding: 10px; margin-bottom: 10px; background-color: #d4af37;">
                                            <h4 class="title-text">GOLD</h4>
                                            <p class="subtitle-text">Gold is a chemical element with the symbol Au (from
                                                Latin:
                                                aurum) and atomic</p>

                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="card-1"
                                            style="padding: 10px; margin-bottom: 10px; background-color: #e5e4e2;">
                                            <h4 class="title-text">PLATINUM</h4>
                                            <p class="subtitle-text">Platinum is a chemical element with the symbol Pt
                                                and
                                                atomic number 78.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <pre>{{scope | json}}</pre> -->

                </div>
                <div class="col-md-4 pb-3">
                    <div class="bg-white p-3 rounded">
                        <div class="text-center">
                            <img src="{{authUser.image}}" height="160" width="160" class="img-thumbnail mr-2 img-fluid"
                                alt="{{authUser.fullname}}">
                        </div>
                        <div class="text-center">
                            <button class="btn btn-sm btn-outline-primary mt-2" (click)="openFilePickerDialog()"><i
                                    class="fa fa-picture-o fa-fw mr-2"></i>Change</button>
                        </div>
                        <div class="text-center">
                            <h5 class="mt-3 mb-3">{{authUser.fullname}}</h5>
                            <div class="d-flex- mb-3 justify-content-start">
                                <a href="#" class="mr-3">
                                    <img height="24" width="24" src="/assets/icons/spz-instagram-icon.svg">
                                </a>
                                <a href="#" class="mr-3">
                                    <img height="24" width="24" src="/assets/icons/spz-linkedin-icon.svg">
                                </a>
                                <a href="#" class="mr-3">
                                    <img height="24" width="24" src="/assets/icons/spz-facebook-icon.svg">
                                </a>
                                <a href="#">
                                    <img height="24" width="24" src="/assets/icons/spz-x-icon.svg">
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- <mat-divider></mat-divider> -->
                    <div id="badges" class="mt-3">
                        <h5>About</h5>
                        <div class="bg-white p-3 rounded">
                            <small class="">{{userDetails?.profile.biography}}</small>
                        </div>
                    </div>
                    <div id="skills" class="mt-3">
                        <h5>Skills</h5>
                        <div class="bg-white p-3 rounded">
                            <ul class="pl-2">
                                <li *ngFor="let skill of userDetails?.skillsAndExperience">{{skill.title}}</li>
                            </ul>
                        </div>
                    </div>
                    <div id="badges" class="mt-3">
                        <h5>Badges</h5>
                        <div class="bg-white p-3 rounded">
                            <small class="text-muted">There are no badges to display</small>
                        </div>
                    </div>
                    <div id="membership" class="mt-3">
                        <h5>Membership</h5>
                        <div class="bg-white p-3 rounded">
                            <p class="text-muted mb-0">{{userDetails?.membership?.title || ''}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>

<ng-template #bioContent let-modal>
    <div class="modal-header">
        <h4 class="title-text" class="modal-title" id="modal-basic-title">Update biography</h4>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form name="bioForm" #bioForm="ngForm" (ngSubmit)="bioForm.form.valid">
            <div class="row">
                <div class="form-group col-md-12">
                    <p class="subtitle-text" for="biography">Biography</p>
                    <textarea class="form-control" id="biography" rows="3" name="biography"
                        [(ngModel)]="userDetails?.profile.biography" #biography="ngModel"></textarea>
                    <div *ngIf="bioForm.submitted && biography.invalid" class="invalid-feedback animated bounceIn"
                        required minlength="3" maxlength="255"
                        [ngClass]="{ 'is-invalid': bioForm.submitted && biography.invalid }"
                        aria-describedby="biographyHint">
                        <small *ngIf="biography.errors.required" id="biographyHint" class="form-text">
                            biography is required</small>
                        <small *ngIf="biography.errors.minlength" id="biographyHint" class="form-text">Please provide
                            a
                            minimum length of 10 characters</small>
                        <small *ngIf="biography.errors.maxlength" id="biographyHint" class="form-text">
                            Please provide a maximum length of 255 characters</small>
                        <small *ngIf="biography.errors.biography">Please provide a valid
                            biography</small>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" ngbAutofocus class="btn btn-outline-dark" (click)="onSaveBio()">Save
            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
        </button>
    </div>
</ng-template>

<ng-template #profileImageContent let-modal>
    <div class="modal-header">
        <h4 class="title-text" class="modal-title" id="modal-basic-title">Update profile image</h4>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form name="profileImageForm" #profileImageForm="ngForm" (ngSubmit)="profileImageForm.form.valid">
            <div class="row">
                <div class="form-group col-12">
                    <div class="input-group">
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" (change)="fileChangeEvent($event)"
                                id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row" style="margin-top: 4%;">
                <div class="text-center col-12">
                    <h5 class="title-text">Crop Image</h5>
                    <image-cropper #imageCropper name="imageCropper" [imageChangedEvent]="imageChangedEvent" [outputType]="base64" [imageQuality]="60" [autoCrop]="true" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" [resizeToWidth]="256" format="jpeg" (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                </div>
               
            </div> -->
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" ngbAutofocus class="btn btn-outline-dark" (click)="onSaveProfileImage()">Save
            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
        </button>
    </div>
</ng-template>

<ng-template #resetPasswordDialog let-modal>
    <div class="modal-header">
        <h4 class="title-text" class="modal-title" id="modal-basic-title">Reset password</h4>
        <button type="button" class="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="title-text">A link to reset your password will be sent to the email associated with this account</p>
        <form name="loginForm" #loginForm="ngForm" (ngSubmit)="loginForm.form.valid">
            <div class="form-group">
                <p class="subtitle-text" for="email">Please confirm email</p>
                <input [(ngModel)]="userAccountFormData.email" #email="ngModel" type="email" class="form-control"
                    id="email" name="email" required autofocus minlength="5"
                    [ngClass]="{ 'is-invalid': loginForm.submitted && email.invalid }" aria-describedby="emailHint">
                <div *ngIf="loginForm.submitted && email.invalid" class="invalid-feedback animated bounceIn">
                    <small *ngIf="email.errors.required" id="emailHint" class="form-text">email
                        is
                        required</small>
                    <!-- <small *ngIf="email.errors.minlength" id="emailHint" class="form-text">Please
                        provide a minimum length of 5 characters</small> -->
                    <small *ngIf="email.errors.email">Please provide a valid email</small>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" ngbAutofocus class="btn btn-outline-dark" (click)="onResetPassword()">Reset
            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
        </button>
    </div>
</ng-template>