import { Component, OnInit } from '@angular/core';
import { GuardsCheckEnd, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { LocalAuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'startingpointzone';
  public isAuthenticated: boolean = false;
  public authUser = null;

  constructor(public localAuthService: LocalAuthService,
    public router: Router) {
  }

  ngOnInit(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof GuardsCheckEnd) {
          //console.log('GuardsCheckEnd:', event);
          this.isAuthenticated = this.localAuthService.isAuthenticated();
          this.localAuthService.getAuthUser().subscribe(user=>{
            this.authUser = user
          });
         // console.log('appComponent isAuthenticated ' + JSON.stringify(this.authUser));
        }
      });
  }

  signout(): void {
    if (this.localAuthService.logout()) {
      this.isAuthenticated = false;
      this.localAuthService.signOut()
        .then((onfulfilled) => {
          console.log('onfulfilled... ' + onfulfilled);
        }, (onrejected) => {
          console.log('onrejected... ' + onrejected);
        }).catch((onrejected) => {
          console.log('catch onrejected... ' + onrejected);
        }).finally(() => {
          console.log('onfinally...');
          this.router.navigate(['/login']);
        });
    }
  }
}
