import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { MentorRequestData } from 'src/app/models/mentor-request-data';
import { User } from 'src/app/models/user';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { Mentor } from 'src/app/models/mentor';
import { BecomeMentorComponent } from './become-mentor/become-mentor.component';
import { DialogButton } from 'src/app/services/dialog.service';


@Component({
  selector: 'app-mentoring-engagement',
  templateUrl: './mentoring-engagement.component.html',
  styleUrls: ['./mentoring-engagement.component.css']
})
export class MentoringEngagementComponent implements OnInit, OnDestroy {
  public authUser = null;
  public user: User;
  public observers: Array<Subscription> = new Array<Subscription>();
  public mentorshipRequests = new Array<MentorRequestData>();
  public mentors: Mentor[] = [];
  public userId = '';
  public isLoading = false;
  constructor(private localAuthService: LocalAuthService,
    private router: Router,
    private backend: BackendService,
    private dataSource: DataService,
    public appMaterialModule: AppMaterialDesignModule) {
    this.user = new User();
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }

  ngOnInit() {
    const observer = this.localAuthService.getAuthUser().subscribe(data => {
      this.authUser = data;
    });
    this.observers.push(observer);

    this.userId = this.authUser.id;
    this.getUserDetails(this.userId);
    this.getMentorshipRequests(this.userId);
    this.getMentors();
  }

  public getMentorshipRequests(userId: string) {
    let serviceCall = this.backend.getMentorshipRequestsByUser(userId).subscribe({
      next: (response: any) => {
        if (response.code == '000') {
          this.mentorshipRequests = response.data;
        }
      },
      error: (err: any) => {
        this.isLoading = false;
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        this.isLoading = false;
        console.log('on complete getMentorshipRequest');
      }
    });

    this.observers.push(serviceCall);
  }

  getUserDetails(formData: string) {
    this.observers.push(this.backend.getUserDetails3(formData).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          if (response.data.status !== 'verified') {
            //verify email
            this.appMaterialModule.showAlertToaster(AlertType.info, 'Verify your email. Please check your email to verify your account', 3).subscribe();
          }
          this.user = response.data;
          this.authUser.email = response.data.email;
          this.dataSource.setData(this.user);
          this.localAuthService.setUser(this.authUser);
        } else {

        }

      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete getUserDetails');
      }
    }));
  }

  public getMentors() {
    this.isLoading = true
    this.observers.push(
      this.backend.getAllMentors().subscribe({
        next: (response) => {
          if (response.code === '000') {
            this.mentors = response.data;
          }
          this.isLoading = false;
        }, error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false
        }
        , complete: () => {
          console.log('on complete getMentors');
        }
      })
    )
  }

  public editRequest(request: MentorRequestData) {
    if (request.requestType == 'BECOME_MENTOR') {
      this.openBecomeAMentorDialog(request);
    } else {
      this.dataSource.setData(request);
      this.router.navigate(['/engagements/request-mentor/' + request.id], { queryParams: {}, skipLocationChange: false });
    }
  }

  public WithdrawRequest(request: MentorRequestData) {
    //this.appMaterialModule.
  }

  public openBecomeAMentorDialog(request?: MentorRequestData) {
    this.observers.push(
      this.appMaterialModule.openDialog(BecomeMentorComponent, {
        width: '500px',
        data: {
          requestMentorFormData: request ? request : new MentorRequestData()
        }
      }).subscribe({
        next: (result) => {
          if (result.button === DialogButton.ok) {
            let resultRequestMentorFormData: MentorRequestData = result.requestMentorFormData
            var index = this.mentorshipRequests.findIndex((r) => {
              return r.id == resultRequestMentorFormData.id
            });
            this.getMentorshipRequests(this.authUser.id)
            console.log('index of result MentorRequestData : ' + index)
            if (index == -1) {
              //this.mentorshipRequests .unshift(resultRequestMentorFormData)
            } else {
              //this.mentorshipRequests[index] = resultRequestMentorFormData;
            }
          }
        }
      })
    )
  }

}
