<div class="container-fluid">
    <div class="row">
        <div class="col-md-2 col-sm-2">
            <app-side-nav></app-side-nav>
        </div>
        <div class="col-md-10 col-sm-10" style="margin-top: 8px;">
            <div class="col-12 mini-bar-menu bg-white" style="margin-bottom: 5px; padding: 10px;">
                <ul class="horizontal-list">
                    <li>
                        <!-- <button class="btn btn-sm btn-outline-primary" (click)="isEdited=false;openInputDialog(actionPlanDialog)">Set up an action plan for this goal</button> -->
                    </li>
                </ul>
            </div>

            <div class="row" style="margin-bottom: 5px; padding: 10px;">
                <p class="title-text col-2">STEP</p>
                <p class="title-text col-7">PLAN</p>
                <p class="title-text col-2">STATUS</p>
            </div>
            <div class="col-12 bg-white" style="margin-bottom: 5px; padding: 10px;">
                <div *ngFor="let plan of actionPlans; let i = index">
                    <div class="media align-items-center" style="height: 50px; margin-bottom: 10px;">
                        <div class="media-left mr-3" style="padding: 4px;">
                            <h3 class="title-text">{{i+1}}</h3>
                        </div>
                        <div class="media-body row">
                            <div class="col-sm-9">
                                <p class="media-heading title-text text-capitalize" style="margin: 0;">{{plan.title}}</p>
                                <p class="subtitle-text" style="margin: 0; font-size: 13px;">{{plan.description}}</p>
                            </div>

                            <div class="col-sm-3">
                                <ul class="delete-and-edit-list">
                                    <li>
                                        <div class="form-check form-check-inline mt-2">
                                            <input value="{{plan.status}}" class="form-check-input position-static" style="font-size: 18px;" #status [(ngModel)]="plan.status" name="status" type="checkbox" id="status" (change)="onCheckboxChanged(plan,$event)" aria-label="...">
                                        </div>
                                    </li>
                                    <li>
                                        <span (click)="deleteActionPlan(plan)" style="margin: 0px;" class="pull-right">
                                        <svg class="icon-cancel-circled small red clickable">
                                            <use
                                                xlink:href="/assets/icons/icons-sheet.svg#icon-cancel-circled">
                                            </use>
                                        </svg>
                                    </span>
                                    </li>
                                    <li class="divider-vertical"></li>
                                    <li>
                                        <span (click)="actionPlanFormData=plan; isEdited = true; openInputDialog(actionPlanDialog);" style="margin: 0px;" class="pull-right">
                                        <svg class="icon-pencil-square small primary clickable">
                                            <use
                                                xlink:href="/assets/icons/icons-sheet.svg#icon-pencil-square">
                                            </use>
                                        </svg>
                                    </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr class="divider">
                </div>
            </div>
            <div class="col-md-12 d-flex justify-content-center mt-3">
                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                </mat-spinner>
            </div>
        </div>
    </div>
</div>

<ng-template #actionPlanDialog let-modal>
    <div class="modal-header">
        <h4 class="title-text" class="modal-title" id="modal-basic-title">Create an action plan</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss( 'Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <blockquote cite="" class="subtitle-text">Action plans, when executed properly, helps you achieve your goals. - David Thompson</blockquote>
        <form class="animated fadeIn" style="margin-top: 10px;" name="actionPlanForm" #actionPlanForm="ngForm" (ngSubmit)="actionPlanForm.form.valid">
            <div class="row" style="margin: 0;">
                <div class="form-group col-12">
                    <p class="subtitle-text" for="title">Title</p>
                    <input [(ngModel)]="actionPlanFormData.title" #title="ngModel" type="text" class="form-control" id="title" name="title" [ngClass]="{ 'is-invalid': actionPlanForm.submitted
                && title.invalid }" required aria-describedby="titleHint" autocomplete="off">

                    <div *ngIf="actionPlanForm.submitted && title.invalid" class="invalid-feedback animated bounceIn">
                        <small *ngIf="title.errors.required" id="titleHint" class="form-text">title is
                            required</small>
                        <!-- <div *ngIf="title.errors.required"></div> -->
                    </div>
                </div>
                <div class="form-group col-12">
                    <p class="subtitle-text" for="description">Description</p>
                    <textarea [(ngModel)]="actionPlanFormData.description" #description="ngModel" type="text" class="form-control" id="description" name="description" required autofocus minlength="5" [ngClass]="{ 'is-invalid': actionPlanForm.submitted && description.invalid }"
                        aria-describedby="descriptionHint" rows="4">
                    </textarea>
                    <div *ngIf="actionPlanForm.submitted && description.invalid" class="invalid-feedback animated bounceIn">
                        <small *ngIf="description.errors.required" id="descriptionHint" class="form-text">description
                            is
                            required</small>
                        <small *ngIf="description.errors.minlength" id="descriptionHint" class="form-text">Please
                            provide a minimum length of 5 characters</small>
                        <small *ngIf="description.errors.description">Please provide a valid description</small>
                    </div>
                </div>

                <div class="form-group col-12 date">
                    <p class="subtitle-text" for="dateOfExecution">Date of execution</p>
                    <div class="input-group date" data-target-input="nearest" NgTempusdominusBootstrap>
                        <input autocomplete="off" [(ngModel)]="actionPlanFormData.dateOfExecution" #dateOfExecution="ngModel" type="text" NgTempusdominusBootstrapInput class="form-control date" id="dateOfExecution" name="dateOfExecution" minlength="3" [options]="dateTimePickerOptions"
                            [ngClass]="{ 'is-invalid': actionPlanForm.submitted && dateOfExecution.invalid }" aria-describedby="dateOfExecutionHint">
                        <div class="input-group-append" NgTempusdominusBootstrapToggle>
                            <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                        </div>
                        <div *ngIf="actionPlanForm.submitted && dateOfExecution.invalid" class="invalid-feedback animated bounceIn">
                            <small *ngIf="dateOfExecution.errors.dateOfExecution">
                                Please provide date</small>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <div class="modal-footer">
        <button type="submit" ngbAutofocus class="btn btn-outline-dark" (click)="onSaveActionPlan()">Save
            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class="" width="24">
        </button>
    </div>
</ng-template>