<div class="file-picker-dialog">
    <h1 mat-dialog-title>Image cropper</h1>
    <div mat-dialog-content>
        <p class="subtitle-text">Select an image you want to crop</p>
        <input class="mt-3 mb-4" type="file" #fileInput style="display: block"
            (change)="fileChangeEvent($event)" accept="image/png, image/jpeg">
        <div>
            <image-cropper #imageCropper name="imageCropper" [imageChangedEvent]="imageChangedEvent" [imageQuality]="dialogOptions.data.imageQuality || 70"
            [autoCrop]="true" [maintainAspectRatio]="true" [aspectRatio]="dialogOptions.data.aspectRatio || 1/1" format="jpeg"
                (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="cancel()">Cancel</button>
        <button mat-button (click)="upload();">Done</button>
    </div>
</div>