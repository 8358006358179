<div class="sidenav">
    <!-- <div data-toggle="collapse1" data-target="#collapseMenu" aria-expanded="false" aria-controls="collapseMenu" class="card-shadow bg-white" style="padding: 3px;">
        <div class="media align-items-center" style="height: 50px;">
            <div class="media-left" style="padding: 4px;">
                <img src="{{authUser.image}}" class="media-object profile-image-small">
            </div>
            <a class="link media-body" href="startingpointzone/profile">
                <div class="media-heading" style="white-space: pre-wrap; max-width: 210px;">
                    {{authUser.fullname}}</div>
            </a>
        </div>
    </div> -->
    <ul id="collapseMenu1" class="collapse1 sidenav-menu">
        <li class="menu-item">
            <svg class="icon-news small dark" style="margin:8px;">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-news"></use>
            </svg>
            <a routerLink="/feed" routerLinkActive="sidenav-menu-active">News feed</a>
            <div class="active-indicator fa fa-caret-left fa-fw fa-2x"></div>
        </li>
        <li class="menu-item">
            <svg class="icon-event-2 small dark" style="margin:8px;">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-event-2"></use>
            </svg>
            <a routerLink="/events" routerLinkActive="sidenav-menu-active">Events</a>
            <div class="active-indicator fa fa-caret-left fa-fw fa-2x"></div>
        </li>

        <li class="menu-item">
            <svg class="icon-inspiration small dark" style="margin:8px;">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-inspiration"></use>
            </svg>
            <a routerLink="/inspirations" routerLinkActive="sidenav-menu-active">Find
                inspirations</a>
            <div class="active-indicator fa fa-caret-left fa-fw fa-2x"></div>
        </li>

        <li class="menu-item">
            <svg class="icon-speech-user small dark" style="margin:8px;">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-speech-user"></use>
            </svg>
            <a routerLink="/engagements" routerLinkActive="sidenav-menu-active">Mentoring
                engagements</a>
            <div class="active-indicator fa fa-caret-left fa-fw fa-2x"></div>
        </li>

        <li class="menu-item">
            <svg class="icon-earth-globe small dark" style="margin:8px;">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-earth-globe"></use>
            </svg>
            <a routerLink="/trainings" routerLinkActive="sidenav-menu-active">External
                Trainings</a>
            <div class="active-indicator fa fa-caret-left fa-fw fa-2x"></div>
        </li>

        <li class="menu-item">
            <svg class="icon-question small dark" style="margin:8px;">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-question"></use>
            </svg>
            <a routerLink="/questionnaires" routerLinkActive="sidenav-menu-active">Questionnaires</a>
            <div class="active-indicator fa fa-caret-left fa-fw fa-2x"></div>
        </li>
        <li class="menu-item">
            <svg class="icon-like small dark" style="margin:8px;">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-like"></use>
            </svg>
            <a routerLink="/polls" routerLinkActive="sidenav-menu-active">Polls</a>
            <div class="active-indicator fa fa-caret-left fa-fw fa-2x"></div>
        </li>

        <li class="menu-item">
            <svg class="icon-man-people-streamline-user small dark" style="margin:8px;">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-man-people-streamline-user"></use>
            </svg>
            <a routerLink="/profile" routerLinkActive="sidenav-menu-active">Profile</a>
            <div class="active-indicator fa fa-caret-left fa-fw fa-2x"></div>
        </li>

        <li class="menu-item">
            <svg class="icon-earth-globe-streamline small dark" style="margin:8px;">
                <use xlink:href="/assets/icons/icons-sheet.svg#icon-earth-globe-streamline"></use>
            </svg>
            <a routerLink="/goals" routerLinkActive="sidenav-menu-active">Goals And Action plan</a>
            <div class="active-indicator fa fa-caret-left fa-fw fa-2x"></div>
        </li>

    </ul>
</div>