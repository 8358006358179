import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { LocalAuthService } from 'src/app/services/auth.service';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { AlertType } from 'src/app/services/alert.service';

export class userPasswordFormData {
  public password: string;
  public confirmPassword: string = '';
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public userId: string;
  public otp: string;
  public userPasswordFormData = new userPasswordFormData();
  public userPasswordFormGroup: FormGroup;
  public isLoading = false;

  @ViewChild('invalidConfirmPassword') invalidConfirmPassword: ElementRef;
  @ViewChild('password', { read: ElementRef }) passwordInput: ElementRef;

  private observers: Array<Subscription> = new Array<Subscription>();
  constructor(
    private backend: BackendService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private confirmDialog: ConfirmDialogComponent,
    private appMaterialDesignModule: AppMaterialDesignModule,
    private localAuthService: LocalAuthService,
    private dataSource: DataService
  ) {
    this.userPasswordFormGroup = new FormGroup({
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
      this.userId = params.rp || '0';
      this.otp = params.otp || '0';
      this.checkPasswordResetStatus();
    });
  }

  public onResetPassword() {
    if (!this.onConfirmPassword()) {
      return;
    }
    this.isLoading = true;
      this.backend
        .resetUserPassword({
          userId: this.userId,
          otp: this.otp,
          password: this.userPasswordFormGroup.value.password,
        })
        .pipe(take(1))
        .subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              this.localAuthService.logout();
              this.router.navigate(['login'], {
                queryParams: {},
                skipLocationChange: false,
              });
              this.appMaterialDesignModule
                .showAlertToaster(
                  AlertType.success,
                  'Password reset successful. Please login with your new password',
                  3
                )
                .subscribe();
            } else {
              this.appMaterialDesignModule.showAlertToaster(
                AlertType.error,
                response.message,
                3
              );
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.userPasswordFormData = new userPasswordFormData();
          },
          complete: () => {
            this.isLoading = false;
            this.userPasswordFormData = new userPasswordFormData();
            console.log('on complete resetUserPassword');
          },
        })
  }

 public showPassword(): void {
    if (this.passwordInput.nativeElement.type === 'password') {
      this.passwordInput.nativeElement.type = 'text';
    } else {
      this.passwordInput.nativeElement.type = 'password';
    }
  }

  checkPasswordResetStatus(): void {
    this.isLoading = true;
      this.backend
        .checkPasswordResetStatus({ user: this.userId, otp: this.otp })
        .pipe(take(1))
        .subscribe({
          next: (response: any) => {
            if (response.code === '000') {
            } else {
              this.localAuthService.logout();
              this.router.navigate(['login'], {
                queryParams: {},
                skipLocationChange: false,
              });
              this.appMaterialDesignModule
                .showAlertToaster(AlertType.error, response.message, 3)
                .subscribe();
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.userPasswordFormData = new userPasswordFormData();
          },
          complete: () => {
            this.isLoading = false;
            this.userPasswordFormData = new userPasswordFormData();
            console.log('on complete checkPasswordResetStatus');
          },
        })
  }

  onConfirmPassword(): boolean {
    if (
      this.userPasswordFormGroup.get('password').value !==
      this.userPasswordFormGroup.get('confirmPassword').value
    ) {
      this.invalidConfirmPassword.nativeElement.style.display = 'block';
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.observers.forEach((observer) => {
      observer.unsubscribe();
    });
  }
}
