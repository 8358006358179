import { Component, OnInit, OnDestroy } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Subject, Subscription } from 'rxjs';
import { Event } from 'src/app/models/event';
import { take } from 'rxjs/operators';
import { LocalAuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit, OnDestroy {

  private eventId: string = '0';
  public eventDetails: Event;
  public attendees:[]=[]
  private unSubscriptioNotifier = new Subject();
  public authUser = null;
  public isLoadingAttendees = false;


  constructor(private backend: BackendService,
    private router: Router,
    private localAuthService: LocalAuthService,
    private activateRoute: ActivatedRoute,
    private dataSource: DataService) {
      this.authUser  = this.localAuthService.getUser();
  }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      this.eventId = params.id || '0';
      this.eventDetails = new Event();
      this.getEventDetails();
      this.getEventAttendees();
    });
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next();
    this.unSubscriptioNotifier.complete();
  }

  getEventDetails(): void {
    this.dataSource.filter('isLoading=true');
    this.backend.getEvent(this.eventId)
    .pipe(take(1))
    .subscribe({
      next: (response:any) => {
        if(response.code === '000'){
          this.eventDetails = response.data;
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete getEventDetails');
        this.dataSource.filter('isLoading=false');
      }
    });
  }

  getEventAttendees(): void {
    this.isLoadingAttendees = true
    this.backend.getEventAttendees(this.eventId)
    .pipe(take(1))
    .subscribe({
      next: (response:any) => {

        if(response.code === '000'){
          this.attendees = response.data;
        }
      },
      error: (err: any) => {
        this.isLoadingAttendees = false;
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete getEventAttendees');
        this.isLoadingAttendees = false;
      }
    });
  }

  public interested(){
    const attendeeIndex = this.eventDetails.attendees.indexOf(this.authUser.id);
    if(attendeeIndex < 0){
      //add to event.attendees
      this.eventDetails.attendees.push(this.authUser.id);
      //this.events.splice(index,1,event);
      //call backend to add attendee to event
      this.backend.addEventAttendee({user:this.authUser.id},this.eventId)
      .pipe(take(1))
      .subscribe({
        next:(response)=>{

        }
      })
    }else{
      //remove from event.attendees
      this.eventDetails.attendees.splice(attendeeIndex);
      //this.events.splice(index,1,event);
      //call backend to remove attendee from event
      this.backend.deleteEventAttendee({user:this.authUser.id},this.eventId)
      .pipe(take(1))
      .subscribe({
        next:(response)=>{

        }
      })
    }
  }
  deleteEvent(event:Event){}

  public signout(): void {
    if (this.localAuthService.logout()) {
      this.localAuthService
        .signOut()
        .then(
          (onfulfilled) => {
            console.log('onfulfilled... ' + onfulfilled);
          },
          (onrejected) => {
            console.log('onrejected... ' + onrejected);
          }
        )
        .catch((onrejected) => {
          console.log('catch onrejected... ' + onrejected);
        })
        .finally(() => {
          console.log('onfinally...');
          this.router.navigate(['/login']);
        });
    }
  }
}
