import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, } from 'rxjs';
import { Membership } from '../models/membership';
import { SocialAuthService } from 'angularx-social-login';
import { AuthUser } from '../models/auth-user';

@Injectable({
  providedIn: 'root'
})
export class LocalAuthService extends SocialAuthService {
  private authSource = new BehaviorSubject<AuthUser>(new AuthUser());
  public user: Observable<AuthUser> = this.authSource.asObservable();
  public authenticated: boolean = false;

  setUser(user: AuthUser): boolean {
    if (user !== null) {
    localStorage.setItem('authUser', JSON.stringify(user));
    this.authSource.next(user);
    }
    return true;
  }

  getUser(): AuthUser {
    //console.log('getUser '+localStorage.getItem('authUser'));
    return JSON.parse(localStorage.getItem('authUser')) || new AuthUser();
  }

  getAuthUser(): Observable<AuthUser> {
    //console.log('getUser '+localStorage.getItem('authUser'));
    //return JSON.parse(localStorage.getItem('authUser')) || new AuthUser();
    this.authSource.next(JSON.parse(localStorage.getItem('authUser')) || new AuthUser());
    return this.authSource.asObservable();
  }

  isAuthenticated() {
    return this.getUser().isAuthenticated;
  }

  getRole() {
    return this.getUser().role || '';
  }

  logout(): boolean {
    return this.setUser(new AuthUser());
  }

  getMembership(): Observable<Membership> {
    return;
  }

}
