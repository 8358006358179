<h2 mat-dialog-reference>Assign A Mentor</h2>
<div mat-dialog-content>
    <form [formGroup]="assignMentorFormGroup" class="animated fadeIn" style="margin-top: 10px;" (ngSubmit)="onAssignMentor()">
        <mat-action-list>
            <button mat-list-item (click)="unSelectMentor()">
                <mat-icon class="mt-3" matListAvatar>block</mat-icon>
                <div class="justify-content-between">
                    <div class="subtitle-text">No mentor</div>
                </div>
            </button>
        </mat-action-list>
        <mat-action-list>
            <button mat-list-item *ngFor="let mentor of mentors" (click)="selectMentor(mentor)">
                <img matListAvatar src="{{mentor.image}}" alt="...">
                <div class="justify-content-between">
                    <div class="subtitle-text align-self-center ml-3 mt-2">{{mentor?.firstname+' '+mentor?.lastname}}</div>
                </div>
            </button>
        </mat-action-list>
    </form>
    <div class="col-md-12 d-flex justify-content-center mt-3">
        <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
        </mat-spinner>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel() ">Cancel</button>
    <button type="submit " mat-button (click)="onAssignMentor(); " [disabled]="!assignMentorFormGroup.valid || isLoading">Submit</button>
</div>