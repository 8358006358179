import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { BackendService } from '../../services/backend.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { AppComponent } from 'src/app/app.component';
import { Subscription } from 'rxjs';
import { LocalAuthService } from 'src/app/services/auth.service';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { AuthUser } from 'src/app/models/auth-user';
import { AlertService, AlertType } from 'src/app/services/alert.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { User } from 'src/app/models/user';
import { ReCaptcha2Component } from 'ngx-captcha/public_api';
import { environment } from '../../../environments/environment';
import { AppMaterialDesignModule, DialogType } from 'src/app/app-material-design.module';
import { ConfirmEmailDialogComponent } from 'src/app/components/confirm-email-dialog/confirm-email-dialog.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export class FormData {
  username: string = '';
  password: string = '';
  captcha: string;
};
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

  public formData: FormData = new FormData();
  isLoading = false;
  public observers: Array<Subscription> = new Array<Subscription>();
  public authUser: User;
  closeResult: string;
  public loginFormGroup: FormGroup;

  public env = environment;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public captchaConfigs = this.env.captchaConfigs;

  //public passwordInput;

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('password', { read: ElementRef }) passwordInput: ElementRef;
  @ViewChild('progressRipple') progressRipple: ElementRef;

  public readonly siteKey = this.env.captchaConfigs.siteKey;

  constructor(private backend: BackendService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private dataSource: DataService,
    private localAuthService: LocalAuthService,
    //private authService: AuthService,
    public alertService: AlertService,
    private appComponent: AppComponent,
    private appMaterialComponent: AppMaterialDesignModule,
    public renderer: Renderer2,
    private elementRef: ElementRef,
    public cdr: ChangeDetectorRef) {

    this.loginFormGroup = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.minLength(8)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      captcha: new FormControl('', [Validators.nullValidator])
    });
  }

  ngAfterViewInit() {

    // let captchaElem = this.renderer.selectRootElement('#captchaElem');
  }

  ngOnInit() {
    this.suscribeToSocialLogin();
    this.authUser = new User();
  }

  showPassword(): void {
    if (this.passwordInput.nativeElement.type === 'password') {
      this.passwordInput.nativeElement.type = 'text';
    } else {
      this.passwordInput.nativeElement.type = 'password';
    }
  }

  onSubmit(): void {
    this.formData = { ...this.loginFormGroup.value }
    this.loginUserIn(this.formData);
  }

  sendVerifyAccountMail(formData) {
    //this.observers.push(
    this.backend.sendVerifyAccountMail(formData).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
        } else {
          this.appMaterialComponent.showAlertToaster(AlertType.success, response.message, 3).subscribe();
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete sendVerifyAccountMail');
      }
    })
    //);
  }

  loginUserIn(formData: any) {
    this.showProgressRipple();
    this.observers.push(this.backend.loginUserIn(formData).subscribe({
      next: (response: any) => {
        //console.log('response ' + JSON.stringify(response));
        if (response.code === '000') {
          this.authUser = response.data;
          if (this.authUser.status === 'verified') {
            this.appMaterialComponent.showAlertToaster(AlertType.success, `Welcome back ` + this.authUser.fullname, 3).subscribe();
          }
        } else {
          this.authUser.isAuthenticated = false;
          console.log('message ' + response.message)
          this.appMaterialComponent.showAlertToaster(AlertType.error, response.message, 3).subscribe();
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        this.isLoading = false;
        this.hideProgressRipple();
      },
      complete: () => {
        formData = new FormData();
        //this.loginFormGroup
        this.hideProgressRipple();
        if (this.authUser.isAuthenticated === true) {
          this.localAuthService.setUser(this.authUser);
          if (this.authUser.membershipId === null || this.authUser.membershipId === '') {
            this.router.navigate(['membership/questionnaire'], { skipLocationChange: false });
          } else {
            if (this.authUser.role == 'ADMIN') {
              this.router.navigate(['admin/dashboard'], { queryParams: {}, skipLocationChange: false });
            } else {
              this.router.navigate(['dashboard'], { queryParams: {}, skipLocationChange: false });
            }
          }
        }
      }
    }));
  }
  showProgressRipple(): void {
    this.progressRipple.nativeElement.style.display = 'inline';
  }
  hideProgressRipple(): void {
    this.progressRipple.nativeElement.style.display = 'none';
  }
  suscribeToSocialLogin(): void {
    this.observers.push(this.localAuthService.authState.subscribe((user) => {
      if (user !== null) {
        this.authUser = new User();
        this.authUser.isAuthenticated = true;
        this.authUser.email = user.email;
        this.authUser.id = user.id;
        this.authUser.provider = user.provider;
        this.authUser.image = user.photoUrl;
        this.authUser.fullname = user.name;

        var tempName = user.name.split(' ');

        var data = {
          firstname: tempName[0],
          lastname: tempName.length > 1 ? tempName[1] : tempName[0],
          email: user.email,
          username: user.name,
          password: '',
          provider: user.provider,
          image: user.photoUrl,
        }
        this.observers.push(this.backend.createUser(data).subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              this.authUser = response.data;
              if (this.authUser.status !== 'verified') {
                this.sendVerifyAccountMail({ email: response.data.email });
              }
              if (this.authUser.isAuthenticated === true) {
                this.localAuthService.setUser(this.authUser);

                if (this.authUser.membershipId === null || this.authUser.membershipId === '') {
                  this.router.navigate(['membership/questionnaire'], { queryParams: {}, skipLocationChange: false });
                } else {
                  if (this.authUser.role == 'ADMIN') {
                    this.router.navigate(['admin/users'], { queryParams: {}, skipLocationChange: false });
                  } else {
                    this.router.navigate(['dashboard'], { queryParams: {}, skipLocationChange: false });
                  }
                }
              }
            } else {
              console.log('message ' + response.message)
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.hideProgressRipple();
          },
          complete: () => {
            this.hideProgressRipple();
            this.formData = new FormData();
            console.log('on complete createUser');
          }
        }));
      }
    }));
  }

  signInWithGoogle(): void {
    this.localAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.localAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithTwitter(): void {
    //this.localAuthService.signIn(TwitterLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.localAuthService.signOut();
  }

  onResetPassword() {
    this.isLoading = true;
    console.log('send an email to ' + this.authUser.email + ' to reset password');
    this.observers.push(
      this.backend.createPasswordReset({ email: this.formData.username }).subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            //send email
            //this.modalService.dismissAll();
            this.sendPasswordResetMail({ email: this.formData.username, otp: response.data.otp });
          } else {
            this.appMaterialComponent.showAlertToaster(AlertType.success, response.message, 3).subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          //this.isLoading = false;
          console.log('on complete createPasswordReset');
        }
      })
    );
  }

  sendPasswordResetMail(data: any): void {
    this.observers.push(
      this.backend.sendPasswordResetMail(data).subscribe({
        next: (response: any) => {
          console.log(JSON.stringify(response));
          if (response.code === '000') {
            this.appMaterialComponent.showAlertDialog(DialogType.success, 'Reset password', 'Please check your email for the link to reset your password.').subscribe();
          } else {
            this.appMaterialComponent.showAlertToaster(AlertType.warn, response.message, 3).subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        },
        complete: () => {
          this.isLoading = false;
          //this.modalService.dismissAll();
          console.log('on complete sendPasswordReset');
        }
      })
    );
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  public openComfirmEmailDialog(): void {
    this.appMaterialComponent.openDialog(ConfirmEmailDialogComponent, {
      width: '400px',
      data: {
        title: 'Reset password',
        message: 'A link to reset your password will be sent to the email associated with this account'
      }
    }).subscribe();
  }

  handleSuccess(captchaResponse: string): void {
    //this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();

    this.loginFormGroup.patchValue({
      captcha: captchaResponse
    })
    this.backend.verifyCaptcha({ secret: this.env.captchaConfigs.secretKey, response: captchaResponse }).subscribe({
      next: (response: any) => {
        this.captchaSuccess = response.data.success;
        //console.log(JSON.stringify(response));
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        this.isLoading = false;
        //this.modalService.dismissAll();
        console.log('on complete verifyCaptcha');
      }
    });
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }
}
