<div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center pt-3">
        <h1>My Goals and action plans</h1>
        <div class="d-flex justify-content-end align-items-center">
            <!-- <div class="nav-link" (click)="signout()">
            <i class="fa fa-sign-out" style="font-size: 18px !important; color: #00213D;"></i>
            <span>Sign out</span>
        </div> -->
            <div class="nav-link" [matMenuTriggerFor]="menu">
                <img src="{{authUser.image}}" height="36" width="36"
                    style="border-radius: 50%; padding: 5px; object-fit: cover;">
                <!-- <i class="fa fa-user-circle-o" style="font-size: 24px !important; color: #00213D;"></i> -->
            </div>
            <mat-menu #menu="matMenu" class="spz-profile-menu">
                <div matRipple routerLink="/profile" class="media p-3">
                    <img src="{{authUser.image}}" class="align-self-center mr-2 profile-image-small"
                        alt="{{authUser.fullname}}">
                    <div class="media-body">
                        <h6 class="mt-0 mb-0">{{authUser.fullname}}</h6>
                        <p class="text-muted p-0 m-0" style="font-size: 14px;">View public profile</p>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div matRipple routerLink="/user/bookings" class="media p-3">
                    <div class="align-self-center mr-2">
                        <i class="fa fa-clock-o color-spz-grey" style="font-size: 28px !important;"></i>
                    </div>
                    <div class="media-body">
                        <p style="font-size: 14px;" class="mt-0 mb-0">Bookings</p>
                        <small class="text-muted p-0 m-0">View and manage all your bookings</small>
                    </div>
                </div>
                <div matRipple routerLink="/user/connections" class="media p-3">
                    <div class="align-self-center mr-2">
                        <i class="fa fa-users color-spz-grey" style="font-size: 28px !important;"></i>
                    </div>
                    <div class="media-body">
                        <p style="font-size: 14px;" class="mt-0 mb-0">My connections</p>
                        <small class="text-muted p-0 m-0">Manage your connections</small>
                    </div>
                </div>
                <div matRipple routerLink="/user/calendar" class="media p-3">
                    <div class="align-self-center mr-2">
                        <i class="fa fa-calendar-check-o color-spz-grey" style="font-size: 28px !important;"></i>
                    </div>
                    <div class="media-body">
                        <p style="font-size: 14px;" class="mt-0 mb-0">Calendar</p>
                        <small class="text-muted p-0 m-0">Setup your availability</small>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="p-3">
                    <div matRipple routerLink="/settings" class="d-flex justify-content-between align-items-center">
                        <div class="d-flex justify-content-start align-items-center">
                            <i class="fa fa-cog color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                            <p class="mt-3" style="font-size: 14px;">Settings</p>
                        </div>
                        <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                    </div>

                    <div matRipple class="d-flex justify-content-between align-items-center">
                        <div routerLink="/support" class="d-flex justify-content-start align-items-center">
                            <i class="fa fa-life-ring color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                            <p class="mt-3" style="font-size: 14px;">Support</p>
                        </div>
                        <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                    </div>

                    <div matRipple class="d-flex justify-content-between align-items-center">
                        <div (click)="signout()" class="d-flex justify-content-start align-items-center">
                            <i class="fa fa-sign-out color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                            <p class="mt-3" style="font-size: 14px;">Logout</p>
                        </div>
                        <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                    </div>
                </div>
                <!-- <button mat-menu-item>Item 1</button> -->

                <!-- <button mat-menu-item>Item 2</button> -->
            </mat-menu>
        </div>
    </div>
    <hr>
    <div class="container">
        <div class="row mt-4">
            <!-- <pre>{{goalFormGroup.value | json}}</pre> -->
            <div class="col-md-12 bg-white animate__animated animate__fadeIn"
                style="margin-bottom: 5px;">
                <form [formGroup]="goalFormGroup" class="form row" style="margin-top: 10px;" name="goalForm"
                    #goalForm="ngForm" (ngSubmit)="goalForm.form.valid && onSaveGoal()">
                    <div class="col-xs-12 col-md-6 col-lg-6">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <p class="subtitle-text" for="title">Title</p>
                                <input formControlName="title" type="title" class="form-control" id="title" name="title"
                                    required autofocus minlength="5"
                                    [ngClass]="{ 'is-invalid': goalForm.submitted && goalFormGroup.get('title').hasError('title') }"
                                    aria-describedby="titleHint" autocomplete="off">
                                <div *ngIf="goalForm.submitted && goalFormGroup.get('title').hasError('title')"
                                    class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="goalFormGroup.get('title').hasError('required')" id="titleHint"
                                        class="form-text">title
                                        is
                                        required</small>
                                    <small *ngIf="goalFormGroup.get('title').hasError('minlength')" id="titleHint"
                                        class="form-text">Please
                                        provide a minimum length of 5 characters</small>
                                    <small *ngIf="goalFormGroup.get('title').hasError('title')">Please provide a valid
                                        title</small>
                                </div>
                            </div>
                            <div class="form-group col-md-12">
                                <p class="subtitle-text" for="description">Description</p>
                                <textarea formControlName="description" type="text" class="form-control"
                                    id="description" name="description" required autofocus minlength="5"
                                    [ngClass]="{ 'is-invalid': goalForm.submitted && goalFormGroup.get('description').hasError('description') }"
                                    aria-describedby="descriptionHint" rows="3" autocomplete="off">
                            </textarea>
                                <div *ngIf="goalForm.submitted && goalFormGroup.get('description').hasError('description')"
                                    class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="goalFormGroup.get('description').hasError('required')"
                                        id="descriptionHint" class="form-text">description
                                        is
                                        required</small>
                                    <small *ngIf="goalFormGroup.get('description').hasError('minlength')"
                                        id="descriptionHint" class="form-text">Please
                                        provide a minimum length of 5 characters</small>
                                    <small *ngIf="goalFormGroup.get('description').hasError('description')">Please
                                        provide a valid description</small>
                                </div>
                            </div>
                            <div class="form-group col-md-12">
                                <p class="subtitle-text" for="category">Category</p>
                                <input type="text" placeholder="goal category" matInput formControlName="category"
                                    type="text" class="form-control" id="category" name="category"
                                    [matAutocomplete]="auto" [ngClass]="{ 'is-invalid': goalForm.submitted
                            && goalFormGroup.get('category').hasError('category')}" required
                                    aria-describedby="categoryHint" autocomplete="off">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let cat of filteredCategories | async" [value]="cat">
                                        {{cat}}
                                    </mat-option>
                                </mat-autocomplete>
                                <div *ngIf="goalForm.submitted && goalFormGroup.get('category').hasError('category')"
                                    class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="goalFormGroup.get('category').hasError('required')" id="categoryHint"
                                        class="form-text">category is
                                        required</small>
                                    <!-- <div *ngIf="category.errors.required"></div> -->
                                </div>
                            </div>
                            <div class="form-group col-md-12 date">
                                <p class="subtitle-text" for="duration">Duration</p>
                                <div class="input-group date" data-target-input="nearest">
                                    <input [matDatepicker]="durationPicker" autocomplete="off"
                                        formControlName="duration" type="text" class="form-control date" id="duration"
                                        name="duration" minlength="3"
                                        [ngClass]="{ 'is-invalid': goalForm.submitted && goalFormGroup.get('duration').hasError('duration') }"
                                        aria-describedby="durationHint">
                                    <!-- <mat-datepicker-toggle matSuffix [for]="durationPicker">
                                </mat-datepicker-toggle> -->
                                    <mat-datepicker #durationPicker></mat-datepicker>
                                    <div class="input-group-append">
                                        <div (click)="durationPicker.open()" class="input-group-text"><i
                                                class="fa fa-calendar"></i></div>
                                    </div>
                                    <div *ngIf="goalForm.submitted && goalFormGroup.get('duration').hasError('required')"
                                        class="invalid-feedback  animate__animated animate__bounceIn">
                                        <small *ngIf="goalFormGroup.get('duration').hasError('duration')">
                                            Please provide date</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 col-lg-6">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <p class="subtitle-text" for="actionPlanTitle">Add action plans to this goal.</p>
                                <div class="input-group">
                                    <input formControlName="actionPlanTitle" type="text" class="form-control" placeholder="Type here..."
                                        id="actionPlanTitle" name="actionPlanTitle" [ngClass]="{ 'is-invalid': goalForm.submitted
                            && goalFormGroup.get('actionPlanTitle').hasError('actionPlanTitle') }"
                                        aria-describedby="actionPlanTitleHint" autocomplete="off">
                                    <div class="input-group-append"
                                        (click)="addActionPlan(goalFormGroup.get('actionPlanTitle').value)">
                                        <div class="input-group-text">
                                            <i class="fa fa-fw fa-plus"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-12">
                                <p class="subtitle-text">Action plans</p>
                                <div *ngFor="let plan of goalFormData.actionPlans; let i = index;"
                                    class="col-md-12 bg-grey mb-3" style="border-radius: 10px;">
                                    <div class="" style="padding-top: 5px; padding-bottom: 5px;">
                                        <p class="media-heading title-text text-capitalize"
                                            style="display: inline; font-size: 14px;">
                                            {{plan.title}}</p>
                                        <span (click)="removeActionPlan(plan)"
                                            style="margin-top: 0px; position: absolute; right: 0; bottom:1%">
                                            <svg class="icon-cancel-circled small red clickable">
                                                <use xlink:href="/assets/icons/icons-sheet.svg#icon-cancel-circled">
                                                </use>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 d-flex justify-content-center mt-3">
                        <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate"
                            [diameter]="35" [strokeWidth]="2">
                        </mat-spinner>
                    </div>
                    <div class="col-md-12 d-flex justify-content-end" [hidden]="isLoading"
                        style="margin-bottom: 15px; padding-top: 10px;">
                        <button type="submit" [hidden]="isLoading"
                            class="btn spz-button-green" [disabled]="!goalForm.valid">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>