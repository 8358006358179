import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-school-and-education-info',
  templateUrl: './school-and-education-info.component.html',
  styleUrls: ['./school-and-education-info.component.css'],
})
export class SchoolAndEducationInfoComponent implements OnInit, OnDestroy {
  @Input('educationInfo') educationInfo: any[];
  private unSubscriptioNotifier = new Subject();
  public educationInfoFormGroup: FormGroup;
  public page = false;
  public isLoading = false;
  private authUser = null;
  public isEdited = false;
  public isUserEducationFormCollapsed = false;
  constructor(
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    public appMaterialModule: AppMaterialDesignModule
  ) {
    this.authUser = localAuthService.getUser();

    this.educationInfoFormGroup = new FormGroup({
      id: new FormControl(''),
      institutionName: new FormControl(''),
      fieldOfStudy: new FormControl(''),
      certificate: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      user: new FormControl(''),
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next('');
    this.unSubscriptioNotifier.complete();
  }

  public onSaveUserEducation(): void {
    this.isLoading = true;
    let userEducationFormData = this.educationInfoFormGroup.value;
    if (this.isEdited) {
      this.backend.updateUserEducation(userEducationFormData).subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            var temp = this.educationInfo.find((item) => {
              return item.id == userEducationFormData.id;
            });
            this.educationInfo.splice(this.educationInfo.indexOf(temp), 1);
            this.educationInfo.unshift(response.data);
          } else {
            this.appMaterialModule.showAlertToaster(
              AlertType.error,
              response.message,
              3000
            );
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.isUserEducationFormCollapsed = true;
          this.isEdited = false;
          this.educationInfoFormGroup.reset();
        },
        complete: () => {
          this.isLoading = false;
          this.isUserEducationFormCollapsed = true;
          this.isEdited = false;
          this.educationInfoFormGroup.reset();
          console.log('on complete updateUserEducation');
        },
      });
    } else {
      console.log(
        'userEducationFormData ' + JSON.stringify(userEducationFormData)
      );
      this.backend.createUserEducation(userEducationFormData).subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.educationInfo.unshift(response.data);
          } else {
            this.appMaterialModule.showAlertToaster(
              AlertType.error,
              response.message,
              3000
            );
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.isUserEducationFormCollapsed = true;
          this.educationInfoFormGroup.reset();
        },
        complete: () => {
          this.isLoading = false;
          this.isUserEducationFormCollapsed = true;
          this.educationInfoFormGroup.reset();
          console.log('on complete createUserEducation');
        },
      });
    }
  }

  public addEducationInfo() {
    this.togglePage();
    this.isEdited = false;
    this.educationInfoFormGroup = new FormGroup({
      id: new FormControl(''),
      institutionName: new FormControl(''),
      fieldOfStudy: new FormControl(''),
      certificate: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      user: new FormControl(''),
    });
  }

  public editEducationInfo(educationInfo) {
    this.togglePage();
    this.isEdited = true;
    this.educationInfoFormGroup.patchValue({
      id: educationInfo.id,
      institutionName: educationInfo.institutionName,
      fieldOfStudy: educationInfo.fieldOfStudy,
      certificate: educationInfo.certificate,
      startDate: educationInfo.startDate,
      endDate: educationInfo.endDate,
      user: this.authUser.id,
    });
  }

  public togglePage() {
    this.page = !this.page;
  }
}
