<div class="container">
    <div class="bg-white p-4">
        <h3 class="title-text text-center d-flex justify-content-center" id="modal-basic-title">WELCOME TO STARTING POINT ZONE</h3>

        <form class="col justify-content-center" [formGroup]="questionnaireFormGroup" #questionnaireForm="ngForm" (ngSubmit)="questionnaireFormGroup.valid && onSubmitQuestionnaire()">
            <div formArrayName="pages" *ngFor="let pageItem of getFormControls('pages').controls; let pageIndex = index;">
                <div [formGroupName]="pageIndex" class="swiper-container" [swiper]="swiperConfig" (indexChange)="onIndexChange($event)">
                    <div formArrayName="questions" class="swiper-wrapper mb-4">
                        <div [formGroupName]="questionIndex" *ngFor="let question of pageItem.get('questions').controls; let questionIndex=index" class="swiper-slide p-4 card-1" [attr.data-swiper-slide-index]="questionIndex">
                            <p class="title-text mt-2">{{question.get('text').value}}</p>
                            <mat-divider class="mt-3 mb-3"></mat-divider>
                            <div class="mt-3" [ngSwitch]="question.get('type').value">
                                <div *ngSwitchCase="'00'" class="form-group">
                                    <div class="form-check mb-1" *ngFor="let ans of questionnaireFormBuilder.getAnsweresAsList(question.get('options').value); let i = index">
                                        <input (click)="onAnswerSelected(question.value,ans)" style="font-size: 18px; cursor: pointer;" formControlName="answer" class="form-check-input" id="{{ans+i+questionIndex}}" type="radio" value="{{ans}}">
                                        <label class="form-check-label ml-3" for="{{ans+i+questionIndex}}">
                                            {{ans}}
                                        </label>
                                    </div>
                                </div>
                                <div *ngSwitchCase="'01'" class="form-group">
                                    <div formArrayName="answer" class="form-check mb-1" *ngFor="let ans of question.get('answer').controls; let answerIndex=index">
                                        <input style="font-size: 18px; cursor: pointer;" [formControlName]="answerIndex" class="form-check-input" type="checkbox" id="{{ans+answerIndex+questionIndex}}">
                                        <label class="form-check-label ml-3" for="{{ans+answerIndex+questionIndex}}">
                                            {{questionnaireFormBuilder.getAnsweresAsList(question.get('options').value)[answerIndex]}}
                                        </label>
                                    </div>
                                </div>
                                <div *ngSwitchCase="'02'" class="form-group row">
                                    <div class="col-sm-7">
                                        <input formControlName="answer" type="text" class="form-control form-control-sm" placeholder="Answer here" required autofocus autocomplete="off">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
        </form>

        <div class="col-md-12 d-flex justify-content-center mt-3">
            <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
            </mat-spinner>
        </div>

    </div>
</div>