import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
  Inject,
} from '@angular/core';
import { Goal } from 'src/app/models/goal';
import { Subscription, Subject, Observable, merge } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { DialogOptions, DialogService } from 'src/app/services/dialog.service';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ActionPlan } from 'src/app/models/action-plan';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertType } from 'src/app/services/alert.service';

@Component({
  selector: 'app-setup-goals',
  templateUrl: './setup.goals.component.html',
  styleUrls: ['./setup.goals.component.css'],
})
export class SetupGoalComponent implements OnInit, OnDestroy {
  private unSubscriptioNotifier = new Subject();
  authUser = null;
  public isEdited: boolean = false;
  public goalCategories: Array<string>;
  public filteredCategories: Observable<string[]>;

  public isLoading = false;
  public goalFormData: Goal;

  datePickerOptions = {
    //format: "YYYY-MM-DD h:mm:ss a",
    format: 'YYYY-MM-DD',
    //maxDate: moment(),
    //minDate: moment(),
    // ...
  };

  public goalFormGroup: FormGroup;

  constructor(
    private dialogService: DialogService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private localAuthService: LocalAuthService,
    public dialogRef: MatDialogRef<SetupGoalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
    public appMaterialDesignModule: AppMaterialDesignModule,
    private backend: BackendService
  ) {
    this.goalFormData = new Goal();
    this.goalCategories = new Array<string>();
  }

  ngOnInit() {
    this.localAuthService
      .getAuthUser()
      .pipe(take(1))
      .subscribe((data) => {
        this.authUser = data;
      });

    this.setupGoalFormGroup(this.goalFormData);
    this.getGoalCategories();

    this.activateRoute.paramMap.subscribe((params: ParamMap) => {
      let goalId = params.get('id') || '0';
      this.isEdited = goalId != '0';
      if (this.isEdited) {
        this.getGoalDetails(goalId);
      }
    });

    this.filteredCategories = this.goalFormGroup
      .get('category')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next('');
    this.unSubscriptioNotifier.complete();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.goalCategories.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  public setupGoalFormGroup(goal: Goal) {
    this.goalFormGroup = new FormGroup({
      title: new FormControl(goal.title, [
        Validators.required,
        Validators.minLength(5),
      ]),
      description: new FormControl(goal.description, [
        Validators.required,
        Validators.minLength(5),
      ]),
      category: new FormControl(goal.category, [
        Validators.required,
        Validators.minLength(5),
      ]),
      duration: new FormControl(goal.duration, [
        Validators.required,
        Validators.minLength(5),
      ]),
      actionPlanTitle: new FormControl('', [Validators.nullValidator]),
      actionPlans: new FormArray(
        goal.actionPlans.map((action) => {
          return new FormGroup({
            id: new FormControl(action?.id),
            goal: new FormControl(action?.goal),
            status: new FormControl(action?.status),
            user: new FormControl(action?.user),
            dateOfExecution: new FormControl(action?.dateOfExecution),
            title: new FormControl(action?.title),
            description: new FormControl(action?.description),
          });
        })
      ),
    });
  }

  public addActionPlan(value: string) {
    if (value && '' != value) {
      console.log('action plan text ' + value);
      var actionPlan = new ActionPlan();
      actionPlan.id = new Date().getTime() + '';
      actionPlan.title = value.trim();
      actionPlan.status = '';
      actionPlan.dateOfExecution = new Date().toISOString();
      actionPlan.description = value;
      actionPlan.user = this.authUser.id;

      let actionPlanFormGroup = new FormGroup({
        id: new FormControl(actionPlan?.id),
        goal: new FormControl(actionPlan?.goal),
        status: new FormControl(actionPlan?.status),
        user: new FormControl(actionPlan?.user),
        dateOfExecution: new FormControl(actionPlan?.dateOfExecution),
        title: new FormControl(actionPlan?.title),
        description: new FormControl(actionPlan?.description),
      });
      this.goalFormData.actionPlans.push(actionPlan);
      let actionPlansFormArray = this.goalFormGroup.get(
        'actionPlans'
      ) as FormArray;
      actionPlansFormArray.controls.unshift(actionPlanFormGroup);

      this.goalFormGroup.setControl('actionPlans', actionPlansFormArray);
      this.goalFormGroup.get('actionPlanTitle').setValue('');

      if (this.goalFormData.actionPlans.length >= 5) {
        //this.actionPlanInput.nativeElement.disabled = true;
        this.appMaterialDesignModule
          .showAlertToaster(
            AlertType.info,
            'You are limited to 5 action plans per goal',
            3
          )
          .pipe(take(1))
          .subscribe();
      }
    } else {
      this.appMaterialDesignModule
        .showAlertToaster(
          AlertType.warn,
          'Cannot submit empty title for action plan.',
          3
        )
        .pipe(take(1))
        .subscribe();
    }
  }

  public removeActionPlan(value: ActionPlan) {
    var temp = this.goalFormData.actionPlans.find((item) => {
      return item.title == value.title;
    });
    var index = this.goalFormData.actionPlans.indexOf(temp);
    //this.actionPlans.splice(this.actionPlans.indexOf(temp), 1);
    this.goalFormData.actionPlans.splice(index, 1);

    let actionPlansFormArray = this.goalFormGroup.get(
      'actionPlans'
    ) as FormArray;
    actionPlansFormArray.removeAt(index);
    this.goalFormGroup.setControl('actionPlans', actionPlansFormArray);

    if (this.goalFormData.actionPlans.length < 5) {
      //this.actionPlanInput.nativeElement.disabled = false;
    }
  }

  public editActionPlan(value: ActionPlan) {
    //this.actionPlan = value.title;
    this.removeActionPlan(value);
    if (this.goalFormData.actionPlans.length < 5) {
      //this.actionPlanInput.nativeElement.disabled = false;
    }
  }

  onSaveGoal(): void {
    this.goalFormData = { ...this.goalFormGroup.value };
    this.goalFormData.user = this.authUser.id;
    console.log('goalFormData : ' + JSON.stringify(this.goalFormData));
    this.isLoading = true;
    if (this.isEdited) {
        this.backend.updateGoal(this.goalFormData).subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              this.router.navigate(['/goals'], {
                queryParams: {},
                skipLocationChange: false,
              });
            } else {
                this.appMaterialDesignModule
                  .showAlertToaster(AlertType.warn, response.message, 3)
                  .pipe(take(1))
                  .subscribe();
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isEdited = false;
          },
          complete: () => {
            this.isLoading = false;
            this.isEdited = false;
            this.goalFormData = new Goal();
            console.log('on complete updateGoal');
          },
        });
    } else {
      this.goalFormData.user = this.authUser.id;
        this.backend.createGoal(this.goalFormData)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              this.router.navigate(['/goals'], {
                queryParams: {},
                skipLocationChange: false,
              });
            } else {
                this.appMaterialDesignModule
                  .showAlertToaster(AlertType.warn, response.message, 3)
                  .pipe(take(1))
                  .subscribe();
            }
            this.isLoading = false;
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isEdited = false;
            this.goalFormData = new Goal();
          },
          complete: () => {
            this.isLoading = false;
            this.isEdited = false;
            this.goalFormData = new Goal();
            console.log('on complete createGoal');
          },
        });
    }
  }
  getGoalCategories(): void {
      this.backend.getGoalCategories()
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.goalCategories = response.data;
            //this.filteredCategories = response.data
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete getGoalCategories');
        },
      });
  }

  getGoalDetails(goalId: string): void {
    this.isLoading = true;
      this.backend.getGoal(goalId)
      .pipe(takeUntil(this.unSubscriptioNotifier))
      .subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.goalFormData = response.data;
            if (this.authUser.id != this.goalFormData.user) {
                this.appMaterialDesignModule
                  .showAlertToaster(
                    AlertType.warn,
                    'You are not allowed to edit this resource.',
                    3
                  )
                  .pipe(take(1))
                  .subscribe();
              this.router.navigate(['/goals'], {
                queryParams: {},
                skipLocationChange: false,
              });
            } else {
              this.setupGoalFormGroup(this.goalFormData);
            }
          } else {
              this.appMaterialDesignModule
                .showAlertToaster(AlertType.warn, response.message, 3)
                .pipe(take(1))
                .subscribe();
            this.router.navigate(['/goals'], {
              queryParams: {},
              skipLocationChange: false,
            });
          }
          this.isLoading = false;
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete getGoals');
        },
      });
  }

  public signout(): void {
    if (this.localAuthService.logout()) {
      this.localAuthService
        .signOut()
        .then(
          (onfulfilled) => {
            console.log('onfulfilled... ' + onfulfilled);
          },
          (onrejected) => {
            console.log('onrejected... ' + onrejected);
          }
        )
        .catch((onrejected) => {
          console.log('catch onrejected... ' + onrejected);
        })
        .finally(() => {
          console.log('onfinally...');
          this.router.navigate(['/login']);
        });
    }
  }
}
