import { QuestionPage } from './question-page';
import { Question } from './question';
import { AuthUser } from './auth-user';

export class Questionnaire {
    public id: string;
    public footer: Footer;
    public title: string;
    public type:string;
    public settings: Settings;
    public pages: Array<QuestionPage>;
    public createdAt: number;
    public createdBy: AuthUser;

    constructor() {
        this.pages = new Array<QuestionPage>();
        this.createdBy = new AuthUser();
        this.footer = new Footer();
        this.settings = new Settings();
    }

    public createWithDefaults(): Questionnaire {
        let questionnaire = new Questionnaire();
        questionnaire.title = 'new questionnaire';
        questionnaire.id = new Date().getTime().toString();
        let page = new QuestionPage();
        page.title = 'new page';
        page.description = 'new page';
        page.index = 1;
        page.questions = new Array<Question>();
        questionnaire.pages.push(page);

        return questionnaire;
    }
}

export class Footer {
    public title: string;
    public description: string;
}

export class Settings {
    public EmailNotification: any;
    public EnableScoring: any
    public ShowQuestionNumbers: any;
    public ShowPageNumbers: any;
    public ShowProgressBar: any;
    public ButtonText: any;
    public FinishButtonText: any;
    public NextButtonText: any;
    public PreviousButtonText: any;
    public SaveButtonText: any;

    public PageRandomisation: [
        {
            title: "No randomisation",
            selected: true
        },
        {
            title: "Randomisation",
            selected: false
        },
        {
            title: "Flip pages",
            selected: false
        }
    ];

    constructor() {
        this.EmailNotification = true;
        this.EnableScoring = false;
        this.ShowQuestionNumbers = true;
        this.ShowPageNumbers = true;
        this.ShowProgressBar = true;
        this.FinishButtonText = 'Finish button';
        this.NextButtonText = 'Next button';
        this.PreviousButtonText = 'Previous button';
        this.SaveButtonText = 'Save button';

    }
}



