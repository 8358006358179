<div class="container-fluid section-header pb-5">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-dark pb-4">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="container-fluid">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <a class="navbar-brand" href="/startingpointzone/home">
                        <img alt="temp image" src="/assets/img/spz-logo.svg" class="d-inline-block align-middle mr-3">
                    </a>
                    <div class="nav-scroller navbar-nav navbar-left">
                        <nav class="nav d-flex justify-content-start">
                            <!-- <a class="p-2 nav-link" href="#home" routerLinkActive="sidenav-menu-active">Home</a> -->
                            <a class="p-2 nav-link" href="#about" routerLinkActive="sidenav-menu-active">About SPZ</a>
                            <a class="p-2 nav-link" href="#features" routerLinkActive="sidenav-menu-active">Features</a>
                            <a class="p-2 nav-link" routerLink="/membership"
                                routerLinkActive="sidenav-menu-active">Membership Plan</a>
                            <a class="p-2 nav-link" href="#spz-fellowship" routerLinkActive="sidenav-menu-active">SPZ
                                Fellowship</a>
                            <a class="p-2 nav-link" href="#spz-fellowship"
                                routerLinkActive="sidenav-menu-active">Resources</a>
                            <!-- <a [hidden]="isAuthenticated" class="p-2 nav-link home-login-btn"
                            routerLink="/login">Login</a> -->
                            <!-- <button [hidden]="!isAuthenticated" class="btn btn-outline-success" routerLink="/login"
                            type="submit">Login</button> -->
                        </nav>
                    </div>
                </div>
            </div>
        </nav>
        <div>
            <h1 class="color-white title-text text-left d-flex justify-content-start">MEMBERSHIP PROFILES</h1>
            <!-- <mat-divider class="mb-3"></mat-divider> -->
            <h3><i class="subtitle-text color-white">Starting Point Zone operates three levels of membership. The first
                    two are open for general applications while the third is by invitation/referrals only.</i></h3>
        </div>
    </div>
</div>

<div class="container-fluid section-2 pb-5 pt-3">
    <div class="container">
        <section>
            <div class="media">
                <!-- <div style="width: 10px; height: 70px; background-color: silver;" class="align-self-start mr-3"></div> -->
                <div class="media-body">
                    <h1 class="p-0 mb-0 ml-0 mr-0 mt-2" style="font-size: 40px; font-weight: bolder;">SILVER</h1>
                    <p class="p-0 mt-2" style="font-size: 20px; font-weight: 400;">MEMBERSHIP</p>
                </div>
            </div>
            <p class="mt-3">To qualify for membership in this category, you will be a person not currently in
                employment, education or training (NEET) who needs support to set achieve-able goals.</p>
            <p>You have a minimum first degree/HND and at the minimum a vague idea of what your purpose is in life. You
                are willing to commit to learning relevant skills to your vision while benefiting from mentorship
                opportunities.</p>
            <p>Potential Silver Members are required to fill an application form and you will receive an invitation for
                an initial telephone chat by our in-house coach or administrator</p>
            <p>If you are confirmed suitable for membership the following are the requirements and benefits;
            </p>
            <ul>
                <li>Attend the VGMT bootcamp. This is a free but compulsory seminar/training for all silver members. You
                    will meet the organization’s promoters and have the opportunity to network with other intending
                    member
                </li>
                <li>Indicate your preference for Mentorship and apply into the mentoring program
                </li>
                <li>You will have the opportunity to apply for Fellowship Program
                </li>
                <li>Access to the members’ only area on the website and free subscription to the digital magazine.
                </li>
            </ul>
        </section>
    </div>
</div>

<div class="container-fluid section-3 pb-5 pt-3">
    <div class="container">
        <section>
            <div class="media">
                <div style="width: 10px; height: 70px; background-color: gold;" class="align-self-start mr-3"></div>
                <div class="media-body">
                    <h1 class="p-0 mb-0 ml-0 mr-0 mt-2" style="font-size: 40px; font-weight: bolder;">GOLD</h1>
                    <p class="p-0 mt-2" style="font-size: 20px; font-weight: 400;">MEMBERSHIP</p>
                </div>
            </div>
            <p class="mt-3">Gold membership application is open to people who have been in employment or
                entrepreneurship and have experience they are willing to share.</p>
            <p>You are willing to commit to learning relevant skills to your vision while benefiting from mentorship
                opportunities.
            </p>
            <p>Potential members are required to fill an application form and you will receive an invitation for an
                initial telephone chat by our in-house coach or administrator.</p>
            <p class="p-0 m-0">If you are confirmed suitable for membership the following are the requirements and
                benefits;
            </p>
            <ul class="mt-3">
                <li>Attend the VGMT bootcamp. This is a free but compulsory seminar/training for all silver members. You
                    will meet the organization’s promoters and have the opportunity to network with other intending
                    member
                </li>
                <li>Indicate your preference for Mentorship and apply into the mentoring program
                </li>
                <li>You will have the opportunity to apply for Fellowship Program
                </li>
                <li>Access to the members’ only area on the website and free subscription to the digital magazine.
                </li>
                <li>Free 5 hours Counselling opportunity (extendable at a fee)
                </li>
                <li>Access to the members’ only area on the website and minimal subscription to the digital magazine.
                </li>
            </ul>
        </section>
    </div>
</div>
<div class="container-fluid section-4 pb-5 pt-3">
    <div class="container">
        <section>
            <div class="media d-flex justify-content-end">
                <div class="media-body">
                    <h1 class="d-flex justify-content-end p-0 mb-0 ml-0 mr-0 mt-2"
                        style="font-size: 40px; font-weight: bolder;">Platinum</h1>
                    <p class="d-flex justify-content-end p-0 mt-2" style="font-size: 20px; font-weight: bold;">Angels
                    </p>
                </div>
                <div style="width: 10px; height: 70px; background-color: #E5E4E2;" class="align-self-start ml-3"></div>
            </div>
            <p class="mt-3">Our Platinum Angel's category is strictly by invitation and referral. Members typically have
                more than 10 years’ employment /entrepreneurial experience.</p>
            <p>Having made a mark in their chosen professional field, they are willing to share personal experiences and
                give back to their community/country through cutting edge informational networking and mentoring.
            </p>
            <p>Platinum Angels form a source database of faculties at the Network’s specialized seminars/boot
                camps/conferences.
            </p>
            <p>They voluntarily donate financially to the cause of the network and serve as mentors to the Silver, Gold
                and Gold+ members.
            </p>
            <p class="" style="font-size: 20px; font-weight: 500;">SPZ in return will</p>
            <ol class="mt-3">
                <li>Provide a platform where the Platinum members can engage and interact with their peers for
                    professional purposes through specialized events targeted specifically to their needs under the VGMT
                    protocol.</li>
                <li>Provide them with enhanced visibility and opportunity to promote their businesses/causes.
                </li>
                <li>Provide the platform for as many that wishes to express their latent talents outside their formal
                    working environment including as investors in SPZ Capital (Platform for Equity investments in
                    startups and companies looking for expansion).</li>
            </ol>
        </section>
    </div>
</div>

<div class="container-fluid section-footer pb-5 pt-5">
    <div class="container">
        <div class="p-4">
            <h4 style="color:white" class="title-text text-center d-flex justify-content-start">Useful links</h4>
            <mat-divider style="background-color: white;" class="mb-2"></mat-divider>
            <div>
                <a class="link" style="color: white;" routerLink="/home">Home</a>
                <a class="link" style="color: white;" routerLink="/profile">Profile</a>
                <a class="link" style="color: white;" routerLink="/membership/questionnaire">Membership
                    Questionnaire</a>
                <a class="link" style="color: white;" routerLink="/engagements">Mentoring Engagement</a>
                <a class="link" style="color: white;" routerLink="/goals">Goals</a>
                <a class="link" style="color: white;" routerLink="/inspirations">Sucess Stories</a>
                <a class="link" style="color: white;" routerLink="/events">Event</a>
            </div>
        </div>
    </div>
</div>