import { FormGroup, FormControl } from '@angular/forms';

export class Question {
    public id: string;
    public index: number;
    public text: string;
    public type: string;
    public answer: any;
    public options: string;
    public logic: any;

    constructor() {
        this.logic = '';
        var now = new Date();
        this.id = now.getTime().toString();
        this.text = 'new question';
        this.answer = '';
    }

}

export class Type {
    public title: string;
    public value: string;
}


export class Logic {
    public answer: any;
    public operator: any;
    public value: any;

    constructor(){

    }

    public createFromOptions(options:string):Array<any>{
        let temp = options.split('\n');
        let tempInput = [];
        temp.forEach(t => {
            var fg = new FormGroup({
              answer: new FormControl(t),
              nextIndex: new FormControl(''),
              operator: new FormControl(''),
              valueToCompare: new FormControl(t)
            });
            tempInput.push(fg);
          });
        return tempInput;
    }
}

export const enum LogicOperator {
    GreaterThan = 'GreaterThan',
    LessThan = 'LessThan',
    EqualTo = 'EqualTo',
    NotEqualTo = 'NotEqualTo'
}

export class Decision {
    public type: string;
    public nextIndex: number;

    constructor(type,nextIndex){
        this.type = type;
        this.nextIndex = nextIndex;
    }
}
