import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Subscription, Subject, Observable, merge, of, forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { Education } from 'src/app/models/education';
import { SocialMediaAccount } from 'src/app/models/social-media-account';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { Profile } from 'src/app/models/profile';
import { switchMap, take } from 'rxjs/operators';
import { Contact } from 'src/app/models/contact';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { DialogButton } from 'src/app/services/dialog.service';
import { Employment } from 'src/app/models/employment';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { Mentor } from 'src/app/models/mentor';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileType } from 'src/app/components/file-picker-dialog/file-picker-dialog.component';
import { ImageCropperDialogComponent } from 'src/app/components/image-cropper-dialog/image-cropper-dialog.component';

export class UserAccountFormData {
  public username: string;
  public email: string;
}

export class userPasswordFormData {
  public password: string;
  public newpassword: string;
  public confirmPassword: string = '';
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit, OnDestroy, AfterViewInit {
  public sidebarToggle = 'off';
  public isEdit: boolean = false;
  public isEdited: boolean = false;
  public observers: Array<Subscription> = new Array<Subscription>();
  public userId = '';
  public authUser:any;
  public userAccountFormData = new UserAccountFormData();
  public userPasswordFormData = new userPasswordFormData();
  public userProfileFormData = new Profile();
  public userContactFormData = new Contact();
  public userEducationFormData = new Education();
  public userEmploymentFormData = new Employment();
  public userSocialMediaAccountFormData = new SocialMediaAccount();
  public filteredCountries: Observable<string[]>;
  public isUserEducationFormCollapsed = true;
  public isUserEmploymentFormCollapsed = true;
  public isUserAccountFormCollapsed = true;
  public isUserProfileFormCollapsed = true;
  public isUserPasswordFormCollapsed = true;
  public isUserContactFormCollapsed = true;
  public isUserSocialMediaAccountFormCollapsed = true;
  public mentorDetails: Mentor;
  public mentorUpdate: MentorUpdate;
  public mentorUpdateFormGroup: FormGroup;
  public userDetails: any;
  public action: string;
  public otp: string;

  public imageChangedEvent: any = '';
  public croppedImage: any = '';

  public socialMediaAccount: SocialMediaAccount;
  public addOrEditText = 'Add';
  public isLoading = false;

  @ViewChild('startDate') datepicker: ElementRef;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  @ViewChild('invalidConfirmPassword') invalidConfirmPassword: ElementRef;
  @ViewChild('usernameCheckProgress') usernameCheckProgress: ElementRef;
  @ViewChild('usernameExistError') usernameExistError: ElementRef;
  @ViewChild('emailExistError') emailExistError: ElementRef;
  //@ViewChild('instance') instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  public countries = new Array<any>();

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  public onCountryChange($value) {
    this.filteredCountries = of(this._filter($value));
  }

  constructor(
    private localAuthService: LocalAuthService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private backend: BackendService,
    private dataSource: DataService,
    public appMaterialModule: AppMaterialDesignModule
  ) {
    this.authUser = localAuthService.getUser();
    this.socialMediaAccount = new SocialMediaAccount();

    this.mentorUpdateFormGroup = new FormGroup({
      userId: new FormControl(''),
      industry: new FormControl(''),
      mentoringArea: new FormControl('', [Validators.required]),
      preferredModeOfCommunication: new FormControl('', [Validators.required]),
      rating: new FormControl(''),
    });
  }

  ngAfterViewInit(): void {
    //this.datepicker.nativeElement.style.fontSize = '25px';
    //this.datepicker.nativeElement.placeholder = 'placeholder';
    //this.datepicker.nativeElement.setAttribute('data-date-start-date', '2007-12-02');
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe((params) => {
      this.userId = params.rp || '0';
      this.action = params.ac || '0';
      this.otp = params.otp || '0';
      this.sidebarToggle = params.sb || 'off';
    });

    if (this.action === 'verifyemail') {
      console.log('verifyemail');
      this.verifyUserStatus({ userId: this.userId, otp: this.otp });
    } //else {
    //this.isEdit = this.router.url.includes('profile/edit');

    this.userId = this.authUser.id;
    this.getCountries();
    this.getUserDetails(this.userId);
    //}
  }

  public toggleSidebar() {
    if (this.sidebarToggle == 'on') this.sidebarToggle = 'off';
    else this.sidebarToggle = 'on';
  }

  verifyUserStatus(formData: any): void {
    this.observers.push(
      this.backend.verifyUserStatus(formData).subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            //reload the page
            var observer = this.appMaterialModule
              .showAlertToaster(
                AlertType.info,
                'You have successfully verified your account',
                3
              )
              .subscribe();
            this.observers.push(observer);
            this.router.navigate(['profile'], {
              queryParams: {},
              skipLocationChange: false,
            });
          } else {
            var observer = this.appMaterialModule
              .showAlertToaster(AlertType.error, response.message, 3)
              .subscribe();
            this.observers.push(observer);
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        },
        complete: () => {
          console.log('on complete verifyUserStatus');
        },
      })
    );
  }

  getCountries() {
    this.backend
      .getCountries()
      .pipe(take(1))
      .subscribe({
        next: (data: any) => {
          this.countries = data;
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        },
        complete: () => {
          console.log('on complete getCountries');
        },
      });
  }

  getUserDetails(formData: string) {
    forkJoin([
      this.backend.getUserDetails3(formData),
      this.backend.getMentorById(formData),
    ])
      .pipe(take(1))
      .subscribe({
        next: (response: any) => {
          if (response[0].code === '000') {
            if (response[0].data.status !== 'verified') {
              //verify email
              this.appMaterialModule
                .showAlertToaster(
                  AlertType.info,
                  'Verify your email. Please check your email to verify your account',
                  5
                )
                .subscribe();
            }
            this.userDetails = response[0].data;
            //console.log(JSON.stringify(this.userDetails));
            this.authUser.email = response[0].data.email;
            this.userAccountFormData.email = this.userDetails.email;
            this.userAccountFormData.username = this.userDetails.username;
            this.userProfileFormData = this.userDetails.profile;
            this.userContactFormData = this.userDetails.contact;
            this.dataSource.setData(this.userDetails);
            this.localAuthService.setUser(this.authUser);
          }
          if (response[1].code === '000') {
            this.mentorDetails = response[1].data;

            this.mentorUpdate = {
              industry: this.mentorDetails.mentoringArea,
              mentoringArea: this.mentorDetails.mentoringArea,
              preferredModeOfCommunication:
                this.mentorDetails.preferredModeOfCommunication,
            };

            this.mentorUpdateFormGroup
              .get('mentoringArea')
              .valueChanges.subscribe((val) => {
                this.mentorDetails.industry = val;
                this.mentorUpdateFormGroup.get('industry').setValue(val);
              });

            this.mentorUpdateFormGroup.patchValue({
              userId: this.mentorDetails.user,
              mentoringArea: this.mentorDetails.mentoringArea,
              preferredModeOfCommunication:
                this.mentorDetails.preferredModeOfCommunication,
              rating: this.mentorDetails.rating,
              industry: this.mentorDetails.mentoringArea,
            });
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        },
        complete: () => {
          console.log('on complete getUserDetails');
        },
      });
  }

  public openInputDialog(dialog) {}

  onDateSelected(input: string, value: string): void {
    if (input === 'dateOfBirth') this.userProfileFormData.dateOfBirth = value;
    else if (input === 'eduStartDate')
      this.userEducationFormData.startDate = value;
    else if (input === 'eduEndDate') this.userEducationFormData.endDate = value;
    else if (input === 'empStartDate')
      this.userEmploymentFormData.startDate = value;
    else if (input === 'empEndDate')
      this.userEmploymentFormData.endDate = value;
  }

  onCheckboxChanged(value, event) {
    if (event.target.checked)
      // this.userEmploymentFormData.isCurrentEmployment = event.target.checked;
      this.userEmploymentFormData.isCurrentEmployment = 'true';
    else this.userEmploymentFormData.isCurrentEmployment = '';
  }

  userBiographyFormControl: FormControl = new FormControl('');
  onSaveBio(): void {
    if (this.userBiographyFormControl.valid) {
      this.isLoading = true;
      this.backend
        .updateUserProfileBiography({
          userId: this.authUser.id,
          biography: this.userBiographyFormControl.value,
        })
        .pipe(take(1))
        .subscribe({
          next: (response: any) => {
            this.userProfileFormData.biography = response.data.biography;
            this.userDetails.profile = this.userProfileFormData;
            this.dataSource.setData(this.userDetails);
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
            this.croppedImage = '';
            this.imageChangedEvent = null;
            console.log('on complete updateUserProfileBiography');
          },
        });
    }
  }

  deleteUserEducation(education: any): void {
    this.appMaterialModule
      .openDialog(ConfirmDialogComponent, {
        width: '400px',
        title: 'Please confirm',
        message: 'Are sure you want to delete! This cannot be undone.',
      })
      .pipe(
        switchMap((result) => {
          if (result.button == DialogButton.ok) {
            this.appMaterialModule.showProgressDialog('Deleting...');
            return this.backend.deleteUserEducation(education);
          }
        })
      )
      .subscribe({
        next: (response: any) => {
          this.dataSource.setData(response.data);
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
          if (response.code === '000') {
            var index = this.userDetails.education.indexOf(education);
            this.userDetails.education.splice(index, 1);
            this.appMaterialModule
              .showAlertToaster(AlertType.success, 'successfully deleted')
              .pipe(take(1))
              .subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
        },
        complete: () => {
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
          console.log('on complete deleteUserEducation');
        },
      });
  }

  deleteUserEmployment(employment: any): void {
    this.appMaterialModule
      .openDialog(ConfirmDialogComponent, {
        width: '400px',
        title: 'Please confirm',
        message: 'Are sure you want to delete! This cannot be undone.',
      })
      .pipe(
        switchMap((result) => {
          if (result.button == DialogButton.ok) {
            this.appMaterialModule.showProgressDialog('Deleting...');
            return this.backend.deleteUserEmployment(employment);
          }
        })
      )
      .subscribe({
        next: (response: any) => {
          this.dataSource.setData(response.data);
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
          if (response.code === '000') {
            var index = this.userDetails.employment.indexOf(employment);
            this.userDetails.employment.splice(index, 1);
            this.appMaterialModule
              .showAlertToaster(AlertType.success, 'successfully deleted')
              .pipe(take(1))
              .subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
        },
        complete: () => {
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
          console.log('on complete deleteUserEmployment');
        },
      });
  }

  deleteUserSocialMediaAccount(socialMedia: any): void {
    this.observers.push(
      this.appMaterialModule
        .openDialog(ConfirmDialogComponent, {
          width: '400px',
          title: 'Please confirm',
          message: 'Are sure you want to delete! This cannot be undone.',
        })
        .pipe(
          switchMap((result) => {
            if (result.button == DialogButton.ok) {
              this.appMaterialModule.showProgressDialog('Deleting...');
              return this.backend.deleteUserSocialMediaAccount(socialMedia);
            }
          })
        )
        .subscribe({
          next: (response: any) => {
            this.dataSource.setData(response.data);
            this.isLoading = false;
            this.appMaterialModule.hideProgressDialog();
            if (response.code === '000') {
              var index =
                this.userDetails.socialMediaAccounts.indexOf(socialMedia);
              this.userDetails.socialMediaAccounts.splice(index, 1);
              var observer2 = this.appMaterialModule
                .showAlertToaster(AlertType.success, 'successfully deleted')
                .subscribe();
              this.observers.push(observer2);
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.appMaterialModule.hideProgressDialog();
          },
          complete: () => {
            this.isLoading = false;
            this.appMaterialModule.hideProgressDialog();
            console.log('on complete deleteUserEmployment');
          },
        })
    );
  }

  public onSaveUserEducation(): void {
    this.isLoading = true;
    if (this.isEdited) {
      this.observers.push(
        this.backend.updateUserEducation(this.userEducationFormData).subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              var temp = this.userDetails.education.find((item) => {
                return item.id == this.userEducationFormData.id;
              });
              this.userDetails.education.splice(
                this.userDetails.education.indexOf(temp),
                1
              );
              this.userDetails.education.unshift(response.data);
            } else {
              this.appMaterialModule.showAlertToaster(
                AlertType.error,
                response.message,
                3
              );
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isUserEducationFormCollapsed = true;
            this.isEdited = false;
            this.userEducationFormData = new Education();
          },
          complete: () => {
            this.isLoading = false;
            this.isUserEducationFormCollapsed = true;
            this.isEdited = false;
            this.userEducationFormData = new Education();
            console.log('on complete updateUserEducation');
          },
        })
      );
    } else {
      this.userEducationFormData.user = this.authUser.id;
      console.log(
        'userEducationFormData ' + JSON.stringify(this.userEducationFormData)
      );
      this.observers.push(
        this.backend.createUserEducation(this.userEducationFormData).subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              this.dataSource.setData(this.userDetails);
              this.userDetails.education.unshift(response.data);
            } else {
              this.appMaterialModule.showAlertToaster(
                AlertType.error,
                response.message,
                3
              );
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isUserEducationFormCollapsed = true;
            this.userEducationFormData = new Education();
          },
          complete: () => {
            this.isLoading = false;
            this.isUserEducationFormCollapsed = true;
            this.userEducationFormData = new Education();
            console.log('on complete createUserEducation');
          },
        })
      );
    }
  }

  public onSaveUserEmployment(): void {
    this.isLoading = true;
    console.log('isEdited ' + this.isEdited);
    if (this.isEdited) {
      this.observers.push(
        this.backend
          .updateUserEmployment(this.userEmploymentFormData)
          .subscribe({
            next: (response: any) => {
              var temp = this.userDetails.employment.find((item) => {
                return item.id == this.userEmploymentFormData.id;
              });
              this.userDetails.employment.splice(
                this.userDetails.employment.indexOf(temp),
                1
              );
              this.userDetails.employment.unshift(response.data);
            },
            error: (err: any) => {
              if (err.error instanceof Error) {
                // A client-side or network error occurred.
                console.log('An error occurred:', err.error.message);
              } else {
                // Backend returns unsuccessful response codes such as 404, 500 etc.
                console.log('Backend returned status code: ', err.status);
                console.log('Response body:', err.error);
              }
              this.isLoading = false;
              this.isEdited = false;
              this.userEmploymentFormData = new Employment();
            },
            complete: () => {
              this.isLoading = false;
              this.isUserEmploymentFormCollapsed = true;
              this.isEdited = false;
              this.userEmploymentFormData = new Employment();
              console.log('on complete updateUserEmployment');
            },
          })
      );
    } else {
      this.userEmploymentFormData.user = this.authUser.id;
      console.log(
        'userEmploymentFormData ' + JSON.stringify(this.userEmploymentFormData)
      );
      this.observers.push(
        this.backend
          .createUserEmployment(this.userEmploymentFormData)
          .subscribe({
            next: (response: any) => {
              this.dataSource.setData(this.userDetails);
              this.userDetails.employment.unshift(response.data);
            },
            error: (err: any) => {
              if (err.error instanceof Error) {
                // A client-side or network error occurred.
                console.log('An error occurred:', err.error.message);
              } else {
                // Backend returns unsuccessful response codes such as 404, 500 etc.
                console.log('Backend returned status code: ', err.status);
                console.log('Response body:', err.error);
              }
              this.isLoading = false;
              this.isUserEmploymentFormCollapsed = true;
              this.userEmploymentFormData = new Employment();
            },
            complete: () => {
              this.isLoading = false;
              this.isUserEmploymentFormCollapsed = true;
              this.userEmploymentFormData = new Employment();
              console.log('on complete createUserEmployment');
            },
          })
      );
    }
  }

  public onSaveUserSocialMediaAccount(): void {
    this.isLoading = true;
    console.log('isEdited ' + this.isEdited);
    if (this.isEdited) {
      this.observers.push(
        this.backend
          .updateUserSocialMediaAccount(this.userSocialMediaAccountFormData)
          .subscribe({
            next: (response: any) => {
              var temp = this.userDetails.socialMediaAccounts.find((item) => {
                return item.id == this.userSocialMediaAccountFormData.id;
              });
              this.userDetails.socialMediaAccounts.splice(
                this.userDetails.socialMediaAccounts.indexOf(temp),
                1
              );
              this.userDetails.socialMediaAccounts.unshift(response.data);
            },
            error: (err: any) => {
              if (err.error instanceof Error) {
                // A client-side or network error occurred.
                console.log('An error occurred:', err.error.message);
              } else {
                // Backend returns unsuccessful response codes such as 404, 500 etc.
                console.log('Backend returned status code: ', err.status);
                console.log('Response body:', err.error);
              }
              this.isLoading = false;
              this.isUserSocialMediaAccountFormCollapsed = true;
              this.isEdited = false;
              this.userSocialMediaAccountFormData = new SocialMediaAccount();
            },
            complete: () => {
              this.isLoading = false;
              this.isUserSocialMediaAccountFormCollapsed = true;
              this.isEdited = false;
              this.userSocialMediaAccountFormData = new SocialMediaAccount();
              console.log('on complete updateUserSocialMediaAccount');
            },
          })
      );
    } else {
      this.userSocialMediaAccountFormData.user = this.authUser.id;
      console.log(
        'userSocialMediaAccountFormData ' +
          JSON.stringify(this.userSocialMediaAccountFormData)
      );
      this.observers.push(
        this.backend
          .createUserSocialMediaAccount(this.userSocialMediaAccountFormData)
          .subscribe({
            next: (response: any) => {
              this.dataSource.setData(this.userDetails);
              this.userDetails.socialMediaAccounts.unshift(response.data);
            },
            error: (err: any) => {
              if (err.error instanceof Error) {
                // A client-side or network error occurred.
                console.log('An error occurred:', err.error.message);
              } else {
                // Backend returns unsuccessful response codes such as 404, 500 etc.
                console.log('Backend returned status code: ', err.status);
                console.log('Response body:', err.error);
              }
              this.isLoading = false;
              this.isUserSocialMediaAccountFormCollapsed = true;
              this.userSocialMediaAccountFormData = new SocialMediaAccount();
            },
            complete: () => {
              this.isLoading = false;
              this.isUserSocialMediaAccountFormCollapsed = true;
              this.userSocialMediaAccountFormData = new SocialMediaAccount();
              console.log('on complete createUserSocialMediaAccount');
            },
          })
      );
    }
  }

  public editEducation(edu, isEdited) {
    this.userEducationFormData = edu;
    this.addOrEditText = 'Edit';
    console.log('edited ' + JSON.stringify(edu));
    if (isEdited) {
      //call api to save editted
      this.addOrEditText = 'Add';
    }
  }

  public editSocialMediaAccount(media, isEdited) {
    this.socialMediaAccount = media;
    this.addOrEditText = 'Edit';
    console.log('edited ' + JSON.stringify(media));
    if (isEdited) {
      //call api to save editted
      this.addOrEditText = 'Add';
    }
  }
  public openFilePickerDialog() {
    this.appMaterialModule
      .openDialog(ImageCropperDialogComponent, {
        width: '400px',
        title: 'File Upload',
        message: 'File size less than 20Kb',
        data: {
          fileType: FileType.image,
          overrideFilename:true,
          aspectRatio:1/1,
          imageQuality:90,
        },
      }).pipe(take(1))
      .subscribe((result) => {
        if (result.button === DialogButton.upload) {
          console.log('result.files.length : '+result.files.length)
          if (result.files.length > 0) {
            result.files.forEach((element) => {
              console.log(JSON.stringify(element))
              this.authUser.image = element.path
              this.croppedImage = element.path;

              this.onSaveProfileImage();
            });
          }
        }
      });
  }

  public onSaveProfileImage(): void {
    if (this.croppedImage.length > 20) {
      this.isLoading = true;
        this.backend
          .updateUserProfileImage({
            userId: this.authUser.id,
            image: this.croppedImage,
          })
          .subscribe({
            next: (data: any) => {
              this.authUser.image = this.croppedImage;
              this.localAuthService.setUser(this.authUser);
            },
            error: (err: any) => {
              if (err.error instanceof Error) {
                // A client-side or network error occurred.
                console.log('An error occurred:', err.error.message);
              } else {
                // Backend returns unsuccessful response codes such as 404, 500 etc.
                console.log('Backend returned status code: ', err.status);
                console.log('Response body:', err.error);
              }
              this.isLoading = false;
            },
            complete: () => {
              console.log('on complete updateUserProfileImage');
            },
          });
    }
  }

  public onConfirmPassword(): boolean {
    if (
      this.userPasswordFormData.newpassword !==
      this.userPasswordFormData.confirmPassword
    ) {
      this.invalidConfirmPassword.nativeElement.style.display = 'block';
      return false;
    }
    return true;
  }

  public chechIfUsernameExist(): void {
    //check if username exist
    const t = this;
    this.usernameCheckProgress.nativeElement.style.display = 'block';
    this.observers.push(
      this.backend
        .chechIfUsernameExist({ username: this.userAccountFormData.username })
        .subscribe({
          next: (data: any) => {
            console.log(data);
            if (data.code !== '000') {
              t.usernameExistError.nativeElement.style.display = 'block';
              t.userAccountFormData.username = '';
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            t.usernameCheckProgress.nativeElement.style.display = 'none';
          },
          complete: () => {
            t.usernameCheckProgress.nativeElement.style.display = 'none';
            console.log('on complete chechIfUsernameExist');
          },
        })
    );
  }

  chechIfEmailExist(): void {
    //check if username exist
    const t = this;
    this.usernameCheckProgress.nativeElement.style.display = 'block';
    this.observers.push(
      this.backend
        .chechIfUsernameExist({ username: this.userAccountFormData.email })
        .subscribe({
          next: (data: any) => {
            console.log(data);
            if (data.code !== '000') {
              t.emailExistError.nativeElement.style.display = 'block';
              t.userAccountFormData.email = '';
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            t.usernameCheckProgress.nativeElement.style.display = 'none';
          },
          complete: () => {
            t.usernameCheckProgress.nativeElement.style.display = 'none';
            console.log('on complete chechIfUsernameExist');
          },
        })
    );
  }

  updateUserAccount(): void {
    this.isLoading = true;
    this.observers.push(
      this.backend
        .updateUserAccount({
          userId: this.authUser.id,
          username: this.userAccountFormData.username,
          email: this.userAccountFormData.email,
        })
        .subscribe({
          next: (response: any) => {
            this.authUser.username = response.username;
            this.authUser.email = response.email;
            this.authUser.username = response.username;
            this.authUser.email = response.email;
            this.localAuthService.setUser(this.authUser);
            this.dataSource.setData(this.authUser);
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
          },
          complete: () => {
            this.isUserAccountFormCollapsed = true;
            this.isLoading = false;
            console.log('on complete updateUserAccount');
          },
        })
    );
  }

  updateUserProfile(): void {
    this.isLoading = true;
    this.userProfileFormData.user = this.authUser.id;
    this.observers.push(
      this.backend.updateUserProfile(this.userProfileFormData).subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.userDetails = this.userProfileFormData;
            this.authUser.fullname =
              this.userProfileFormData.firstname +
              ' ' +
              this.userProfileFormData.lastname;
            this.localAuthService.setUser(this.authUser);
            this.dataSource.setData(this.userDetails);
            this.appMaterialModule
              .showAlertToaster(
                AlertType.success,
                'Profile information updated successfully',
                3
              )
              .subscribe();
          } else {
            this.appMaterialModule
              .showAlertToaster(AlertType.error, response.message, 3)
              .subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isUserProfileFormCollapsed = true;
          this.isLoading = false;
          console.log('on complete updateUserProfile');
        },
      })
    );
  }

  updateUserContact(): void {
    this.isLoading = true;
    this.userContactFormData.user = this.authUser.id;
    console.log(
      'userContactFormData ' + JSON.stringify(this.userContactFormData)
    );
    this.backend
      .updateUserContact(this.userContactFormData)
      .pipe(take(1))
      .subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.userDetails.contact = this.userContactFormData;
            this.localAuthService.setUser(this.authUser);
            this.dataSource.setData(this.userDetails);
            this.appMaterialModule
              .showAlertToaster(
                AlertType.success,
                'Profile information updated successfully',
                3
              )
              .subscribe();
          } else {
            this.appMaterialModule
              .showAlertToaster(AlertType.error, response.message, 3)
              .subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
        },
        complete: () => {
          this.isUserContactFormCollapsed = true;
          this.isLoading = false;
          console.log('on complete updateUserContact');
        },
      });
  }

  updateUserPassowrd(): void {
    if (!this.onConfirmPassword()) {
      return;
    }
    this.isLoading = true;
    this.observers.push(
      this.backend
        .changeUserPassword({
          userId: this.authUser.id,
          password: this.userPasswordFormData.password,
          newpassword: this.userPasswordFormData.newpassword,
        })
        .subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              //log user out
              this.localAuthService.logout();
              this.router.navigate(['login'], {
                queryParams: {},
                skipLocationChange: false,
              });
              this.appMaterialModule
                .showAlertToaster(
                  AlertType.success,
                  'Password changed successful. Please login with your new password',
                  3
                )
                .subscribe();
            } else {
              this.appMaterialModule
                .showAlertToaster(AlertType.error, response.message, 3)
                .subscribe();
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
            this.isUserPasswordFormCollapsed = true;
            console.log('on complete updateUserPassowrd');
          },
        })
    );
  }

  onResetPassword() {
    this.isLoading = true;
    console.log(
      'send an email to ' + this.authUser.email + ' to reset password'
    );
    this.observers.push(
      this.backend
        .createPasswordReset({ email: this.userAccountFormData.email })
        .subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              //send email
              //this.modalService.dismissAll();
              this.sendPasswordResetMail({
                email: this.userAccountFormData.email,
                otp: response.data.otp,
              });
            } else {
              this.appMaterialModule
                .showAlertToaster(AlertType.error, response.message, 3)
                .subscribe();
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isUserPasswordFormCollapsed = true;
          },
          complete: () => {
            //this.isLoading = false;
            this.isUserPasswordFormCollapsed = true;
            console.log('on complete createPasswordReset');
          },
        })
    );
  }

  sendPasswordResetMail(data: any): void {
    this.observers.push(
      this.backend.sendPasswordResetMail(data).subscribe({
        next: (response: any) => {
          console.log(JSON.stringify(response));
          if (response.code === '000') {
            this.appMaterialModule
              .showAlertToaster(
                AlertType.info,
                'Please check your email for the link to reset your password.',
                3
              )
              .subscribe();
          } else {
            this.appMaterialModule
              .showAlertToaster(AlertType.error, response.message, 3)
              .subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        },
        complete: () => {
          this.isLoading = false;
          this.isUserPasswordFormCollapsed = true;
          console.log('on complete sendPasswordReset');
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.observers.forEach((observer) => {
      observer.unsubscribe();
    });
  }

  mentoringAreaGroups: mentoringAreaGroup[] = [
    {
      name: 'Career',
      mentoringArea: [
        { value: 'Banking', viewValue: 'Banking' },
        { value: 'Telecommunications', viewValue: 'Telecommunications' },
        { value: 'Technology', viewValue: 'Technology' },
        { value: 'Medical Sciences', viewValue: 'Medical Sciences' },
        { value: 'Public Services', viewValue: 'Public Services' },
        { value: 'Retail', viewValue: 'Retail' },
        { value: 'Financial Services', viewValue: 'Financial Services' },
        { value: 'Consultancy', viewValue: 'Consultancy' },
        { value: 'Social Services', viewValue: 'Social Services' },
        { value: 'Engineering', viewValue: 'Engineering' },
        { value: 'Oil and Gas', viewValue: 'Oil and Gas' },
      ],
    },
    {
      name: 'Business',
      mentoringArea: [
        { value: 'SME', viewValue: 'SME' },
        { value: 'Fashion Designing', viewValue: 'Fashion Designing' },
        { value: 'Video/Photography ', viewValue: 'Video/Photography ' },
        {
          value: 'Journalism /Online Publishing/Blogging',
          viewValue: 'Journalism /Online Publishing/Blogging',
        },
        {
          value: 'IT Coding/Webdesigning',
          viewValue: 'IT Coding/Webdesigning',
        },
        {
          value: 'Financial Markets Trading',
          viewValue: 'Financial Markets Trading',
        },
        {
          value: 'Trade - Importing/Exporting ',
          viewValue: 'Trade - Importing/Exporting ',
        },
        { value: 'Plumbing', viewValue: 'Plumbing' },
        {
          value: 'Contracting/Purchasing/Supply chain',
          viewValue: 'Contracting/Purchasing/Supply chain',
        },
      ],
    },
    {
      name: 'Education or Training',
      disabled: false,
      mentoringArea: [
        {
          value: 'Ordinary National Diploma',
          viewValue: 'Ordinary National Diploma',
        },
        {
          value: 'Higher National Diploma',
          viewValue: 'Higher National Diploma',
        },
        {
          value: 'University Postgraduate',
          viewValue: 'University Postgraduate',
        },
        {
          value: 'University Undergraduate',
          viewValue: 'University Undergraduate',
        },
        { value: 'Specialist Education', viewValue: 'Specialist Education' },
        {
          value: 'Fashion Designing Training',
          viewValue: 'Fashion Designing Training',
        },
        {
          value: 'Video/Photography Training',
          viewValue: 'Video/Photography Training',
        },
        { value: 'Journalism Training', viewValue: 'Journalism Training' },
        {
          value: 'IT Coding/Webdesigning/Database Training',
          viewValue: 'IT Coding/Webdesigning/Database Training',
        },
      ],
    },
    {
      name: 'Family',
      mentoringArea: [
        { value: 'New Relationship', viewValue: 'New Relationship' },
        { value: 'Marriage', viewValue: 'Marriage' },
        { value: 'Journalism Training', viewValue: 'Journalism Training' },
        { value: 'Parenting', viewValue: 'Parenting' },
      ],
    },
  ];

  public onUpdateMentor() {
    this.isLoading = true;
    let formData = this.mentorUpdateFormGroup.value;
    this.backend.updateMentor(formData).subscribe({
      next: (response) => {
        this.isLoading = false;
        if (response.code == '000') {
          this.appMaterialModule
            .showAlertToaster(
              AlertType.success,
              'Successfully updated mentoring details.',
              3
            )
            .subscribe();
        }
      },
    });
  }

  public signout(): void {
    if (this.localAuthService.logout()) {
      this.localAuthService
        .signOut()
        .then(
          (onfulfilled) => {
            console.log('onfulfilled... ' + onfulfilled);
          },
          (onrejected) => {
            console.log('onrejected... ' + onrejected);
          }
        )
        .catch((onrejected) => {
          console.log('catch onrejected... ' + onrejected);
        })
        .finally(() => {
          console.log('onfinally...');
          this.router.navigate(['/login']);
        });
    }
  }
}

interface MentorUpdate {
  mentoringArea: string;
  industry: string;
  preferredModeOfCommunication: [];
}

interface mentoringArea {
  value: string;
  viewValue: string;
}

interface mentoringAreaGroup {
  disabled?: boolean;
  name: string;
  mentoringArea: mentoringArea[];
}
