export class AuthUser {

    public isAuthenticated: boolean = false;
    public id: string;
    public email:string;
    public image: string;
    public fullname: string;
    public provider : string;
    public membershipId:string;
    public role:string;

    constructor(){
        this.id='';
        this.email='';
        this.image='';
        this.fullname='';
        this.provider='';
        this.membershipId='';
        this.role='';
    }

}
