import { Injectable } from '@angular/core';
import { Questionnaire, Settings } from '../models/questionnaire';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { QuestionPage } from '../models/question-page';
import { Question, Logic } from '../models/question';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireFormService {

  constructor(private formBuilder: FormBuilder) { }

  public buildForm(questionnaire: Questionnaire):FormGroup{

    let tempQ = []
    questionnaire.pages.forEach(element=>{
      tempQ.push(this.createPageItem(element));
    });
   return this.formBuilder.group({
      id: questionnaire.id,
      createdAt: questionnaire.createdAt,
      createdBy: questionnaire.createdBy,
      type: questionnaire.type,
      title: [questionnaire.title, Validators.required],
      footer: this.formBuilder.group({
        title: [questionnaire.footer.title, Validators.required],
        description: [questionnaire.footer.description, Validators.required],
      }),
      settings: this.createQuestionnaireSettings(questionnaire.settings),
      pages: this.formBuilder.array(tempQ)
    });
  }

  createQuestionnaireSettings(settings: Settings): FormGroup {
    return this.formBuilder.group(settings)
  }

  createPageItem(pq: QuestionPage): FormGroup {
    let tempQ = []
    pq.questions.forEach(element=>{
      tempQ.push(this.createQuestionItem(element));
    });
    return this.formBuilder.group({
      title: pq.title,
      description: pq.description,
      questions: this.formBuilder.array(tempQ)
    });
  }

  createQuestionItem(question: Question): FormGroup {
    if (question.type === '01') {
      question.answer = this.getAnsweresAsFormArray(question.options);
    } else if (question.type === '00') {
      if(question.logic){
      let tempL = [];
      question.logic.forEach(logic => {
        tempL.push(this.createLogicItem(logic));
      });
      question.logic = this.formBuilder.array(tempL);
    }else{
      question.logic = this.addLogicToAnswer(question.options);
    }
  }
    return this.formBuilder.group(question);
  }

  createLogicItem(logic: Logic): FormGroup {
    return this.formBuilder.group(logic);
  }

  public getAnsweresAsList(options: string): Array<string> {
    let temp = options.split('\n');
    return temp;
  }

  public addLogicToAnswer(options: string): FormArray {
    let temp = this.getAnsweresAsList(options);
    let tempInput = [];

    temp.forEach(t => {
      var fg = this.formBuilder.group({
        answer: new FormControl(t),
        nextIndex: new FormControl(''),
        operator: new FormControl(''),
        valueToCompare: new FormControl(t)
      });
      tempInput.push(fg);
    })
    return new FormArray(tempInput);
  }

  public getAnsweresAsFormArray(options: string): FormArray {
    let temp = options.split('\n');
    let tempInput = [];
    temp.forEach(t => {
      var k = {
        key: t,
        value: t,
        selected: false
      };
      tempInput.push(k);
    });
    const arr = tempInput.map(obj => {
      return new FormControl(obj.selected || false);
    });
    return new FormArray(arr);
  }
}
