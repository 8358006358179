<div class="bio bg-white p-4 rounded animate__animated animate__fadeIn">
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="title-text">Contact information</h4>
            <div (click)="togglePage()">
                <i style="font-size: 24px;" class="fa fa-pencil-square-o"></i>
            </div>
        </div>
        <div class="row" *ngIf="!page">
            <div class="col">
                <p class="subtitle-text">Primary phone number</p>
                <p class="title-text">
                    {{contactInfo.primaryPhonenumber}}</p>
                <mat-divider></mat-divider>

                <p class="subtitle-text">Secondary phone number</p>
                <p class="title-text">
                    {{contactInfo.alternatePhonenumber}}</p>
                <mat-divider></mat-divider>

                <p class="subtitle-text">Work phone number</p>
                <p class="title-text">
                    {{contactInfo.workPhonenumber}}</p>
                <mat-divider></mat-divider>

                <p class="subtitle-text">Personal Email</p>
                <p class="title-text">
                    {{contactInfo.personalEmail}}</p>
                <!--<mat-divider></mat-divider> -->
            </div>
            <div class="col">
                <p class="subtitle-text">Work Email</p>
                <p class="title-text">
                    {{contactInfo.workEmail}}</p>
                <mat-divider></mat-divider>

                <p class="subtitle-text">Current Location
                </p>
                <p class="title-text">
                    {{contactInfo.currentLocation}}</p>
                <mat-divider></mat-divider>

                <p class="subtitle-text">Residential Address</p>
                <p class="title-text">
                    {{contactInfo.residentialAddress}}</p>
                <mat-divider></mat-divider>

                <p class="subtitle-text">Workplace Address</p>
                <address class="title-text">
                    {{contactInfo.workplaceAddress}}
                </address>
                <!--<mat-divider></mat-divider> -->
            </div>
        </div>

        <div *ngIf="page">
            <form [formGroup]="contactInfoFormGroup" name="contactInfoFormGroup"
                id="contactInfoFormGroup" #userContactForm="ngForm" (ngSubmit)="contactInfoFormGroup.valid">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="primaryPhonenumber">Primary phone number</p>
                        <input formControlName="primaryPhonenumber" autocomplete="off" id="primaryPhonenumber"
                            type="text" class="form-control" id="primaryPhonenumber" name="primaryPhonenumber"
                            minlength="3"
                            [ngClass]="{ 'is-invalid': userContactForm.submitted && contactInfoFormGroup.get('primaryPhonenumber').hasError('required')}"
                            aria-describedby="primaryPhonenumberHint" autocomplete="off">
                        <div *ngIf="userContactForm.submitted && contactInfoFormGroup.get('primaryPhonenumber').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="primaryPhonenumber.errors.primaryPhonenumber">
                                Please provide a primary phone number</small>
                        </div>
                    </div>

                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="alternatePhonenumber">Alternate phone number</p>
                        <input formControlName="alternatePhonenumber" id="alternatePhonenumber" type="text"
                            class="form-control" id="alternatePhonenumber" name="alternatePhonenumber"
                            [ngClass]="{ 'is-invalid': userContactForm.submitted && contactInfoFormGroup.get('alternatePhonenumber').hasError('required')}"
                            aria-describedby="alternatePhonenumberHint" minlength="3" autocomplete="off">
                        <div *ngIf="userContactForm.submitted && contactInfoFormGroup.get('alternatePhonenumber').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="alternatePhonenumber.errors.alternatePhonenumber">Please
                                provide a
                                valid alternate phone number</small>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="workPhonenumber">Work phone number</p>
                        <input formControlName="workPhonenumber" type="text" class="form-control" id="workPhonenumber"
                            name="workPhonenumber" minlength="3"
                            [ngClass]="{ 'is-invalid': userContactForm.submitted && contactInfoFormGroup.get('alternatePhonenumber').hasError('required')}"
                            aria-describedby="workPhonenumberHint" autocomplete="off">
                        <div *ngIf="userContactForm.submitted && contactInfoFormGroup.get('alternatePhonenumber').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="workPhonenumber.errors.workPhonenumber">
                                Please provide a work phone number</small>
                        </div>
                    </div>

                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="currentLocation">Current Location</p>
                        <input formControlName="currentLocation" type="text" class="form-control" id="currentLocation"
                            name="currentLocation"
                            [ngClass]="{ 'is-invalid': userContactForm.submitted && contactInfoFormGroup.get('currentLocation').hasError('required') }"
                            aria-describedby="currentLocationHint" minlength="3" autocomplete="off">
                        <div *ngIf="userContactForm.submitted && contactInfoFormGroup.get('currentLocation').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="currentLocation.errors.currentLocation">Please provide a
                                valid location</small>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="personalEmail">Personal email</p>
                        <input formControlName="personalEmail" type="email" class="form-control" id="personalEmail"
                            name="personalEmail" minlength="3"
                            [ngClass]="{ 'is-invalid': userContactForm.submitted && contactInfoFormGroup.get('personalEmail').hasError('required') }"
                            aria-describedby="personalEmailHint" autocomplete="off">
                        <div *ngIf="userContactForm.submitted && contactInfoFormGroup.get('personalEmail').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="personalEmail.errors.personalEmail">
                                Please provide a personal email</small>
                        </div>
                    </div>

                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="workEmail">Work email</p>
                        <input formControlName="workEmail" type="email" class="form-control" id="workEmail"
                            name="workEmail"
                            [ngClass]="{ 'is-invalid': userContactForm.submitted && contactInfoFormGroup.get('workEmail').hasError('required') }"
                            aria-describedby="workEmailHint" minlength="3" autocomplete="off">
                        <div *ngIf="userContactForm.submitted && contactInfoFormGroup.get('workEmail').hasError('required')"
                            class="invalid-feedback animated bounceIn">
                            <small *ngIf="workEmail.errors.workEmail">Please provide a
                                valid work email</small>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="residentialAddress">Residential address</p>
                        <textarea formControlName="residentialAddress" class="form-control" id="residentialAddress"
                            rows="2" name="residentialAddress"></textarea>
                        <div *ngIf="userContactForm.submitted && contactInfoFormGroup.get('residentialAddress').hasError('required')"
                            class="invalid-feedback animated bounceIn" maxlength="255"
                            [ngClass]="{ 'is-invalid': userContactForm.submitted && contactInfoFormGroup.get('residentialAddress').hasError('required') }"
                            aria-describedby="residentialAddressHint">
                            <small *ngIf="residentialAddress.errors.maxlength" id="residentialAddressHint"
                                class="form-text">
                                Please provide a maximum length of 255 characters</small>
                            <small *ngIf="residentialAddress.errors.residentialAddress">Please provide a
                                valid
                                address</small>
                        </div>
                    </div>

                    <div class="form-group col-md-6">
                        <p class="subtitle-text" for="workplaceAddress">workplace address</p>
                        <textarea formControlName="workplaceAddress" class="form-control" id="workplaceAddress" rows="2"
                            name="workplaceAddress"></textarea>
                        <div *ngIf="userContactForm.submitted && contactInfoFormGroup.get('workplaceAddress').hasError('required')"
                            class="invalid-feedback animated bounceIn" maxlength="255"
                            [ngClass]="{ 'is-invalid': userContactForm.submitted && contactInfoFormGroup.get('workplaceAddress').hasError('required')}"
                            aria-describedby="workplaceAddressHint">
                            <small *ngIf="workplaceAddress.errors.maxlength" id="workplaceAddress" class="form-text">
                                Please provide a maximum length of 255 characters</small>
                            <small *ngIf="workplaceAddress.errors.workplaceAddress">Please provide a
                                valid
                                address</small>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12">
                        <button type="submit" style="margin-bottom: 10px;" (click)="updateUserContact()"
                            class="btn spz-button-green ml-auto">Save
                            <img #progressRipple [hidden]="!isLoading" alt="" src="/assets/img/ripple.gif" class=""
                                width="24">
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>