import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AppComponent } from 'src/app/app.component';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AlertType } from 'src/app/services/alert.service';
import { AuthUser } from 'src/app/models/auth-user';
import { User } from 'src/app/models/user';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DialogButton } from 'src/app/services/dialog.service';
import { SidebarService } from 'src/app/services/sidebar.service';

export class FormData {
  firstname: string = '';
  lastname: string = '';
  email: string = '';
  username: string = '';
  password: string = '';
  confirmPassword: string = '';
  provider: string = 'spz';
};


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnDestroy {

  public observers: Array<Subscription> = new Array<Subscription>();
  public formData: FormData = new FormData();
  public authUser: AuthUser;
  public signupFormGroup: FormGroup;
  public isLoading = false
  public userList$: Observable<User[]> = new Observable<User[]>();
  public userListBehaviour: BehaviorSubject<User[]> = new BehaviorSubject([]);
  public userList: User[] = [];
  public searchInputFormControl: FormControl;
  public sortAscOrDesc = false;

  @ViewChild('invalidConfirmPassword') invalidConfirmPassword: ElementRef;
  @ViewChild('usernameCheckProgress') usernameCheckProgress: ElementRef;
  @ViewChild('emailCheckProgress') emailCheckProgress: ElementRef;
  @ViewChild('usernameExistError') usernameExistError: ElementRef;
  @ViewChild('emailExistError') emailExistError: ElementRef;
  @ViewChild('progressRipple') progressRipple: ElementRef;

  constructor(private backend: BackendService,
    public localAuthService: LocalAuthService,
    public appComponent: AppComponent,
    private appMaterialComponent: AppMaterialDesignModule,
    public alertDialog: AlertDialogComponent,
    private formBuilder: FormBuilder,
    public sidebarService: SidebarService,
    public router: Router) {
    this.userList$ = this.userListBehaviour.asObservable();
  }
  ngOnInit() {
    this.authUser = new AuthUser();
    this.observers.push(
      this.localAuthService.getAuthUser().subscribe(data => {
        this.authUser = data;
      })
    );
    this.signupFormGroup = new FormGroup({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      username: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required]),
      provider: new FormControl('spz', [Validators.nullValidator])
    }, {
      validators: (group: FormGroup) => {
        const password = group.get('password').value;
        const confirmPassword = group.get('confirmPassword').value;
        return password === confirmPassword ? null : { notSame: true }
      }
    });

    this.getUserList();

    this.searchInputFormControl = new FormControl('');

    this.searchInputFormControl.valueChanges
      .pipe(
        debounceTime(450),
        distinctUntilChanged(),
      )
      .subscribe(term => {
        this._filter(term)
      })
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    let users = this.userList.filter((user) => {
      return (user.username.toLowerCase().includes(filterValue)
        || user.profile.firstname.toLowerCase().includes(filterValue)
        || user.profile.lastname.toLowerCase().includes(filterValue)
        || user.email.toLowerCase().includes(filterValue)
        || user.role?.toLowerCase().includes(filterValue)
        || user.membership?.title.toLowerCase().includes(filterValue))
    });
    this.userList$ = new BehaviorSubject(users);
  }

  sortUserList(field) {
    if (field == 'firstname') {
      if (this.sortAscOrDesc) {
        this.userList = this.userList.sort((a, b) => (a.profile.firstname > b.profile.firstname) ? 1 : -1);
        //(a.profile.firstname === a.profile.firstname) ? ((a.profile.lastname > b.profile.lastname) ? 1 : -1) : -1);
      } else {
        this.userList = this.userList.sort((a, b) => (a.profile.firstname < b.profile.firstname) ? 1 : -1);
      }
    }
    this.userList$ = new BehaviorSubject(this.userList);
    this.sortAscOrDesc = !this.sortAscOrDesc
  }

  public editUser(user) {
    this.signupFormGroup = new FormGroup({
      firstname: new FormControl(user.profile.firstname, [Validators.required]),
      lastname: new FormControl(user.profile.lastname, [Validators.required]),
      username: new FormControl(user.username, [Validators.required]),
      email: new FormControl(user.email, [Validators.required, Validators.email]),
      //password: new FormControl({ value: '', disabled: true }),
      //confirmPassword: new FormControl({ value: '', disabled: true }),
      provider: new FormControl('spz', [Validators.nullValidator])
    });
  }

  public makeUserAdmin(user) {
    this.observers.push(
      this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
        width: '500px',
        title: 'Confirm',
        message: 'Are you sure you want to give ADMIN rights to ' + user.fullname

      }).pipe(switchMap((results) => {
        if (results.button === DialogButton.ok) {
          this.appMaterialComponent.showProgressDialog('Updating... Please wait.')
          return this.backend.updateUserRole({ userId: user.id, role: 'ADMIN' });
        }
      })).subscribe({
        next: (response) => {
          if (response.code == '000') {
            this.observers.push(this.appMaterialComponent.showAlertToaster(AlertType.success, 'User role has been updated successfully', 3).subscribe())
            let index = this.userList.indexOf(user);
            user.role = 'ADMIN'
            this.userList[index] = user;
            this.userListBehaviour.next(this.userList)
          } else {
            this.observers.push(this.appMaterialComponent.showAlertToaster(AlertType.error, response.message, 3).subscribe())
          }
          this.isLoading = false
          this.appMaterialComponent.hideProgressDialog();
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false
          this.appMaterialComponent.hideProgressDialog();
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete makeUserAdmin');
          this.appMaterialComponent.hideProgressDialog();
        }
      })
    )
  }

  public changeUserRole(user, role: string) {
    this.observers.push(
      this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
        width: '400px',
        title: 'Confirm',
        message: 'Are you sure you want to give ' + role + ' rights to ' + user.fullname

      }).pipe(switchMap((results) => {
        if (results.button === DialogButton.ok) {
          this.appMaterialComponent.showProgressDialog('Updating... Please wait.')
          return this.backend.updateUserRole({ userId: user.id, role: role });
        }
      })).subscribe({
        next: (response) => {
          if (response.code == '000') {
            this.observers.push(this.appMaterialComponent.showAlertToaster(AlertType.success, 'User role has been updated successfully', 3).subscribe())
            let index = this.userList.indexOf(user);
            user.role = role
            this.userList[index] = user;
            this.userListBehaviour.next(this.userList)
          } else {
            this.observers.push(this.appMaterialComponent.showAlertToaster(AlertType.error, response.message, 3).subscribe())
          }
          this.isLoading = false
          this.appMaterialComponent.hideProgressDialog();
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false
          this.appMaterialComponent.hideProgressDialog();
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete changeUserRole');
          this.appMaterialComponent.hideProgressDialog();
        }
      })
    )
  }

  public changeUserMembership(user, membership: string) {
    this.observers.push(
      this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
        width: '400px',
        title: 'Confirm',
        message: 'Are you sure you want to give ' + membership + ' membership to ' + user.fullname

      }).pipe(switchMap((results) => {
        if (results.button === DialogButton.ok) {
          this.appMaterialComponent.showProgressDialog('Updating... Please wait.')
          return this.backend.updateUserMembership({ userId: user.id, membership: membership });
        }
      })).subscribe({
        next: (response) => {
          if (response.code == '000') {
            this.observers.push(this.appMaterialComponent.showAlertToaster(AlertType.success, 'User membership has been updated successfully', 3).subscribe())
            let index = this.userList.indexOf(user);
            user.profile.membership = response.data;
            this.userList[index] = user;
            this.userListBehaviour.next(this.userList)
          } else {
            this.observers.push(this.appMaterialComponent.showAlertToaster(AlertType.error, response.message, 3).subscribe())
          }
          this.isLoading = false
          this.appMaterialComponent.hideProgressDialog();
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false
          this.appMaterialComponent.hideProgressDialog();
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete changeUserRole');
          this.appMaterialComponent.hideProgressDialog();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }

  onSubmit(): void {
    if (!this.onConfirmPassword()) {
      this.formData.confirmPassword = '';
      return;
      //this.formData = new FormData();
    }
    //hash password
    this.showProgressRipple();
    this.observers.push(this.backend.createUser(this.formData).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          this.sendVerifyAccountMail({ email: this.authUser.email });
          var observer = this.appMaterialComponent.showAlertToaster(AlertType.success, 'Please check your email to verify your account', 3).subscribe();
          this.observers.push(observer);
        } else {
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        //this.hideProgressRipple();
      },
      complete: () => {
        //this.hideProgressRipple();
        this.formData = new FormData();
        console.log('on complete createUser');
      }
    }));
  }

  sendVerifyAccountMail(formData) {
    //this.observers.push(
    this.backend.sendVerifyAccountMail(formData).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
        } else {
          this.appMaterialComponent.showAlertToaster(AlertType.success, response.message, 3);
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete sendVerifyAccountMail');
      }
    })
    //);
  }

  onConfirmPassword(): boolean {
    if (this.formData.password !== this.formData.confirmPassword) {
      this.invalidConfirmPassword.nativeElement.style.display = 'block';
      return false;
    }
    return true;
  }

  showProgressRipple(): void {
    this.progressRipple.nativeElement.style.display = 'inline';
  }
  hideProgressRipple(): void {
    this.progressRipple.nativeElement.style.display = 'none';
  }

  chechIfUsernameExist(): void {
    //check if username exist
    const t = this;
    this.usernameCheckProgress.nativeElement.style.display = 'block';
    this.observers.push(this.backend.chechIfUsernameExist({ username: this.formData.username }).subscribe({
      next: (data: any) => {
        console.log(data);
        if (data.code !== '000') {
          t.usernameExistError.nativeElement.style.display = 'block';
          t.formData.username = '';
          t.signupFormGroup.patchValue({
            username: ''
          })
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        t.usernameCheckProgress.nativeElement.style.display = 'none';
      },
      complete: () => {
        t.usernameCheckProgress.nativeElement.style.display = 'none';
        console.log('on complete chechIfUsernameExist');
      }
    }));

  }

  chechIfEmailExist(): void {
    //check if username exist
    const t = this;
    this.emailCheckProgress.nativeElement.style.display = 'block';
    this.observers.push(this.backend.chechIfUsernameExist({ username: this.formData.email }).subscribe({
      next: (data: any) => {
        console.log(data);
        if (data.code !== '000') {
          t.emailExistError.nativeElement.style.display = 'block';
          t.formData.email = '';
          t.signupFormGroup.patchValue({
            username: ''
          })
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
        t.emailCheckProgress.nativeElement.style.display = 'none';
      },
      complete: () => {
        t.emailCheckProgress.nativeElement.style.display = 'none';
        console.log('on complete chechIfUsernameExist');
      }
    }));
  }

  public getUserList() {
    this.isLoading = true;
    this.observers.push(
      this.backend.getUsers().subscribe({
        next: (response) => {
          if (response.code == '000') {
            this.userList = response.data;
            this.userListBehaviour.next(response.data);
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete getUserList');
        }
      })
    )
  }

  public deleteUser(user) {
    this.observers.push(
      this.appMaterialComponent.openDialog(ConfirmDialogComponent, {
        width: '350px',
        title: 'Caution',
        message: 'Are you sure you want to delete this user?'

      }).subscribe({
        next: (result) => {
          if (result.button === DialogButton.ok) {

          }
        }
      })
    )
  }

}

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
