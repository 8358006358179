<div class="container-fluid bg-white pt-5 pb-5">
    <div class="container">
        <a class="navbar-brand" href="/startingpointzone/home">
            <img alt="temp image" src="/assets/img/spz-logo-dark.svg" class="img-fluid">
        </a>
        <div class="row mt-5">
            <div class="col-md-6">
                <div class="d-flex justify-content-start">
                    <a class="nav-link mr-5" routerLinkActive="spz-button-green" routerLink="/signup">Sign up</a>
                    <a class="nav-link ml-2" routerLinkActive="spz-button-green" routerLink="/login">Login</a>
                </div>

                <div class="mt-5">
                    <form [formGroup]="signupFormGroup" name="signupForm" #signupForm="ngForm"
                        (ngSubmit)="signupFormGroup.valid && onSubmit()">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <p class="subtitle-text" for="firstname">First name</p>
                                <input formControlName="firstname" type="text" class="form-control" id="firstname"
                                    name="firstname" required autofocus minlength="3"
                                    [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('firstname').hasError('firstname')  }"
                                    aria-describedby="firstnameHint">
                                <div *ngIf="signupForm.submitted && signupFormGroup.get('firstname').hasError('firstname')"
                                    class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="signupFormGroup.get('firstname').hasError('required')"
                                        id="firstnameHint" class="form-text">First name
                                        is
                                        required</small>
                                    <small *ngIf="signupFormGroup.get('firstname').hasError('minlength')"
                                        id="firstnameHint" class="form-text">Please
                                        provide a minimum length of 3 characters</small>
                                    <small *ngIf="signupFormGroup.get('firstname').hasError('firstname')">Please provide
                                        a valid first name</small>
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <p class="subtitle-text" for="lastname">Last name</p>
                                <input formControlName="lastname" type="text" class="form-control" id="lastname"
                                    name="lastname"
                                    [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('lastname').hasError('lastname') }"
                                    required lastname aria-describedby="lastnameHint" minlength="3">
                                <div *ngIf="signupForm.submitted && signupFormGroup.get('lastname').hasError('lastname')"
                                    class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="signupFormGroup.get('lastname').hasError('required')"
                                        id="lastnameHint" class="form-text">Last name is
                                        required</small>
                                    <small *ngIf="signupFormGroup.get('lastname').hasError('minlength')"
                                        id="lastnameHint" class="form-text">Please
                                        provide a minimum length of 3 characters</small>
                                    <small *ngIf="signupFormGroup.get('lastname').hasError('lastname')">Please provide a
                                        valid last name</small>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <p class="subtitle-text" for="email">Email address</p>
                            <div class="input-group">
                                <input formControlName="email" type="email" class="form-control"
                                    (blur)="chechIfEmailExist()" id="email" name="email"
                                    [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('email').hasError('email') }"
                                    required email aria-describedby="emailHint" autocomplete="off">
                                <div class="input-group-append">
                                    <div #emailCheckProgress class="input-group-text"
                                        style="background: transparent !important; display: none;">
                                        <img alt="temp image" src="/assets/img/ripple.gif" class="" width="20">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="signupForm.submitted && signupFormGroup.get('email').hasError('email')"
                                class="invalid-feedback animate__animated animate__bounceIn">
                                <small *ngIf="signupFormGroup.get('email').hasError('required')" id="emailHint"
                                    class="form-text">Email is
                                    required</small>
                                <!-- <div *ngIf="email.errors.required"></div> -->
                                <small *ngIf="signupFormGroup.get('email').hasError('email')">Email must be a valid
                                    email address</small>
                            </div>
                            <small #emailExistError style="display: none;"
                                class="invalid-feedback animate__animated animate__bounceIn form-text">this email has
                                been taken</small>
                        </div>
                        <div class="form-group">
                            <p class="subtitle-text" for="username">Choose a username</p>
                            <div class="input-group">
                                <input formControlName="username" (blur)="chechIfUsernameExist()" type="text"
                                    class="form-control" id="username" name="username"
                                    [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('username').hasError('username') }"
                                    required aria-describedby="emailHint" autocomplete="off">
                                <div class="input-group-append">
                                    <div #usernameCheckProgress class="input-group-text"
                                        style="background: transparent !important; display: none;">
                                        <img alt="temp image" src="/assets/img/ripple.gif" class="" width="20">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="signupForm.submitted && signupFormGroup.get('username').hasError('username')"
                                class="invalid-feedback animate__animated animate__bounceIn">
                                <small *ngIf="signupFormGroup.get('username').hasError('required')" id="usernameHint"
                                    class="form-text">username is
                                    required</small>
                                <!-- <div *ngIf="username.errors.required"></div> -->
                                <small *ngIf="signupFormGroup.get('username').hasError('username')">this username has
                                    been taken</small>
                            </div>
                            <small #usernameExistError style="display: none;"
                                class="invalid-feedback animate__animated animate__bounceIn form-text">this username has
                                been taken</small>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <p class="subtitle-text" for="password">Password</p>
                                <input formControlName="password" type="password" class="form-control"
                                    [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('password').hasError('password') }"
                                    id="password" autocomplete="off" name="password" required minlength="8"
                                    aria-describedby="passwordHint" placeholder="(8 characters or more)">
                                <div *ngIf="signupForm.submitted && signupFormGroup.get('password').hasError('password')"
                                    class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="signupFormGroup.get('password').hasError('required')"
                                        id="passwordHint" class="form-text">password is
                                        required</small>
                                    <small *ngIf="signupFormGroup.get('password').hasError('minlength')"
                                        id="passwordHint" class="form-text">Please
                                        provide a minimum length of 8 characters</small>
                                    <small *ngIf="signupFormGroup.get('password').hasError('password')">Please provide a
                                        valid password</small>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <p class="subtitle-text" for="confirmPassword">Confirm password</p>
                                <input formControlName="confirmPassword" type="password" class="form-control"
                                    [ngClass]="{ 'is-invalid': signupForm.submitted && signupFormGroup.get('confirmPassword').hasError('confirmPassword') }"
                                    autocomplete="off" id="confirmPassword" name="confirmPassword" required
                                    minlength="8">
                                <div *ngIf="signupForm.submitted && signupFormGroup.get('confirmPassword').hasError('confirmPassword')"
                                    class="invalid-feedback animate__animated animate__bounceIn">
                                    <small *ngIf="signupFormGroup.get('confirmPassword').hasError('required')"
                                        id="confirmPasswordHint" class="form-text">Please confirm password</small>
                                    <small *ngIf="signupFormGroup.get('confirmPassword').hasError('minlength')"
                                        id="confirmPasswordHint" class="form-text ">Please provide a minimum length of 8
                                        characters</small>
                                    <small
                                        *ngIf="signupFormGroup.get( 'confirmPassword').hasError( 'confirmPassword')">Please
                                        provide a valid
                                        password</small>
                                </div>
                                <small #invalidConfirmPassword style="display: none; "
                                    class="invalid-feedback animate__animated animate__bounceIn form-text ">Please be
                                    sure both passwords are
                                    the same</small>
                            </div>

                            <div class="form-group col ">
                                <small class="text-muted ">By sigining up, You agree to the spz User Agreement, Privacy
                                    Policy, and
                                    Cookie Policy.</small>
                            </div>
                        </div>
                        <button type="submit" style="margin-bottom: 10px;" [disabled]="!signupFormGroup.valid"
                            class="btn spz-button-green">
                            Sign up
                            <img #progressRipple style="display: none;" alt="" src="/assets/img/ripple.gif" class=""
                                width="24">
                        </button>
                        <p>Already have an account? <a class="link " href="/startingpointzone/login ">Sign in</a></p>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <img alt="temp image" src="/assets/img/spz-login-image.svg" class="img-fluid">
            </div>
        </div>
    </div>
</div>