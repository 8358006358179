import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { Mentor } from 'src/app/models/mentor';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { User } from 'src/app/models/user';
import { AlertType } from 'src/app/services/alert.service';

@Component({
  selector: 'app-mentor-details',
  templateUrl: './mentor.details.component.html',
  styleUrls: ['./mentor.details.component.css']
})
export class MentorDetailsComponent implements OnInit, OnDestroy {
  public mentor: Mentor;
  selectedMentorId: string;
  public authUser = null;
  public user: User;
  public isLoading = false;
  public observers: Array<Subscription> = new Array<Subscription>();
  constructor(
    private localAuthService: LocalAuthService,
    private router: Router,
    private backend: BackendService,
    private dataSource: DataService,
    private activateRoute: ActivatedRoute,
    public appMaterialModule: AppMaterialDesignModule) {
    this.selectedMentorId = '';
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }

  ngOnInit() {
    const observer = this.localAuthService.getAuthUser().subscribe(data => {
      this.authUser = data;
    });
    this.observers.push(observer);

    this.activateRoute.params.subscribe(params => {
      this.getUserDetails(this.authUser.id);
      this.selectedMentorId = params.id || '0';
      if (this.selectedMentorId != '') {
        this.getMentorById(this.selectedMentorId);
      }
    });

  }

  getUserDetails(formData: string) {
    this.observers.push(this.backend.getUserDetails3(formData).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          if (response.data.status !== 'verified') {
            //verify email
            this.appMaterialModule.showAlertToaster(AlertType.info, 'Verify your email. Please check your email to verify your account', 3).subscribe();
          }
          this.user = response.data;
          this.authUser.email = response.data.email;
          this.dataSource.setData(this.user);
          this.localAuthService.setUser(this.authUser);

        } else {

        }

      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
        }
      },
      complete: () => {
        console.log('on complete getUserDetails');
      }
    }));
  }


  public getMentorById(id: string) {
    this.isLoading = true
    this.observers.push(
      forkJoin([this.backend.getMentorById(id), this.backend.getMenteesByMentorId(id)])
        .subscribe({
          next: (response) => {
            if (response[0].code == '000') {
              this.mentor = response[0].data;
            }
             if (response[1].code == '000') {
              this.mentor.mentees = response[1].data;
            }
            console.log('mentees : '+JSON.stringify(this.mentor))
            this.isLoading = false;
          }, error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false
          },
          complete: () => {
            this.isLoading = false;
            console.log('on complete getMentors');
          }
        })
    )
  }

}
