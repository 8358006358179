<div class="container-fluid">
    <mat-tab-group dynamicHeight mat-align-tabs="start" animationDuration="500ms">
        <mat-tab label="Mentoring Requests">
            <div class="row mr-auto ml-auto">
                <div *ngFor="let request of mentoringRequestList$ | async" class="col-md-4 p-1">
                    <div class="media bg-white p-3" style="border-radius: 5px; max-height: 160px; min-height: 160px; overflow: hidden; border: 0.1px solid #e4e8f0;">
                        <img class="mr-3 align-self-start img-responsive img-circle profile-image-small" style="width: 50px; height:50px" src="{{request.user.image}}" alt="Generic placeholder image">
                        <div class="media-body">
                            <h4 class="mt-0 title-text">{{request.user.username}}</h4>
                            <p class="mt-0 mb-1">{{request.mentoringArea}}</p>
                            <p class="mb-0 subtitle-text" style="font-size: 12px;">{{request.objectiveOfMentorship | slice:0:80}}...
                            </p>
                        </div>
                        <!-- <mat-divider class="mt-2"></mat-divider> -->
                        <div class="d-flex justify-content-end">
                            <div *ngIf="request.user.role!='ADMIN'">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <a (click)="dataSource.setData(request)" class="link" routerLink="/admin/engagements/mentoring-requests/{{request.id}}" mat-menu-item>
                                        <mat-icon>details</mat-icon>
                                        <span>View Details</span>
                                    </a>
                                    <button (click)="WithdrawRequest(request)" mat-menu-item>
                                        <mat-icon>block</mat-icon>
                                        <span>Withdraw Request</span>
                                    </button>
                                </mat-menu>
                            </div>
                            <div *ngIf="request.user.role=='MENTOR'">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button (click)="editRequest(request)" mat-menu-item>
                                        <mat-icon>check</mat-icon>
                                        <span>Reject</span>
                                    </button>
                                    <button (click)="WithdrawRequest(request)" mat-menu-item>
                                        <mat-icon>block</mat-icon>
                                        <span>Reject</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 d-flex justify-content-center mt-3">
                    <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                    </mat-spinner>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Become A Mentor">
            <div class="row mr-auto ml-auto">
                <div *ngFor="let request of becomeAMentorRequestList$ | async" class="col-md-4 p-1">
                    <div class="media bg-white p-3" style="border-radius: 5px; max-height: 160px; min-height: 160px; overflow: hidden; border: 0.1px solid #e4e8f0;">
                        <img class="mr-3 align-self-start img-responsive img-circle profile-image-small" style="width: 50px; height:50px" src="{{request.user.image}}" alt="Generic placeholder image">
                        <div class="media-body">
                            <h4 class="mt-0 title-text">{{request.user.username}}</h4>
                            <p class="mt-0 mb-1">{{request.mentoringArea}}</p>
                            <p class="mb-0 subtitle-text" style="font-size: 12px;">{{request.objectiveOfMentorship | slice:0:80}}...
                            </p>
                        </div>
                        <!-- <mat-divider class="mt-2"></mat-divider> -->
                        <div class="d-flex justify-content-end">
                            <div *ngIf="request.user.role!='ADMIN'">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <a (click)="dataSource.setData(request)" class="link" routerLink="/admin/engagements/mentoring-requests/{{request.id}}" mat-menu-item>
                                        <mat-icon>details</mat-icon>
                                        <span>View Details</span>
                                    </a>
                                    <button (click)="WithdrawRequest(request)" mat-menu-item>
                                        <mat-icon>block</mat-icon>
                                        <span>Withdraw Request</span>
                                    </button>
                                </mat-menu>
                            </div>
                            <div *ngIf="request.user.role=='MENTOR'">
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button (click)="editRequest(request)" mat-menu-item>
                                        <mat-icon>check</mat-icon>
                                        <span>Reject</span>
                                    </button>
                                    <button (click)="WithdrawRequest(request)" mat-menu-item>
                                        <mat-icon>block</mat-icon>
                                        <span>Reject</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 d-flex justify-content-center mt-3">
                    <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35" [strokeWidth]="2">
                    </mat-spinner>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>