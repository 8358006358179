import { Mentor } from "./mentor";
import { User } from "./user";

export class MentorRequestData {
    public id: string;
    public user:User;
    public mentoringArea: string;
    public objectiveOfMentorship: string;
    public startDate:string;
    public endDate:string;
    public isFirstTimeMentee:string;
    public preferredModeOfCommunication:[]
    public status:string;
    public mentor:Mentor; 
    public createdAt:number;
    public updatedAt:number;
    public requestType:string;

    constructor(){
        this.user = new User();
    }
  }