import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AlertType } from 'src/app/services/alert.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { DialogButton } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-social-media-accounts-info',
  templateUrl: './social-media-accounts-info.component.html',
  styleUrls: ['./social-media-accounts-info.component.css'],
})
export class SocialMediaAccountsInfoComponent implements OnInit, OnDestroy {
  private unSubscriptioNotifier = new Subject();
  @Input('socialMediaAccountsInfo') socialMediaAccounts: any[];
  public socialMediaAccountInfoFormGroup: FormGroup;
  public page = false;
  public isLoading = false;
  private authUser = null;
  public isEdited = false;
  constructor(
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    public appMaterialModule: AppMaterialDesignModule
  ) {
    this.authUser = localAuthService.getUser();

    this.socialMediaAccountInfoFormGroup = new FormGroup({
      id: new FormControl(''),
      title: new FormControl(''),
      username: new FormControl(''),
      linkToAccount: new FormControl(''),
      user: new FormControl(''),
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next('');
    this.unSubscriptioNotifier.complete();
  }

  public addSocialMediaAccount() {
    this.togglePage()
    this.isEdited = false;
    this.socialMediaAccountInfoFormGroup = new FormGroup({
      id: new FormControl(''),
      title: new FormControl(''),
      username: new FormControl(''),
      linkToAccount: new FormControl(''),
      user: new FormControl(''),
    });
  }

  public editSocialMediaAccount(socialMediaAccount) {
    this.togglePage();
    this.isEdited = true;
    //console.log(JSON.stringify(socialMediaAccount));
    this.isEdited = true;
    this.socialMediaAccountInfoFormGroup.patchValue({
      id: socialMediaAccount.id,
      title: socialMediaAccount.title,
      username: socialMediaAccount.username,
      linkToAccount: socialMediaAccount.linkToAccount,
      user: this.authUser.id,
    });
  }

  public togglePage() {
    this.page = !this.page;
  }

  public onSaveUserSocialMediaAccount(): void {
    this.isLoading = true;
    let userSocialMediaAccountFormData =
      this.socialMediaAccountInfoFormGroup.value;
    console.log('isEdited ' + this.isEdited);
    if (this.isEdited) {
      this.backend
        .updateUserSocialMediaAccount(userSocialMediaAccountFormData)
        .pipe(take(1))
        .subscribe({
          next: (response: any) => {
            var temp = this.socialMediaAccounts.find((item) => {
              return item.id == userSocialMediaAccountFormData.id;
            });
            this.socialMediaAccounts.splice(
              this.socialMediaAccounts.indexOf(temp),
              1
            );
            this.socialMediaAccounts.unshift(response.data);
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.page = false;
            this.isEdited = false;
            this.socialMediaAccountInfoFormGroup.reset();
          },
          complete: () => {
            this.isLoading = false;
            this.page = false;
            this.isEdited = false;
            console.log('on complete updateUserSocialMediaAccount');
          },
        });
    } else {
      console.log(
        'userSocialMediaAccountFormData ' +
          JSON.stringify(userSocialMediaAccountFormData)
      );
      this.backend
        .createUserSocialMediaAccount(userSocialMediaAccountFormData)
        .pipe(take(1))
        .subscribe({
          next: (response: any) => {
            this.socialMediaAccounts.unshift(response.data);
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.page = false;
          },
          complete: () => {
            this.isLoading = false;
            this.page = false;
            console.log('on complete createUserSocialMediaAccount');
          },
        });
    }
  }

  deleteUserSocialMediaAccount(socialMedia: any): void {
    this.appMaterialModule
      .openDialog(ConfirmDialogComponent, {
        width: '400px',
        title: 'Please confirm',
        message: 'Are sure you want to delete! This cannot be undone.',
      })
      .pipe(
        switchMap((result) => {
          if (result.button == DialogButton.ok) {
            this.appMaterialModule.showProgressDialog('Deleting...');
            return this.backend.deleteUserSocialMediaAccount(socialMedia);
          }
        })
      )
      .subscribe({
        next: (response: any) => {
          this.dataSource.setData(response.data);
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
          if (response.code === '000') {
            var index = this.socialMediaAccounts.indexOf(socialMedia);
            this.socialMediaAccounts.splice(index, 1);
            this.appMaterialModule
              .showAlertToaster(AlertType.success, 'successfully deleted')
              .subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
        },
        complete: () => {
          this.isLoading = false;
          this.appMaterialModule.hideProgressDialog();
          console.log('on complete deleteUserEmployment');
        },
      });
  }
}
