<div style="background-color: #f9f9f9;">
    <div class="container-fluid bg-white mb-3">
        <div class="d-flex justify-content-between align-items-center pt-3">
            <h1>Mentoring sections and events</h1>
            <div class="d-flex justify-content-end align-items-center">
                <!-- <div class="nav-link" (click)="signout()">
            <i class="fa fa-sign-out" style="font-size: 18px !important; color: #00213D;"></i>
            <span>Sign out</span>
        </div> -->
                <div class="nav-link" [matMenuTriggerFor]="menu">
                    <img src="{{authUser.image}}" height="36" width="36"
                        style="border-radius: 50%; padding: 5px; object-fit: cover;">
                    <!-- <i class="fa fa-user-circle-o" style="font-size: 24px !important; color: #00213D;"></i> -->
                </div>
                <mat-menu #menu="matMenu" class="spz-profile-menu">
                    <div matRipple routerLink="/profile" class="media p-3">
                        <img src="{{authUser.image}}" class="align-self-center mr-2 profile-image-small"
                            alt="{{authUser.fullname}}">
                        <div class="media-body">
                            <h6 class="mt-0 mb-0">{{authUser.fullname}}</h6>
                            <p class="text-muted p-0 m-0" style="font-size: 14px;">View public profile</p>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div matRipple routerLink="/user/bookings" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-clock-o color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">Bookings</p>
                            <small class="text-muted p-0 m-0">View and manage all your bookings</small>
                        </div>
                    </div>
                    <div matRipple routerLink="/user/connections" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-users color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">My connections</p>
                            <small class="text-muted p-0 m-0">Manage your connections</small>
                        </div>
                    </div>
                    <div matRipple routerLink="/user/calendar" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-calendar-check-o color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">Calendar</p>
                            <small class="text-muted p-0 m-0">Setup your availability</small>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="p-3">
                        <div matRipple routerLink="/settings" class="d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-cog color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Settings</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>

                        <div matRipple class="d-flex justify-content-between align-items-center">
                            <div routerLink="/support" class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-life-ring color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Support</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>

                        <div matRipple class="d-flex justify-content-between align-items-center">
                            <div (click)="signout()" class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-sign-out color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Logout</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>
                    </div>
                    <!-- <button mat-menu-item>Item 1</button> -->

                    <!-- <button mat-menu-item>Item 2</button> -->
                </mat-menu>
            </div>
        </div>
        <!-- <hr> -->
    </div>
    <div class="container" style="background-color: #f9f9f9;">
        <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12">
                <div class="bg-white rounded p-2 mb-3">
                    <ul class="horizontal-list">
                        <li>
                            <a class="spz-button-outline-green" style="font-size: 14px;" routerLink="/events/add">Set up
                                an event</a>
                        </li>
                        <li>
                            <a class="rounded" [routerLink]="['/events']" [queryParams]="{category:'bootcamp'}"
                                routerLinkActive="active-link">Bootcamps</a>
                        </li>
                        <li>
                            <a class="rounded" [routerLink]="['/events']" [queryParams]="{category:'talkshow'}"
                                routerLinkActive="active-link">Talk shows</a>
                        </li>
                        <li>
                            <a class="rounded" [routerLink]="['/events']" [queryParams]="{category:'conference'}"
                                routerLinkActive="active-link">Conferences</a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="row"> -->
            <div class="col-md-8 col-xs-12">
                <div>
                    <p [hidden]="events.length  < 1" class="title-text">
                        Events You May Like</p>
                    <!-- <mat-divider></mat-divider> -->
                    <div class="row p-0 mt-2" style="margin-bottom: 5px;">
                        <div *ngFor="let event of events" class="col-md-4 mb-4">
                            <div class="event-card">
                                <img class="event-poster img-fluid rounded"
                                    style="height: 100%; width: 100%; object-fit: cover;"
                                    [src]="event.posterUrl || '/assets/img/img-placeholder.png'">
                                <div class="p-3">
                                    <a class="link" [routerLink]="['/events',event.id]">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="mt-2">
                                                <h6 class="mt-0 mb-0 text-capitalize title-text">{{event.title}}
                                                </h6>
                                                <p class="text-muted">{{event.description | truncateText:80}}</p>
                                                <!-- <p class="subtitle-text">{{event.startDateAndTime | date}}</p> -->
                                            </div>
                                            <div>
                                                <h6 class="title-text color-primary m-0">
                                                    {{event.startDateAndTime | date:'MMM'}}
                                                </h6>
                                                <h5 class="subtitle-text text-center m-0">
                                                    {{event.startDateAndTime | date:'d'}}</h5>
                                            </div>
                                        </div>
                                    </a>
                                    <mat-divider class="mb-1"></mat-divider>
                                    <!-- <pre>{{authUser | json}}</pre> -->
                                    <button class="btn btn-sm btn-outline-primary mt-2" (click)="interested(event)"><i
                                        [ngClass]="event.attendees?.includes(authUser.id) ? 'fa fa-star fa-fw mr-1' : 'fa fa-star-o fa-fw mr-1'"></i>Interested</button>
                                    <div class="d-flex justify-content-end">
                                        <div *ngIf="event.postedBy === authUser.id" style="position: relative;">
                                            <div class="horizontal-list"
                                                style="position: absolute; right: 0; bottom: 0;">
                                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <a class="link" routerLink="/events/edit/{{event.id}}"
                                                        mat-menu-item>
                                                        <mat-icon>edit</mat-icon>
                                                        <span>Edit</span>
                                                    </a>
                                                    <button (click)="deleteEvent(event)" mat-menu-item>
                                                        <mat-icon>delete</mat-icon>
                                                        <span>Delete</span>
                                                    </button>
                                                </mat-menu>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" *ngIf="events.length < 1" [hidden]="isLoading">
                    <p>No events yet. click <a routerLink="/events/add">here</a> to setup your event</p>
                </div>
                <div class="col-md-12 d-flex justify-content-center mt-3">
                    <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="25"
                        [strokeWidth]="2">
                    </mat-spinner>
                </div>
            </div>
            <div class="col-md-4 order-md-1">
                <div class="bg-white p-3 rounded">
                    <p class="title-text">
                        Find Events</p>
                    <!-- <mat-divider></mat-divider> -->
                    <div class="row">
                        <div class="col-md-6 mb-2 col-sm-6 col-xs-6">
                            <div style="padding: 10px;border-radius: 8px; background-color: #3578E5; color: white;">
                                <i class="fa fa-sun-o fa-2x mt-2"></i>
                                <p class="title-text mt-2" style="color: white !important;">Today</p>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2 col-sm-6 col-xs-6">
                            <div
                                style="padding: 10px;border-radius: 8px; background-color: rgb(231, 222, 53); color: white;">
                                <i class="fa fa-moon-o fa-2x mt-2"></i>
                                <p class="title-text mt-2" style="color: white !important;">Tomorrow</p>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2 col-sm-6 col-xs-6">
                            <div
                                style="padding: 10px;border-radius: 8px; background-color: rgb(201, 83, 171); color: white;">
                                <i class="fa fa-calendar fa-2x mt-2"></i>
                                <p class="title-text mt-2" style="color: white !important;">This Week</p>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2 col-sm-6 col-xs-6">
                            <div style="padding: 10px;border-radius: 8px; background-color:#d5d5d5; color: white;">
                                <i class="fa fa-calendar-o fa-2x mt-2"></i>
                                <p class="title-text mt-2" style="color: white !important;">Choose Date</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->

        </div>
    </div>
</div>