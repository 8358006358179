export class UploadFile {
    public name: string;
    public size: number;
    public id: string;
    public url: string;
    public path: string;
    public type: string;
    public file: File;

    constructor(name: string, type: string, size: number, file: File) {
        this.name = name;
        this.type = type;
        this.size = size;
        this.file = file;
        if (file)
            this.preview();
    }

    preview(): string {
        var reader = new FileReader();
        let temp: string;
        reader.onload = (event: any) => {
            temp = (event.target.result);
            this.path = temp;
            this.url = temp;
        }
        reader.readAsDataURL(this.file);
        return temp;
    }
}


