import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DialogService, DialogOptions } from 'src/app/services/dialog.service';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { BackendService } from 'src/app/services/backend.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { AlertService, AlertType } from 'src/app/services/alert.service';


@Component({
  selector: 'app-confirm-email-dialog',
  templateUrl: './confirm-email-dialog.component.html',
  styleUrls: ['./confirm-email-dialog.component.css']
})
export class ConfirmEmailDialogComponent implements OnInit, OnDestroy {

  public isLoading = false;
  public observers: Array<Subscription> = new Array<Subscription>();
  public comfirmEmailFormGroup: FormGroup;

  constructor(private backend: BackendService,
    public dialogRef: MatDialogRef<ConfirmEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions,
    private dialogService: DialogService,
    public alertService: AlertService,
    private appMaterialComponent: AppMaterialDesignModule,) {

      this.comfirmEmailFormGroup = new FormGroup({
        email:new FormControl('',[Validators.required])
      });
     }

  ngOnInit() {
  }

  public cancel(): void {
    this.isLoading = false;
    this.dialogRef.close();
  }

  public onResetPassword() {
    this.isLoading = true;
    console.log('send an email to ' + this.comfirmEmailFormGroup.value.email + ' to reset password');
      this.backend.createPasswordReset({ email: this.comfirmEmailFormGroup.value.email })
      .pipe(take(1))
      .subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            //send email
            this.sendPasswordResetMail({ email: this.comfirmEmailFormGroup.value.email, otp: response.data.otp });
          } else {
            this.appMaterialComponent.showAlertToaster(AlertType.warn, response.message, 3).subscribe();
          }
          this.isLoading = false;
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.dialogRef.close()
        },
        complete: () => {
          this.isLoading = false;
          this.dialogRef.close()
          console.log('on complete createPasswordReset');
        }
      })
  }

  sendPasswordResetMail(data: any): void {
      this.backend.sendPasswordResetMail(data)
      .pipe(take(1))
      .subscribe({
        next: (response: any) => {
          console.log(JSON.stringify(response));
          if (response.code === '000') {
            this.appMaterialComponent.showAlertToaster(AlertType.success, 'Please check your email for the link to reset your password.',3000).subscribe();
          } else {
            this.appMaterialComponent.showAlertToaster(AlertType.success, response.message, 3).subscribe();
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        },
        complete: () => {
          this.isLoading = false;
          //this.modalService.dismissAll();
          console.log('on complete sendPasswordReset');
        }
      })
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }
}
