import { Component, OnInit } from '@angular/core';
import { Goal } from 'src/app/models/goal';
import { Router } from '@angular/router';
import { BackendService } from 'src/app/services/backend.service';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { LocalAuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { ActionPlan } from 'src/app/models/action-plan';
import { Subscription } from 'rxjs';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';

@Component({
  selector: 'app-action-plan-details',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.css']
})
export class ActionPlanComponent implements OnInit {

  public goal: Goal = new Goal();
  public actionPlanFormData: ActionPlan = new ActionPlan();
  public actionPlans: Array<ActionPlan> = new Array<ActionPlan>();
  closeResult: string;
  observers = new Array<Subscription>();
  authUser = null;
  public isLoading: boolean = false;
  public isEdited = false;

  dateTimePickerOptions = {
    format: "YYYY-MM-DD h:mm:ss a",
    //format: "YYYY-MM-DD",
    //maxDate: moment(),
    //minDate: moment(),
    // ...
  };

  constructor(
    private router: Router,
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    private appMaterialDesignModule: AppMaterialDesignModule) { }

  ngOnInit() {
    const observer = this.localAuthService.getAuthUser().subscribe(data => {
      this.authUser = data;
    });
    this.observers.push(observer);
    this.dataSource.currentdata.subscribe(data => {
      this.goal = data;
    });
  }

  onCheckboxChanged(value: ActionPlan, event) {
    if (event.target.checked) {
      value.status = 'completed';
      value.dateOfExecution = new Date().toISOString();
    } else {
      value.status = '';
    }
    this.actionPlanFormData = value;
    this.isEdited = true;
    this.onSaveActionPlan();
  }

  onSaveActionPlan(): void {
    this.isLoading = true;
    if (this.isEdited) {
      this.observers.push(
        this.backend.updateActionPlan(this.actionPlanFormData).subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              var temp = this.actionPlans.find((item) => {
                return item.id == this.actionPlanFormData.id;
              });
              var index = this.actionPlans.indexOf(temp);
              //this.actionPlans.splice(this.actionPlans.indexOf(temp), 1);
              this.actionPlans.splice(index, 1);
              this.actionPlans.unshift(response.data);
            } else {
              //this.appComponent.showAlertToaster(AlertType.success, response.message, 10000);
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isEdited = false;
            this.actionPlanFormData = new ActionPlan();
            //this.modalService.dismissAll();
          },
          complete: () => {
            this.isLoading = false;
            this.isEdited = false;
            this.actionPlanFormData = new ActionPlan();
            
            console.log('on complete updateActionPlan');
          }
        })
      );

    } else {
      this.actionPlanFormData.user = this.authUser.id;
      this.actionPlanFormData.goal = this.goal.id;
      this.observers.push(
        this.backend.createActionPlan(this.actionPlanFormData).subscribe({
          next: (response: any) => {
            if (response.code === '000') {
              this.actionPlans.push(response.data);
            } else {
              //this.appComponent.showAlertToaster(AlertType.success, response.message, 10000);
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isEdited = false;
            this.actionPlanFormData = new ActionPlan();
            //this.modalService.dismissAll();
          },
          complete: () => {
            this.isLoading = false;
            this.isEdited = false;
            this.actionPlanFormData = new ActionPlan();
            //this.modalService.dismissAll();
            console.log('on complete createActionPlan');
          }
        })
      );
    }

  }

  // deleteActionPlan(goal: any): void {
  //   this.observers.push(this.confirmDialog.showDialog('Please confirm', 'Are sure you want to delete! This cannot be undone.')
  //     .subscribe((result) => {
  //       if (result.button === DialogButton.ok) {
  //         //confirm delete
  //         var observer1 = this.backend.deleteActionPlan(goal).subscribe({
  //           next: (response: any) => {
  //             if (response.code === '000') {
  //               var tempArr = this.actionPlans.filter(function (value, index, arr) {
  //                 return value.id !== goal.id
  //               });
  //               this.actionPlans = tempArr;
  //               this.confirmDialog.cancel();
  //               var observer2 = this.alertDialog.showDialog(AlertType.success, 'SUCCESS', 'successful.').subscribe(result => {

  //               });
  //               this.observers.push(observer1);
  //               this.observers.push(observer2);
  //             } else {
  //               this.appComponent.showAlertToaster(AlertType.success, response.message, 10000);
  //             }
  //           },
  //           error: (err: any) => {
  //             if (err.error instanceof Error) {
  //               // A client-side or network error occurred.
  //               console.log('An error occurred:', err.error.message);
  //             } else {
  //               // Backend returns unsuccessful response codes such as 404, 500 etc.
  //               console.log('Backend returned status code: ', err.status);
  //               console.log('Response body:', err.error);
  //             }
  //             this.isLoading = false;
  //             this.confirmDialog.cancel();
  //           },
  //           complete: () => {
  //             this.isLoading = false;
  //             this.confirmDialog.cancel();
  //             console.log('on complete deleteActionPlan');
  //           }
  //         });
  //       } else if (result.button === DialogButton.cancel) {

  //       } else {

  //       }
  //     }));
  // }



  ngOnDestroy(): void {
    this.observers.forEach((observer) => {
      observer.unsubscribe();
    });
  }
}
