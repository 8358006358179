import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AuthUser } from 'src/app/models/auth-user';
import { MentorRequestData } from 'src/app/models/mentor-request-data';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-mentoring-request',
  templateUrl: './mentoring-request.component.html',
  styleUrls: ['./mentoring-request.component.css']
})
export class MentoringRequestComponent implements OnInit, OnDestroy {

  public observers: Array<Subscription> = new Array<Subscription>();
  public formData: FormData = new FormData();
  public authUser: AuthUser;
  public isLoading = false
  public mentoringRequestList$: Observable<MentorRequestData[]> = new Observable<MentorRequestData[]>();
  public mentoringRequestListBehaviour: BehaviorSubject<MentorRequestData[]> = new BehaviorSubject([]);
  public mentorshipRequests: MentorRequestData[] = [];

  public becomeAMentorRequestList$: Observable<MentorRequestData[]> = new Observable<MentorRequestData[]>();
  public becomeAMentorRequestListBehaviour: BehaviorSubject<MentorRequestData[]> = new BehaviorSubject([]);
  public becomeAMentorRequestList: MentorRequestData[] = [];

  public searchInputFormControl: FormControl;
  public sortAscOrDesc = false;
  constructor(private localAuthService: LocalAuthService,
    private router: Router,
    private backend: BackendService,
    public sidebarService: SidebarService,
    public dataSource: DataService,
    public appMaterialModule: AppMaterialDesignModule) {

    this.mentoringRequestList$ = this.mentoringRequestListBehaviour.asObservable();
    this.becomeAMentorRequestList$ = this.becomeAMentorRequestListBehaviour.asObservable();
    
  }

  ngOnInit(): void {
    this.authUser = new AuthUser();
    this.observers.push(
      this.localAuthService.getAuthUser().subscribe(data => {
        this.authUser = data;
      })
    );

    this.getMentorshipRequests();
    this.searchInputFormControl = new FormControl('');

    this.searchInputFormControl.valueChanges
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
      )
      .subscribe(term => {
        this._filter(term)
      })
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    let users = this.mentorshipRequests.filter((request) => {
      return (request.user.username.toLowerCase().includes(filterValue))
    });
    this.mentoringRequestList$ = new BehaviorSubject(users);
  }

  public getMentorshipRequests() {
    this.isLoading = true
    let serviceCall = forkJoin([
      this.backend.getMentorshipRequests(),
      this.backend.getBecomeMentorRequests()
    ]).subscribe({
        next: (response: any) => {
          let m0 = response[0];
          let m1 = response[1];
          if (m0.code == '000') {
            this.mentorshipRequests = m0.data;
            this.mentoringRequestListBehaviour.next(m0.data)
          }

          if (m1.code == '000') {
            this.becomeAMentorRequestList = m1.data;
            this.becomeAMentorRequestListBehaviour.next(m1.data)
          }
        },
        error: (err: any) => {
          this.isLoading = false;
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete getMentorshipRequest');
        }
      });

    this.observers.push(serviceCall);
  }

  public assignMentor(request) {

  }


  public WithdrawRequest(request: MentorRequestData) {
    //this.appMaterialModule.
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }

}
