import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Inject } from '@angular/core';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { Inspiration } from 'src/app/models/inspiration';
import { Subscription, forkJoin, Observable, of } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DialogService, DialogButton, DialogOptions } from 'src/app/services/dialog.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { map, startWith, switchMap } from 'rxjs/operators';
import { AlertType } from 'src/app/services/alert.service';

@Component({
  selector: 'app-setup-inspirations',
  templateUrl: './setup-inspirations.component.html',
  styleUrls: ['./setup-inspirations.component.css']
})
export class SetupInspirationsComponent implements OnInit, OnDestroy {

  public inspirationFormData: Inspiration = new Inspiration();
  public inspirationalCategories:string[] = [];
  public inspirationalQuotes = new Array<Inspiration>();
  public observers: Array<Subscription> = new Array<Subscription>();
  public authUser = null;
  public isLoading = false;
  public inspirationFormGroup: FormGroup;
  public filteredCategories: Observable<string[]>;
  public isEdited: boolean = false;
  
  @ViewChild('progressRipple') progressRipple: ElementRef;

  constructor(private router: Router,
    public dialogRef: MatDialogRef<SetupInspirationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
    private backend: BackendService,
    private dialogService: DialogService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    private activateRoute: ActivatedRoute,
    private appMaterialDesignModule: AppMaterialDesignModule) {
    this.setupInspirationFormGroup(new Inspiration());
  }

  ngOnInit() {
    const observer = this.localAuthService.getAuthUser().subscribe(data => {
      this.authUser = data;
    });
    this.observers.push(observer);
    this.getInspirationalCategories();

    this.dataSource.currentdata.subscribe(data => {
      this.inspirationFormData = data?.inspirationQuote;
      this.isEdited = this.inspirationFormData.id != null
      this.setupInspirationFormGroup(this.inspirationFormData);
    });

    this.filteredCategories = this.inspirationFormGroup.get('category').valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.inspirationalCategories.filter((option) => {
      return option.toLowerCase().includes(filterValue)
    });
  }

  public setupInspirationFormGroup(inspiration: Inspiration) {
    this.inspirationFormGroup = new FormGroup({
      id: new FormControl(inspiration?.id, [Validators.nullValidator]),
      userId: new FormControl(inspiration?.userId, [Validators.nullValidator]),
      reference: new FormControl(inspiration?.reference, [Validators.nullValidator]),
      user: new FormControl(inspiration?.user, [Validators.nullValidator]),
      datePosted: new FormControl(inspiration?.datePosted, [Validators.required]),
      category: new FormControl(inspiration?.category, [Validators.required]),
      description: new FormControl(inspiration?.description, [Validators.required])
    })
  }

  ngAfterViewInit(): void {
  }

  public getInspirationalCategories() {
    this.observers.push(
      this.backend.getInspirationalCategories().subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.inspirationalCategories = response.data
            this.filteredCategories = this.inspirationFormGroup.get('category').valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
            console.log('filter:' + this.inspirationalCategories.length)
          }
        }, error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
        }, complete: () => {
          console.log('on complete inspirationalCategories');
        }
      })
    );
  }

  public cancel(): void {
    this.isLoading = false;
    this.dialogService.sendMessage({ text: 'cancel button clicked', button: DialogButton.cancel });
    this.dialogRef.close();
  }

  subscribeToFeed(): void {
    //
  }

  ngOnDestroy(): void {
    this.observers.forEach(observer => {
      observer.unsubscribe();
    });
  }

  onSubmitInspirationalQuote(): void {
    this.isLoading = true;
    this.inspirationFormData = { ...this.inspirationFormGroup.value }
    console.log('this.inspirationFormData : ' + JSON.stringify(this.inspirationFormData))
    if (!this.isEdited) {
      this.inspirationFormData.userId = this.authUser.id;
      this.observers.push(this.backend.createInspirationalQuote(this.inspirationFormData).subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            //console.log('response : ' + JSON.stringify(response))
            this.dialogService.sendMessage({ text: 'ok button clicked', button: DialogButton.ok, inspirationalQuote: response.data });
            this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.info, 'Inspirational quote saved successfully', 3).subscribe());
          } else {
            this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.info, 'Failed to save inspirational quote', 3).subscribe());
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.inspirationFormData = new Inspiration();
        },
        complete: () => {
          this.isLoading = false;
          this.inspirationFormData = new Inspiration();
          this.dialogRef.close();
          console.log('on complete createInspirationalQuote');
        }
      }));
    } else {
      this.observers.push(this.backend.updateInspirationalQuote(this.inspirationFormData).subscribe({
        next: (response: any) => {
          if (response.code === '000') {
            this.dialogService.sendMessage({ text: 'ok button clicked', button: DialogButton.ok, inspirationalQuote: response.data });
            this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.info, 'Inspirational quote saved successfully', 3).subscribe());
          } else {
            this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.info, 'Failed to save inspirational quote', 3).subscribe());
          }
        },
        error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }

          this.isLoading = false;
          this.inspirationFormData = new Inspiration();
        },
        complete: () => {
          this.isLoading = false;
          this.inspirationFormData = new Inspiration();
          this.dialogRef.close()
          console.log('on complete updateInspirationalQuote');
        }
      }));
    }
  }

  public getInspiration(id: string) {
    this.observers.push(
      this.backend.getInspirationalQuote(id).subscribe({
        next: (response) => {
          if (response.code == '000') {
            this.inspirationFormData = response.data;
            this.setupInspirationFormGroup(this.inspirationFormData);
          } else {
            this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.error, response.message, 3).subscribe());
          }
        }, error: (err: any) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', err.error.message);
          } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', err.status);
            console.log('Response body:', err.error);
          }
          this.isLoading = false;
          this.observers.push(this.appMaterialDesignModule.showAlertToaster(AlertType.error, err.error, 3).subscribe())
        },
        complete: () => {
          this.isLoading = false;
          console.log('on complete getInspirationalQuote');
        }
      })

    )
  }
}
