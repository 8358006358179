import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, GuardsCheckEnd } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { AlertType } from 'src/app/services/alert.service';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public isAuthenticated: boolean = false;
  public authUser = null;
  public contactUsFormGroup: FormGroup;
  public newsLetterFormGroup: FormGroup;
  public newsArticles: Observable<any>;
  public isLoadingArticles:boolean = false;
  public observers: Array<Subscription> = new Array<Subscription>();
  public isLoading = false;
  constructor(
    public appMaterialDesignModule: AppMaterialDesignModule,
    private backend: BackendService,
    public localAuthService: LocalAuthService,
    public router: Router
  ) {
    this.contactUsFormGroup = new FormGroup({
      fullname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', [
        Validators.required,
        Validators.minLength(60),
      ]),
    });

    this.newsLetterFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  ngOnDestroy(): void {
    this.observers.forEach((observer) => {
      observer.unsubscribe();
    });
  }

  public onSubscribeToNewsLetter() {
    this.isLoading = true;
    let newLetterFormValue = this.newsLetterFormGroup.value;
    console.log(JSON.stringify(newLetterFormValue));
    this.isLoading = false;
  }

  // onSubmit() {
  //   this.isLoading = true;
  //   let contactFormValue = this.contactUsFormGroup.value;
  //   let userMail = this.backend.sendMail({
  //     subject: 'New Contact Request',
  //     email: contactFormValue.email,
  //     recipient: contactFormValue.fullname,
  //     href: 'https://startingpointzone.herokuapp.com/startingpointzone/home',
  //     body: 'We Acknowledge the Receipt of your mentoring request. The Admin will review and get back to you Shortly. Kindly log on and check.',
  //   });
  //   let adminMail = this.backend.sendMail({
  //     subject: `New message from ${
  //       contactFormValue.fullname + ' (' + contactFormValue.email + ')'
  //     }`,
  //     email: environment.mail.spzEmail,
  //     recipient: environment.mail.recipient,
  //     href: 'https://startingpointzone.herokuapp.com/startingpointzone/home',
  //     body: `${contactFormValue.message}`,
  //   });
  //   let observer = forkJoin([userMail, adminMail]).subscribe({
  //     next: (response) => {
  //       this.isLoading = false;
  //       this.contactUsFormGroup.patchValue({
  //         fullname: '',
  //         email: '',
  //         message: '',
  //       });
  //       if (response[0].code == '000') {
  //         this.observers.push(
  //           this.appMaterialDesignModule
  //             .showAlertToaster(
  //               AlertType.success,
  //               'We acknowledge the receipt of you message. We will contact you as soon as possible.',
  //               3000
  //             )
  //             .subscribe()
  //         );
  //       } else {
  //         this.observers.push(
  //           this.appMaterialDesignModule
  //             .showAlertToaster(
  //               AlertType.error,
  //               "We are sorry we couldn't get. Please try to reach us again",
  //               3000
  //             )
  //             .subscribe()
  //         );
  //       }
  //     },
  //   });

  //   this.observers.push(observer);
  // }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckEnd) {
        //console.log('GuardsCheckEnd:', event);
        this.isAuthenticated = this.localAuthService.isAuthenticated();
        this.localAuthService.getAuthUser().subscribe((user) => {
          this.authUser = user;
        });
        // console.log('appComponent isAuthenticated ' + JSON.stringify(this.authUser));
      }
    });
    this.getNyNewsArticle();
  }

  getNyNewsArticle() {
    this.isLoadingArticles = true;
    const sections = [
      'arts',
      'books/review',
      'science',
      'technology',
      'theater',
    ];
    this.backend
      .getnytimes_topstories(sections[Math.floor(Math.random()*sections.length)])
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          this.isLoadingArticles = false;
          if ('OK' == response['status']) {
            const results = response['results'];
            const behavouir = new BehaviorSubject<any>(results);
            this.newsArticles = behavouir.asObservable();
            behavouir.next(results.slice(0,6));
          }
        },
      });
  }
}
