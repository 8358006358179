<div style="background-color: #f9f9f9;">
    <div class="container-fluid bg-white mb-3">
        <div class="d-flex justify-content-between align-items-center pt-3">
            <h1>Events and sessions</h1>
            <div class="d-flex justify-content-end align-items-center">
                <div class="nav-link" [matMenuTriggerFor]="menu">
                    <img src="{{authUser.image}}" height="36" width="36"
                        style="border-radius: 50%; padding: 5px; object-fit: cover;">
                    <!-- <i class="fa fa-user-circle-o" style="font-size: 24px !important; color: #00213D;"></i> -->
                </div>
                <mat-menu #menu="matMenu" class="spz-profile-menu">
                    <div matRipple routerLink="/profile" class="media p-3">
                        <img src="{{authUser.image}}" class="align-self-center mr-2 profile-image-small"
                            alt="{{authUser.fullname}}">
                        <div class="media-body">
                            <h6 class="mt-0 mb-0">{{authUser.fullname}}</h6>
                            <p class="text-muted p-0 m-0" style="font-size: 14px;">View public profile</p>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div matRipple routerLink="/user/bookings" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-clock-o color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">Bookings</p>
                            <small class="text-muted p-0 m-0">View and manage all your bookings</small>
                        </div>
                    </div>
                    <div matRipple routerLink="/user/connections" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-users color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">My connections</p>
                            <small class="text-muted p-0 m-0">Manage your connections</small>
                        </div>
                    </div>
                    <div matRipple routerLink="/user/calendar" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-calendar-check-o color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">Calendar</p>
                            <small class="text-muted p-0 m-0">Setup your availability</small>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="p-3">
                        <div matRipple routerLink="/settings" class="d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-cog color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Settings</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>

                        <div matRipple class="d-flex justify-content-between align-items-center">
                            <div routerLink="/support" class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-life-ring color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Support</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>

                        <div matRipple class="d-flex justify-content-between align-items-center">
                            <div (click)="signout()" class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-sign-out color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Logout</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>
                    </div>
                    <!-- <button mat-menu-item>Item 1</button> -->

                    <!-- <button mat-menu-item>Item 2</button> -->
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-3 mb-3">
                <div class="p-3 rounded bg-white">
                    <p>Attending</p>
                    <div *ngIf="attendees.length > 0">
                        <div *ngFor="let attendee of attendees" class="d-flex justify-content-start align-items-center mb-2">
                            <img src="{{attendee.user.image}}" class="img-fluid img-thumbnail" style="height: 40px; width: 40px; border-radius: 50%;" />
                            <p class="ml-2 text-muted m-0 p-0">{{attendee.user.username}}</p>
                        </div>
                    </div>
                    <div *ngIf="attendees.length < 1" [hidden]="isLoading">
                        <p>No attendees yet.</p>
                        <button class="btn btn-sm btn-outline-primary mt-2" (click)="interested(event)">
                            <i [ngClass]="eventDetails.attendees?.includes(authUser.id) ? 'fa fa-star fa-fw mr-1' : 'fa fa-star-o fa-fw mr-1'"></i>Interested</button>
                    </div>
                    <div class="d-flex justify-content-center mt-3 mb-3">
                        <mat-spinner [hidden]="!isLoadingAttendees" class="align-self-center" mode="indeterminate"
                            [diameter]="20" [strokeWidth]="2">
                        </mat-spinner>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="p-3 rounded bg-white">
                    <p>About this event</p>
                    <img src="{{eventDetails.posterUrl}}" class="img-fluid img-thumbnail mb-2"/>
                    <p class="m-0 p-0">{{eventDetails.title}}</p>
                    <small class="text-muted">{{eventDetails.description}}</small>
                    <div class="d-flex justify-content-start align-items-center mt-2 mb-2">
                        <i class="fa fa-map-marker fa-fw mr-1" style="font-size: 16px;"></i>
                        <p class="m-0 p-0 text-muted">{{eventDetails.location}}</p>
                    </div>
                    <div class="d-flex justify-content-start align-items-center mb-2">
                        <i class="fa fa-calendar-o fa-fw mr-1" style="font-size: 16px;"></i>
                        <p class="m-0 p-0 text-muted">{{eventDetails.startDateAndTime | date}}-{{eventDetails.endDateAndTime | date}}</p>
                    </div>
                    <div class="d-flex justify-content-start align-items-center mb-2">
                        <i class="fa fa-user-circle-o fa-fw mr-1" style="font-size: 16px;"></i>
                        <p class="m-0 p-0 text-muted">{{eventDetails.host}}</p>
                    </div>
                    <div class="d-flex justify-content-start align-items-center mb-2">
                        <i class="fa fa-filter fa-fw mr-1" style="font-size: 16px;"></i>
                        <p class="m-0 p-0 text-muted">{{eventDetails.category}}</p>
                    </div>
                    <small class="text-muted mb-2">{{eventDetails.tags}}</small>
                    <p style="font-size: 14px;" class="text-muted mb-2"><a href="{{eventDetails.ticketUrl}}">Get ticket</a></p>
                </div>
            </div>
            <div class="col-md-3 order-md-1">
                <div class="bg-white p-3 rounded">
                    <p class="title-text">
                        Find Events</p>
                    <!-- <mat-divider></mat-divider> -->
                    <div class="row">
                        <div class="col-md-6 mb-2 col-sm-6 col-xs-6">
                            <div style="padding: 10px;border-radius: 8px; background-color: #3578E5; color: white;">
                                <i class="fa fa-sun-o fa-2x mt-2"></i>
                                <p class="title-text mt-2" style="color: white !important;">Today</p>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2 col-sm-6 col-xs-6">
                            <div
                                style="padding: 10px;border-radius: 8px; background-color: rgb(231, 222, 53); color: white;">
                                <i class="fa fa-moon-o fa-2x mt-2"></i>
                                <p class="title-text mt-2" style="color: white !important;">Tomorrow</p>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2 col-sm-6 col-xs-6">
                            <div
                                style="padding: 10px;border-radius: 8px; background-color: rgb(201, 83, 171); color: white;">
                                <i class="fa fa-calendar fa-2x mt-2"></i>
                                <p class="title-text mt-2" style="color: white !important;">This Week</p>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2 col-sm-6 col-xs-6">
                            <div style="padding: 10px;border-radius: 8px; background-color:#d5d5d5; color: white;">
                                <i class="fa fa-calendar-o fa-2x mt-2"></i>
                                <p class="title-text mt-2" style="color: white !important;">Choose Date</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>