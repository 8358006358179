import { UploadFile } from './upload-file';

export class Event {
    public title: string;
    public description: string;
    public startDateAndTime: string;
    public endDateAndTime: string;
    public posterUrl: string;
    public id: string;
    public createdAt: number;
    public updatedAt: number;
    public postedBy: string;
    public category: string;
    public host: string;
    public location: string;
    public ticketUrl: string;
    public tags: string;
    public occurance: string
    public attendees:string[]
    public isLoading = false;

    public imageFiles: Array<UploadFile>;

    constructor() {
        this.imageFiles = new Array<UploadFile>();
    }
}
