<div style="background-color: #f9f9f9;" class="h-100">
    <div class="container-fluid bg-white mb-3">
        <div class="d-flex justify-content-between align-items-center pt-3">
            <h1>My Goals and action plans</h1>
            <div class="d-flex justify-content-end align-items-center">
                <!-- <div class="nav-link" (click)="signout()">
            <i class="fa fa-sign-out" style="font-size: 18px !important; color: #00213D;"></i>
            <span>Sign out</span>
        </div> -->
                <div class="nav-link" [matMenuTriggerFor]="menu">
                    <img src="{{authUser.image}}" height="36" width="36"
                        style="border-radius: 50%; padding: 5px; object-fit: cover;">
                    <!-- <i class="fa fa-user-circle-o" style="font-size: 24px !important; color: #00213D;"></i> -->
                </div>
                <mat-menu #menu="matMenu" class="spz-profile-menu">
                    <div matRipple routerLink="/profile" class="media p-3">
                        <img src="{{authUser.image}}" class="align-self-center mr-2 profile-image-small"
                            alt="{{authUser.fullname}}">
                        <div class="media-body">
                            <h6 class="mt-0 mb-0">{{authUser.fullname}}</h6>
                            <p class="text-muted p-0 m-0" style="font-size: 14px;">View public profile</p>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div matRipple routerLink="/user/bookings" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-clock-o color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">Bookings</p>
                            <small class="text-muted p-0 m-0">View and manage all your bookings</small>
                        </div>
                    </div>
                    <div matRipple routerLink="/user/connections" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-users color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">My connections</p>
                            <small class="text-muted p-0 m-0">Manage your connections</small>
                        </div>
                    </div>
                    <div matRipple routerLink="/user/calendar" class="media p-3">
                        <div class="align-self-center mr-2">
                            <i class="fa fa-calendar-check-o color-spz-grey" style="font-size: 28px !important;"></i>
                        </div>
                        <div class="media-body">
                            <p style="font-size: 14px;" class="mt-0 mb-0">Calendar</p>
                            <small class="text-muted p-0 m-0">Setup your availability</small>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="p-3">
                        <div matRipple routerLink="/settings" class="d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-cog color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Settings</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>

                        <div matRipple class="d-flex justify-content-between align-items-center">
                            <div routerLink="/support" class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-life-ring color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Support</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>

                        <div matRipple class="d-flex justify-content-between align-items-center">
                            <div (click)="signout()" class="d-flex justify-content-start align-items-center">
                                <i class="fa fa-sign-out color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                <p class="mt-3" style="font-size: 14px;">Logout</p>
                            </div>
                            <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                        </div>
                    </div>
                    <!-- <button mat-menu-item>Item 1</button> -->

                    <!-- <button mat-menu-item>Item 2</button> -->
                </mat-menu>
            </div>
        </div>
        <!-- <hr> -->
    </div>
    <div class="container">
        <a class="btn spz-button-outline-green mb-3" style="font-size: 15px;" routerLink="/goals/add">Set up a
            goal</a>
        <div class="row">
            <div *ngFor="let goal of goals" class="col-md-4 mb-3">
                <div class="rounded goal-card p-2 bg-white">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="media" (click)="goalDetails(goal)">
                            <img height="30" width="30" src="{{goal.progress==100?'/assets/icons/check-green.png':'/assets/icons/check-grey.png'}}"
                                class="align-self-center mr-3 img-fluid" alt="check-grey">
                            <div class="media-body">
                                <h5 (click)="goalDetails(goal)" class="mt-0 mb-0" style="font-size: 16px;">{{goal.title}}</h5>
                                <p (click)="goalDetails(goal)" style="max-height: 55px; overflow: hidden;"
                                    class="subtitle-text m-0 mb-2 p-0">
                                    {{goal.description
                                    | truncateText:120}}
                                </p>
                            </div>
                        </div>
                        <div>
                            <button class="justify-content-end" mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <a class="link" routerLink="/goals/edit/{{goal.id}}" mat-menu-item>
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </a>
                                <button (click)="deleteGoal(goal)" mat-menu-item>
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <small class="text-muted">{{goal.category}}</small>
                    <mat-divider class="mt-2 mb-2"></mat-divider>
                    <div (click)="goalDetails(goal)" class="d-flex justify-content-between align-items-center">
                        <div class="bg-spz-grey-2 p-1 rounded">
                            <svg class="icon-stopwatch small dark">
                                <use xlink:href="/assets/icons/icons-sheet.svg#icon-stopwatch">
                                </use>
                            </svg>
                            <p style="display: inline;" class="subtitle-text mr-2">{{goal.duration | date}}</p>
                        </div>
                        <div class="bg-spz-grey-2 p-1 rounded">
                            <svg class="icon-meter small dark">
                                <use xlink:href="/assets/icons/icons-sheet.svg#icon-meter">
                                </use>
                            </svg>
                            <p style="display: inline;" class="subtitle-text mr-2">{{goal.progress}}%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="goals.length < 1" [hidden]="isLoading">
                <p>No goals yet. click <a routerLink="/goals/add">here</a> to setup your goals</p>
            </div>
            <div class="col-md-12 d-flex justify-content-center mt-3">
                <mat-spinner [hidden]="!isLoading" class="align-self-center" mode="indeterminate" [diameter]="35"
                    [strokeWidth]="2">
                </mat-spinner>
            </div>
        </div>
    </div>
</div>