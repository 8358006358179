// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mail:{
    spzEmail:'spzdev19@gmail.com',
    recipient:'Starting Point Zone'
  },
  spz:{
    pages:{
      signup_with_referralcode:'https://startingpointzone.up.railway.app/startingpointzone/signup?ac=referral'
    }
  },
  captchaConfigs: {
    siteKey: '6Ld4us8UAAAAADg8c9nL_V9kWXvEJVLuOwe_1jct',
    theme: 'light',
    size: 'normal',
    lang: 'en',
    type: 'image',
    useGlobalDomain: false,
    verifyUserResponseUrl:'https://www.google.com/recaptcha/api/siteverify',
    secretKey:'6Ld4us8UAAAAAPdW0lLR4aW92rOxIcc82E76lSbB',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
