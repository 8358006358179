<div [ngClass]="sidebarToggle == 'on' ? 'page-wrapper chiller-theme toggled' : 'page-wrapper chiller-theme'">
    <div id="show-sidebar" class="btn btn-sm btn-dark" (click)="toggleSidebar()">
        <i class="fa fa-bars" aria-hidden="true"></i>
    </div>
    <nav id="sidebar" class="sidebar-wrapper">
        <div class="sidebar-content">
            <div class="sidebar-brand p-4">
                <a href="/startingpointzone/home">
                    <img alt="temp image" src="/assets/img/spz-logo.svg" class="img-fluid">
                </a>
                <div id="close-sidebar" (click)="toggleSidebar()">
                    <i class="fa fa-times-circle-o" aria-hidden="true"></i>
                </div>
            </div>
            <div class="sidebar-menu">
                <ul class="mt-3">
                    <li class="mb-2">
                        <a routerLink="/home">
                            <i class="fa fa-home color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Home</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/admin/users">
                            <i class="fa fa-users color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Users</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/admin/engagements/mentoring-requests">
                            <i class="fa fa-share-square-o color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Mentoring Requests</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/admin/engagements/mentors">
                            <i class="fa fa-handshake-o color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Mentor</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/admin/questionnaire">
                            <i class="fa fa-question-circle color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Questionnaire</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/admin/questionnaire/setup">
                            <i class="fa fa-cogs color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Setup questionnaire</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/admin/invitations">
                            <i class="fa fa-ticket color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Invitations</span>
                        </a>
                    </li>
                    
                    <li class="mb-2">
                        <a routerLink="/admin/polls">
                            <i class="fa fa-thumbs-o-up color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Polls</span>
                        </a>
                    </li>
                    <li class="mb-2">
                        <a routerLink="/admin/messages">
                            <i class="fa fa-comments color-spz-green" style="font-size: 24px !important;"></i>
                            <span>Messages</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- sidebar-menu  -->
        </div>
        <!-- sidebar-content  -->
        <div class="sidebar-footer">
            <!-- <a href="#">
              <i class="fa fa-bell"></i>
              <span class="badge badge-pill badge-warning notification">3</span>
            </a>
            <a href="#">
              <i class="fa fa-envelope"></i>
              <span class="badge badge-pill badge-success notification">7</span>
            </a>
            <a href="#">
              <i class="fa fa-cog"></i>
              <span class="badge-sonar"></span>
            </a> -->
            <a href="#" (click)="signout()">
                Sign out
                <span><i class="fa fa-power-off"></i></span>
            </a>
        </div>
    </nav>
    <!-- sidebar-wrapper  -->
    <main class="page-content bg-white">
        <div class="container-fluid p-0 pr-3 pl-3">
            <div class="d-flex justify-content-between align-items-center">
                <h1>Dashboard</h1>
                <!-- <div class="d-flex  justify-content-between">
                    <a class="p-2 nav-link" routerLink="/home" routerLinkActive="sidenav-menu-active">Home</a>
                    <a class="p-2 nav-link" routerLink="/spz-fellowship" routerLinkActive="sidenav-menu-active">SPZ
                        Fellowship</a>
                    <a class="p-2 nav-link" routerLink="/inspirations" routerLinkActive="sidenav-menu-active">Find
                        inspiration</a>
                </div> -->
                <div class="d-flex justify-content-end align-items-center">
                    <!-- <div class="nav-link" (click)="signout()">
                        <i class="fa fa-sign-out" style="font-size: 18px !important; color: #00213D;"></i>
                        <span>Sign out</span>
                    </div> -->
                    <div class="nav-link" [matMenuTriggerFor]="menu">
                        <img src="{{authUser.image}}" height="36" width="36"
                            style="border-radius: 50%; padding: 5px; object-fit: cover;">
                        <!-- <i class="fa fa-user-circle-o" style="font-size: 24px !important; color: #00213D;"></i> -->
                    </div>
                    <mat-menu #menu="matMenu" class="spz-profile-menu">
                        <div matRipple routerLink="/profile" class="media p-3">
                            <img src="{{authUser.image}}" class="align-self-center mr-2 profile-image-small"
                                alt="{{authUser.fullname}}">
                            <div class="media-body">
                                <h6 class="mt-0 mb-0">{{authUser.fullname}}</h6>
                                <p class="text-muted p-0 m-0" style="font-size: 14px;">View public profile</p>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div matRipple routerLink="/user/bookings" class="media p-3">
                            <div class="align-self-center mr-2">
                                <i class="fa fa-clock-o color-spz-grey" style="font-size: 28px !important;"></i>
                            </div>
                            <div class="media-body">
                                <p style="font-size: 14px;" class="mt-0 mb-0">Bookings</p>
                                <small class="text-muted p-0 m-0">View and manage all your bookings</small>
                            </div>
                        </div>
                        <div matRipple routerLink="/user/connections" class="media p-3">
                            <div class="align-self-center mr-2">
                                <i class="fa fa-users color-spz-grey" style="font-size: 28px !important;"></i>
                            </div>
                            <div class="media-body">
                                <p style="font-size: 14px;" class="mt-0 mb-0">My connections</p>
                                <small class="text-muted p-0 m-0">Manage your connections</small>
                            </div>
                        </div>
                        <div matRipple routerLink="/user/calendar" class="media p-3">
                            <div class="align-self-center mr-2">
                                <i class="fa fa-calendar-check-o color-spz-grey"
                                    style="font-size: 28px !important;"></i>
                            </div>
                            <div class="media-body">
                                <p style="font-size: 14px;" class="mt-0 mb-0">Calendar</p>
                                <small class="text-muted p-0 m-0">Setup your availability</small>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="p-3">
                            <div matRipple routerLink="/settings" class="d-flex justify-content-between align-items-center">
                                <div class="d-flex justify-content-start align-items-center">
                                    <i class="fa fa-cog color-spz-grey mr-2" style="font-size: 24px !important;"></i>
                                    <p class="mt-3" style="font-size: 14px;">Settings</p>
                                </div>
                                <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                            </div>

                            <div matRipple class="d-flex justify-content-between align-items-center">
                                <div routerLink="/support" class="d-flex justify-content-start align-items-center">
                                    <i class="fa fa-life-ring color-spz-grey mr-2"
                                        style="font-size: 24px !important;"></i>
                                    <p class="mt-3" style="font-size: 14px;">Support</p>
                                </div>
                                <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                            </div>

                            <div matRipple class="d-flex justify-content-between align-items-center">
                                <div (click)="signout()" class="d-flex justify-content-start align-items-center">
                                    <i class="fa fa-sign-out color-spz-grey mr-2"
                                        style="font-size: 24px !important;"></i>
                                    <p class="mt-3" style="font-size: 14px;">Logout</p>
                                </div>
                                <i class="fa fa-chevron-right color-spz-grey" style="font-size: 15px !important;"></i>
                            </div>
                        </div>
                        <!-- <button mat-menu-item>Item 1</button> -->

                        <!-- <button mat-menu-item>Item 2</button> -->
                    </mat-menu>
                </div>
            </div>
            <hr>
            <router-outlet></router-outlet>
        </div>
    </main>
    <!-- page-content" -->
</div>