import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmEmailDialogComponent } from './components/confirm-email-dialog/confirm-email-dialog.component';
import { FilePickerDialogComponent } from './components/file-picker-dialog/file-picker-dialog.component';
import { FileuploadDialogComponent } from './components/fileupload-dialog/fileupload-dialog.component';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import { MaterialProgressDialogComponent } from './components/material-progress-dialog/material-progress-dialog.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { EventDetailsComponent } from './pages/event-details/event-details.component';
import { EventsComponent } from './pages/events/events.component';
import { SetupEventComponent } from './pages/events/setup-event/setup.events.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { MembershipComponent } from './pages/membership/membership.component';
import { MembershipQuestionnaireComponent } from './pages/membership/membership-questionnaire/membership.questionnaire.component';
import { GoalDetailsComponent } from './pages/goal-details/goal-details.component';
import { GoalsComponent } from './pages/goals/goals.component';
import { SetupGoalComponent } from './pages/goals/setup-goal/setup-goals.component';
import { ActionPlanComponent } from './pages/action-plan/action-plan.component';
import { SetupActionPlanComponent } from './pages/action-plan/setup-action-plan.component';
import { InspirationsComponent } from './pages/inspirations/inspirations.component';
import { SetupInspirationsComponent } from './pages/inspirations/setup-inspiration/setup-inspirations.component';
import { RequestMentorComponent } from './pages/mentoring-engagement/request-mentor/request-mentor.component';
import { MentoringEngagementComponent } from './pages/mentoring-engagement/mentoring-engagement.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { MentorDetailsComponent } from './pages/mentoring-engagement/mentor-details/mentor.details.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProfileDetailsComponent } from './pages/profile/profile-details/profile-details.component';
import { BecomeMentorComponent } from './pages/mentoring-engagement/become-mentor/become-mentor.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  //{ path: '**', redirectTo: '' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },

  { path: 'events', component: EventsComponent, canActivate: [AuthGuard] },
  {
    path: 'events/add',
    component: SetupEventComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'events/edit/:id',
    component: SetupEventComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'events/:id',
    component: EventDetailsComponent,
    canActivate: [AuthGuard],
  },

  { path: 'goals', component: GoalsComponent, canActivate: [AuthGuard] },
  {
    path: 'goals/add',
    component: SetupGoalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'goals/edit/:id',
    component: SetupGoalComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'goals/:id',
    component: GoalDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'goals/details',
    component: GoalDetailsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'inspirations',
    component: InspirationsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'membership',
    component: MembershipComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'membership/questionnaire',
    component: MembershipQuestionnaireComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'engagements',
    component: MentoringEngagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'engagements/request-mentor',
    component: RequestMentorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'engagements/sessions',
    component: EventsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'engagements/request-mentor/:id',
    component: RequestMentorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'engagements/mentor/:id',
    component: MentorDetailsComponent,
    canActivate: [AuthGuard],
  },

  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'profile/verify', component: ProfileComponent },
  {
    path: 'users/profile/:id',
    component: ProfileDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile/edit',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },

  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const AppRoutingComponents = [
  InputDialogComponent,
  ConfirmDialogComponent,
  ConfirmEmailDialogComponent,
  AlertDialogComponent,
  FileuploadDialogComponent,
  FilePickerDialogComponent,
  MaterialProgressDialogComponent,
  HomeComponent,
  DashboardComponent,
  LoginComponent,
  SignupComponent,
  SetupEventComponent,
  EventDetailsComponent,
  EventsComponent,
  SideNavComponent,
  MembershipComponent,
  MembershipQuestionnaireComponent,
  GoalDetailsComponent,
  GoalsComponent,
  SetupGoalComponent,
  ActionPlanComponent,
  SetupActionPlanComponent,
  InspirationsComponent,
  SetupInspirationsComponent,
  RequestMentorComponent,
  MentoringEngagementComponent,
  ResetPasswordComponent,
  MentorDetailsComponent,
  ProfileComponent,
  ProfileDetailsComponent,
  BecomeMentorComponent,
  PageNotFoundComponent,
];
