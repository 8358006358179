import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Event } from 'src/app/models/event';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AppMaterialDesignModule } from '../../app-material-design.module';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { DialogButton } from '../../services/dialog.service';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { AlertType } from 'src/app/services/alert.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit, OnDestroy {

  public eventFormData: Event = new Event();
  public events = new Array<Event>();
  eventCategories: Array<string> = new Array<string>();
  public eventDetails: Event;
  private observers: Array<Subscription> = new Array<Subscription>();
  private isEventDetails: boolean;
  private unSubscriptioNotifier = new Subject();
  private category = '0';
  authUser = null;
  isLoading = false;
  public isEdited: boolean = false;

  //@ViewChild('progressRipple') progressRipple: ElementRef;

  constructor(private localAuthService: LocalAuthService,
    private backend: BackendService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private dataSource: DataService,
    private appComponent: AppComponent,
    public appMaterialDesignModule: AppMaterialDesignModule) {
    this.eventDetails = new Event();
    this.isEventDetails = false;
  }

  ngOnInit() {
    this.localAuthService.getAuthUser()
    .pipe(take(1))
    .subscribe(data => {
      this.authUser = data;
    });
    this.activateRoute.queryParams
      .subscribe(params => {
        this.category = params.category || 'all';
        console.log('category ' + this.category);
      });

    //this.eventFormData.host = this.authUser.fullname;
    this.getEvents();
  }

  public getEvents() {
    this.isLoading = true;
    forkJoin([this.backend.getEventCategories(), this.backend.getEvents(this.category)])
    .pipe(takeUntil(this.unSubscriptioNotifier))
    .subscribe({
      next: (response: any) => {
        if (response[1].code === '000') {
          this.events = response[1].data;
        }
        if (response[0].code === '000') {
          this.eventCategories = response[0].data
        }
      },
      error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
          const ob = this.appMaterialDesignModule.showAlertToaster(AlertType.error, err.error, 3).subscribe();
          this.observers.push(ob);
        }
      }, complete: () => {
        console.log('on complete getEvents');
        this.isLoading = false;
      }
    });
  }

  setEventDetails(event: Event): void {
    this.isEventDetails = false;
    this.eventDetails = event;
    this.isEventDetails = true;
    console.log(JSON.stringify(this.eventDetails));
  }

  public deleteEvent(event: Event) {
    console.log('event to delete : ' + JSON.stringify(event))
    this.appMaterialDesignModule.openDialog(ConfirmDialogComponent, {
      width: '400px',
      title: 'Caution',
      message: 'Are you sure you want to this event?'

    }).pipe(
      take(1),
      switchMap((result) => {
      if (result.button === DialogButton.ok) {
        this.isLoading = true;
        this.appMaterialDesignModule.showProgressDialog('Deleting...');
        return this.backend.deleteEvent(event);
      }
    })).subscribe({
      next: (response: any) => {
        if (response.code === '000') {
          var index = this.events.indexOf(event);
          this.events.splice(index, 1);
        }
        this.isLoading = false;
        this.appMaterialDesignModule.hideProgressDialog();
      }, error: (err: any) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          console.log('An error occurred:', err.error.message);
        } else {
          // Backend returns unsuccessful response codes such as 404, 500 etc.
          console.log('Backend returned status code: ', err.status);
          console.log('Response body:', err.error);
          const ob = this.appMaterialDesignModule.showAlertToaster(AlertType.error, err.error, 3).subscribe();
          this.observers.push(ob);
        }
        this.isLoading = false;
      }, complete: () => {
        console.log('on complete delete event');
        this.isLoading = false;
      }
    });
  }
  public interested(event:Event) {
    //so here get the index of the  event in array
    const index = this.events.indexOf(event);
    //check if userid is in event.attendees
   const attendeeIndex = event.attendees.indexOf(this.authUser.id);
    if(attendeeIndex < 0){
      //add to event.attendees
      event.attendees.push(this.authUser.id);
      this.events.splice(index,1,event);
      //call backend to add attendee to event
      this.backend.addEventAttendee({user:this.authUser.id},event.id)
      .pipe(take(1))
      .subscribe({
        next:(response)=>{

        }
      })
    }else{
      //remove from event.attendees
      event.attendees.splice(attendeeIndex);
      this.events.splice(index,1,event);
      //call backend to remove attendee from event
      this.backend.deleteEventAttendee({user:this.authUser.id},event.id)
      .pipe(take(1))
      .subscribe({
        next:(response)=>{

        }
      })
    }
  }

  public signout(): void {
    if (this.localAuthService.logout()) {
      this.localAuthService
        .signOut()
        .then(
          (onfulfilled) => {
            console.log('onfulfilled... ' + onfulfilled);
          },
          (onrejected) => {
            console.log('onrejected... ' + onrejected);
          }
        )
        .catch((onrejected) => {
          console.log('catch onrejected... ' + onrejected);
        })
        .finally(() => {
          console.log('onfinally...');
          this.router.navigate(['/login']);
        });
    }
  }

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next('');
    this.unSubscriptioNotifier.complete();
  }
}


