import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AppMaterialDesignModule } from 'src/app/app-material-design.module';
import { LocalAuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-experience-and-employment-info',
  templateUrl: './experience-and-employment-info.component.html',
  styleUrls: ['./experience-and-employment-info.component.css'],
})
export class ExperienceAndEmploymentInfoComponent implements OnInit, OnDestroy {
  @Input('employmentInfo') employmentInfo: any[];
  @Input('skillsInfo') skillsInfo: any[];
  private unSubscriptioNotifier = new Subject();
  public employmentInfoFormGroup: FormGroup;
  public page = false;
  public page2 = false;
  public isLoading = false;
  private authUser = null;
  public isEdited = false;
  public isUserEmploymentFormCollapsed = false;
  public collapsedUserSkills = false;
  public userSkillsFormGroup: FormGroup;
  constructor(
    private backend: BackendService,
    private localAuthService: LocalAuthService,
    private dataSource: DataService,
    public appMaterialModule: AppMaterialDesignModule
  ) {
    this.authUser = localAuthService.getUser();

    this.employmentInfoFormGroup = new FormGroup({
      id: new FormControl(''),
      title: new FormControl(''),
      workplace: new FormControl(''),
      description: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      isCurrentEmployment: new FormControl(''),
      user: new FormControl(this.authUser.id),
    });

    this.userSkillsFormGroup = new FormGroup({
      id: new FormControl(''),
      title: new FormControl(''),
      description: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      user: new FormControl(this.authUser.id),
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unSubscriptioNotifier.next('');
    this.unSubscriptioNotifier.complete();
  }

  public addEmploymentInfo() {
    this.togglePage();
    this.isEdited = false;
    this.employmentInfoFormGroup = new FormGroup({
      id: new FormControl(''),
      title: new FormControl(''),
      workplace: new FormControl(''),
      description: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      isCurrentEmployment: new FormControl(''),
      user: new FormControl(this.authUser.id),
    });
  }

  public editEmploymentInfo(employmentInfo) {
    this.togglePage();
    //console.log(JSON.stringify(socialMediaAccount));
    this.isEdited = true;
    this.employmentInfoFormGroup.patchValue({
      id: employmentInfo.id,
      title: employmentInfo.title,
      workplace: employmentInfo.workplace,
      description: employmentInfo.description,
      startDate: employmentInfo.startDate,
      endDate: employmentInfo.endDate,
      isCurrentEmployment: employmentInfo.isCurrentEmployment,
      user: this.authUser.id,
    });
  }

  addUserSkills() {
    this.togglePage2();
    this.isEdited = false;
    this.userSkillsFormGroup = new FormGroup({
      id: new FormControl(''),
      title: new FormControl(''),
      description: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      user: new FormControl(this.authUser.id),
    });
  }

  editUserSkills(skillInfo) {
    this.togglePage2();
    this.isEdited = true;
    this.userSkillsFormGroup.patchValue({
      id: skillInfo.id,
      title: skillInfo.title,
      description: skillInfo.description,
      fromDate: skillInfo.fromDate,
      toDate: skillInfo.toDate,
      user: skillInfo.user,
    });
  }

  public onSaveUserEmployment(): void {
    this.isLoading = true;
    let userEmploymentFormData = this.employmentInfoFormGroup.value;
    console.log('isEdited ' + this.isEdited);
    if (this.isEdited) {
      this.backend
        .updateUserEmployment(userEmploymentFormData)
        .pipe(take(1))
        .subscribe({
          next: (response: any) => {
            var temp = this.employmentInfo.find((item) => {
              return item.id == userEmploymentFormData.id;
            });
            this.employmentInfo.splice(this.employmentInfo.indexOf(temp), 1);
            this.employmentInfo.unshift(response.data);
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isEdited = false;
            this.employmentInfoFormGroup.reset();
          },
          complete: () => {
            this.togglePage();
            this.isLoading = false;
            this.isUserEmploymentFormCollapsed = true;
            this.isEdited = false;
            this.employmentInfoFormGroup.reset();
            console.log('on complete updateUserEmployment');
          },
        });
    } else {
      console.log(
        'userEmploymentFormData ' + JSON.stringify(userEmploymentFormData)
      );
      this.backend
        .createUserEmployment(userEmploymentFormData)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: (response: any) => {
            this.employmentInfo.unshift(response.data);
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isUserEmploymentFormCollapsed = true;
            this.employmentInfoFormGroup.reset();
          },
          complete: () => {
            this.togglePage();
            this.isLoading = false;
            this.isUserEmploymentFormCollapsed = true;
            this.employmentInfoFormGroup.reset();
            console.log('on complete createUserEmployment');
          },
        });
    }
  }

  public onSaveUserSkills(): void {
    this.isLoading = true;
    let userSkillFormData = this.userSkillsFormGroup.value;
    console.log(JSON.stringify(userSkillFormData));
    console.log('isEdited ' + this.isEdited);
    if (this.isEdited) {
      this.backend
        .updateUserSkill(userSkillFormData)
        .pipe(take(1))
        .subscribe({
          next: (response: any) => {
            var temp = this.skillsInfo.find((item) => {
              return item.id == userSkillFormData.id;
            });
            this.skillsInfo.splice(this.skillsInfo.indexOf(temp), 1);
            this.skillsInfo.unshift(response.data);
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            this.isEdited = false;
            this.userSkillsFormGroup.reset();
          },
          complete: () => {
            this.togglePage2();
            this.isLoading = false;
            //this.isUserEmploymentFormCollapsed = true;
            this.isEdited = false;
            this.userSkillsFormGroup.reset();
            console.log('on complete updateUserSkill');
          },
        });
    } else {
      this.backend
        .createUserSkill(userSkillFormData)
        .pipe(takeUntil(this.unSubscriptioNotifier))
        .subscribe({
          next: (response: any) => {
            console.log(JSON.stringify(response));
            if (response.code == '000') {
              this.skillsInfo.unshift(response.data);
            }
          },
          error: (err: any) => {
            if (err.error instanceof Error) {
              // A client-side or network error occurred.
              console.log('An error occurred:', err.error.message);
            } else {
              // Backend returns unsuccessful response codes such as 404, 500 etc.
              console.log('Backend returned status code: ', err.status);
              console.log('Response body:', err.error);
            }
            this.isLoading = false;
            //this.isUserEmploymentFormCollapsed = true;
            this.userSkillsFormGroup.reset();
          },
          complete: () => {
            this.togglePage2();
            this.isLoading = false;
            //this.isUserEmploymentFormCollapsed = true;
            this.userSkillsFormGroup.reset();
            console.log('on complete createUserSkill');
          },
        });
    }
  }

  public onCheckboxChanged(value, event) {
    if (event.target.checked)
      // this.userEmploymentFormData.isCurrentEmployment = event.target.checked;
      this.employmentInfoFormGroup.patchValue({
        isCurrentEmployment: 'true',
      });
    else
      this.employmentInfoFormGroup.patchValue({
        isCurrentEmployment: '',
      });
  }

  onDateSelected(input: string, value: string): void {
    if (input === 'empStartDate')
      this.employmentInfoFormGroup.patchValue({
        startDate: value,
      });
    else if (input === 'empEndDate')
      this.employmentInfoFormGroup.patchValue({
        endDate: value,
      });
    else if (input === 'skillFromDate')
      this.userSkillsFormGroup.patchValue({
        fromDate: value,
      });
    else if (input === 'skillToDate')
      this.userSkillsFormGroup.patchValue({
        toDate: value,
      });
  }

  public togglePage() {
    this.page = !this.page;
  }

  public togglePage2() {
    this.page2 = !this.page2;
  }
}
